// v18

import { useCallback, useRef, useState } from 'react'

const DELAY = 600

function preventDefault(e) {
    if (!('touches' in e)) return
    if (e.touches.length < 2 && e.preventDefault) {
        e.preventDefault()
    }
}

const useLongPress = (onLongPress, onClick) => {
    const [isPressing, setIsPressing] = useState(false)
    const timeoutRef = useRef(undefined)
    const ref = useRef(undefined)

    const onStart = (e) => {
        if (e.target) {
            e.target.addEventListener('touchend', preventDefault, {
                passive: false,
            })
            ref.current = e.target
        }
        timeoutRef.current = setTimeout(() => {
            onLongPress(e)
            setIsPressing(true)
        }, DELAY)
    }

    const onClear = (e, shouldTriggerClick = true) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        if (shouldTriggerClick && !isPressing) {
            onClick()
        }
        setIsPressing(false)
        if (ref.current) {
            ref.current.removeEventListener('touchend', preventDefault)
        }
    }

    const start = useCallback(onStart, [onLongPress])
    const clear = useCallback(onClear, [onClick, isPressing])

    return {
        onMouseDown: (e) => start(e),
        onTouchStart: (e) => start(e),
        onMouseUp: (e) => clear(e),
        onMouseLeave: (e) => clear(e, false),
        onTouchEnd: (e) => clear(e),
    }
}

export default useLongPress
