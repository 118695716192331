// .acronym ok
// import useSWR from 'swr'
// import swrFetcher from '../../../common/swrFetcher'
// import ResourceList from '../../../components/admin/ResourceList'
// import useData from '../../../common/hooks/useData'
// import { useParams } from 'react-router-dom'

function AssignedDrivesList() {
    return 'Under construction'
    // const { userId } = useParams()
    // const { user, userError, userLoading } = useData(
    //     `/v1/users/${userId}?fields=drives,company,acronym`,
    //     'user',
    // )

    // const designUrl = user?.acronym
    //     ? `/v1/files/drives?name=${`${user.acronym} - DESIGN`}&exact=true`
    //     : null
    // const { data: designDrivesByName, error: designDrivesError } = useSWR(
    //     designUrl,
    //     swrFetcher,
    // )

    // const mainUrl = user?.acronym
    //     ? // TODO BUG (encode &)
    //       // ? `/v1/files/drives?name=${`${client.acronym} - ${client.company}`}&exact=true`
    //       `/v1/files/drives?name=${`${user.acronym} - ${user.company
    //           ?.replace(/&/g, '')
    //           ?.replace("'", '')}`}&exact=true`
    //     : null
    // const { data: mainDrivesByName, error: mainDrivesError } = useSWR(
    //     mainUrl,
    //     swrFetcher,
    // )

    // const loading =
    //     (user?.acronym && !designDrivesByName && !designDrivesError) ||
    //     (user?.acronym &&
    //         user?.company &&
    //         !mainDrivesByName &&
    //         !mainDrivesError) ||
    //     userLoading

    // if (userError || designDrivesError || mainDrivesError) {
    //     return (
    //         <div className="error alert">
    //             {userError || designDrivesError || mainDrivesError}
    //         </div>
    //     )
    // }

    // const allDrives = [...(user?.drives?.map((id) => ({ id })) || [])]
    // if (designDrivesByName?.length === 1) {
    //     allDrives.push({
    //         id: designDrivesByName[0]?.id,
    //         name: designDrivesByName[0]?.name,
    //         autopickedDesign: true,
    //     })
    // }
    // if (mainDrivesByName?.length === 1) {
    //     allDrives.push({
    //         id: mainDrivesByName[0]?.id,
    //         name: mainDrivesByName[0]?.name,
    //         autopickedMain: true,
    //     })
    // }

    // return (
    //     <>
    //         {!user?.acronym ||
    //             (!loading && designDrivesByName?.length !== 1 && (
    //                 <section className="error alert">
    //                     Unable to find Design drive by acronym
    //                 </section>
    //             ))}
    //         {!user?.company ||
    //             (!loading && mainDrivesByName?.length !== 1 && (
    //                 <section className="error alert">
    //                     Unable to find Main drive by acronym and company name
    //                 </section>
    //             ))}
    //         <ResourceList
    //             renderMainButton={false}
    //             items={allDrives}
    //             itemsLoading={loading}
    //             newItemPath={'/users/:userId/settings/assigned-drives/new'}
    //             itemClickPath={
    //                 '/users/:userId/settings/assigned-drives/:item.id'
    //             }
    //             fields={[
    //                 {
    //                     column: 1,
    //                     wrapperEl: 'h3',
    //                     getValue: (item) => (item.name ? `${item.name}` : ''),
    //                 },
    //                 {
    //                     column: 1,
    //                     wrapperEl: 'p',
    //                     getValue: (item) => `ID: ${item.id}`,
    //                 },
    //                 {
    //                     column: 1,
    //                     wrapperEl: 'div',
    //                     getValue: (item) =>
    //                         item.autopickedDesign && (
    //                             <span className="tag primary">
    //                                 Design (By name)
    //                             </span>
    //                         ),
    //                 },
    //                 {
    //                     column: 1,
    //                     wrapperEl: 'div',
    //                     getValue: (item) =>
    //                         item.autopickedMain && (
    //                             <span className="tag primary">
    //                                 Main (By name)
    //                             </span>
    //                         ),
    //                 },
    //             ]}
    //         />
    //     </>
    // )
}

export default AssignedDrivesList
