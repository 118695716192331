import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateServiceCategory() {
    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState({ title: '' })
    const navigate = useNavigate()
    const setToast = useToast()

    const onSubmit = async (e) => {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/services/categories`,
            {
                title: data.title,
                order: data.order,
            },
            'POST',
        )
        setActionLoading(false)
        if (error) return setError(error)
        setToast('Done')
        navigate('/service-categories')
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-title" className="required">
                        Title
                    </label>
                    <TextInput
                        type="text"
                        value={data.title}
                        id="form-title"
                        name="title"
                        required
                        onChange={(v) =>
                            setData({
                                ...data,
                                title: v,
                            })
                        }
                    />

                    <label htmlFor="form-order">Order</label>
                    <TextInput
                        type="number"
                        value={data.order}
                        id="form-order"
                        name="order"
                        onChange={(v) =>
                            setData({
                                ...data,
                                order: v,
                            })
                        }
                    />
                </div>
                <div>
                    <Button
                        text="Create"
                        isLoading={actionLoading}
                        type="submit"
                    />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreateServiceCategory
