// v18

import { useEffect, useRef, useState } from 'react'
import fetchAPI from '../fetchAPI'
import TextSkeleton from '../../components/common/TextSkeleton'
import { useStore } from '../Context'

export default function useTranslations() {
    const [state, dispatch] = useStore()
    // Initially the page is loading
    const [loading, setLoading] = useState(true)

    // const [itemsToReq, setItemsToReq] = useState({})
    const [items, setItems] = useState(state?.translationsCache || {})
    const [hasRequestedFirst, setHasRequestedFirst] = useState(false)

    const timeoutForLoadingRef = useRef(null)
    const timeoutForInitialReqRef = useRef(null)
    const itemsToReq = useRef({})

    // In any case the page stops loading in 500ms
    useEffect(function () {
        if (timeoutForLoadingRef.current) return
        timeoutForLoadingRef.current = setTimeout(async () => {
            setLoading(false)
        }, 500)
    }, [])

    // In any case, we request the keys that made it into the queue
    // in the first 100ms
    useEffect(function () {
        if (timeoutForInitialReqRef.current) return
        timeoutForInitialReqRef.current = setTimeout(async () => {
            const translatedItems = await reqTranslations(itemsToReq.current)
            // Add the received translations to the items
            setItems(function (currV) {
                const newV = { ...currV, ...translatedItems }
                return newV
            })
            dispatch({
                type: 'SET_TRANSLATIONS',
                payload: translatedItems,
            })
            // Mark the finish of the first batch request
            itemsToReq.current = {}
            setHasRequestedFirst(true)
            // Stop loading, first batch is received
            setLoading(false)
        }, 100)
    }, [])

    async function getSingleTranslation(key, defaultValue) {
        const translatedItems = await reqTranslations({
            [key]: { defaultValue },
        })
        setItems(function (currV) {
            const newV = { ...currV, ...translatedItems }
            return newV
        })
        dispatch({
            type: 'SET_TRANSLATIONS',
            payload: translatedItems,
        })
    }

    async function reqTranslations(items) {
        const url = '/v1/translations'
        const reqItems = Object.entries(items).map(
            ([key, { defaultValue }]) => ({
                key,
                defaultValue,
            }),
        )
        const body = { items: reqItems }
        const { responseData } = await fetchAPI(url, body, 'POST')
        const result = (responseData || []).reduce((a, { key, value }) => {
            a[key] = value
            return a
        }, {})
        return result
    }

    // The main `t` function used in the component, called when rendering
    function t(key, defaultValue) {
        if (items[key] || state.translationsCache[key]) {
            // We already received the translation
            return items[key] || state.translationsCache[key]
        } else if (items[key] || state.translationsCache[key] === null) {
            // Server says it doesn't exist
            return defaultValue
        } else if (itemsToReq.current[key]) {
            // The item has been requested and we're waiting
            return <TextSkeleton defaultText={defaultValue} />
        } else if (!hasRequestedFirst) {
            // The item hasn't been requested yet, add to queue
            itemsToReq.current = {
                ...itemsToReq.current,
                [key]: {
                    defaultValue,
                },
            }
            return <TextSkeleton defaultText={defaultValue} />
        } else {
            // Start request now
            itemsToReq.current = {
                ...itemsToReq.current,
                [key]: {
                    defaultValue,
                },
            }
            getSingleTranslation(key, defaultValue)
            return <TextSkeleton defaultText={defaultValue} />
        }
    }

    return { t, tLoading: loading }
}
