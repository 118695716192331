import { useEffect, useRef } from 'react'

function useOutsideClick(callback) {
    const ref = useRef(undefined)

    useEffect(
        function () {
            function handleClick(e) {
                if (!ref?.current?.contains(e.target)) {
                    callback()
                }
            }

            document.addEventListener('click', handleClick, true)

            return function () {
                document.removeEventListener('click', handleClick, true)
            }
        },
        [ref],
    )

    return ref
}

export default useOutsideClick
