import { getUserDisplayName } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import ContactCard from '../clients/ContactCard'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
    filters: {
        onCollections: {
            label: 'Collections',
            dbField: 'onCollections',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

function AssignableClientsList(props) {
    return (
        <ResourceList
            getSelectedItemText={(item) => getUserDisplayName(item)}
            apiUrl={'/users'}
            initialQuery={{
                role: 'user',
                onBlackList: false,
                sortBy: 'lastName:asc,firstName:asc,email:asc',
                fields: 'firstName,lastName,email,role,activeStatus,isBlocked,onHold,onBlackList,onCollections,billingIds',
                populate: 'orgsData',
            }}
            getItemsFromResponse={(data) => data?.results}
            filterItems={(client) => client.billingIds.length}
            filters={filterFields}
            isSelectable={true}
            preselectedIds={props?.assignedClients || []}
            onSelectedItemsChange={props.onChange}
            fields={[
                {
                    column: 1,
                    getValue: (item) =>
                        item.role === 'user' && (
                            <ContactCard user={item} summary />
                        ),
                },
            ]}
        />
    )
}

export default AssignableClientsList
