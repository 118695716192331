import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../common/fetchAPI'
import useError from '../../common/hooks/useError'
import useToast from '../../common/hooks/useToast'
import Button from '../../components/common/Button'
import ErrorMessage from '../../components/common/ErrorMessage'
import PasswordInput from '../../components/common/data-form/PasswordInput'
import SectionContainer from '../../components/common/SectionContainer'

function ResetPin() {
    const [error, setError] = useError()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ pin: '' })
    const [searchParams] = useSearchParams()
    const setToast = useToast()
    const navigate = useNavigate()

    async function onSubmit(e) {
        e.preventDefault()
        const token = searchParams.get('token')

        setError('')

        const { pin } = data

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/auth/reset-pin?token=` + token,
            { pin },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('PIN has been reset')
        navigate('/')
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <label htmlFor="form-pin">PIN</label>
                <PasswordInput
                    id="form-pin"
                    placeholder="****"
                    value={data.pin}
                    onChange={(v) =>
                        setData({
                            ...data,
                            pin: v,
                        })
                    }
                />

                <Button text="Submit" type="submit" isLoading={loading} />
            </form>
        </SectionContainer>
    )
}

export default ResetPin
