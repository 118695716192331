import { useEffect } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import useToast from '../../../common/hooks/useToast'
import { loadStripe } from '@stripe/stripe-js'
import useAuth from '../../../common/hooks/useAuth'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

const stripePromise = loadStripe(apiKey)

function DonationCompleteCallback() {
    return (
        <Elements stripe={stripePromise}>
            <DonationCompleteCallbackInner />
        </Elements>
    )
}

function DonationCompleteCallbackInner() {
    const stripe = useStripe()
    const setToast = useToast()
    const navigate = useNavigate()
    const auth = useAuth()

    const [searchParams] = useSearchParams()
    const clientSecret = searchParams.get('payment_intent_client_secret')

    useEffect(
        function () {
            if (!stripe || !clientSecret) {
                return
            }

            async function handleCallback() {
                try {
                    await auth.refetchAuth()
                    const { paymentIntent } =
                        await stripe.retrievePaymentIntent(clientSecret)
                    switch (paymentIntent.status) {
                        case 'succeeded':
                            setToast('Your donation was received. Thank you!')
                            navigate('/')
                            break
                        case 'requires_action':
                            setToast(
                                'Your payment requires additional action. Please try again.',
                                'alert',
                            )
                            navigate('/')
                            break
                        case 'requires_payment_method':
                            setToast(
                                'Failed to process payment details. Please try another payment method.',
                                'alert',
                            )
                            navigate('/')
                            break
                        case 'processing':
                        default:
                            break
                    }
                } catch (error) {
                    console.error(error)
                    setToast(
                        'There was a problem processing your payment. Please try again and if the issue persists, contact Support.',
                        'alert',
                    )
                }
            }

            handleCallback()
        },
        [stripe],
    )

    if (!clientSecret) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    There was a problem processing your payment. Please try
                    again and if the issue persists, contact Support.&nbsp;
                    <Link to="/">Back to Home.</Link>
                </ErrorMessage>
            </SectionContainer>
        )
    }

    return <Spinner />
}

export default DonationCompleteCallback
