import useAuth from '../../common/hooks/useAuth'
import TabsOverview from '../../components/navigation/TabsOverview'
import VendorPage from './vendor/VendorPage'
import ShopsList from './shops/ShopsList'
import DBOrdersList from './orders/DBOrdersList'
import AdminDBOrdersList from './orders/AdminDBOrdersList'
import ShopifyGraphQLProductsList from './products/ShopifyGraphQLProductsList'
import AdminShopifyGraphQLProductsList from './products/AdminShopifyGraphQLProductsList'
import ProductsList from './products/ProductsList'
import AllProductsList from './shop/AllProductsList'
import { CartStoreProvider } from './CartContext'
import { cartInitialState, cartReducer } from './cart-reducer'
import WishlistProductsList from '../more/wishlist/WishlistProductsList'

export default function ShopOverview({ parentPage }) {
    const auth = useAuth()

    const pages = {}
    let defaultTab

    pages.shop = <AllProductsList />

    if (auth.isAdmin || auth.isAssistant || auth.isAccountant) {
        pages.orders = <AdminDBOrdersList />
        pages.shopify_inventory = <AdminShopifyGraphQLProductsList />
        defaultTab = 'orders'
    } else if (auth.isClient) {
        // defaultTab = 'setup__'
        defaultTab = 'my_orders'
        pages.my_orders = <DBOrdersList />
        // if (auth.isWholesaleClient) {
        // }
        pages.orders = <DBOrdersList />

        pages.inventory = <ProductsList />
        pages.shopify_inventory = <ShopifyGraphQLProductsList />

        if (auth.isClient) {
            pages.vendor_info = <VendorPage />
        }
    }

    if (auth.isAdmin) {
        // pages.app_shops__ = <AdminShopifyShopsList />
        // pages.all_app_products = <AdminProductsList />
        pages.inventory = <ProductsList />
        // pages.quantity_requests = <AdminRequestedQuantities />
        // pages.all_inventory = <AdminAllInventoryItemsList />
    }

    pages.wishlist = <WishlistProductsList />

    pages.setup__ = <ShopsList />

    if (auth.isAdmin) {
        pages.all_shops = <ShopsList showAll />
    }

    return (
        <CartStoreProvider
            initialState={cartInitialState}
            reducer={cartReducer}
        >
            <TabsOverview
                defaultTab={defaultTab}
                pagesMap={pages}
                parentPage={parentPage}
            />
        </CartStoreProvider>
    )
}
