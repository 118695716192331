// .company ready
import { useState } from 'react'
import {
    getHoursMinutesBetween,
    getUserDisplayName,
} from '../../../common/helpers'
import Toggle from '../../../components/common/Toggle'
import Button from '../../../components/common/Button'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useError from '../../../common/hooks/useError'
import useDebounce from '../../../common/hooks/useDebounce'
import fetchAPI from '../../../common/fetchAPI'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useAuth from '../../../common/hooks/useAuth'
import { Link } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import LiveSearch from '../../../components/common/data-form/LiveSearch'

function isoStrTimeToLocalTime(isoStr) {
    if (!isoStr) return ''
    const date = new Date(isoStr)
    return `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
}

function isoDateStrWithLocalTime(isoDateStr, localTimeStr) {
    if (!isoDateStr || !localTimeStr) return ''
    const date = new Date(isoDateStr)
    const [hours, minutes] = localTimeStr.split(':')
    date.setHours(hours)
    date.setMinutes(minutes)

    return date.toISOString()
}

function getDefaultRangeDates(date) {
    const defaultStartDate9am = date && isoDateStrWithLocalTime(date, '09:00')
    const defaultEndDate5pm = date && isoDateStrWithLocalTime(date, '17:00')

    return {
        startDate: defaultStartDate9am,
        endDate: defaultEndDate5pm,
    }
}

function TimesheetEntryForm(props) {
    const { entry, dayStartDate } = props

    const setToast = useToast()
    const [error, setError] = useError()
    const [saveLoading, setSaveLoading] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)
    const matchMutate = useMatchMutate()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const auth = useAuth()

    const initData = entry
        ? {
              startDate: entry.startDate,
              endDate: entry.endDate,
          }
        : {
              startDate: getDefaultRangeDates(dayStartDate).startDate,
              endDate: getDefaultRangeDates(dayStartDate).endDate,
              notes: '',
              clientId: null,
              taskId: null,
          }

    const [data, setData] = useState(initData)

    useDebounce(data, entry && onDataChange, 1000)

    async function onDataChange(newData) {
        setError('')
        if (!entry) return

        if (!newData.startDate || !newData.endDate) return

        if (entry.isSplit) {
            setError(
                'You cannot edit an entry created on different timezone. Delete the entry and add it again.',
            )
            return
        }

        if (new Date(newData.startDate) >= new Date(newData.endDate)) {
            setError('Start date must be before end date.')
            return
        }

        const body = { ...newData }

        setSaveLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/timesheets/${entry.id}`,
            body,
            'PATCH',
        )
        setSaveLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Entry updated')
        matchMutate(/\/v1\/timesheets/)
        setData({
            startDate: responseData.startDate,
            endDate: responseData.endDate,
        })
    }

    async function handleFormSave(e) {
        e.preventDefault()
        setError(null)
        if (entry) return

        if (!data.clientId) {
            setError('Please select a client')
            return
        }

        if (new Date(data.startDate) >= new Date(data.endDate)) {
            setError('Start date must be before end date.')
            return
        }

        if (!data.clientId && !data.companyId) {
            setError('Please select a client or a company.')
            return
        }

        setCreateLoading(true)
        const body = { ...data }
        const { error } = await fetchAPI(`/v1/timesheets`, body, 'POST')
        setCreateLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Entry created successfully.')
        matchMutate(/\/v1\/timesheets/)
        setData({
            clientId: data.clientId,
            taskId: data.taskId,
            startDate: getDefaultRangeDates(dayStartDate).startDate,
            endDate: getDefaultRangeDates(dayStartDate).endDate,
            notes: '',
        })
    }

    function deleteEntry(entry) {
        setModal(
            <ConfirmModalContent
                onConfirm={async function () {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/timesheets/${entry.id}`,
                        {},
                        'DELETE',
                    )
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    matchMutate(/\/v1\/timesheets/)
                    setModal(null)
                    setToast('Deleted')
                }}
            />,
            'Are you sure you want to delete this entry?',
        )
    }

    if (saveLoading) {
        return <Spinner />
    }

    const hoursMinutesBetween = getHoursMinutesBetween(
        new Date(data.startDate),
        new Date(data.endDate),
    )

    return (
        <div
            className={`timesheet-entry-form${
                entry ? '' : ' timesheet-create-form'
            }`}
        >
            {error && <ErrorMessage section>{error}</ErrorMessage>}
            <form onSubmit={handleFormSave}>
                {Boolean(entry) && (
                    <Toggle title="Project" small>
                        <label>Client</label>
                        <span>{getUserDisplayName(entry.client)}</span>
                        <br />
                        <br />
                        <label>Notes</label>
                        <span>{entry.notes || 'No notes'}</span>
                        <br />
                        <br />
                        <label>Task</label>
                        <span>
                            {entry.task ? (
                                <Link
                                    target="_blank"
                                    to={`/tasks/${entry.taskId}`}
                                >
                                    {entry.task.title}&nbsp;&rarr;
                                </Link>
                            ) : (
                                'None'
                            )}
                        </span>
                    </Toggle>
                )}
                {!entry && (
                    <div className="timesheet-project-input">
                        <label htmlFor="form-client" className="required">
                            Client
                        </label>
                        <LiveSearch
                            id="form-client"
                            getResultValue={(result) =>
                                getUserDisplayName(result)
                            }
                            url="/v1/users?role=user&fields=firstName,lastName"
                            getItemsFromResults={(data) => data?.results || []}
                            onItemClick={(item) => {
                                setData({
                                    ...data,
                                    clientId: item.id,
                                })
                            }}
                        />
                    </div>
                )}
                <div>
                    <label className="required" htmlFor="form-start">
                        Start
                    </label>
                    <TextInput
                        required
                        id="form-start"
                        name="startDate"
                        type="time"
                        value={isoStrTimeToLocalTime(data.startDate)}
                        onChange={(v) =>
                            setData({
                                ...data,
                                startDate: isoDateStrWithLocalTime(
                                    entry ? data.startDate : dayStartDate,
                                    v,
                                ),
                            })
                        }
                    />
                </div>
                <div>
                    <label className="required" htmlFor="form-end">
                        End
                    </label>
                    <TextInput
                        required
                        id="form-end"
                        name="endDate"
                        type="time"
                        value={isoStrTimeToLocalTime(data.endDate)}
                        onChange={(v) =>
                            setData({
                                ...data,
                                endDate: isoDateStrWithLocalTime(
                                    entry ? data.startDate : dayStartDate,
                                    v,
                                ),
                            })
                        }
                    />
                    {data.startDate && data.endDate ? (
                        <small className="text-subdued">
                            Duration: {hoursMinutesBetween.hours}:
                            {hoursMinutesBetween.minutes}
                        </small>
                    ) : null}
                </div>
                {!entry && (
                    <div className="timesheet-project-input">
                        <label htmlFor="form-task">Task</label>
                        <LiveSearch
                            id="form-task"
                            getResultValue={(result) => result.title}
                            url={`/v1/tasks?assignedTeamMemberId=${auth.user.id}&status=accepted&target=role,team&fields=title`}
                            getItemsFromResults={(data) => data?.results || []}
                            onItemClick={(item) => {
                                setData({
                                    ...data,
                                    taskId: item.id,
                                })
                            }}
                        />
                    </div>
                )}
                {!entry && (
                    <div className="timesheet-project-input">
                        <label htmlFor="form-notes">
                            What did you work on?
                        </label>
                        <small className="input-info">
                            Please be sure to add separate times any time you
                            work on a different project or client.
                        </small>
                        <TextInput
                            type="text"
                            id="form-notes"
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    notes: v,
                                })
                            }
                            value={data.notes || ''}
                        />
                    </div>
                )}
                {!entry && (
                    <Button
                        type="submit"
                        text="Add time"
                        isLoading={createLoading}
                    />
                )}
            </form>
            {Boolean(entry) && !entry.submissionDate && (
                <Button
                    destructive
                    text="Delete"
                    tiny
                    outline
                    alignRight
                    onClick={() => deleteEntry(entry)}
                />
            )}
        </div>
    )
}

export default TimesheetEntryForm
