import { useEffect } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import useToast from '../../../common/hooks/useToast'
import { loadStripe } from '@stripe/stripe-js'
import useAuth from '../../../common/hooks/useAuth'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
// process.env.REACT_APP_CONTEXT === 'NETLIFY_DEV' ||
// process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
//     : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV

const stripePromise = loadStripe(apiKey)

function StripePremiumSubCompleteCallback() {
    return (
        <Elements stripe={stripePromise}>
            <StripePremiumSubCompleteCallbackInner />
        </Elements>
    )
}

function StripePremiumSubCompleteCallbackInner() {
    const stripe = useStripe()
    const setToast = useToast()
    const navigate = useNavigate()
    const auth = useAuth()

    const [searchParams] = useSearchParams()
    const clientSecret = searchParams.get('payment_intent_client_secret')

    async function updateUserPremiumStatus() {
        const { error } = await fetchAPI(
            `/v1/app-billing/me/refresh-premium-subscription`,
            {},
            'POST',
        )
        if (error) {
            setToast(
                'There was a problem processing your payment. Please try again and if the issue persists, contact Support.',
                'alert',
            )
            return
        }
        auth.refetchAuth()
    }

    useEffect(
        function () {
            if (!stripe || !clientSecret) {
                return
            }

            async function handleCallback() {
                try {
                    await auth.refetchAuth()
                    const { paymentIntent } =
                        await stripe.retrievePaymentIntent(clientSecret)
                    switch (paymentIntent.status) {
                        case 'succeeded':
                            setToast(
                                'Success! Your subscription has been created.',
                            )
                            await updateUserPremiumStatus()
                            navigate('/profile')
                            break
                        case 'requires_action':
                            setToast(
                                'Your payment requires additional action. Please try again.',
                                'alert',
                            )
                            navigate('/profile/billing/purchase-premium')
                            break
                        case 'requires_payment_method':
                            setToast(
                                'Failed to process payment details. Please try another payment method.',
                                'alert',
                            )
                            navigate('/profile/billing/purchase-premium')
                            break
                        case 'processing':
                        default:
                            break
                    }
                } catch (error) {
                    console.error(error)
                    setToast(
                        'There was a problem processing your payment. Please try again and if the issue persists, contact Support.',
                        'alert',
                    )
                }
            }

            handleCallback()
        },
        [stripe],
    )

    if (!clientSecret) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    There was a problem processing your payment. Please try
                    again and if the issue persists, contact Support.&nbsp;
                    <Link to="/">Back to Home.</Link>
                </ErrorMessage>
            </SectionContainer>
        )
    }

    return <Spinner />
}

export default StripePremiumSubCompleteCallback
