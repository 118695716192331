import { ICONS } from '../../common/constants'
import { formatDateTime } from '../../common/helpers'
import useAuth from '../../common/hooks/useAuth'
import Break from '../../components/common/Break'
import InfoList from '../../components/common/InfoList'
import MessageSection from '../../components/common/MessageSection'

export default function AccessInfo({
    lastAccountAccessDate,
    lastPasswordResetDate,
}) {
    const auth = useAuth()
    return (
        <>
            {auth.isAdmin && (
                <>
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Only admins, assistants and client managers can see this."
                    ></MessageSection>
                    <Break />
                </>
            )}

            <h3 className="h6 text-subdued">Account access</h3>
            <InfoList
                rows={[
                    {
                        icon: ICONS.SAVE_WHITE,
                        content: `Last API access: ${
                            lastAccountAccessDate
                                ? formatDateTime(lastAccountAccessDate * 1000)
                                : 'None'
                        }`,
                    },
                    {
                        icon: ICONS.LOCK_WHITE,
                        content: `Last password reset: ${
                            lastPasswordResetDate
                                ? formatDateTime(lastPasswordResetDate * 1000)
                                : 'None'
                        }`,
                    },
                ]}
            />
        </>
    )
}
