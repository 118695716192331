import ResourceList from '../../../components/admin/ResourceList'

export default function BlogsList() {
    return (
        <>
            <ResourceList
                apiUrl={'/blogs'}
                initialQuery={{
                    fields: 'id,title,shop.id,shop.fullName',
                }}
                itemClickPath={'/content/blogs/:item.shop.id/:item.id'}
                emptyText={
                    'No blogs. Make sure your shop keys have the "write_content" permission.'
                }
                newItemLabel={'New blog'}
                newItemPath={`/content/blogs/new`}
                renderMainButton
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.title,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) => item.shop.fullName,
                        getClassName: () => 'subtitle',
                    },
                ]}
            />
        </>
    )
}
