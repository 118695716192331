import { useEffect, useState } from 'react'

export default function InputWrapper({
    inputId,
    required,
    hideRequired,
    label,
    labelButton,
    infoTop,
    infoBottom,
    children,
    value,
    getPreviewText,
}) {
    const [previewValue, setPreviewValue] = useState(null)
    useEffect(
        function () {
            if (!getPreviewText) return
            async function getPreview() {
                const preview = await getPreviewText(value)
                setPreviewValue(preview)
            }
            getPreview()
        },
        [value],
    )
    return (
        <div className="input-wrapper">
            <label
                htmlFor={inputId}
                className={`${required && !hideRequired ? 'required' : ''}${
                    labelButton ? ' has-button' : ''
                }`}
            >
                {label}
                {labelButton ? labelButton : null}
            </label>
            {infoTop ? <small className="input-info">{infoTop}</small> : null}

            {children}

            {infoBottom ? (
                <small className="input-info">{infoBottom}</small>
            ) : null}

            {getPreviewText ? (
                <small className="input-info">{previewValue}</small>
            ) : null}
        </div>
    )
}
