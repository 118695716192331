import React, { useEffect, useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'

import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import AssignedDrivesList from '../assigned-drives/AssignedDrivesList'
import Button from '../../../components/common/Button'
import useModal from '../../../common/hooks/useModal'
import Toggle from '../../../components/common/Toggle'
import ClientBuyerSettings from './ClientBuyerSettings'
import ClientVendorSettings from './ClientVendorSettings'
import EditClientPublicProfile from './EditClientPublicProfile'
import ClientOrgMemberInfo from './ClientOrgMemberInfo'
import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

export default function ClientSettings() {
    const { userId } = useParams()

    const { user, userError, userLoading, userMutate } = useData(
        `/v1/users/${userId}?fields=activeStatusOverride,premiumStatusOverride,disableSocialPostPostfix,activeStatus,isPremiumUser,isEmailVerified,disableSocialPostPostfix`,
        'user',
    )

    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const [inviteLoading, setInviteLoading] = useState(false)
    const [activeStatusOverride, setActiveStatusOverride] = useState(null)
    const [premiumStatusOverride, setPremiumStatusOverride] = useState(null)

    useEffect(() => {
        if (!user) return
        setActiveStatusOverride(user.activeStatusOverride)
        setPremiumStatusOverride(user.premiumStatusOverride)
    }, [user])

    const onSubmitPremiumStatus = async (value) => {
        const newStatus = value === 'null' ? null : value
        setPremiumStatusOverride(newStatus)
        setModal(
            <ConfirmModalContent
                onCancel={() => {
                    setPremiumStatusOverride(user.premiumStatusOverride)
                }}
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { responseData, error } = await fetchAPI(
                        `/v1/users/${user.id}`,
                        { premiumStatusOverride: newStatus },
                        'PATCH',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        setPremiumStatusOverride(user.premiumStatusOverride)
                        return
                    }

                    userMutate({ ...user, ...responseData })
                    setModal(null)
                    setToast('Saved')
                }}
            />,
            "Are you sure you want to change the user's premium status?",
        )
    }

    const onSubmitActiveStatus = async (value) => {
        const newStatus = value === 'null' ? null : value
        setActiveStatusOverride(newStatus)
        setModal(
            <ConfirmModalContent
                onCancel={() => {
                    setActiveStatusOverride(user.activeStatusOverride)
                }}
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { responseData, error } = await fetchAPI(
                        `/v1/users/${user.id}`,
                        { activeStatusOverride: newStatus },
                        'PATCH',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        setActiveStatusOverride(user.activeStatusOverride)
                        return
                    }

                    userMutate({ ...user, ...responseData })
                    setModal(null)
                    setToast('Saved')
                }}
            />,
            "Are you sure you want to change the user's active status?",
        )
    }

    const onSendInviteEmail = async (isBuyer) => {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    setInviteLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/users/${user.id}/send-invite${
                            isBuyer ? '?buyer=true' : ''
                        }`,
                        {},
                        'POST',
                    )
                    setInviteLoading(false)
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')

                        return
                    }

                    setModal(null)
                    setToast('Email sent')
                }}
            />,
            'Are you sure you want to send an invite email?',
        )
    }

    const onVerifyEmail = async () => {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { responseData, error } = await fetchAPI(
                        `/v1/users/${user.id}`,
                        { isEmailVerified: true },
                        'PATCH',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    userMutate({ ...user, ...responseData })
                    setModal(null)
                    setToast('Email verified')
                }}
            />,
            'Are you sure you want to mark the email as verified?',
        )
    }

    const onSocialPostfixToggle = async () => {
        const { responseData, error } = await fetchAPI(
            `/v1/users/${user.id}`,
            { disableSocialPostPostfix: !user.disableSocialPostPostfix },
            'PATCH',
        )
        if (error) {
            setModal(null)
            setToast(error, 'alert')
            return
        }

        setToast('Saved')
        userMutate({ ...user, ...responseData })
    }

    if (userLoading) return <Spinner />
    if (userError)
        return (
            <SectionContainer>
                <ErrorMessage>{userError}</ErrorMessage>
            </SectionContainer>
        )

    return (
        <>
            <SectionContainer>
                <Toggle title={'Assigned Drives'}>
                    <AssignedDrivesList />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title={'App account'}>
                    <div>
                        <EditClientPublicProfile userId={user.id} />
                        <form>
                            <div>
                                Current status&nbsp;
                                {user.activeStatus ? (
                                    <Tag color={'success'}>Active</Tag>
                                ) : (
                                    <Tag color={'success'}>Inactive</Tag>
                                )}
                            </div>
                            <Break />
                            <label htmlFor="override-select-active">
                                Override Active Status
                            </label>

                            <SelectInput
                                id="override-select-active"
                                value={
                                    activeStatusOverride === null
                                        ? 'null'
                                        : activeStatusOverride
                                }
                                onChange={(v) => onSubmitActiveStatus(v)}
                                options={[
                                    { value: 'null', label: 'Do not override' },
                                    { value: true, label: 'Force active' },
                                    { value: false, label: 'Force inactive' },
                                ]}
                            />
                            <small className="input-info">
                                Up to 24 hours to take effect
                            </small>
                        </form>
                        <form>
                            <div>
                                Premium status&nbsp;
                                {user.isPremiumUser ? (
                                    <Tag color={'success'}>Premium</Tag>
                                ) : (
                                    <Tag color={'alert'}>Free</Tag>
                                )}
                            </div>
                            <Break />

                            <label htmlFor="override-select-premium">
                                Override Premium Status
                            </label>
                            <SelectInput
                                id="override-select-premium"
                                value={
                                    premiumStatusOverride === null
                                        ? 'null'
                                        : premiumStatusOverride
                                }
                                onChange={(v) => onSubmitPremiumStatus(v)}
                                options={[
                                    { value: 'null', label: 'Do not override' },
                                    { value: true, label: 'Force premium' },
                                    { value: false, label: 'Force free' },
                                ]}
                            />
                            <small className="input-info">
                                Up to 24 hours to take effect
                            </small>
                        </form>
                    </div>

                    {!user.isEmailVerified ? (
                        <Button
                            type="button"
                            text="Mark email as verified"
                            onClick={onVerifyEmail}
                        />
                    ) : (
                        <div>
                            Email status&nbsp;
                            <Tag color={'success'}>Verified</Tag>
                        </div>
                    )}
                    <Button
                        text="Send invite email"
                        onClick={onSendInviteEmail}
                        isLoading={inviteLoading}
                    />
                </Toggle>
            </SectionContainer>

            <ClientVendorSettings />

            <ClientBuyerSettings />

            <ClientOrgMemberInfo />

            <SectionContainer>
                <Toggle title={'Social posts'}>
                    <form>
                        <CheckboxInput
                            label="Disable social post postfix text"
                            id={`has-social-postfix`}
                            onChange={onSocialPostfixToggle}
                            value={user.disableSocialPostPostfix}
                        />
                    </form>
                </Toggle>
            </SectionContainer>
        </>
    )
}
