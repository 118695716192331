import { useEffect, useRef, useState } from 'react'
import Button from '../common/Button'
import { ICONS } from '../../common/constants'

const MAX_HEIGHT = 200

export default function CustomDetails({ children, maxHeight = MAX_HEIGHT }) {
    const [isShort, setIsShort] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current && ref.current.clientHeight < maxHeight) {
            setIsShort(true)
        }
    }, [])

    function handleToggle(e) {
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    if (isShort) {
        return <div>{children}</div>
    }

    return (
        <div
            ref={ref}
            className="custom-details"
            style={{ '--max-height': `${maxHeight}px` }}
        >
            <div
                aria-expanded={isOpen}
                className={isOpen ? 'details-open' : ''}
            >
                {children}
            </div>
            <Button
                link
                iconRight
                icon={ICONS.CHEVRON_RIGHT_WHITE}
                underline={false}
                text={isOpen ? 'Read less' : 'Read more'}
                onClick={handleToggle}
            />
        </div>
    )
}
