function ResourceListPagination(props) {
    const {
        hasCursorPagination,
        page,
        count,
        pages,
        hasPrevCursor,
        hasNextCursor,
        onNav,
        hasEmptyText,
    } = props

    return (
        <div className="resource-list-pagination">
            {hasCursorPagination && (
                <button
                    type="button"
                    onClick={() => onNav(-1)}
                    disabled={hasCursorPagination ? !hasPrevCursor : page === 1}
                >
                    <img
                        src={`/img/icons/chevron-left-white.svg`}
                        alt="prev page"
                        width="24"
                        height="24"
                    />
                </button>
            )}
            {page !== null &&
                (hasCursorPagination ? (
                    <div className="page-counter">
                        <span>{page}</span>
                        <span>&nbsp;/&nbsp;{pages}</span>
                        {count !== null ? (
                            <span>
                                &nbsp;({count || 0}{' '}
                                {count === 1 ? 'result' : 'results'})
                            </span>
                        ) : null}
                    </div>
                ) : (
                    count !== null &&
                    ((count === 0 && !hasEmptyText) || count > 0) && (
                        <div className="page-counter">
                            <span>
                                {count || 0}{' '}
                                {count === 1 ? 'result' : 'results'}
                            </span>
                        </div>
                    )
                ))}
            {hasCursorPagination && (
                <button
                    type="button"
                    onClick={() => onNav(1)}
                    disabled={
                        hasCursorPagination ? !hasNextCursor : page === pages
                    }
                >
                    <img
                        src={`/img/icons/chevron-left-white.svg`}
                        alt="next page"
                        width="24"
                        height="24"
                        style={{ transform: 'rotate(180deg)' }}
                    />
                </button>
            )}
        </div>
    )
}

export default ResourceListPagination
