import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { formatDateForInput, formatMoney } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function EditInvoice() {
    const { userId, invoiceId } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [saveLoading, _setSaveLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState({})
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/payment/clients/${userId}/invoices/manual/${invoiceId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)

            setData({
                amount: formatMoney(responseData.total, true),
                status: responseData.status,
                created: formatDateForInput(responseData.createdDate),
                due: formatDateForInput(responseData.dueDate),
                start: formatDateForInput(responseData.periodStartDate),
                end: formatDateForInput(responseData.periodEndDate),
            })
        }

        getData()
    }, [])

    const onSubmit = async (e) => {
        if (
            !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(data.amount)
        ) {
            setError('Invalid amount')
            return e.preventDefault()
        }
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/invoices/manual/${invoiceId}`,
                        {
                            status: data.status,
                            dueDate: data.due || null,
                            creationDate: data.created || null,
                            periodStartDate: data.start || null,
                            periodEndDate: data.end || null,
                            amount: String(data.amount * 100),
                        },
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to save these changes?',
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {loading && 'Loading...'}

            <h2>Edit Invoice</h2>
            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-amount">Amount</label>
                    <TextInput
                        name="amount"
                        id="form-amount"
                        type="number"
                        step="0.01"
                        value={data.amount}
                        onChange={(v) =>
                            setData({
                                ...data,
                                amount: v,
                            })
                        }
                    />

                    <label htmlFor="form-status">Status</label>
                    <SelectInput
                        name="status"
                        id="form-status"
                        value={data.status}
                        onChange={(v) =>
                            setData({
                                ...data,
                                status: v,
                            })
                        }
                        options={[
                            { value: 'paid', label: 'Paid' },
                            { value: 'open', label: 'Open' },
                            { value: 'void', label: 'Void' },
                        ]}
                    />

                    <label htmlFor="form-created">Created</label>
                    <TextInput
                        name="created"
                        id="form-created"
                        type="date"
                        value={data.created}
                        onChange={(v) =>
                            setData({
                                ...data,
                                created: v,
                            })
                        }
                    />

                    <label htmlFor="form-due">Due</label>
                    <TextInput
                        name="due"
                        id="form-due"
                        type="date"
                        value={data.due}
                        onChange={(v) =>
                            setData({
                                ...data,
                                due: v,
                            })
                        }
                    />

                    <label htmlFor="form-start">Period start</label>
                    <TextInput
                        name="start"
                        id="form-start"
                        type="date"
                        value={data.start}
                        onChange={(v) =>
                            setData({
                                ...data,
                                start: v,
                            })
                        }
                    />
                    <label htmlFor="form-end">Period end</label>
                    <TextInput
                        name="end"
                        id="form-end"
                        type="date"
                        value={data.end}
                        onChange={(v) =>
                            setData({
                                ...data,
                                end: v,
                            })
                        }
                    />
                </div>

                <div>
                    <Button text="Save" isLoading={saveLoading} type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}

export default EditInvoice
