import { useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useAuth from '../../../common/hooks/useAuth'
import TextInput from '../../../components/common/data-form/TextInput'
import FileInput from '../../../components/common/data-form/FileInput'
import SectionContainer from '../../../components/common/SectionContainer'

function VendorQuestions(props) {
    const { showProductsInput = true } = props
    const formRef = useRef(null)
    const [error, setError] = useError()
    const [loading, setLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const auth = useAuth()

    const [data, setData] = useState({
        files: [],
    })

    const {
        productsQuestions,
        productsQuestionsError,
        productsQuestionsLoading,
    } = useData(
        '/v1/wholesale/products-questions',
        'productsQuestions',
        null,
        null,
        null,
        (items) => {
            setData({
                ...data,
                ...Object.values(items).reduce(
                    (a, b) => ({ ...a, [b.label]: '' }),
                    {},
                ),
            })
        },
    )

    const orgData = auth.user.orgsData?.find(
        (o) => o.id === searchParams.get('organizationId'),
    )

    async function onSubmit(e) {
        setError('')

        if (!formRef.current.reportValidity()) {
            e.preventDefault()
            return
        }
        e.preventDefault()

        if (data.files.length > 5) {
            setError('Please upload 3-5 images of your products')
            return
        }

        const MAX_FILE_SIZE = 10 * 1024 * 1024
        for (const file of Object.values(data.files)) {
            if (!file) continue
            if (file.size > MAX_FILE_SIZE) {
                setError(`One of the files is too big. Max size is 10MB.`)
                return
            }
        }

        setLoading(true)

        const formData = new FormData()

        const bodyData = { ...data }
        delete bodyData.files

        const bodyObj = {
            formFields: bodyData,
            source: 'productsQuestions',
            organizationId: searchParams.get('organizationId'),
        }

        formData.append('data', JSON.stringify(bodyObj))

        for (let i = 0; i < data.files.length; i++) {
            formData.append(`files[product_image_${i}]`, data.files[i])
        }

        setLoading(true)

        const { error } = await fetchAPI('/v1/forms', formData, 'POST', {})

        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Submitted successfully')
        navigate('/profile')
    }

    if (productsQuestionsLoading) {
        return <Spinner marginTop />
    }

    if (productsQuestionsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{productsQuestionsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!orgData) {
        return (
            <SectionContainer>
                <ErrorMessage>{`Company information is missing. Please contact Support.`}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <h2>{orgData.name}</h2>

            <form ref={formRef}>
                {showProductsInput && (
                    <>
                        <label htmlFor="form-photos">
                            Upload photos of your products (3-5 files, JPG
                            format)
                        </label>
                        <FileInput
                            id="form-photos"
                            accept="image/jpeg"
                            multiple
                            setFiles={(files) => {
                                return setData((data) => ({
                                    ...data,
                                    files,
                                }))
                            }}
                        />
                    </>
                )}

                {Object.keys(productsQuestions).map((key) => (
                    <div key={key}>
                        <label
                            htmlFor={`form-${key}`}
                            className={
                                productsQuestions[key].required
                                    ? 'required'
                                    : ''
                            }
                        >
                            {productsQuestions[key].label}
                        </label>
                        <TextInput
                            required={productsQuestions[key].required}
                            type={productsQuestions[key].type || 'text'}
                            id={`form-${key}`}
                            value={data[productsQuestions[key].label]}
                            placeholder={
                                productsQuestions[key].placeholder || ''
                            }
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    [productsQuestions[key].label]: v,
                                })
                            }
                        />
                        {productsQuestions[key].info ? (
                            <small className="input-info">
                                {productsQuestions[key].info}
                            </small>
                        ) : null}
                    </div>
                ))}

                <Button
                    onClick={onSubmit}
                    isLoading={loading}
                    text={'Submit'}
                    type="submit"
                />
            </form>
        </SectionContainer>
    )
}

export default VendorQuestions
