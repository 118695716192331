import { useParams, useNavigate } from 'react-router-dom'
import { parseResourcePath } from '../../common/helpers'
import CardLayout from './CardLayout'
import { ICONS } from '../../common/constants'

function Item(props) {
    const {
        item,
        items,
        fields,
        itemHrefUrl,
        itemsNavSearch,
        actions,
        onClick,
        selectable,
        isSelected,
        filterQuery,
    } = props
    const allParams = useParams()
    const navigate = useNavigate()

    const columnNumbers = Array.from(
        new Set(fields.map((item) => item.column)),
    ).sort()

    const processedActions = actions?.map((action) => ({
        title: action.title,
        onClick: action.link
            ? () =>
                  navigate(
                      parseResourcePath(
                          action.link,
                          allParams,
                          item,
                          filterQuery,
                      ),
                  )
            : () => action.onClick(item, items),
        disabled: action.getDisabled && action.getDisabled(item),
        hide: action.hide || (action.getHide && action.getHide(item)),
        icon: action.icon,
        type: action.type,
        loading: action.loading,
    }))

    const noCallback = () => {}

    return (
        <CardLayout
            href={
                itemHrefUrl
                    ? parseResourcePath(
                          itemHrefUrl,
                          allParams,
                          item,
                          filterQuery,
                      )
                    : null
            }
            itemsNavSearch={itemsNavSearch}
            actions={processedActions || []}
            style={isSelected ? { backgroundColor: 'var(--primary-500)' } : {}}
            selectable={selectable}
            isSelected={isSelected}
            clickable={Boolean(onClick)}
            itemView={props.itemView}
        >
            {columnNumbers.map((index) => {
                const columnFields = fields.filter(
                    (item) => item.column === index,
                )

                const width = columnFields.find(
                    (item) => item.columnWidth,
                )?.columnWidth

                return (
                    <div
                        dkey={props.dkey}
                        key={index}
                        onClick={onClick ? () => onClick(item) : noCallback}
                        role={props.onClick ? 'button' : 'none'}
                        style={width ? { minWidth: width, flex: 'unset' } : {}}
                    >
                        {Boolean(selectable) &&
                            (!props.isSelected ? (
                                <img
                                    className="selectable-icon-disabled"
                                    src={ICONS.CHECK_WHITE}
                                    alt="checkbox"
                                    width="24"
                                    height="24"
                                />
                            ) : (
                                props.isSelected && (
                                    <img
                                        className="selectable-icon"
                                        src={ICONS.CHECK_WHITE}
                                        alt="checkbox"
                                        width="24"
                                        height="24"
                                    />
                                )
                            ))}
                        {columnFields.map((field, i) => {
                            if (allParams[field.hideIfUrlHas]) return null
                            const value = field.getValue(item)
                            const className = `${
                                field.getClassName
                                    ? field.getClassName(item)
                                    : ''
                            }`

                            if (field.wrapperEl === 'span') {
                                return (
                                    <span key={i} className={className}>
                                        {field.label || ''} {value}
                                    </span>
                                )
                            } else if (field.wrapperEl === 'h3') {
                                return (
                                    <h3 key={i} className={className}>
                                        {field.label || ''} {value}
                                    </h3>
                                )
                            } else if (field.wrapperEl === 'div') {
                                return (
                                    <div key={i} className={className}>
                                        {field.label || ''} {value}
                                    </div>
                                )
                            } else if (field.wrapperEl === 'p') {
                                return (
                                    <p key={i} className={className}>
                                        {field.label || ''} {value}
                                    </p>
                                )
                            } else if (
                                field.getClassName &&
                                field.getClassName(item).includes('table-row')
                            ) {
                                return (
                                    <div key={i} className={className}>
                                        {field.label || ''} {value}
                                    </div>
                                )
                            } else {
                                return (
                                    <div
                                        key={i}
                                        className={`${className} resource-list-item-default`}
                                    >
                                        {field.label || ''} {value}
                                    </div>
                                )
                            }
                        })}
                    </div>
                )
            })}
        </CardLayout>
    )
}

function ResourceListItems(props) {
    const {
        items,
        fields,
        itemClickPath,
        itemsNavSearch,
        onItemClick,
        onItemSelect,
        selectedIds,
        selectedIdField,
        actions,
        filterQuery,
    } = props

    if (!items.length) return null

    return items.map((item, i) => {
        return (
            <Item
                dkey={item.id ?? i}
                key={item.id ?? i}
                item={item}
                itemView={props.itemView}
                items={items}
                fields={fields}
                itemHrefUrl={itemClickPath}
                itemsNavSearch={itemsNavSearch}
                onClick={onItemClick || onItemSelect || null}
                selectable={onItemSelect}
                isSelected={
                    onItemSelect && selectedIds.includes(item[selectedIdField])
                }
                actions={actions}
                filterQuery={filterQuery}
            />
        )
    })
}

export default ResourceListItems
