export const API_URL =
    process.env.REACT_APP_CONTEXT === 'NETLIFY_DEV'
        ? process.env.REACT_APP_BACKEND_URL_DEV
        : process.env.REACT_APP_BACKEND_URL

export const SOCIAL_SERVICE_NAMES = {
    // facebook: 'Facebook',
    facebook: 'Facebook & Instagram Business',
}

export const SOCIAL_PROPERTY_NAMES = {
    facebookPage: 'Facebook Page',
    facebookGroup: 'Facebook Group',
    facebookGroupAsPage: 'Facebook Group',
    instagramProfile: 'Instagram',
}

export const DEFAULT_TEAM_ROLES = [
    'assistants',
    'accountants',
    'collections',
    'late fees',
    'social editors',
    'client managers',
    'sales',
]

export const CONNECTED_ACCOUNT_SERVICE_NAMES = {
    facebook: 'Facebook',
}

export const CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES = {
    facebookPage: 'Page',
    igBusinessAccount: 'IG Account',
}

export const ALLOWED_NOTIF_TYPES = {
    admin: [
        'vendorAccountSubmission',
        'vendorProductCreation',
        'commentAdded',
        'orgMemberInvite',
        'connection',
    ],
    staff: ['commentAdded', 'orgMemberInvite', 'connection'],
    user: ['commentAdded', 'orgMemberInvite', 'connection'],
}

export const USER_PREFERENCES = {
    SKIP_NOTIFS: {
        COMMENT_ADDED: 'skipNotifsCommentAdded',
    },
}

export const RETAIL_BUSINESS_CATEGORIES = {
    salon: {
        title: 'Salon',
        subcategories: {
            spa: {
                title: 'Spa',
            },
            hairSpa: {
                title: 'Hair Spa',
            },
            makeup: {
                title: 'Makeup',
            },
            other: {
                title: 'Other',
            },
        },
    },
    other: {
        title: 'Other',
        subcategories: {
            other: {
                title: 'Other',
            },
        },
    },
}

export const COLOR_VALUES = {
    green: '#00b894',
    red: '#d63031',
    white: '#ffffff',
}

export const TASK_STATUSES = {
    REVIEW: 'review', // accepted by staff, worked, req. review by admin
    REJECTED: 'rejected', // Admin rejected work, sends back for correction
    ACCEPTED: 'accepted', // Staff accepted task assigned to them
    OPEN: 'open', // Waiting acceptance by staff member
    DENIED: 'denied', // Staff denies task
    COMPLETE: 'complete', // Complete
    APPROVAL: 'approval', // Assistant created task and it needs acceptance by admin
    RECREATION: 'recreation', // Admin rejects new creation and provides details
    BLOCKED: 'blocked', // Staff marks it blocked because of some reason
}

export const TASK_STATUS_LABELS = {
    open: { label: 'Open', tagColor: 'primary' },
    accepted: { label: 'In progress', tagColor: 'primary' },
    review: { label: 'Internal review', tagColor: 'warning' },
    complete: { label: 'Complete', tagColor: 'success' },
    rejected: { label: 'Rejected', tagColor: 'alert' },
    denied: { label: 'User rejected', tagColor: 'alert' },
    approval: { label: 'New task', tagColor: 'warning' },
    recreation: { label: 'Creation rejected', tagColor: 'alert' },
    blocked: { label: 'Blocked', tagColor: 'alert' },
}

export const TASK_PRIORITY_LABELS = {
    high: { label: 'High', tagColor: 'alert' },
    medium: { label: 'Medium', tagColor: 'warning' },
    low: { label: 'Low', tagColor: 'primary' },
}

export const ICONS = {
    EDIT_WHITE: '/img/icons/edit-white.svg',
    EDIT_GRAY: '/img/icons/edit-gray.svg',
    EDIT_ACTIVE: '/img/icons/edit-active.svg',
    TRASH_WHITE: '/img/icons/trash-white.svg',
    TRASH_RED: '/img/icons/trash-red.svg',
    TRASH_GRAY: '/img/icons/trash-gray.svg',
    FOLDER_WHITE: '/img/icons/folder-white.svg',
    FOLDER_ACTIVE: '/img/icons/folder-active.svg',
    FOLDER_GRAY: '/img/icons/folder-gray.svg',
    CHEVRON_LEFT_WHITE: '/img/icons/chevron-left-white.svg',
    CHEVRON_RIGHT_GRAY: '/img/icons/chevron-right-gray.svg',
    CHEVRON_RIGHT_WHITE: '/img/icons/chevron-right-white.svg',
    CHEVRON_RIGHT_ACTIVE: '/img/icons/chevron-right-active.svg',
    SHOPPING_CART_WHITE: '/img/icons/shopping-cart-white.svg',
    SHOPPING_CART_ACTIVE: '/img/icons/shopping-cart-active.svg',
    MAIL_GRAY: '/img/icons/mail-gray.svg',
    MAIL_GRAY_2: '/img/icons/mail-gray-2.svg',
    MAIL_WHITE: '/img/icons/mail-white.svg',
    MAIL_ACTIVE: '/img/icons/mail-active.svg',
    SLASH_GRAY: '/img/icons/slash-gray.svg',
    SLASH_RED: '/img/icons/slash-red.svg',
    LOGIN_GRAY: '/img/icons/login-gray.svg',
    SETTINGS_GRAY: '/img/icons/settings-gray.svg',
    SETTINGS_WHITE: '/img/icons/settings-white.svg',
    LINK_GRAY: '/img/icons/link-gray.svg',
    LINK_ACTIVE: '/img/icons/link-active.svg',
    LINK_WHITE: '/img/icons/link-white.svg',
    PAUSE_GRAY: '/img/icons/pause-gray.svg',
    CHECK_GRAY: '/img/icons/check-gray.svg',
    CHECK_WHITE: '/img/icons/check-white.svg',
    EYE_GRAY: '/img/icons/eye-gray.svg',
    EYE_ACTIVE: '/img/icons/eye-active.svg',
    EYE_OFF_GRAY: '/img/icons/eye-off-gray.svg',
    CLIPBOARD_GRAY: '/img/icons/clipboard-gray.svg',
    CLIPBOARD_WHITE: '/img/icons/clipboard-white.svg',
    CLIPBOARD_ACTIVE: '/img/icons/clipboard-active.svg',
    DOWNLOAD_GRAY: '/img/icons/download-gray.svg',
    DOWNLOAD_ACTIVE: '/img/icons/download-active.svg',
    HOME_WHITE: '/img/icons/home-white.svg',
    PHONE_GRAY: '/img/icons/phone-gray.svg',
    PHONE_GRAY_2: '/img/icons/phone-gray-2.svg',
    PHONE_WHITE: '/img/icons/phone-white.svg',
    PHONE_ACTIVE: '/img/icons/phone-active.svg',
    SAVE_WHITE: '/img/icons/save-white.svg',
    LOCK_WHITE: '/img/icons/lock-white.svg',
    LOCK_ACTIVE: '/img/icons/lock-active.svg',
    LOCK_GRAY: '/img/icons/lock-gray.svg',
    PACKAGE_WHITE: '/img/icons/package-white.svg',
    PAYPAL_WHITE: '/img/icons/paypal-white.svg',
    BELL_WHITE: '/img/icons/bell-white.svg',
    BELL_ACTIVE: '/img/icons/bell-active.svg',
    HASH_GRAY: '/img/icons/hash-gray.svg',
    LABEL_WHITE: '/img/icons/label-white.svg',
    FILE_TEXT_ACTIVE: '/img/icons/file-text-active.svg',
    FEATHER_ACTIVE: '/img/icons/feather-active.svg',
    MONITOR_ACTIVE: '/img/icons/monitor-active.svg',
    HEART_ACTIVE: '/img/icons/heart-active.svg',
    SHARE_2_ACTIVE: '/img/icons/share-2-active.svg',
    SHARE_2_GRAY: '/img/icons/share-2-gray.svg',
    CALENDAR_ACTIVE: '/img/icons/calendar-active.svg',
    CALENDAR_GRAY: '/img/icons/calendar-gray.svg',
    X_WHITE: '/img/icons/x-white.svg',
    X_ACTIVE: '/img/icons/x-active.svg',
    X_GRAY: '/img/icons/x-gray.svg',
    ALERT_ACTIVE: '/img/icons/alert-active.svg',
    ALERT_WHITE: '/img/icons/alert-white.svg',
    HELP_ACTIVE: '/img/icons/help-active.svg',
    HELP_WHITE: '/img/icons/help-white.svg',
    PLUS_WHITE: '/img/icons/plus-white.svg',
    PLUS_GRAY: '/img/icons/plus-gray.svg',
    PLUS_ACTIVE: '/img/icons/plus-active.svg',
    CLOCK_ACTIVE: '/img/icons/clock-active.svg',
    USER_ACTIVE: '/img/icons/user-active.svg',
    USER_GRAY: '/img/icons/user-gray.svg',
    USER_WHITE: '/img/icons/user-white.svg',
    LIST_ACTIVE: '/img/icons/list-active.svg',
    LIST_WHITE: '/img/icons/list-white.svg',
    SQUARE_ACTIVE: '/img/icons/square-active.svg',
    SQUARE_WHITE: '/img/icons/square-white.svg',
    ARROW_UP_A_Z_WHITE: '/img/icons/arrow-down-a-z-white.svg',
    ARROW_DOWN_Z_A_WHITE: '/img/icons/arrow-up-z-a-white.svg',
    GRID_WHITE: '/img/icons/grid-white.svg',
    GRID_ACTIVE: '/img/icons/grid-active.svg',
    SEARCH_BLACK: '/img/icons/search-black.svg',
    SEARCH_GRAY: '/img/icons/search-gray.svg',
    SEARCH_CURRENT: '/img/icons/search-current.svg',
    STAR_WHITE: '/img/icons/star-white.svg',
    STAR_FILLED_WHITE: '/img/icons/star-filled-white.svg',
    HEART_WHITE: '/img/icons/heart-white.svg',
    HEART_FILLED_WHITE: '/img/icons/heart-filled-white.svg',
    COPY_GRAY: '/img/icons/copy-gray.svg',
    COPY_ACTIVE: '/img/icons/copy-active.svg',
    COPY_WHITE: '/img/icons/copy-white.svg',
    MESSAGE_SQUARE_ACTIVE: '/img/icons/message-square-active.svg',
    MESSAGE_SQUARE_GRAY: '/img/icons/message-square-gray.svg',
    ARROW_DOWN_WHITE: '/img/icons/arrow-down-white.svg',
    BUG_ACTIVE: '/img/icons/bug-active.svg',
    BUG_GRAY: '/img/icons/bug-gray.svg',
    CLIENT_ACTIVE: '/img/icons/client-active.svg',
    CLIENT_GRAY: '/img/icons/client-gray.svg',
    ALIGN_LEFT_WHITE: '/img/icons/align-left-white.svg',
    ORDERED_LIST_WHITE: '/img/icons/ordered-list-white.svg',
    UNORDERED_LIST_WHITE: '/img/icons/unordered-list-white.svg',
    IMAGE_WHITE: '/img/icons/image-white.svg',
    FILTER_WHITE: '/img/icons/filter-white.svg',
}

export const HTML_CONTRACT_TYPES = {
    vendorRegistration: {
        title: 'Vendor Registration (Deprecated)',
    },
    vendorRegistrationIowa: {
        title: 'Vendor registration (Iowa)',
    },
    vendorRegistrationOnline: {
        title: 'Vendor registration (Online)',
    },
    vendorRegistrationIowaOnline: {
        title: 'Vendor registration (Iowa + Online)',
    },
    vendorConsignmentRegistration: {
        title: 'Consignment Registration',
    },
}

export const DRIVE_USAGES = {
    WORK_PROOF: 'workProof',
}

export const DRIVE_USAGE_SETTINGS = {
    workProof: {
        onlyUpload: false,
        accept: "'.jpg,.jpeg,.png,.pdf'",
        maxFileSize: 1024 * 1024 * 5,
        maxFiles: 2,
        mimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    },
    vendorProductSample: {
        onlyUpload: false,
        accept: "'.jpg,.jpeg,.png'",
        maxFileSize: 1024 * 1024 * 5,
        maxFiles: 5,
        mimeTypes: ['image/jpeg', 'image/png'],
    },
}

export const DRIVE_PARENTS_PER_USAGE = {
    clientTask: ['client-design', 'client-main', 'app-tasks'],
    teamTask: ['client-design', 'client-main', 'app-tasks'],
    social: ['client-design'],
    taskImageField: ['client-design', 'client-main', 'app-tasks'],
    taskFileField: ['client-design', 'client-main', 'app-tasks'],
    pdfForm: ['app-submitted-docs'],
    workProof: ['app-submitted-docs'],
}

export const PDF_FORM_DATA = {
    salesTaxExemptIowa: {
        name: 'Sales Tax Exempt Iowa',
    },
    salesTaxExemptTexas: {
        name: 'Sales Tax Exempt Texas',
    },
    workProof: {
        name: 'Proof of work',
    },
}

export const COMPANY_TYPES = [
    'Sole Proprietor',
    'LLC',
    'S Corporation',
    'C Corporation',
    'Non Profit',
    'Government',
]

export const TASK_ACTION_TEXT = {
    MARK_PAYOUT_PAID: 'Mark as paid',
    ACCEPT_COST_REQUEST: 'Accept and update Shopify',
    TEST: 'Click to test',
}

export const DEFALT_MENU_TILES = [
    {
        label: 'Logs',
        icon: ICONS.FILE_TEXT_ACTIVE,
        href: '/logs',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'PDF Contracts',
        icon: ICONS.FEATHER_ACTIVE,
        href: '/pdf-contracts',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Custom Contracts',
        icon: ICONS.FEATHER_ACTIVE,
        href: '/html-contracts',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Repos',
        href: '/repos',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Components',
        href: '/storybook',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Pages',
        icon: ICONS.MONITOR_ACTIVE,
        href: '/edit-pages',
        showToAssistants: 'true',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Forms',
        icon: ICONS.SHARE_2_ACTIVE,
        href: '/plain-forms',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Timesheets',
        icon: ICONS.CLOCK_ACTIVE,
        href: '/timesheets',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Passwords',
        icon: ICONS.LOCK_ACTIVE,
        href: '/vault',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Forms',
        icon: ICONS.MAIL_ACTIVE,
        href: '/contact-forms',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Donations',
        icon: ICONS.HEART_ACTIVE,
        href: '/donate',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Referrals Program',
        href: '/referrals',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Contact',
        icon: ICONS.MAIL_ACTIVE,
        href: '/contactus',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'true',
        isDefault: true,
    },
    {
        label: 'Admin Chat',
        href: '/admin-chat',
        showToAssistants: 'false',
        showToStaff: 'false',
        showToClients: 'false',
        isDefault: true,
    },
    {
        label: 'Chats',
        href: '/chats',
        showToAssistants: 'true',
        showToStaff: 'true',
        showToClients: 'true',
        isDefault: true,
    },
]

export const VENDOR_APPLY_QUESTIONS = {
    brandQuestion: {
        label: 'Tell us about your brand and the products you offer',
        info: 'How did you get started? What is your product?',
        required: true,
    },
    idealClientQuestion: {
        label: 'Who is your ideal client?',
        required: true,
    },
    freqProdQuestion: {
        label: 'How often do you have new products?',
        info: 'How often would you be sending us product? Seasonally? Quarterly?',
        required: true,
    },
    goalQuestion: {
        label: 'What is your goal in joining the showroom?',
    },
    grossQuestion: {
        label: 'How much does your brand make on average gross per year?',
        info: 'Based on the last 1-3 years',
        required: true,
    },
    costQuestion: {
        label: 'What is the average wholesale cost per product?',
        required: true,
    },
    siteQuestion: {
        label: 'Website',
        type: 'url',
        placeholder: 'https://www.yourwebsite.com',
    },
    fbPageQuestion: {
        label: 'Facebook Business Page',
        info: 'This is so we can help cross promote you and follow your page.',
        type: 'url',
        placeholder: 'https://www.facebook.com/yourbusiness',
    },
    fbGroupQuestion: {
        label: 'Facebook Group',
        type: 'url',
        placeholder: 'https://www.facebook.com/groups/yourgroup',
    },
    pinterestQuestion: {
        label: 'Pinterest',
        type: 'url',
        placeholder: 'https://www.pinterest.com/yourbusiness',
    },
    tikTokQuestion: {
        label: 'TikTok',
        type: 'url',
        placeholder: 'https://www.tiktok.com/@yourbusiness',
    },
    igQuestion: {
        label: 'Instagram',
        type: 'url',
        placeholder: 'https://www.instagram.com/yourbusiness',
    },
    yearQuestion: {
        label: 'Year your business was established',
        required: true,
    },
    hearQuestion: {
        label: 'How did you hear about the Showroom?',
    },
}

export const WEEK_DAYS = [
    {
        abbreviation: 'Sun',
        name: 'Sunday',
    },
    {
        abbreviation: 'Mon',
        name: 'Monday',
    },
    {
        abbreviation: 'Tue',
        name: 'Tuesday',
    },
    {
        abbreviation: 'Wed',
        name: 'Wednesday',
    },
    {
        abbreviation: 'Thu',
        name: 'Thursday',
    },
    {
        abbreviation: 'Fri',
        name: 'Friday',
    },
    {
        abbreviation: 'Sat',
        name: 'Saturday',
    },
]

export const MONTHS = [
    {
        abbreviation: 'Jan',
        name: 'January',
    },
    {
        abbreviation: 'Feb',
        name: 'February',
    },
    {
        abbreviation: 'Mar',
        name: 'March',
    },
    {
        abbreviation: 'Apr',
        name: 'April',
    },
    {
        abbreviation: 'May',
        name: 'May',
    },
    {
        abbreviation: 'Jun',
        name: 'June',
    },
    {
        abbreviation: 'Jul',
        name: 'July',
    },
    {
        abbreviation: 'Aug',
        name: 'August',
    },
    {
        abbreviation: 'Sep',
        name: 'September',
    },
    {
        abbreviation: 'Oct',
        name: 'October',
    },
    {
        abbreviation: 'Nov',
        name: 'November',
    },
    {
        abbreviation: 'Dec',
        name: 'December',
    },
]

export const COUNTRIES = [
    {
        value: 'AF',
        text: 'Afghanistan',
        dialCode: '+93',
    },
    {
        value: 'AX',
        text: 'Åland Islands',
        dialCode: '+358',
    },
    {
        value: 'AL',
        text: 'Albania',
        dialCode: '+355',
    },
    {
        value: 'DZ',
        text: 'Algeria',
        dialCode: '+213',
    },
    {
        value: 'AS',
        text: 'American Samoa',
        dialCode: '+1-684',
    },
    {
        value: 'AD',
        text: 'Andorra',
        dialCode: '+376',
    },
    {
        value: 'AO',
        text: 'Angola',
        dialCode: '+244',
    },
    {
        value: 'AI',
        text: 'Anguilla',
        dialCode: '+1-264',
    },
    {
        value: 'AQ',
        text: 'Antarctica',
        dialCode: '+672',
    },
    {
        value: 'AG',
        text: 'Antigua and Barbuda',
        dialCode: '+1-268',
    },
    {
        value: 'AR',
        text: 'Argentina',
        dialCode: '+54',
    },
    {
        value: 'AM',
        text: 'Armenia',
        dialCode: '+374',
    },
    {
        value: 'AW',
        text: 'Aruba',
        dialCode: '+297',
    },
    {
        value: 'AU',
        text: 'Australia',
        dialCode: '+61',
    },
    {
        value: 'AT',
        text: 'Austria',
        dialCode: '+43',
    },
    {
        value: 'AZ',
        text: 'Azerbaijan',
        dialCode: '+994',
    },
    {
        value: 'BS',
        text: 'Bahamas',
        dialCode: '+1-242',
    },
    {
        value: 'BH',
        text: 'Bahrain',
        dialCode: '+973',
    },
    {
        value: 'BD',
        text: 'Bangladesh',
        dialCode: '+880',
    },
    {
        value: 'BB',
        text: 'Barbados',
        dialCode: '+1-246',
    },
    {
        value: 'BY',
        text: 'Belarus',
        dialCode: '+375',
    },
    {
        value: 'BE',
        text: 'Belgium',
        dialCode: '+32',
    },
    {
        value: 'BZ',
        text: 'Belize',
        dialCode: '+501',
    },
    {
        value: 'BJ',
        text: 'Benin',
        dialCode: '+229',
    },
    {
        value: 'BM',
        text: 'Bermuda',
        dialCode: '+1-441',
    },
    {
        value: 'BT',
        text: 'Bhutan',
        dialCode: '+975',
    },
    {
        value: 'BO',
        text: 'Bolivia, Plurinational State of',
        dialCode: '+591',
    },
    {
        value: 'BQ',
        text: 'Bonaire, Sint Eustatius and Saba',
        dialCode: '+599',
    },
    {
        value: 'BA',
        text: 'Bosnia and Herzegovina',
        dialCode: '+387',
    },
    {
        value: 'BW',
        text: 'Botswana',
        dialCode: '+267',
    },
    {
        value: 'BV',
        text: 'Bouvet Island',
        dialCode: '+47',
    },
    {
        value: 'BR',
        text: 'Brazil',
        dialCode: '+55',
    },
    {
        value: 'IO',
        text: 'British Indian Ocean Territory',
        dialCode: '+246',
    },
    {
        value: 'BN',
        text: 'Brunei Darussalam',
        dialCode: '+673',
    },
    {
        value: 'BG',
        text: 'Bulgaria',
        dialCode: '+359',
    },
    {
        value: 'BF',
        text: 'Burkina Faso',
        dialCode: '+226',
    },
    {
        value: 'BI',
        text: 'Burundi',
        dialCode: '+257',
    },
    {
        value: 'KH',
        text: 'Cambodia',
        dialCode: '+855',
    },
    {
        value: 'CM',
        text: 'Cameroon',
        dialCode: '+237',
    },
    {
        value: 'CA',
        text: 'Canada',
        dialCode: '+1',
    },
    {
        value: 'CV',
        text: 'Cape Verde',
        dialCode: '+238',
    },
    {
        value: 'KY',
        text: 'Cayman Islands',
        dialCode: '+1-345',
    },
    {
        value: 'CF',
        text: 'Central African Republic',
        dialCode: '+236',
    },
    {
        value: 'TD',
        text: 'Chad',
        dialCode: '+235',
    },
    {
        value: 'CL',
        text: 'Chile',
        dialCode: '+56',
    },
    {
        value: 'CN',
        text: 'China',
        dialCode: '+86',
    },
    {
        value: 'CX',
        text: 'Christmas Island',
        dialCode: '+61',
    },
    {
        value: 'CC',
        text: 'Cocos (Keeling) Islands',
        dialCode: '+61',
    },
    {
        value: 'CO',
        text: 'Colombia',
        dialCode: '+57',
    },
    {
        value: 'KM',
        text: 'Comoros',
        dialCode: '+269',
    },
    {
        value: 'CG',
        text: 'Congo',
        dialCode: '+242',
    },
    {
        value: 'CD',
        text: 'Congo, the Democratic Republic of the',
        dialCode: '+243',
    },
    {
        value: 'CK',
        text: 'Cook Islands',
        dialCode: '+682',
    },
    {
        value: 'CR',
        text: 'Costa Rica',
        dialCode: '+506',
    },
    {
        value: 'CI',
        text: "Côte d'Ivoire",
        dialCode: '+225',
    },
    {
        value: 'HR',
        text: 'Croatia',
        dialCode: '+385',
    },
    {
        value: 'CU',
        text: 'Cuba',
        dialCode: '+53',
    },
    {
        value: 'CW',
        text: 'Curaçao',
        dialCode: '+599',
    },
    {
        value: 'CY',
        text: 'Cyprus',
        dialCode: '+357',
    },
    {
        value: 'CZ',
        text: 'Czech Republic',
        dialCode: '+420',
    },
    {
        value: 'DK',
        text: 'Denmark',
        dialCode: '+45',
    },
    {
        value: 'DJ',
        text: 'Djibouti',
        dialCode: '+253',
    },
    {
        value: 'DM',
        text: 'Dominica',
        dialCode: '+1-767',
    },
    {
        value: 'DO',
        text: 'Dominican Republic',
        dialCode: '+1-809',
    },
    {
        value: 'EC',
        text: 'Ecuador',
        dialCode: '+593',
    },
    {
        value: 'EG',
        text: 'Egypt',
        dialCode: '+20',
    },
    {
        value: 'SV',
        text: 'El Salvador',
        dialCode: '+503',
    },
    {
        value: 'GQ',
        text: 'Equatorial Guinea',
        dialCode: '+240',
    },
    {
        value: 'ER',
        text: 'Eritrea',
        dialCode: '+291',
    },
    {
        value: 'EE',
        text: 'Estonia',
        dialCode: '+372',
    },
    {
        value: 'ET',
        text: 'Ethiopia',
        dialCode: '+251',
    },
    {
        value: 'FK',
        text: 'Falkland Islands (Malvinas)',
        dialCode: '+500',
    },
    {
        value: 'FO',
        text: 'Faroe Islands',
        dialCode: '+298',
    },
    {
        value: 'FJ',
        text: 'Fiji',
        dialCode: '+679',
    },
    {
        value: 'FI',
        text: 'Finland',
        dialCode: '+358',
    },
    {
        value: 'FR',
        text: 'France',
        dialCode: '+33',
    },
    {
        value: 'GF',
        text: 'French Guiana',
        dialCode: '+594',
    },
    {
        value: 'PF',
        text: 'French Polynesia',
        dialCode: '+689',
    },
    {
        value: 'TF',
        text: 'French Southern Territories',
        dialCode: '+262',
    },
    {
        value: 'GA',
        text: 'Gabon',
        dialCode: '+241',
    },
    {
        value: 'GM',
        text: 'Gambia',
        dialCode: '+220',
    },
    {
        value: 'GE',
        text: 'Georgia',
        dialCode: '+995',
    },
    {
        value: 'DE',
        text: 'Germany',
        dialCode: '+49',
    },
    {
        value: 'GH',
        text: 'Ghana',
        dialCode: '+233',
    },
    {
        value: 'GI',
        text: 'Gibraltar',
        dialCode: '+350',
    },
    {
        value: 'GR',
        text: 'Greece',
        dialCode: '+30',
    },
    {
        value: 'GL',
        text: 'Greenland',
        dialCode: '+299',
    },
    {
        value: 'GD',
        text: 'Grenada',
        dialCode: '+1-473',
    },
    {
        value: 'GP',
        text: 'Guadeloupe',
        dialCode: '+590',
    },
    {
        value: 'GU',
        text: 'Guam',
        dialCode: '+1-671',
    },
    {
        value: 'GT',
        text: 'Guatemala',
        dialCode: '+502',
    },
    {
        value: 'GG',
        text: 'Guernsey',
        dialCode: '+44-1481',
    },
    {
        value: 'GN',
        text: 'Guinea',
        dialCode: '+224',
    },
    {
        value: 'GW',
        text: 'Guinea-Bissau',
        dialCode: '+245',
    },
    {
        value: 'GY',
        text: 'Guyana',
        dialCode: '+592',
    },
    {
        value: 'HT',
        text: 'Haiti',
        dialCode: '+509',
    },
    {
        value: 'HM',
        text: 'Heard Island and McDonald Islands',
        dialCode: '+672',
    },
    {
        value: 'VA',
        text: 'Holy See (Vatican City State)',
        dialCode: '+379',
    },
    {
        value: 'HN',
        text: 'Honduras',
        dialCode: '+504',
    },
    {
        value: 'HK',
        text: 'Hong Kong',
        dialCode: '+852',
    },
    {
        value: 'HU',
        text: 'Hungary',
        dialCode: '+36',
    },
    {
        value: 'IS',
        text: 'Iceland',
        dialCode: '+354',
    },
    {
        value: 'IN',
        text: 'India',
        dialCode: '+91',
    },
    {
        value: 'ID',
        text: 'Indonesia',
        dialCode: '+62',
    },
    {
        value: 'IR',
        text: 'Iran, Islamic Republic of',
        dialCode: '+98',
    },
    {
        value: 'IQ',
        text: 'Iraq',
        dialCode: '+964',
    },
    {
        value: 'IE',
        text: 'Ireland',
        dialCode: '+353',
    },
    {
        value: 'IM',
        text: 'Isle of Man',
        dialCode: '+44-1624',
    },
    {
        value: 'IL',
        text: 'Israel',
        dialCode: '+972',
    },
    {
        value: 'IT',
        text: 'Italy',
        dialCode: '+39',
    },
    {
        value: 'JM',
        text: 'Jamaica',
        dialCode: '+1-876',
    },
    {
        value: 'JP',
        text: 'Japan',
        dialCode: '+81',
    },
    {
        value: 'JE',
        text: 'Jersey',
        dialCode: '+44-1534',
    },
    {
        value: 'JO',
        text: 'Jordan',
        dialCode: '+962',
    },
    {
        value: 'KZ',
        text: 'Kazakhstan',
        dialCode: '+7',
    },
    {
        value: 'KE',
        text: 'Kenya',
        dialCode: '+254',
    },
    {
        value: 'KI',
        text: 'Kiribati',
        dialCode: '+686',
    },
    {
        value: 'KP',
        text: "Korea, Democratic People's Republic of",
        dialCode: '+850',
    },
    {
        value: 'KR',
        text: 'Korea, Republic of',
        dialCode: '+82',
    },
    {
        value: 'KW',
        text: 'Kuwait',
        dialCode: '+965',
    },
    {
        value: 'KG',
        text: 'Kyrgyzstan',
        dialCode: '+996',
    },
    {
        value: 'LA',
        text: "Lao People's Democratic Republic",
        dialCode: '+856',
    },
    {
        value: 'LV',
        text: 'Latvia',
        dialCode: '+371',
    },
    {
        value: 'LB',
        text: 'Lebanon',
        dialCode: '+961',
    },
    {
        value: 'LS',
        text: 'Lesotho',
        dialCode: '+266',
    },
    {
        value: 'LR',
        text: 'Liberia',
        dialCode: '+231',
    },
    {
        value: 'LY',
        text: 'Libya',
        dialCode: '+218',
    },
    {
        value: 'LI',
        text: 'Liechtenstein',
        dialCode: '+423',
    },
    {
        value: 'LT',
        text: 'Lithuania',
        dialCode: '+370',
    },
    {
        value: 'LU',
        text: 'Luxembourg',
        dialCode: '+352',
    },
    {
        value: 'MO',
        text: 'Macao',
        dialCode: '+853',
    },
    {
        value: 'MK',
        text: 'Macedonia, the former Yugoslav Republic of',
        dialCode: '+389',
    },
    {
        value: 'MG',
        text: 'Madagascar',
        dialCode: '+261',
    },
    {
        value: 'MW',
        text: 'Malawi',
        dialCode: '+265',
    },
    {
        value: 'MY',
        text: 'Malaysia',
        dialCode: '+60',
    },
    {
        value: 'MV',
        text: 'Maldives',
        dialCode: '+960',
    },
    {
        value: 'ML',
        text: 'Mali',
        dialCode: '+223',
    },
    {
        value: 'MT',
        text: 'Malta',
        dialCode: '+356',
    },
    {
        value: 'MH',
        text: 'Marshall Islands',
        dialCode: '+692',
    },
    {
        value: 'MQ',
        text: 'Martinique',
        dialCode: '+596',
    },
    {
        value: 'MR',
        text: 'Mauritania',
        dialCode: '+222',
    },
    {
        value: 'MU',
        text: 'Mauritius',
        dialCode: '+230',
    },
    {
        value: 'YT',
        text: 'Mayotte',
        dialCode: '+262',
    },
    {
        value: 'MX',
        text: 'Mexico',
        dialCode: '+52',
    },
    {
        value: 'FM',
        text: 'Micronesia, Federated States of',
        dialCode: '+691',
    },
    {
        value: 'MD',
        text: 'Moldova, Republic of',
        dialCode: '+373',
    },
    {
        value: 'MC',
        text: 'Monaco',
        dialCode: '+377',
    },
    {
        value: 'MN',
        text: 'Mongolia',
        dialCode: '+976',
    },
    {
        value: 'ME',
        text: 'Montenegro',
        dialCode: '+382',
    },
    {
        value: 'MS',
        text: 'Montserrat',
        dialCode: '+1-664',
    },
    {
        value: 'MA',
        text: 'Morocco',
        dialCode: '+212',
    },
    {
        value: 'MZ',
        text: 'Mozambique',
        dialCode: '+258',
    },
    {
        value: 'MM',
        text: 'Myanmar',
        dialCode: '+95',
    },
    {
        value: 'NA',
        text: 'Namibia',
        dialCode: '+264',
    },
    {
        value: 'NR',
        text: 'Nauru',
        dialCode: '+674',
    },
    {
        value: 'NP',
        text: 'Nepal',
        dialCode: '+977',
    },
    {
        value: 'NL',
        text: 'Netherlands',
        dialCode: '+31',
    },
    {
        value: 'NC',
        text: 'New Caledonia',
        dialCode: '+687',
    },
    {
        value: 'NZ',
        text: 'New Zealand',
        dialCode: '+64',
    },
    {
        value: 'NI',
        text: 'Nicaragua',
        dialCode: '+505',
    },
    {
        value: 'NE',
        text: 'Niger',
        dialCode: '+227',
    },
    {
        value: 'NG',
        text: 'Nigeria',
        dialCode: '+234',
    },
    {
        value: 'NU',
        text: 'Niue',
        dialCode: '+683',
    },
    {
        value: 'NF',
        text: 'Norfolk Island',
        dialCode: '+672',
    },
    {
        value: 'MP',
        text: 'Northern Mariana Islands',
        dialCode: '+1-670',
    },
    {
        value: 'NO',
        text: 'Norway',
        dialCode: '+47',
    },
    {
        value: 'OM',
        text: 'Oman',
        dialCode: '+968',
    },
    {
        value: 'PK',
        text: 'Pakistan',
        dialCode: '+92',
    },
    {
        value: 'PW',
        text: 'Palau',
        dialCode: '+680',
    },
    {
        value: 'PS',
        text: 'Palestinian Territory, Occupied',
        dialCode: '+970',
    },
    {
        value: 'PA',
        text: 'Panama',
        dialCode: '+507',
    },
    {
        value: 'PG',
        text: 'Papua New Guinea',
        dialCode: '+675',
    },
    {
        value: 'PY',
        text: 'Paraguay',
        dialCode: '+595',
    },
    {
        value: 'PE',
        text: 'Peru',
        dialCode: '+51',
    },
    {
        value: 'PH',
        text: 'Philippines',
        dialCode: '+63',
    },
    {
        value: 'PN',
        text: 'Pitcairn',
        dialCode: '+64',
    },
    {
        value: 'PL',
        text: 'Poland',
        dialCode: '+48',
    },
    {
        value: 'PT',
        text: 'Portugal',
        dialCode: '+351',
    },
    {
        value: 'PR',
        text: 'Puerto Rico',
        dialCode: '+1-787',
    },
    {
        value: 'QA',
        text: 'Qatar',
        dialCode: '+974',
    },
    {
        value: 'RE',
        text: 'Réunion',
        dialCode: '+262',
    },
    {
        value: 'RO',
        text: 'Romania',
        dialCode: '+40',
    },
    {
        value: 'RU',
        text: 'Russian Federation',
        dialCode: '+7',
    },
    {
        value: 'RW',
        text: 'Rwanda',
        dialCode: '+250',
    },
    {
        value: 'BL',
        text: 'Saint Barthélemy',
        dialCode: '+590',
    },
    {
        value: 'SH',
        text: 'Saint Helena, Ascension and Tristan da Cunha',
        dialCode: '+290',
    },
    {
        value: 'KN',
        text: 'Saint Kitts and Nevis',
        dialCode: '+1-869',
    },
    {
        value: 'LC',
        text: 'Saint Lucia',
        dialCode: '+1-758',
    },
    {
        value: 'MF',
        text: 'Saint Martin (French part)',
        dialCode: '+590',
    },
    {
        value: 'PM',
        text: 'Saint Pierre and Miquelon',
        dialCode: '+508',
    },
    {
        value: 'VC',
        text: 'Saint Vincent and the Grenadines',
        dialCode: '+1-784',
    },
    {
        value: 'WS',
        text: 'Samoa',
        dialCode: '+685',
    },
    {
        value: 'SM',
        text: 'San Marino',
        dialCode: '+378',
    },
    {
        value: 'ST',
        text: 'Sao Tome and Principe',
        dialCode: '+239',
    },
    {
        value: 'SA',
        text: 'Saudi Arabia',
        dialCode: '+966',
    },
    {
        value: 'SN',
        text: 'Senegal',
        dialCode: '+221',
    },
    {
        value: 'RS',
        text: 'Serbia',
        dialCode: '+381',
    },
    {
        value: 'SC',
        text: 'Seychelles',
        dialCode: '+248',
    },
    {
        value: 'SL',
        text: 'Sierra Leone',
        dialCode: '+232',
    },
    {
        value: 'SG',
        text: 'Singapore',
        dialCode: '+65',
    },
    {
        value: 'SX',
        text: 'Sint Maarten (Dutch part)',
        dialCode: '+1-721',
    },
    {
        value: 'SK',
        text: 'Slovakia',
        dialCode: '+421',
    },
    {
        value: 'SI',
        text: 'Slovenia',
        dialCode: '+386',
    },
    {
        value: 'SB',
        text: 'Solomon Islands',
        dialCode: '+677',
    },
    {
        value: 'SO',
        text: 'Somalia',
        dialCode: '+252',
    },
    {
        value: 'ZA',
        text: 'South Africa',
        dialCode: '+27',
    },
    {
        value: 'GS',
        text: 'South Georgia and the South Sandwich Islands',
        dialCode: '+500',
    },
    {
        value: 'SS',
        text: 'South Sudan',
        dialCode: '+211',
    },
    {
        value: 'ES',
        text: 'Spain',
        dialCode: '+34',
    },
    {
        value: 'LK',
        text: 'Sri Lanka',
        dialCode: '+94',
    },
    {
        value: 'SD',
        text: 'Sudan',
        dialCode: '+249',
    },
    {
        value: 'SR',
        text: 'Suriname',
        dialCode: '+597',
    },
    {
        value: 'SJ',
        text: 'Svalbard and Jan Mayen',
        dialCode: '+47',
    },
    {
        value: 'SZ',
        text: 'Swaziland',
        dialCode: '+268',
    },
    {
        value: 'SE',
        text: 'Sweden',
        dialCode: '+46',
    },
    {
        value: 'CH',
        text: 'Switzerland',
        dialCode: '+41',
    },
    {
        value: 'SY',
        text: 'Syrian Arab Republic',
        dialCode: '+963',
    },
    {
        value: 'TW',
        text: 'Taiwan, Province of China',
        dialCode: '+886',
    },
    {
        value: 'TJ',
        text: 'Tajikistan',
        dialCode: '+992',
    },
    {
        value: 'TZ',
        text: 'Tanzania, United Republic of',
        dialCode: '+255',
    },
    {
        value: 'TH',
        text: 'Thailand',
        dialCode: '+66',
    },
    {
        value: 'TL',
        text: 'Timor-Leste',
        dialCode: '+670',
    },
    {
        value: 'TG',
        text: 'Togo',
        dialCode: '+228',
    },
    {
        value: 'TK',
        text: 'Tokelau',
        dialCode: '+690',
    },
    {
        value: 'TO',
        text: 'Tonga',
        dialCode: '+676',
    },
    {
        value: 'TT',
        text: 'Trinidad and Tobago',
        dialCode: '+1-868',
    },
    {
        value: 'TN',
        text: 'Tunisia',
        dialCode: '+216',
    },
    {
        value: 'TR',
        text: 'Turkey',
        dialCode: '+90',
    },
    {
        value: 'TM',
        text: 'Turkmenistan',
        dialCode: '+993',
    },
    {
        value: 'TC',
        text: 'Turks and Caicos Islands',
        dialCode: '+1-649',
    },
    {
        value: 'TV',
        text: 'Tuvalu',
        dialCode: '+688',
    },
    {
        value: 'UG',
        text: 'Uganda',
        dialCode: '+256',
    },
    {
        value: 'UA',
        text: 'Ukraine',
        dialCode: '+380',
    },
    {
        value: 'AE',
        text: 'United Arab Emirates',
        dialCode: '+971',
    },
    {
        value: 'GB',
        text: 'United Kingdom',
        dialCode: '+44',
    },
    {
        value: 'US',
        text: 'United States',
        dialCode: '+1',
    },
    {
        value: 'UM',
        text: 'United States Minor Outlying Islands',
        dialCode: '+1',
    },
    {
        value: 'UY',
        text: 'Uruguay',
        dialCode: '+598',
    },
    {
        value: 'UZ',
        text: 'Uzbekistan',
        dialCode: '+998',
    },
    {
        value: 'VU',
        text: 'Vanuatu',
        dialCode: '+678',
    },
    {
        value: 'VE',
        text: 'Venezuela, Bolivarian Republic of',
        dialCode: '+58',
    },
    {
        value: 'VN',
        text: 'Viet Nam',
        dialCode: '+84',
    },
    {
        value: 'VG',
        text: 'Virgin Islands, British',
        dialCode: '+1-284',
    },
    {
        value: 'VI',
        text: 'Virgin Islands, U.S.',
        dialCode: '+1-340',
    },
    {
        value: 'WF',
        text: 'Wallis and Futuna',
        dialCode: '+681',
    },
    {
        value: 'EH',
        text: 'Western Sahara',
        dialCode: '+212',
    },
    {
        value: 'YE',
        text: 'Yemen',
        dialCode: '+967',
    },
    {
        value: 'ZM',
        text: 'Zambia',
        dialCode: '+260',
    },
    {
        value: 'ZW',
        text: 'Zimbabwe',
        dialCode: '+263',
    },
]

export const US_STATES = [
    {
        text: 'Alabama',
        value: 'AL',
    },
    {
        text: 'Alaska',
        value: 'AK',
    },
    {
        text: 'American Samoa',
        value: 'AS',
    },
    {
        text: 'Arizona',
        value: 'AZ',
    },
    {
        text: 'Arkansas',
        value: 'AR',
    },
    {
        text: 'California',
        value: 'CA',
    },
    {
        text: 'Colorado',
        value: 'CO',
    },
    {
        text: 'Connecticut',
        value: 'CT',
    },
    {
        text: 'Delaware',
        value: 'DE',
    },
    {
        text: 'District Of Columbia',
        value: 'DC',
    },
    {
        text: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        text: 'Florida',
        value: 'FL',
    },
    {
        text: 'Georgia',
        value: 'GA',
    },
    {
        text: 'Guam',
        value: 'GU',
    },
    {
        text: 'Hawaii',
        value: 'HI',
    },
    {
        text: 'Idaho',
        value: 'ID',
    },
    {
        text: 'Illinois',
        value: 'IL',
    },
    {
        text: 'Indiana',
        value: 'IN',
    },
    {
        text: 'Iowa',
        value: 'IA',
    },
    {
        text: 'Kansas',
        value: 'KS',
    },
    {
        text: 'Kentucky',
        value: 'KY',
    },
    {
        text: 'Louisiana',
        value: 'LA',
    },
    {
        text: 'Maine',
        value: 'ME',
    },
    {
        text: 'Marshall Islands',
        value: 'MH',
    },
    {
        text: 'Maryland',
        value: 'MD',
    },
    {
        text: 'Massachusetts',
        value: 'MA',
    },
    {
        text: 'Michigan',
        value: 'MI',
    },
    {
        text: 'Minnesota',
        value: 'MN',
    },
    {
        text: 'Mississippi',
        value: 'MS',
    },
    {
        text: 'Missouri',
        value: 'MO',
    },
    {
        text: 'Montana',
        value: 'MT',
    },
    {
        text: 'Nebraska',
        value: 'NE',
    },
    {
        text: 'Nevada',
        value: 'NV',
    },
    {
        text: 'New Hampshire',
        value: 'NH',
    },
    {
        text: 'New Jersey',
        value: 'NJ',
    },
    {
        text: 'New Mexico',
        value: 'NM',
    },
    {
        text: 'New York',
        value: 'NY',
    },
    {
        text: 'North Carolina',
        value: 'NC',
    },
    {
        text: 'North Dakota',
        value: 'ND',
    },
    {
        text: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        text: 'Ohio',
        value: 'OH',
    },
    {
        text: 'Oklahoma',
        value: 'OK',
    },
    {
        text: 'Oregon',
        value: 'OR',
    },
    {
        text: 'Palau',
        value: 'PW',
    },
    {
        text: 'Pennsylvania',
        value: 'PA',
    },
    {
        text: 'Puerto Rico',
        value: 'PR',
    },
    {
        text: 'Rhode Island',
        value: 'RI',
    },
    {
        text: 'South Carolina',
        value: 'SC',
    },
    {
        text: 'South Dakota',
        value: 'SD',
    },
    {
        text: 'Tennessee',
        value: 'TN',
    },
    {
        text: 'Texas',
        value: 'TX',
    },
    {
        text: 'Utah',
        value: 'UT',
    },
    {
        text: 'Vermont',
        value: 'VT',
    },
    {
        text: 'Virgin Islands',
        value: 'VI',
    },
    {
        text: 'Virginia',
        value: 'VA',
    },
    {
        text: 'Washington',
        value: 'WA',
    },
    {
        text: 'West Virginia',
        value: 'WV',
    },
    {
        text: 'Wisconsin',
        value: 'WI',
    },
    {
        text: 'Wyoming',
        value: 'WY',
    },
]

export const TIMEZONES = {
    'Dateline Standard Time': {
        text: '(UTC-12:00) International Date Line West',
        offset: -12,
    },
    'UTC-11': {
        text: '(UTC-11:00) Coordinated Universal Time-11',
        offset: -11,
    },
    'Hawaiian Standard Time': {
        text: '(UTC-10:00) Hawaii',
        offset: -10,
    },
    'Alaskan Standard Time': {
        text: '(UTC-09:00) Alaska',
        offset: -8,
    },
    'Pacific Standard Time (Mexico)': {
        text: '(UTC-08:00) Baja California',
        offset: -7,
    },
    'Pacific Daylight Time': {
        text: '(UTC-07:00) Pacific Daylight Time (US & Canada)',
        offset: -7,
    },
    'Pacific Standard Time': {
        text: '(UTC-08:00) Pacific Standard Time (US & Canada)',
        offset: -8,
    },
    'US Mountain Standard Time': {
        text: '(UTC-07:00) Arizona',
        offset: -7,
    },
    'Mountain Standard Time (Mexico)': {
        text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
        offset: -6,
    },
    'Mountain Standard Time': {
        text: '(UTC-07:00) Mountain Time (US & Canada)',
        offset: -6,
    },
    'Central America Standard Time': {
        text: '(UTC-06:00) Central America',
        offset: -6,
    },
    'Central Standard Time': {
        text: '(UTC-06:00) Central Time (US & Canada)',
        offset: -5,
    },
    'Central Standard Time (Mexico)': {
        text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
        offset: -5,
    },
    'Canada Central Standard Time': {
        text: '(UTC-06:00) Saskatchewan',
        offset: -6,
    },
    'SA Pacific Standard Time': {
        text: '(UTC-05:00) Bogota, Lima, Quito',
        offset: -5,
    },
    'Eastern Standard Time': {
        text: '(UTC-05:00) Eastern Time (US & Canada)',
        offset: -5,
    },
    'Eastern Daylight Time': {
        text: '(UTC-04:00) Eastern Daylight Time (US & Canada)',
        offset: -4,
    },
    'US Eastern Standard Time': {
        text: '(UTC-05:00) Indiana (East)',
        offset: -5,
    },
    'Venezuela Standard Time': {
        text: '(UTC-04:30) Caracas',
        offset: -4.5,
    },
    'Paraguay Standard Time': {
        text: '(UTC-04:00) Asuncion',
        offset: -4,
    },
    'Atlantic Standard Time': {
        text: '(UTC-04:00) Atlantic Time (Canada)',
        offset: -3,
    },
    'Central Brazilian Standard Time': {
        text: '(UTC-04:00) Cuiaba',
        offset: -4,
    },
    'SA Western Standard Time': {
        text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        offset: -4,
    },
    'Pacific SA Standard Time': {
        text: '(UTC-04:00) Santiago',
        offset: -4,
    },
    'Newfoundland Standard Time': {
        text: '(UTC-03:30) Newfoundland',
        offset: -2.5,
    },
    'E. South America Standard Time': {
        text: '(UTC-03:00) Brasilia',
        offset: -3,
    },
    'Argentina Standard Time': {
        text: '(UTC-03:00) Buenos Aires',
        offset: -3,
    },
    'SA Eastern Standard Time': {
        text: '(UTC-03:00) Cayenne, Fortaleza',
        offset: -3,
    },
    'Greenland Standard Time': {
        text: '(UTC-03:00) Greenland',
        offset: -3,
    },
    'Montevideo Standard Time': {
        text: '(UTC-03:00) Montevideo',
        offset: -3,
    },
    'Bahia Standard Time': {
        text: '(UTC-03:00) Salvador',
        offset: -3,
    },
    'UTC-02': {
        text: '(UTC-02:00) Coordinated Universal Time-02',
        offset: -2,
    },
    'Mid-Atlantic Standard Time': {
        text: '(UTC-02:00) Mid-Atlantic - Old',
        offset: -1,
    },
    'Azores Standard Time': {
        text: '(UTC-01:00) Azores',
        offset: 0,
    },
    'Cape Verde Standard Time': {
        text: '(UTC-01:00) Cape Verde Is.',
        offset: -1,
    },
    'Morocco Standard Time': {
        text: '(UTC) Casablanca',
        offset: 1,
    },
    UTC: {
        text: '(UTC) Coordinated Universal Time',
        offset: 0,
    },
    'GMT Standard Time': {
        text: '(UTC) Dublin, Lisbon',
        offset: 1,
    },
    'British Summer Time': {
        text: '(UTC+01:00) Edinburgh, London',
        offset: 1,
    },
    'Greenwich Standard Time': {
        text: '(UTC) Monrovia, Reykjavik',
        offset: 0,
    },
    'W. Europe Standard Time': {
        text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        offset: 2,
    },
    'Central Europe Standard Time': {
        text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        offset: 2,
    },
    'Romance Standard Time': {
        text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        offset: 2,
    },
    'Central European Standard Time': {
        text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        offset: 2,
    },
    'W. Central Africa Standard Time': {
        text: '(UTC+01:00) West Central Africa',
        offset: 1,
    },
    'Namibia Standard Time': {
        text: '(UTC+01:00) Windhoek',
        offset: 1,
    },
    'GTB Standard Time': {
        text: '(UTC+02:00) Athens, Bucharest',
        offset: 3,
    },
    'Middle East Standard Time': {
        text: '(UTC+02:00) Beirut',
        offset: 3,
    },
    'Egypt Standard Time': {
        text: '(UTC+02:00) Cairo',
        offset: 2,
    },
    'Syria Standard Time': {
        text: '(UTC+02:00) Damascus',
        offset: 3,
    },
    'E. Europe Standard Time': {
        text: '(UTC+02:00) E. Europe',
        offset: 3,
    },
    'South Africa Standard Time': {
        text: '(UTC+02:00) Harare, Pretoria',
        offset: 2,
    },
    'FLE Standard Time': {
        text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        offset: 3,
    },
    'Turkey Standard Time': {
        text: '(UTC+03:00) Istanbul',
        offset: 3,
    },
    'Israel Standard Time': {
        text: '(UTC+02:00) Jerusalem',
        offset: 3,
    },
    'Libya Standard Time': {
        text: '(UTC+02:00) Tripoli',
        offset: 2,
    },
    'Jordan Standard Time': {
        text: '(UTC+03:00) Amman',
        offset: 3,
    },
    'Arabic Standard Time': {
        text: '(UTC+03:00) Baghdad',
        offset: 3,
    },
    'Kaliningrad Standard Time': {
        text: '(UTC+02:00) Kaliningrad',
        offset: 3,
    },
    'Arab Standard Time': {
        text: '(UTC+03:00) Kuwait, Riyadh',
        offset: 3,
    },
    'E. Africa Standard Time': {
        text: '(UTC+03:00) Nairobi',
        offset: 3,
    },
    'Moscow Standard Time': {
        text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
        offset: 3,
    },
    'Samara Time': {
        text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
        offset: 4,
    },
    'Iran Standard Time': {
        text: '(UTC+03:30) Tehran',
        offset: 4.5,
    },
    'Arabian Standard Time': {
        text: '(UTC+04:00) Abu Dhabi, Muscat',
        offset: 4,
    },
    'Azerbaijan Standard Time': {
        text: '(UTC+04:00) Baku',
        offset: 5,
    },
    'Mauritius Standard Time': {
        text: '(UTC+04:00) Port Louis',
        offset: 4,
    },
    'Georgian Standard Time': {
        text: '(UTC+04:00) Tbilisi',
        offset: 4,
    },
    'Caucasus Standard Time': {
        text: '(UTC+04:00) Yerevan',
        offset: 4,
    },
    'Afghanistan Standard Time': {
        text: '(UTC+04:30) Kabul',
        offset: 4.5,
    },
    'West Asia Standard Time': {
        text: '(UTC+05:00) Ashgabat, Tashkent',
        offset: 5,
    },
    'Yekaterinburg Time': {
        text: '(UTC+05:00) Yekaterinburg',
        offset: 5,
    },
    'Pakistan Standard Time': {
        text: '(UTC+05:00) Islamabad, Karachi',
        offset: 5,
    },
    'India Standard Time': {
        text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        offset: 5.5,
    },
    'Sri Lanka Standard Time': {
        text: '(UTC+05:30) Sri Jayawardenepura',
        offset: 5.5,
    },
    'Nepal Standard Time': {
        text: '(UTC+05:45) Kathmandu',
        offset: 5.75,
    },
    'Central Asia Standard Time': {
        text: '(UTC+06:00) Nur-Sultan (Astana)',
        offset: 6,
    },
    'Bangladesh Standard Time': {
        text: '(UTC+06:00) Dhaka',
        offset: 6,
    },
    'Myanmar Standard Time': {
        text: '(UTC+06:30) Yangon (Rangoon)',
        offset: 6.5,
    },
    'SE Asia Standard Time': {
        text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        offset: 7,
    },
    'N. Central Asia Standard Time': {
        text: '(UTC+07:00) Novosibirsk',
        offset: 7,
    },
    'China Standard Time': {
        text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        offset: 8,
    },
    'North Asia Standard Time': {
        text: '(UTC+08:00) Krasnoyarsk',
        offset: 8,
    },
    'Singapore Standard Time': {
        text: '(UTC+08:00) Kuala Lumpur, Singapore',
        offset: 8,
    },
    'W. Australia Standard Time': {
        text: '(UTC+08:00) Perth',
        offset: 8,
    },
    'Taipei Standard Time': {
        text: '(UTC+08:00) Taipei',
        offset: 8,
    },
    'Ulaanbaatar Standard Time': {
        text: '(UTC+08:00) Ulaanbaatar',
        offset: 8,
    },
    'North Asia East Standard Time': {
        text: '(UTC+08:00) Irkutsk',
        offset: 8,
    },
    'Japan Standard Time': {
        text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        offset: 9,
    },
    'Korea Standard Time': {
        text: '(UTC+09:00) Seoul',
        offset: 9,
    },
    'Cen. Australia Standard Time': {
        text: '(UTC+09:30) Adelaide',
        offset: 9.5,
    },
    'AUS Central Standard Time': {
        text: '(UTC+09:30) Darwin',
        offset: 9.5,
    },
    'E. Australia Standard Time': {
        text: '(UTC+10:00) Brisbane',
        offset: 10,
    },
    'AUS Eastern Standard Time': {
        text: '(UTC+10:00) Canberra, Melbourne, Sydney',
        offset: 10,
    },
    'West Pacific Standard Time': {
        text: '(UTC+10:00) Guam, Port Moresby',
        offset: 10,
    },
    'Tasmania Standard Time': {
        text: '(UTC+10:00) Hobart',
        offset: 10,
    },
    'Yakutsk Standard Time': {
        text: '(UTC+09:00) Yakutsk',
        offset: 9,
    },
    'Central Pacific Standard Time': {
        text: '(UTC+11:00) Solomon Is., New Caledonia',
        offset: 11,
    },
    'Vladivostok Standard Time': {
        text: '(UTC+11:00) Vladivostok',
        offset: 11,
    },
    'New Zealand Standard Time': {
        text: '(UTC+12:00) Auckland, Wellington',
        offset: 12,
    },
    'UTC+12': {
        text: '(UTC+12:00) Coordinated Universal Time+12',
        offset: 12,
    },
    'Fiji Standard Time': {
        text: '(UTC+12:00) Fiji',
        offset: 12,
    },
    'Magadan Standard Time': {
        text: '(UTC+12:00) Magadan',
        offset: 12,
    },
    'Kamchatka Standard Time': {
        text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
        offset: 13,
    },
    'Tonga Standard Time': {
        text: "(UTC+13:00) Nuku'alofa",
        offset: 13,
    },
    'Samoa Standard Time': {
        text: '(UTC+13:00) Samoa',
        offset: 13,
    },
}
