import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import ErrorMessage from '../../../components/common/ErrorMessage'

import PrivateFile from '../../../components/common/PrivateFile'
import SectionContainer from '../../../components/common/SectionContainer'
import Spinner from '../../../components/common/Spinner'

export default function VendorPage() {
    const { accounts, accountsLoading, accountsError } = useData(
        `/v1/accounts/me`,
        'accounts',
        (data) => (data || []).filter((a) => a.accountType === 'vendor'),
    )

    const { questionsForm, questionsFormError, questionsFormLoading } = useData(
        '/v1/wholesale/me/products-questions',
        'questionsForm',
    )

    if (accountsLoading) {
        return <Spinner />
    }

    if (accountsError) {
        return <ErrorMessage>{accountsError}</ErrorMessage>
    }

    if (!accounts?.length) {
        return <SectionContainer>No vendor accounts found.</SectionContainer>
    }

    return (
        <>
            {accounts.map((account) => (
                <VendorInfo key={account.id} account={account} />
            ))}
            <SectionContainer card>
                <h3>Submitted answers & product images</h3>
                <div>
                    {questionsFormLoading ? <Spinner /> : null}
                    {questionsFormError ? (
                        <ErrorMessage>{questionsFormError}</ErrorMessage>
                    ) : null}
                    {questionsForm
                        ? Object.entries(questionsForm.formFields || {}).map(
                              ([key, value]) => (
                                  <div key={key}>
                                      <small className="text-subdued">
                                          {key}
                                      </small>
                                      <div>
                                          {key.startsWith('_drive_file_id') ? (
                                              <PrivateFile
                                                  openFullOnClick
                                                  driveFileId={value}
                                                  usage={'vendorProductSample'}
                                                  parentName={
                                                      'user/vendorProductSample'
                                                  }
                                              />
                                          ) : value === '' ? (
                                              'N/A'
                                          ) : (
                                              value
                                          )}
                                      </div>
                                      <br />
                                  </div>
                              ),
                          )
                        : null}
                </div>
            </SectionContainer>
        </>
    )
}

function VendorInfo({ account }) {
    const auth = useAuth()

    const company = auth.user.orgsData.find((o) => o.id === account.ownerId)

    return (
        <>
            <SectionContainer card>
                <h3>Information</h3>
                <div>
                    <div>
                        <small>
                            <strong>Company</strong>
                        </small>
                        <br />
                        <small>{company?.name || 'None'}</small>
                    </div>
                    <div>
                        <small>
                            <strong>Acronym</strong>
                        </small>
                        <br />
                        <small> {company?.acronym || 'None'}</small>
                    </div>
                    <div>
                        <small>
                            <strong>Locations</strong>
                        </small>
                        <br />
                        <small>
                            {account?.locations?.join(', ') || 'None'}
                        </small>
                    </div>
                </div>
            </SectionContainer>
        </>
    )
}
