import BrowserFileInput from './BrowserFileInput'

export default function FileInput({ onChange, ...rest }) {
    function handleChange(files) {
        onChange(files)
    }

    return (
        <BrowserFileInput
            setFiles={handleChange}
            {...rest}
            required={rest.required}
            accept={rest.accept}
        />
    )
}
