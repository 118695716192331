import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import { useState } from 'react'
import Input from '../../../components/common/data-form/Input'
import Tag from '../../../components/common/Tag'

export default function ShopsList({ showAll }) {
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const auth = useAuth()
    const [orgId, setOrgId] = useState(auth.user.orgsData[0]?.id)

    const hasMultipleOrgs = auth.user.orgsData.length > 1

    function onDelete(item) {
        setModal(
            <ConfirmModalContent
                text={
                    auth.isAdmin
                        ? 'This may cause issues with app functionality. Any products will stop syncing.'
                        : ''
                }
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/shop/shops/${item.id}`,
                        {},
                        'DELETE',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    matchMutate(/\/v1\/shop\/shops*/)
                    setToast('Deleted')
                }}
            />,
            auth.isAdmin
                ? 'Are you sure?'
                : 'Are you sure you want to delete this shop?',
        )
    }

    return (
        <>
            {!auth.isAdmin && hasMultipleOrgs && (
                <>
                    <br />
                    <Input
                        label="Company"
                        type="select"
                        id="company"
                        required
                        options={[
                            { value: '', label: 'Select Company' },
                            ...auth.user.orgsData.map((o) => ({
                                value: o.id,
                                label: o.name,
                            })),
                        ]}
                        value={orgId}
                        onChange={(v) => setOrgId(v)}
                    />
                </>
            )}

            {(Boolean(orgId) || auth.isAdmin) && (
                <ResourceList
                    key={orgId}
                    renderMainButton={!showAll}
                    newItemLabel={'New shop'}
                    apiUrl={'/shop/shops'}
                    initialQuery={{
                        fields:
                            auth.isAdmin && !showAll
                                ? 'name,fullName,markupPercentage,secondaryMarkupPercentage,isWholesale,isSecondary,ownerId'
                                : 'name,fullName,ownerId',
                        appShops: auth.isAdmin && !showAll ? true : false,
                        ownerId: !showAll && !auth.isAdmin ? orgId : undefined,
                        withShared: !showAll,
                    }}
                    newItemPath={!showAll && '/shop/shopify-shops/new'}
                    itemClickPath={!showAll && '/shop/shopify-shops/:item.id'}
                    actions={[
                        {
                            title: 'Locations',
                            icon: ICONS.LINK_GRAY,
                            link: '/shop/shopify-shops/:item.id/locations',
                            getHide: (item) =>
                                !auth.isAdmin || item.isShared || showAll,
                        },
                        {
                            title: 'Permissions (Beta)',
                            link: '/settings/permissions/shopify_shop/:item.id',
                            icon: ICONS.LOCK_GRAY,
                            getHide: (item) =>
                                auth.isClient || item.isShared || showAll,
                        },
                        {
                            title: 'Edit',
                            link: '/shop/shopify-shops/:item.id/edit',
                            icon: ICONS.EDIT_GRAY,
                            getHide: () => showAll,
                        },
                        {
                            title: 'Delete',
                            icon: ICONS.TRASH_RED,
                            type: 'alert',
                            getHide: () => showAll,

                            onClick: (item) => {
                                onDelete(item)
                            },
                        },
                    ]}
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => item.fullName,
                            getClassName: () => 'title',
                        },
                        {
                            column: 1,
                            getValue: (item) => `${item.name}.myshopify.com`,
                            getClassName: () => 'subtitle',
                        },
                        {
                            column: 1,
                            getValue: (item) =>
                                item.ownerId &&
                                showAll && (
                                    <Link to={`/organizations/${item.ownerId}`}>
                                        <span className="text-subdued">
                                            See company →
                                        </span>
                                    </Link>
                                ),
                            getClassName: () => 'subtitle',
                        },
                        {
                            column: 1,
                            getValue: (item) =>
                                auth.isAdmin && !showAll
                                    ? `Markup: ${
                                          item.markupPercentage
                                              ? `${item.markupPercentage}%`
                                              : 'none'
                                      }${
                                          item.secondaryMarkupPercentage
                                              ? `, Secondary: ${item.secondaryMarkupPercentage}%`
                                              : ''
                                      }`
                                    : '',
                            getClassName: () => 'subtitle',
                        },
                        {
                            column: 1,
                            getValue: (item) => (
                                <>
                                    {!item.ownerId && (
                                        <Tag color={'primary'}>App</Tag>
                                    )}
                                    {item.isWholesale && (
                                        <Tag outline>Wholesale</Tag>
                                    )}
                                    {item.isSecondary && (
                                        <Tag outline>Secondary</Tag>
                                    )}
                                </>
                            ),
                        },
                        {
                            column: 1,
                            getValue: (item) =>
                                item.isShared ? (
                                    <>
                                        <Tag outline>Shared</Tag>
                                    </>
                                ) : (
                                    ''
                                ),
                        },
                    ]}
                />
            )}
        </>
    )
}
