import React, { useState, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import useAuth from '../../../common/hooks/useAuth'
import { RETAIL_BUSINESS_CATEGORIES } from '../../../common/constants'
import FormSteps from '../../../common/FormSteps'
import ButtonGroup from '../../../components/common/ButtonGroup'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function EditProfileRetailBuyer() {
    const navigate = useNavigate()
    const auth = useAuth()
    const setToast = useToast()
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [step, setStep] = useState(1)
    const [data, setData] = useState({
        businessCategory: '',
        businessSubcategory: '',
        organizationId: searchParams.get('organizationId') || '',
    })

    const formRef = useRef(null)

    function onNext() {
        setError('')
        const currStep = step
        if (!formRef.current.reportValidity()) {
            return
        }
        setStep(currStep + 1)
    }

    function onPrev() {
        setError('')
        setStep(step - 1)
    }

    async function onSubmit(e) {
        setError('')

        if (!formRef.current.reportValidity()) {
            e.preventDefault()
            return
        }
        e.preventDefault()

        setLoading(true)

        const body = {
            accountType: 'retailBuyer',
            businessCategory: data.businessCategory,
            businessSubcategory: data.businessSubcategory,
            organizationId: data.organizationId,
        }

        const { error } = await fetchAPI('/v1/accounts/submit', body, 'POST')
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Submitted successfully')
        await auth.refetchAuth()
        navigate('/')
    }

    const totalSteps = 2

    return (
        <SectionContainer>
            <form ref={formRef}>
                <FormSteps step={step} totalSteps={totalSteps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {step === 1 && (
                    <>
                        <label
                            htmlFor="form-business-category"
                            className="required"
                        >
                            Business Category
                        </label>
                        <SelectInput
                            value={data.businessCategory}
                            id="form-business-category"
                            required
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    businessCategory: v,
                                })
                            }
                            options={[
                                {
                                    value: '',
                                    label: 'Select a business category',
                                },
                                ...Object.keys(RETAIL_BUSINESS_CATEGORIES).map(
                                    (key) => ({
                                        value: key,
                                        label: RETAIL_BUSINESS_CATEGORIES[key]
                                            .title,
                                    }),
                                ),
                            ]}
                        />

                        {data.businessCategory ? (
                            <>
                                <label
                                    htmlFor="form-business-subcategory"
                                    className="required"
                                >
                                    Business Subcategory
                                </label>
                                <SelectInput
                                    value={data.businessSubcategory}
                                    id="form-business-subcategory"
                                    required
                                    onChange={(v) =>
                                        setData({
                                            ...data,
                                            businessSubcategory: v,
                                        })
                                    }
                                    options={[
                                        {
                                            value: '',
                                            label: 'Select a business subcategory',
                                        },
                                        ...Object.keys(
                                            RETAIL_BUSINESS_CATEGORIES[
                                                data.businessCategory
                                            ].subcategories || {},
                                        ).map((key) => ({
                                            value: key,
                                            label: RETAIL_BUSINESS_CATEGORIES[
                                                data.businessCategory
                                            ].subcategories[key].title,
                                        })),
                                    ]}
                                />
                            </>
                        ) : null}
                    </>
                )}

                {step === 2 && (
                    <>
                        <div>
                            {data.businessSubcategory === 'spa' ? (
                                <ButtonGroup
                                    background
                                    label={
                                        'Please complete the Google Form below. When finished, return here and click Submit.'
                                    }
                                >
                                    <div>
                                        <Button
                                            text="Go to form"
                                            type="button"
                                            href={`https://forms.gle/KaZG9ofC3VuSMwKN8`}
                                            newPage
                                        />
                                    </div>
                                </ButtonGroup>
                            ) : (
                                <p>
                                    Finished! Click Submit below to create your
                                    account.
                                </p>
                            )}

                            <br />

                            <Button
                                onClick={onSubmit}
                                isLoading={loading}
                                text={'Submit'}
                                type="submit"
                            />
                        </div>
                    </>
                )}

                {step < totalSteps && (
                    <div>
                        <Button
                            onClick={onNext}
                            isLoading={loading}
                            text={'Next'}
                            type="button"
                        />
                    </div>
                )}
                {step > 1 && (
                    <div>
                        <Button
                            onClick={onPrev}
                            isLoading={loading}
                            text={'Previous'}
                            type="button"
                            outline
                        />
                    </div>
                )}
            </form>
        </SectionContainer>
    )
}

export default EditProfileRetailBuyer
