import { useRef } from 'react'
import { ICONS } from '../../../common/constants'
import Button from '../Button'
import XIcon from '../../../assets/icons/XIcon'

export default function TextInput({
    type = 'text',
    value,
    onChange,
    id,
    ...rest
}) {
    const ref = useRef(null)

    function handleChange(e) {
        onChange(e.target.value)
    }
    const textValue = value || ''

    function handleClear() {
        onChange('')
        ref.current?.focus()

        if (rest.onClear) {
            rest.onClear()
        }
    }

    return (
        <div className={`text-input${rest.withClear ? ' with-clear' : ''}`}>
            {type === 'textarea' ? (
                <textarea
                    id={id}
                    value={textValue}
                    onChange={handleChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    rows={rest.rows}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                    className={rest.noMargin ? 'm-0' : ''}
                ></textarea>
            ) : (
                <input
                    ref={rest.withClear ? ref : undefined}
                    id={id}
                    type={type}
                    value={textValue}
                    onChange={handleChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    min={rest.min}
                    step={rest.step}
                    max={rest.max}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                    className={rest.noMargin ? 'm-0' : ''}
                    onPaste={rest.onPaste}
                />
            )}

            {Boolean(rest.placeholderContent) && (
                <div className="placeholder-content">
                    {rest.placeholderContent}
                </div>
            )}

            {rest.withClear && type !== 'textarea' && (
                <div className="clear-btn">
                    <Button
                        text={'Clear'}
                        onClick={handleClear}
                        tiny
                        plain
                        icon={<XIcon />}
                        iconTop
                    />
                </div>
            )}
        </div>
    )
}
