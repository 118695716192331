import { useState } from 'react'
import { useStore } from '../../common/Context'
import Button from './Button'
import SelectInput from './data-form/SelectInput'

function formatValue(value, isMoney) {
    if (isMoney) {
        if (value === null || typeof value === 'undefined' || value === '') {
            return ''
        }
        return Number(value).toFixed(2)
    }
    return value
}

function FormModalContent(props) {
    const { fields, onCancel, onConfirm } = props
    const [state, dispatch] = useStore()
    const initialData = fields
        .filter((field) => typeof field.default !== 'undefined')
        .reduce(
            (a, b) => ({ ...a, [b.name]: formatValue(b.default, b.money) }),
            {},
        )
    const [data, setData] = useState(initialData)

    const onModalCancel = () => {
        dispatch({ type: 'CLOSE_MODAL' })
        onCancel && onCancel()
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const missingData = fields.some(
            (field) =>
                field.required &&
                (typeof data[field.name] === 'undefined' ||
                    data[field.name] === ''),
        )
        const invalidMoney = fields.some(
            (field) =>
                field.money &&
                (!data[field.name] ||
                    data[field.name] < 0 ||
                    isNaN(Number(data[field.name]))),
        )
        if (missingData || invalidMoney) return
        onConfirm(data)
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div>
                    {props.text || null}
                    <br />
                </div>
                <div className="modal-body">
                    {fields.map((field) =>
                        field.type === 'select' ? (
                            <div key={field.name}>
                                <label htmlFor={`modal-input-${field.name}`}>
                                    {field.label}
                                </label>
                                <SelectInput
                                    name={field.name}
                                    id={`modal-input-${field.name}`}
                                    value={data[field.name] || ''}
                                    onChange={(v) =>
                                        setData({
                                            ...data,
                                            [field.name]: v,
                                        })
                                    }
                                    options={field.options}
                                />
                            </div>
                        ) : // TODO
                        null,
                    )}
                </div>
                <div className="modal-buttons">
                    <Button
                        small
                        destructive
                        link
                        onClick={onModalCancel}
                        text={'Cancel'}
                        disabled={state.isModalLoading}
                    />
                    <Button
                        small
                        type="submit"
                        isLoading={state.isModalLoading}
                        text={props.confirmText || 'Submit'}
                    />
                </div>
            </form>
        </>
    )
}

export default FormModalContent
