import LiveSearch from './LiveSearch'

export default function LiveSearchInput({ value, onChange, ...input }) {
    function onItemClick(item) {
        onChange(item?.id || null)
    }

    return (
        <LiveSearch onItemClick={onItemClick} {...input} defaultLabel={value} />
    )
}
