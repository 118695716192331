import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../common/fetchAPI'
import useError from '../../common/hooks/useError'
import useToast from '../../common/hooks/useToast'
import Button from '../../components/common/Button'
import ErrorMessage from '../../components/common/ErrorMessage'
import PasswordInput from '../../components/common/data-form/PasswordInput'
import SectionContainer from '../../components/common/SectionContainer'

function ResetPassword() {
    const [error, setError] = useError()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ password: '' })
    const [searchParams] = useSearchParams()
    const setToast = useToast()
    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        const token = searchParams.get('token')

        setError('')

        const { password } = data

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/auth/reset-password?token=` + token,
            { password },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Password has been reset, please login')
        navigate('/login')
    }

    return (
        <SectionContainer>
            <div className="hero">
                <h1>Reset password</h1>
                <p>Create your new password</p>
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="password">Password</label>
                    <PasswordInput
                        name="password"
                        placeholder="********"
                        value={data.password}
                        onChange={(v) =>
                            setData({
                                ...data,
                                password: v,
                            })
                        }
                    />
                </div>

                <div>
                    <Button text="Submit" type="submit" isLoading={loading} />
                </div>
            </form>
            <footer>
                <div className="links">
                    Back to <Link to="/login">Login</Link>
                </div>
            </footer>
        </SectionContainer>
    )
}

export default ResetPassword
