import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import { formatDate } from '../../../common/helpers'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import InvoicesList from '../invoices/InvoicesList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

function SubPage() {
    const { subId, userId, source } = useParams()
    const [sub, setSub] = useState({ items: [] })
    // const [contract, setContract] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useError()
    const navigate = useNavigate()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/payment/clients/${userId}/subscriptions/${source}/${subId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)
            setSub(responseData)
        }
        getData()
    }, [])

    const onDelete = async () => {
        setError('')
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(false)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/subscriptions/${source}/${subId}`,
                        {},
                        'DELETE',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    navigate(`/users/${userId}`)
                }}
            />,
            'Are you sure you want to delete this subscription?',
        )
    }

    const onCancel = async () => {
        setError('')

        setModal(
            <ConfirmModalContent
                text="Important: Any pending invoices will still be charged unless deleted."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/subscriptions/${source}/${subId}/cancel`,
                        {},
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Cancel subscription?',
        )
    }

    const onPause = async () => {
        setError('')

        setModal(
            <ConfirmModalContent
                text="Invoices will be kept as drafts."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/subscriptions/${source}/${subId}/pause`,
                        {},
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Pause subscription?',
        )
    }

    // const onDeleteContract = async () => {
    //     alert('Not implemented')
    // }

    if (loading) return <Spinner marginTop />

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </SectionContainer>
            )}

            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>
                            Subscription for &ldquo;
                            {sub.items
                                .map(
                                    (item) =>
                                        item.servicePrice.marketingService
                                            .title,
                                )
                                .join(', ')}
                            &ldquo;
                        </h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Edit',
                                    onClick: () => {
                                        if (sub.source !== 'manual') return
                                        navigate(
                                            `/users/${userId}/subscriptions/${source}/${subId}/edit`,
                                        )
                                    },
                                    disabled: sub.source !== 'manual',
                                    icon: ICONS.EDIT_GRAY,
                                },
                                {
                                    title: 'Pause',
                                    onClick: () => onPause(),
                                    disabled: sub.source === 'manual',
                                    icon: ICONS.PAUSE_GRAY,
                                },
                                {
                                    title: 'Delete',
                                    onClick: () => onDelete(),
                                    disabled: sub.source !== 'manual',
                                    type: 'alert',
                                    icon: ICONS.TRASH_RED,
                                },
                                {
                                    title: 'Cancel',
                                    onClick: () => onCancel(),
                                    disabled: sub.status === 'canceled',
                                    type: 'alert',
                                    icon: ICONS.SLASH_RED,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer className="info-details">
                <p
                    className={`text-caps ${
                        sub.status === 'canceled' ? 'alert' : ''
                    } ${sub.status === 'active' ? 'success' : ''}`}
                >
                    {sub.status}
                    {sub.canceledAt
                        ? ` at ${formatDate(sub.canceledAt)}`
                        : null}
                </p>
                {sub.pauseCollection ? <p>Paused</p> : ''}
                <p>
                    <Tag outline>{sub.source}</Tag>
                </p>
                {/* {typeof contract === 'undefined' && (
                    <p>Getting contract data...</p>
                )}
                {contract === null && <p>No contract</p>}
                {contract ? (
                    <p>
                        {`Contract from ${formatDate(
                            contract.startDate,
                        )} to ${formatDate(
                            contract.endDate,
                        )}, paid ${formatMoney(
                            contract.paid || 0,
                        )}/${formatMoney(contract.totalAmount || 0)}`}
                    </p>
                ) : null} */}
            </SectionContainer>

            <InvoicesList renderMainButton={false} inSubscription={true} />
        </>
    )
}

export default SubPage
