import useData from '../../../common/hooks/useData'
import SelectInput from './SelectInput'

export default function ResourceDropdownInput({
    id,
    url,
    value,
    onChange,
    getItems,
    getItemLabel,
    getItemValue,
    ...rest
}) {
    const { items, itemsError, itemsLoading } = useData(
        url,
        'items',
        (data) => getItems(data) || [],
    )

    const inputValue = value || ''

    function handleChange(v) {
        onChange(v)
    }

    if (itemsLoading) {
        return (
            <SelectInput
                value={''}
                id={id}
                disabled
                options={[
                    {
                        value: '',
                        label: 'Loading...',
                    },
                ]}
            />
        )
    }

    if (itemsError) {
        return (
            <SelectInput
                required={rest.required}
                id={id}
                disabled
                options={[
                    {
                        value: '',
                        label: 'Error',
                    },
                ]}
            />
        )
    }

    return (
        <SelectInput
            required={rest.required}
            id={id}
            value={inputValue}
            onChange={handleChange}
            options={[
                {
                    value: '',
                    label: '',
                },
                ...items.map((item) => ({
                    value: getItemValue(item),
                    label: getItemLabel(item),
                })),
            ]}
        />
    )
}
