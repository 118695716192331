import { useNavigate, useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import ResourceList from '../../../components/admin/ResourceList'
import ContactCard from '../../admin/clients/ContactCard'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useAuth from '../../../common/hooks/useAuth'
import PrivateFile from '../../../components/common/PrivateFile'
import Tag from '../../../components/common/Tag'
import InlineStack from '../../../components/common/InlineStack'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import MainButton from '../../../components/admin/MainButton'
import InlineStackItem from '../../../components/common/InlineStackItem'
import FavoriteInfo from '../../user-profile/FavoriteInfo'
import SectionContainer from '../../../components/common/SectionContainer'

export default function OrganizationPage({ isOwnProfile }) {
    const { organizationId } = useParams()
    const navigate = useNavigate()
    const auth = useAuth()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const {
        organization,
        organizationError,
        organizationLoading,
        organizationMutate,
    } = useData(
        `/v1/organizations/${organizationId}?fields=name,acronym,organizationType,members&populate=members.user.email,members.user.firstName,members.user.lastName,companyInfo.membersInfo,companyInfo.ein,companyInfo.companyType`,
        'organization',
    )

    async function changeRole(role, item) {
        const url = `/v1/organizations/${organizationId}/members/${item.id}`
        const { error } = await fetchAPI(url, { role }, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Changed role')
        organizationMutate()
    }

    async function handleRoleChange(role, item) {
        const showWarning =
            role === 'member' &&
            organization.members.length !== 1 &&
            !organization.members.some(
                (m) => m.id !== item.id && m.role === 'admin',
            )
        if (!showWarning) {
            await changeRole(role, item)
            return
        }

        setModal(
            <ConfirmModalContent
                text="This will leave the organization with no admins."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    await changeRole(role, item)
                    setModalLoading(false)
                    setModal(null)
                }}
            />,
            'Are you sure?',
        )
    }

    if (organizationLoading) {
        return <Spinner />
    }

    if (organizationError) {
        return <ErrorMessage section>{organizationError}</ErrorMessage>
    }

    const memberInfo =
        isOwnProfile &&
        organization?.companyInfo?.membersInfo.find(
            (m) => m.userId === auth.user.id,
        )

    const proofOfWorkFileIds = memberInfo?.workProofFileIds || []

    const jobTitle = memberInfo?.title

    let canEdit = false
    if (!isOwnProfile) {
        canEdit = true
    } else {
        const member = organization.members.find(
            (m) => m.user.id === auth.user.id,
        )
        if (member.role === 'admin' || organization.members.length === 1) {
            canEdit = true
        }
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack gap={'sm'} spaceBetween>
                        <div>
                            <InlineStack itemsCenter>
                                <InlineStackItem>
                                    <h2>{organization.name}</h2>
                                </InlineStackItem>

                                <FavoriteInfo
                                    type={'Organization'}
                                    id={organizationId}
                                />
                            </InlineStack>
                            <div className="text-subdued text-caps">
                                ({organization.acronym})
                            </div>
                            <div className="text-subdued text-caps">
                                {organization.organizationType}
                            </div>
                        </div>

                        <ActionsDropdown
                            actions={[
                                ...(canEdit
                                    ? [
                                          {
                                              title: 'Edit',
                                              onClick: () =>
                                                  navigate(
                                                      isOwnProfile
                                                          ? `/profile/companies/${organizationId}/edit`
                                                          : `/organizations/${organizationId}/edit`,
                                                  ),
                                              icon: ICONS.EDIT_GRAY,
                                          },
                                      ]
                                    : []),
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>

            {Boolean(isOwnProfile) &&
                organization.organizationType === 'company' && (
                    <>
                        <SectionContainer>
                            <h3>Company information</h3>
                            <div>
                                <small>
                                    <strong>EIN / SSN</strong>
                                </small>
                                <br />
                                <small>
                                    {organization?.companyInfo?.ein || 'None'}
                                </small>
                            </div>
                            <br />
                            <div>
                                <small>
                                    <strong>Type</strong>
                                </small>
                                <br />
                                <small>
                                    {organization.companyInfo?.companyType ||
                                        'None'}
                                </small>
                            </div>
                        </SectionContainer>
                        <SectionContainer>
                            <h3>My information</h3>
                            <div>
                                <small>
                                    <strong>Title</strong>
                                </small>
                                <br />
                                <small>{jobTitle || 'None'}</small>
                            </div>
                            <br />
                            <div>
                                <small>
                                    <strong>Submitted proof of work</strong>
                                </small>
                                <div>
                                    {proofOfWorkFileIds?.length ? (
                                        proofOfWorkFileIds.map((id, i) => (
                                            <PrivateFile
                                                key={i}
                                                openFullOnClick
                                                driveFileId={id}
                                                usage={'workProof'}
                                                parentName={'user/workProof'}
                                            />
                                        ))
                                    ) : (
                                        <small>None</small>
                                    )}
                                </div>
                            </div>
                        </SectionContainer>
                    </>
                )}
            <SectionContainer>
                <Toggle title={'Members'} small heading={'h2'}>
                    {isOwnProfile ? (
                        <ResourceList
                            key={organization.members
                                .map((m) => `${m.id}_${m.role}`)
                                .join('-')}
                            newItemBtnText={'Invite member'}
                            newItemPath={
                                canEdit
                                    ? `/organizations/${organization.id}/members/invite`
                                    : undefined
                            }
                            items={organization.members || []}
                            fields={[
                                {
                                    column: 1,
                                    getValue: (item) => (
                                        <InlineStack spaceBetween>
                                            <ContactCard
                                                user={item.user}
                                                summary
                                            />

                                            <Tag
                                                outline
                                                color={'primary'}
                                                onChange={
                                                    canEdit &&
                                                    item.userId !== auth.user.id
                                                        ? (v) =>
                                                              handleRoleChange(
                                                                  v,
                                                                  item,
                                                              )
                                                        : undefined
                                                }
                                                options={[
                                                    {
                                                        label: 'Admin',
                                                        value: 'admin',
                                                        color: 'primary',
                                                    },
                                                    {
                                                        label: 'Member',
                                                        value: 'member',
                                                        color: 'primary',
                                                    },
                                                ]}
                                                initValue={item.role}
                                            >
                                                {item.role}
                                            </Tag>
                                        </InlineStack>
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <ResourceList
                            key={organization.members
                                .map((m) => `${m.id}_${m.role}`)
                                .join('-')}
                            items={organization.members || []}
                            newItemPath={`/organizations/${organization.id}/members`}
                            newItemBtnText={'Edit'}
                            fields={[
                                {
                                    column: 1,
                                    getValue: (item) => (
                                        <InlineStack spaceBetween>
                                            <ContactCard
                                                user={item.user}
                                                summary
                                                withNameLink
                                                withUserLink
                                            />

                                            <Tag
                                                outline
                                                color={'primary'}
                                                onChange={(v) =>
                                                    handleRoleChange(v, item)
                                                }
                                                options={[
                                                    {
                                                        label: 'Admin',
                                                        value: 'admin',
                                                        color: 'primary',
                                                    },
                                                    {
                                                        label: 'Member',
                                                        value: 'member',
                                                        color: 'primary',
                                                    },
                                                ]}
                                                initValue={item.role}
                                            >
                                                {item.role}
                                            </Tag>
                                        </InlineStack>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Toggle>
            </SectionContainer>

            <MainButton
                label="Edit"
                functionality="EDIT"
                onClick={() =>
                    navigate(
                        isOwnProfile
                            ? `/profile/companies/${organizationId}/edit`
                            : `/organizations/${organizationId}/edit`,
                    )
                }
            />
        </>
    )
}
