import { useEffect, useState, useRef } from 'react'
import { MONTHS } from '../../../common/constants'
import {
    formatMoney,
    getQuarter,
    getUnitSumFromCalendar,
    getWeek,
} from '../../../common/helpers'
import SelectInput from '../../../components/common/data-form/SelectInput'

const joinCalendars = (calendars) => {
    const result = {}
    calendars.forEach((calendar) => {
        for (const year of Object.keys(calendar)) {
            if (!result[year]) {
                result[year] = {}
            }
            for (const month of Object.keys(calendar[year])) {
                if (!result[year][month]) {
                    result[year][month] = 0
                }
                result[year][month] += calendar[year][month]
            }
        }
    })
    return result
}

const getNewActiveDate = (unit, currActiveDate, range) => {
    if (range === 'year') {
        return {
            year: String(unit.year),
            quarter: '1',
            month: '0',
            week: '1',
        }
    } else if (range === 'quarter') {
        return {
            year: String(unit.year),
            quarter: String(unit.quarter),
            month: '0',
            week: '1',
        }
    } else if (range === 'month') {
        return {
            year: String(unit.year),
            quarter: '1',
            month: String(unit.month),
            week: '1',
        }
    }
}

const getActiveDateForDate = (date) => {
    return {
        year: String(date.getFullYear()),
        quarter: String(getQuarter(date)),
        month: String(date.getMonth()),
        week: String(getWeek(date)),
    }
}

const scrollToLastUnit = (chartsRef) => {
    setTimeout(() => {
        const chartsContainer = chartsRef?.current
        if (chartsContainer?.scrollWidth) {
            chartsContainer.scrollLeft = chartsContainer.scrollWidth
        }
        window.scrollTo(0, 0)
    }, 10)
}

export default function BillingChart(props) {
    const { data, range, setRange, activeDate, setActiveDate } = props
    const {
        clients: clientsData,
        fixedExpenses: fixedCalendar = {},
        recurringExpenses: recurringCalendar = {},
    } = data

    const chartsRef = useRef(null)
    const now = new Date()

    const hasNoData =
        !clientsData.length &&
        !Object.keys(fixedCalendar).length &&
        !Object.keys(recurringCalendar).length

    const [showAllUnits, setShowAllUnits] = useState(false)

    useEffect(() => {
        if (hasNoData) return
        scrollToLastUnit(chartsRef)
    }, [hasNoData])

    if (hasNoData) return <div className="chart-placeholder"></div>

    const onUnitClick = (unit) => {
        setActiveDate(getNewActiveDate(unit, activeDate, range))
    }

    const onRangeChange = (range) => {
        setRange(range)
        setActiveDate(getActiveDateForDate(now))
    }

    const onYearChange = (newYear) => {
        if (newYear === 'all') {
            setShowAllUnits(true)
            scrollToLastUnit(chartsRef)
        } else {
            setShowAllUnits(false)
        }
        const year =
            newYear === 'all' ? String(now.getFullYear()) : String(newYear)
        setRange(range)
        setActiveDate({
            year: year,
            quarter: year !== String(now.getFullYear()) ? '1' : getQuarter(now),
            month:
                String(year) !== String(now.getFullYear())
                    ? '0'
                    : String(now.getMonth()),
            week:
                String(year) !== String(now.getFullYear()) ? '1' : getWeek(now),
        })
    }

    // Payments calendar
    const paymentCalendars = clientsData.map(
        (customer) => customer.paymentsCalendar || {},
    )
    const allPaymentsCalendar = joinCalendars(paymentCalendars)

    // Owed calendar
    const owedCalendars = clientsData.map(
        (customer) => customer.owedCalendar || {},
    )
    const allOwedCalendar = joinCalendars(owedCalendars)

    // Past due calendar
    const pastDueCalendars = clientsData.map(
        (customer) => customer.pastDueCalendar || {},
    )
    const allPastDueCalendar = joinCalendars(pastDueCalendars)

    // Expenses calendar
    const allExpensesCalendar = joinCalendars([
        recurringCalendar,
        fixedCalendar,
    ])

    // Both calendars combined
    const allCalendars = joinCalendars([
        allPaymentsCalendar,
        allOwedCalendar,
        allPastDueCalendar,
        allExpensesCalendar,
    ])

    // Add current year if missing
    if (!allCalendars[String(now.getFullYear())]) {
        allCalendars[String(now.getFullYear())] = {}
    }

    // Fill in missing months
    for (const year of Object.keys(allCalendars)) {
        const toMonth =
            year === String(now.getFullYear()) ? String(now.getMonth()) : '11'
        for (let month = 0; month <= Number(toMonth); month++) {
            if (!allCalendars[year][String(month)]) {
                allCalendars[year][String(month)] = 0
            }
        }
    }

    // Get options for dropdown
    const allYears = Object.keys(allCalendars).sort(
        (a, b) => Number(a) - Number(b),
    )
    let dropdownItems
    if (range === 'quarter' || range === 'month') {
        dropdownItems = [...allYears].reverse()
    } else if (range === 'year') {
        dropdownItems = []
    }

    // Get units for each chart
    let unitItems = []
    if (range === 'quarter') {
        if (showAllUnits) {
            for (const year of allYears) {
                const fromQuarter = '1'
                const toQuarter =
                    year === String(now.getFullYear()) ? getQuarter(now) : '4'

                for (
                    let quarter = Number(fromQuarter);
                    quarter <= Number(toQuarter);
                    quarter++
                ) {
                    unitItems.push({ quarter: String(quarter), year })
                }
            }
        } else {
            const fromQuarter = '1'
            const toQuarter =
                String(activeDate.year) === String(now.getFullYear())
                    ? getQuarter(now)
                    : '4'
            for (
                let quarter = Number(fromQuarter);
                quarter <= Number(toQuarter);
                quarter++
            ) {
                unitItems.push({
                    quarter: String(quarter),
                    year: String(activeDate.year),
                })
            }
        }
    } else if (range === 'month') {
        if (showAllUnits) {
            for (const year of allYears) {
                const months = Object.keys(allCalendars[year]).sort(
                    (a, b) => Number(a) - Number(b),
                )
                for (const month of months) {
                    unitItems.push({ month, year })
                }
            }
        } else {
            unitItems = Object.keys(allCalendars[activeDate.year])
                .sort((a, b) => Number(a) - Number(b))
                .map((month) => ({ month, year: String(activeDate.year) }))
        }
        // unitItems = Object.keys(allCalendars[activeDate.year]).sort()
    } else if (range === 'year') {
        unitItems = allYears.map((year) => ({ year }))
        // unitItems = allYears
    }

    // Get max amounts for all units, and fill in amounts

    const metrics = ['payments', 'owed', 'pastDue', 'recurring', 'fixed']

    const calendars = {
        payments: allPaymentsCalendar,
        owed: allOwedCalendar,
        pastDue: allPastDueCalendar,
        recurring: recurringCalendar,
        fixed: fixedCalendar,
    }

    const maxValues = {
        payments: 0,
        owed: 0,
        pastDue: 0,
        recurring: 0,
        fixed: 0,
    }

    const getInitUnitAmounts = () => ({
        payments: 0,
        owed: 0,
        pastDue: 0,
        recurring: 0,
        fixed: 0,
    })

    // Populate amounts in units, determine max values
    for (const unit of unitItems) {
        unit.amounts = getInitUnitAmounts()
        for (const metric of metrics) {
            const total = getUnitSumFromCalendar(unit, range, calendars[metric])
            unit.amounts[metric] = total
            if (total > maxValues[metric]) maxValues[metric] = total
        }
    }

    const allMaxMoney = Math.max(
        maxValues.payments,
        maxValues.owed,
        maxValues.pastDue,
        maxValues.recurring + maxValues.fixed,
        0,
    )

    const sumCards = {
        payments: 0,
        owed: 0,
        pastDue: 0,
        pastDueAllTime: 0,
        recurring: 0,
        fixed: 0,
    }
    if (range === 'year') {
        for (const metric of metrics) {
            sumCards[metric] =
                unitItems.find(
                    (unit) => String(unit.year) === String(activeDate.year),
                )?.amounts?.[metric] || 0
        }
    } else if (range === 'quarter') {
        for (const metric of metrics) {
            sumCards[metric] =
                unitItems.find(
                    (unit) =>
                        String(unit.year) === String(activeDate.year) &&
                        String(unit.quarter) === String(activeDate.quarter),
                )?.amounts?.[metric] || 0
        }
    } else if (range === 'month') {
        for (const metric of metrics) {
            sumCards[metric] =
                unitItems.find(
                    (unit) =>
                        String(unit.year) === String(activeDate.year) &&
                        String(unit.month) === String(activeDate.month),
                )?.amounts?.[metric] || 0
        }
    }
    sumCards['pastDueAllTime'] = getUnitSumFromCalendar(
        {},
        '',
        calendars['pastDue'],
    )

    const lineHeights = {
        payments:
            (((sumCards.payments / allMaxMoney).toFixed(2) * 100 || 0) * 70) /
            100,
        expenses:
            ((((sumCards.fixed + sumCards.recurring) / allMaxMoney).toFixed(2) *
                100 || 0) *
                70) /
            100,
        pastDue:
            (((sumCards.pastDue / allMaxMoney).toFixed(2) * 100 || 0) * 70) /
            100,
    }

    const showLines = {}
    const sortedLines = Object.keys(lineHeights).sort(
        (a, b) => lineHeights[b] - lineHeights[a],
    )
    for (let i = 0; i < sortedLines.length; i++) {
        const metric = sortedLines[i]
        if (lineHeights[metric] === 0) continue
        const nextMetric = sortedLines?.[i + 1]
        if (!nextMetric || lineHeights[nextMetric] === 0) {
            showLines[metric] = true
            continue
        }
        if (Math.abs(lineHeights[nextMetric] - lineHeights[metric]) > 10) {
            showLines[metric] = true
        }
    }

    return (
        <>
            <div className="billing-chart-filter">
                <SelectInput
                    value={range}
                    onChange={(v) => onRangeChange(v)}
                    options={[
                        { value: 'year', label: 'Yearly' },
                        { value: 'quarter', label: 'Quarterly' },
                        { value: 'month', label: 'Monthly' },
                    ]}
                />

                {(range === 'month' || range === 'quarter') && (
                    <SelectInput
                        value={showAllUnits ? 'all' : activeDate.year}
                        onChange={(v) => onYearChange(v)}
                        options={[
                            ...dropdownItems.map((year) => ({
                                value: year,
                                label: year,
                            })),
                            { value: 'all', label: 'All' },
                        ]}
                    />
                )}
            </div>
            <section className="billing-chart">
                <div className="chart-container">
                    <div className="lines">
                        {showLines.expenses && (
                            <span
                                style={{
                                    transform: `translate(0, -${lineHeights.expenses}px)`,
                                }}
                            >
                                $
                                {(
                                    Number(
                                        String(
                                            sumCards.fixed + sumCards.recurring,
                                        ).slice(0, -2),
                                    ) / 1000
                                ).toFixed(1)}
                                k
                            </span>
                        )}

                        {showLines.payments && (
                            <span
                                style={{
                                    transform: `translate(0, -${lineHeights.payments}px)`,
                                }}
                            >
                                $
                                {(
                                    Number(
                                        String(sumCards.payments).slice(0, -2),
                                    ) / 1000
                                ).toFixed(1)}
                                k
                            </span>
                        )}

                        {showLines.pastDue ? (
                            <span
                                style={{
                                    transform: `translate(0, -${lineHeights.pastDue}px)`,
                                }}
                            >
                                $
                                {(
                                    Number(
                                        String(sumCards.pastDue).slice(0, -2),
                                    ) / 1000
                                ).toFixed(1)}
                                k
                            </span>
                        ) : null}
                    </div>
                    <div className="months-container" ref={chartsRef}>
                        <div className="months">
                            {unitItems.map((unit, i) => {
                                const unitPayments =
                                    unit?.amounts?.payments || 0
                                const unitPastDue = unit?.amounts?.pastDue || 0
                                const unitExpenses =
                                    (unit?.amounts?.recurring || 0) +
                                    (unit?.amounts?.fixed || 0)

                                const percPayments =
                                    (unitPayments / allMaxMoney).toFixed(2) *
                                        100 || 0

                                const percPastDue =
                                    (unitPastDue / allMaxMoney).toFixed(2) *
                                        100 || 0

                                const percExpenses =
                                    (unitExpenses / allMaxMoney).toFixed(2) *
                                        100 || 0

                                const isActive =
                                    (range === 'year' &&
                                        String(activeDate.year) ===
                                            String(unit.year)) ||
                                    (range === 'quarter' &&
                                        String(activeDate.year) ===
                                            String(unit.year) &&
                                        String(activeDate.quarter) ===
                                            String(unit.quarter)) ||
                                    (range === 'month' &&
                                        String(activeDate.year) ===
                                            String(unit.year) &&
                                        String(activeDate.month) ===
                                            String(unit.month))

                                let label
                                if (range === 'year') {
                                    label = `${unit.year}`
                                } else if (range === 'quarter') {
                                    label = `Q${unit.quarter} ${unit.year}`
                                } else if (range === 'month') {
                                    label = `${
                                        MONTHS[String(unit.month)].abbreviation
                                    } ${unit.year}`
                                }

                                return (
                                    <div
                                        key={i}
                                        className="month"
                                        role="button"
                                        onClick={() => onUnitClick(unit)}
                                    >
                                        <div className="bars">
                                            <span
                                                className="expenses"
                                                style={{
                                                    height: `${percExpenses}%`,
                                                }}
                                            ></span>
                                            <span
                                                className="earned"
                                                style={{
                                                    height: `${percPayments}%`,
                                                }}
                                            ></span>
                                            <span
                                                className="overdue"
                                                style={{
                                                    height: `${percPastDue}%`,
                                                }}
                                            ></span>
                                        </div>
                                        <div
                                            className={`title ${
                                                isActive ? 'selected' : ''
                                            }`}
                                        >
                                            {label}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="index">
                        <span className="expenses">Expenses</span>
                        <span className="earned">Earned</span>
                        <span className="overdue">Overdue</span>
                    </div>
                </div>
                <div className="current-totals">
                    <h3>Earnings</h3>
                    <div>
                        <h4>
                            <small>Pending</small>
                        </h4>
                        <p>-</p>
                    </div>
                    <div>
                        <h4>
                            <small>Paid</small>
                        </h4>
                        <p>{formatMoney(sumCards.payments)}</p>
                    </div>
                    <div>
                        <h4>
                            <small>Anticipated</small>
                        </h4>
                        <p>-</p>
                    </div>
                </div>
                <div className="current-totals">
                    <h3>Outstanding</h3>
                    <div>
                        <h4>
                            <small>Open</small>
                        </h4>
                        <p>{formatMoney(sumCards.owed)}</p>
                    </div>
                    <div>
                        <h4>
                            <small>Overdue</small>
                        </h4>
                        <p>
                            {formatMoney(sumCards.pastDue)}/ (
                            {formatMoney(sumCards.pastDueAllTime)} all time)
                        </p>
                    </div>
                    <div>
                        <h4>
                            <small>Collections</small>
                        </h4>
                        <p>-</p>
                    </div>
                </div>
                <div className="current-totals">
                    <h3>Expenses</h3>
                    <div>
                        <h4>
                            <small>Payroll</small>
                        </h4>
                        <p>{formatMoney(sumCards.recurring)}</p>
                    </div>
                    <div>
                        <h4>
                            <small>Expenses</small>
                        </h4>
                        <p>{formatMoney(sumCards.fixed)}</p>
                    </div>
                    <div>
                        <h4>
                            <small>Tools</small>
                        </h4>
                        <p>-</p>
                    </div>
                </div>
            </section>
        </>
    )
}
