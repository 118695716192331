// .company ready

import { useSearchParams } from 'react-router-dom'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditCompanyMemberInfo() {
    const auth = useAuth()
    const [searchParams] = useSearchParams()
    const org = auth.user.orgsData.find(
        (o) => o.id === searchParams.get('organizationId'),
    )

    if (!org) return null

    return (
        <SectionContainer>
            <h2>{org.name}</h2>
            <DataFormNew
                url={`/v1/organizations/${org.id}/members-info?userId=${auth.user.id}`}
                method="POST"
                fetchItemFirst
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/`}
                inputs={[
                    {
                        key: 'title',
                        label: 'Job Title',
                    },
                    {
                        key: 'files',
                        // prevPreviewKey: 'workProofFile', // TODO
                        label: 'Proof that you work on or own the business (1 file required, JPG or PDF format)',
                        infoBottom:
                            'This will replace any current images.  Upload proof of ownership or employment. Articles of organization (for owners) or paystub and ID (for employees).',
                        type: 'file',
                        accept: 'image/jpeg, application/pdf',
                        multiple: true,
                    },
                ]}
                mapItemToData={(item) => ({
                    title: item?.title,
                    files: [],
                })}
                getBody={(data) => {
                    const MAX_FILE_SIZE = 10 * 1024 * 1024
                    for (const file of data.files) {
                        if (file.size > MAX_FILE_SIZE) {
                            throw new Error(`Max file size is 10MB.`)
                        }
                    }
                    const formData = new FormData()
                    for (let i = 0; i < data.files.length; i++) {
                        formData.append(`files[${i}]`, data.files[i])
                    }
                    delete data.files
                    const body = { ...data, userId: auth.user.id }
                    formData.append('data', JSON.stringify(body))
                    return formData
                }}
                mutationRegexes={[/\/v1\/users/, /\/v1\/organizations/]}
                mainButton
                onSuccess={() => {
                    auth.refetchAuth()
                }}
            />
        </SectionContainer>
    )
}
