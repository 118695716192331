import { useParams } from 'react-router-dom'

import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditProduct() {
    const { productId } = useParams()

    const inputs = [
        // {
        //     key: 'info',
        //     type: 'jsx',
        //     render: (v) => `Will be synced to shops: ${v}`,
        // },
        {
            key: 'title',
            label: 'Title',
            disabled: true,
        },
        {
            key: 'isInStore',
            type: 'checkbox',
            label: 'In store',
        },
        // {
        //     multiplyFor: (data) => (data.variants || []).map((v) => v.title),
        //     key: 'quantities',
        //     label: 'Quantity',
        //     type: 'number',
        //     min: 0,
        //     step: 1,
        //     toggleGroup: 'Quantities',
        // },
    ]

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/shop/products/${productId}?fields=title,descriptionHtml,options,variants&populate=enabledShopifyShopsInfo.shopifyShop.fullName`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/shop#inventory`}
                inputs={inputs}
                mainButton
                getBody={(data) => ({
                    isInStore: data.isInStore,
                    // title: data.title,
                    // descriptionHtml: data.descriptionHtml,
                })}
                mapItemToData={(item) => ({
                    title: item.title,
                    // variants: item.variants,
                    isInStore: item.isInStore,
                    // descriptionHtml: item.descriptionHtml,
                    // info: item.enabledShopifyShopsInfo
                    //     .map((info) => info.shopifyShop.fullName)
                    //     .join(', '),
                })}
            />
        </SectionContainer>
    )
}

/*
    IF they say online only

They should not have the option to add quantity. 

If they say in-store

They should see 

Quantity at XO Studio

This is read only for vendor but editable by aCo staff 

Quantity at Wild Willow Market 

This is read only by vendors but editable by aCo staff and wild willow staff 

Quantity vendor has in-stock 

This is editable by the vendor themselves and their company team 

Quantity XO Studio storage 

Editable by aCo team only / view only for vendor 

Quantity Amazon fulfillment 

This is editable by aCo staff only
    */
