import { useParams } from 'react-router-dom'
import { formatDateTime } from '../../../common/helpers'
import useData from '../../../common/hooks/useData'
import PrivateFile from '../../../components/common/PrivateFile'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function PlainFormPage() {
    const { formId } = useParams()

    const { formData, formDataError, formDataLoading } = useData(
        `/v1/forms/${formId}`,
        'formData',
    )

    if (formDataLoading) {
        return <Spinner marginTop />
    }
    if (formDataError) {
        return (
            <SectionContainer>
                <ErrorMessage>{formDataError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <div className="text-subdued">
                <strong>Received</strong>
            </div>
            <div>{formatDateTime(formData.creationDate)}</div>
            <br />
            {Object.entries(formData.formFields || {}).map(([key, value]) => (
                <div key={key}>
                    <small className="text-subdued">{key}</small>
                    <div>
                        {key.startsWith('_drive_file_id') ? (
                            <PrivateFile openFullOnClick driveFileId={value} />
                        ) : value === '' ? (
                            'N/A'
                        ) : (
                            value
                        )}
                    </div>
                    <br />
                </div>
            ))}
        </SectionContainer>
    )
}
