import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { copyToClipboard } from '../../../common/helpers'
import useData from '../../../common/hooks/useData'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Toggle from '../../../components/common/Toggle'
import HtmlEditor from '../../../components/common/HtmlEditor'
import PdfViewer from '../../../components/common/PdfViewer'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'
import Break from '../../../components/common/Break'

function EditHtmlContract() {
    const setToast = useToast()

    const { contractId } = useParams()
    const [data, setData] = useState({ title: '', htmlContent: '' })
    const [loading, setLoading] = useState(false)
    const [previewObjectUrl, setPreviewObjectUrl] = useState(null)
    const matchMutate = useMatchMutate()
    const navigate = useNavigate()

    const { contract, contractError, contractLoading } = useData(
        `/v1/html-contracts/${contractId}`,
        'contract',
        (data) => data,
        undefined,
        undefined,
        (data) =>
            setData({
                title: data.title || '',
                htmlContent: data.htmlContent || '',
            }),
    )

    const onPreview = async (e) => {
        e.preventDefault()

        const body = {
            title: data.title,
            htmlContent: data.htmlContent,
            contractType: contract.contractType,
        }

        setLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/html-contracts/preview`,
            body,
            'POST',
        )
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        const blob = await responseData.blob()
        const url = window.URL.createObjectURL(blob)
        setPreviewObjectUrl(url)
    }

    async function onSubmit(e) {
        e.preventDefault()

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/html-contracts/${contractId}`,
            data,
            'PATCH',
        )
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        matchMutate(/\/v1\/html-contracts/)
        setToast('Contract updated')
        // navigate('/html-contracts')
    }

    if (contractLoading) return <Spinner marginTop />
    if (contractError) {
        return (
            <SectionContainer>
                <ErrorMessage>{contractError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>{contract.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'History',
                                    onClick: () =>
                                        navigate(
                                            `/html-contracts/${contract.id}/history`,
                                        ),
                                    // icon: ICONS.SETTINGS_GRAY,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                <Toggle title="Available tags">
                    <br />
                    {contract?.contractVariables?.map((tag) => (
                        <div
                            key={tag}
                            role="button"
                            onClick={() => copyToClipboard(`{{${tag}}}`)}
                        >
                            <span>{`{{${tag}}}`}&nbsp;</span>
                            <img
                                width={16}
                                height={16}
                                src="/img/icons/clipboard-active.svg"
                                alt="copy icon"
                            />
                        </div>
                    ))}
                    <br />
                </Toggle>

                <Break />

                <form onSubmit={onSubmit}>
                    <label htmlFor="form-type">Type</label>
                    <SelectInput
                        disabled
                        id="form-type"
                        value={contract.contractType}
                        options={[
                            {
                                value: '',
                                label: '',
                            },
                            {
                                value: 'vendorRegistration',
                                label: 'Vendor registration (Deprecated)',
                            },
                            {
                                value: 'vendorRegistrationIowa',
                                label: 'Vendor registration (Iowa)',
                            },
                            {
                                value: 'vendorRegistrationOnline',
                                label: 'Vendor registration (Online)',
                            },
                            {
                                value: 'vendorRegistrationIowaOnline',
                                label: 'Vendor registration (Iowa + Online)',
                            },
                            {
                                value: 'vendorConsignmentRegistration',
                                label: 'Consignment registration',
                            },
                        ]}
                    />

                    <label htmlFor="form-title" className="required">
                        Title
                    </label>
                    <TextInput
                        required
                        type="text"
                        id="form-title"
                        value={data.title}
                        onChange={(v) =>
                            setData({
                                ...data,
                                title: v,
                            })
                        }
                    />

                    <label htmlFor="form-html-content">HTML</label>

                    <HtmlEditor
                        zoom={0.66}
                        onChange={(v) => {
                            setData({
                                ...data,
                                htmlContent: v,
                            })
                        }}
                        value={data.htmlContent
                            .replace(/&lt;/g, '<')
                            .replace(/&gt;/g, '>')}
                    />

                    <Button
                        isLoading={loading}
                        type="button"
                        text="Preview"
                        outline
                        onClick={onPreview}
                    />

                    <PdfViewer objectUrl={previewObjectUrl} />

                    <Button isLoading={loading} type="submit" text="Save" />
                </form>
            </SectionContainer>
        </>
    )
}

export default EditHtmlContract
