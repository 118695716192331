import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'fullName',
        label: 'Display name',
        required: true,
    },
    {
        key: 'name',
        label: 'Shopify subdomain',
        required: true,
        infoTop: 'Without ".myshopify.com"',
    },
    {
        key: 'vendorsLocationId',
        label: 'Vendors location ID (optional)',
        infoTop:
            'Can be found on the URL of Shopify Settings > Click on location',
    },
    {
        key: 'isWholesale',
        label: 'Wholesale shop',
        type: 'checkbox',
        noBr: true,
    },
    {
        key: 'markupPercentage',
        label: 'Retail markup percentage (optional)',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
    },
    {
        key: 'isSecondary',
        label: 'Secondary shop',
        type: 'checkbox',
        noBr: true,
    },
    {
        key: 'secondaryMarkupPercentage',
        label: "Store's markup percentage (optional)",
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
    },

    {
        key: 'productCreatedByApp',
        type: 'checkbox',
        label: 'All products created in app will be created in store',
        toggleGroup: 'Product sync strategy',
    },

    // {
    //     key: 'titleUpdatesApp',
    //     type: 'checkbox',
    //     label: 'Changes will update app',
    //     toggleGroup: 'Title sync strategy',
    // },
    // {
    //     key: 'titleUpdatedByApp',
    //     type: 'checkbox',
    //     label: 'Changes in app will update it',
    //     toggleGroup: 'Title sync strategy',
    // },
    // {
    //     key: 'descriptionUpdatesApp',
    //     type: 'checkbox',
    //     label: 'Changes will update app',
    //     toggleGroup: 'Description sync strategy',
    // },
    // {
    //     key: 'descriptionUpdatedByApp',
    //     type: 'checkbox',
    //     label: 'Changes in app will update it',
    //     toggleGroup: 'Description sync strategy',
    // },
    // {
    //     key: 'imagesUpdatesApp',
    //     type: 'checkbox',
    //     label: 'Changes will update app',
    //     toggleGroup: 'Images sync strategy',
    // },
    // {
    //     key: 'imagesUpdatedByApp',
    //     type: 'checkbox',
    //     label: 'Changes in app will update it',
    //     toggleGroup: 'Images sync strategy',
    // },
    {
        key: 'accessToken',
        label: 'Access token',
        required: true,
    },
]

function CreateShopifyShop() {
    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/shop/shops`}
                    submitText="Save"
                    submitToast="Saved"
                    submitNavArg={'/shop#setup__'}
                    inputs={inputs}
                    mainButton
                    getBody={(data) => {
                        const result = { ...data }
                        if (data.productCreatedByApp) {
                            result.syncStrategies = [
                                {
                                    field: 'all',
                                    strategy: 'created_by_app',
                                },
                            ]
                            delete result.productCreatedByApp
                        }
                        return result
                    }}
                />
            </SectionContainer>
        </>
    )
}

export default CreateShopifyShop
