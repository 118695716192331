function ResourceListTitle({ title, subtitle, smallTitle }) {
    return (
        <div className="resource-list-title">
            <h2 className={`${smallTitle ? 'small-title' : ''}`}>{title}</h2>
            {Boolean(subtitle) && (
                <small className="resource-list-subtitle text-subdued">
                    {subtitle}
                </small>
            )}
        </div>
    )
}

export default ResourceListTitle
