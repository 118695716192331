import DataFormNew from '../../../components/common/DataFormNew'

const inputs = [
    {
        key: 'publicProfileUrl',
        label: 'Public profile page URL',
    },
]

export default function EditClientPublicProfile(props) {
    const { userId } = props

    return (
        <section>
            <DataFormNew
                url={`/v1/users/${userId}?fields=publicProfileUrl`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/users/${userId}`}
                inputs={inputs}
                mapItemToData={(item) => ({
                    publicProfileUrl: item.publicProfileUrl,
                })}
                mutationRegexes={[/\/v1\/users/]}
                mainButton
            />
        </section>
    )
}
