import { formatDateTime } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'

export default function PlainFormsList() {
    return (
        <ResourceList
            apiUrl={'/forms'}
            paginated
            paginationLimit={20}
            getItemsFromResponse={(data) => data?.results}
            itemClickPath={'/plain-forms/:item.id'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => formatDateTime(item.creationDate),
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => `ID: ${item.id}`,
                    getClassName: () => 'title',
                },
            ]}
        />
    )
}
