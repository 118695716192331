export default function Image({ src, width, height, alt }) {
    return (
        <img
            className="image"
            src={src}
            width={width}
            height={height}
            alt={alt}
        />
    )
}
