import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import { ICONS, TASK_STATUSES } from '../../../common/constants'
import { TASK_STATUS_LABELS } from '../../../common/constants'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import TaskItem from './TaskItem'

const fields =
    'creatorId,clientId,assignedTeamMemberId,title,description,descriptionHtml,priority,order,userOrder,target,teamRoles,status,clientReviewStatus,timeQuoteMinutes,taskType,tags,createdAt'
const populate =
    'commentsCount,client.firstName,client.lastName,client.role,assignedTeamMember.firstName,assignedTeamMember.lastName,assignedTeamMember.role,creator.firstName,creator.lastName,creator.role'

export default function TasksList({
    title,
    onResponseLoaded,
    searchOnly,
    hideFilterTags,
    statusTabs,
    clientId,
    assignedTeamMemberId,
    newItemPath,
    targetTeam,
    targetRole,
    targetClient,
    activeStatusKeys,
    roleWithStaffAssigned,
    clientReviewStatus,
    newItemLabel,
    defaultUserOrder,
    tag,
}) {
    const auth = useAuth()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const statusFilters = {
        statusOpen: {
            label: TASK_STATUS_LABELS.open.label,
            dbField: 'statusOpen',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusAccepted: {
            label: TASK_STATUS_LABELS.accepted.label,
            dbField: 'statusAccepted',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusReview: {
            label: TASK_STATUS_LABELS.review.label,
            dbField: 'statusReview',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusComplete: {
            label: TASK_STATUS_LABELS.complete.label,
            dbField: 'statusComplete',
            default: 'false',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusRejected: {
            label: TASK_STATUS_LABELS.rejected.label,
            dbField: 'statusRejected',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusDenied: {
            label: TASK_STATUS_LABELS.denied.label,
            dbField: 'statusDenied',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusApproval: {
            label: TASK_STATUS_LABELS.approval.label,
            dbField: 'statusApproval',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusRecreation: {
            label: TASK_STATUS_LABELS.recreation.label,
            dbField: 'statusRecreation',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    }

    const targets = []
    if (targetTeam) {
        targets.push('team')
    }
    if (targetRole) {
        targets.push('role')
    }
    if (targetClient) {
        targets.push('client')
    }
    const targetStr = targets.join(',')

    function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/tasks/${item.id}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    matchMutate(/^\/v1\/tasks/)
                }}
            />,
            'Are you sure you want to delete this task?',
        )
    }

    const filters = searchOnly
        ? {
              search: true,
          }
        : {
              search: true,
              sort: {
                  ...(defaultUserOrder
                      ? {
                            userOrder: {
                                label: 'User order',
                                dbFields: ['userOrder'],
                                asc: true,
                            },
                        }
                      : {
                            defaultOrder: {
                                label: 'Default',
                                dbFields: ['default'],
                                asc: true,
                            },
                        }),
                  creationDate: {
                      label: 'Creation date',
                      dbFields: ['createdAt'],
                  },
                  title: {
                      label: 'Title',
                      dbFields: ['title'],
                      asc: true,
                  },
                  priority: {
                      label: 'Priority',
                      dbFields: ['priority'],
                      asc: true,
                  },
                  order: {
                      label: 'Global order',
                      dbFields: ['order'],
                      asc: true,
                  },
                  ...(defaultUserOrder
                      ? {
                            defaultOrder: {
                                label: 'Default',
                                dbFields: ['default'],
                                asc: true,
                            },
                        }
                      : {
                            userOrder: {
                                label: 'User order',
                                dbFields: ['userOrder'],
                                asc: true,
                            },
                        }),
              },
              ...(statusTabs?.length
                  ? {
                        filterCombos: {
                            allTab: {
                                label: 'All',
                                fields: {
                                    statusComplete: 'false',
                                },
                            },
                            ...statusTabs.reduce(
                                (a, b) => ({
                                    ...a,
                                    [`${b}Tab`]: {
                                        label: TASK_STATUS_LABELS[b].label,
                                        fields: {
                                            [`status${
                                                b.charAt(0).toUpperCase() +
                                                b.slice(1)
                                            }`]: 'true',
                                            statusComplete: 'false',
                                        },
                                    },
                                }),
                                {},
                            ),

                            ...(targets.includes('client')
                                ? {}
                                : {
                                      clientReview: {
                                          label: 'Client review',
                                          fields: {
                                              clientReviewStatus: 'submitted',
                                              statusComplete: 'false',
                                          },
                                      },
                                  }),
                        },
                    }
                  : {}),

              filters: {
                  ...statusFilters,
                  priority: {
                      label: 'Priority',
                      dbField: 'priority',
                      options: [
                          {
                              label: 'Any',
                              value: 'undefined',
                          },
                          {
                              label: 'High',
                              value: 'high',
                          },
                          {
                              label: 'Medium',
                              value: 'medium',
                          },
                          {
                              label: 'Low',
                              value: 'low',
                          },
                      ],
                  },
                  attachmentFileIds: {
                      label: 'Has attachments',
                      dbField: 'attachmentFileIds',
                      options: [
                          {
                              label: 'Any',
                              value: 'undefined',
                          },
                          {
                              label: 'Yes',
                              value: 'true',
                          },
                          {
                              label: 'No',
                              value: 'false',
                          },
                      ],
                  },
                  roleWithStaffAssigned: {
                      label: 'Team task assigned to a team member',
                      dbField: 'roleWithStaffAssigned',
                      options: [
                          {
                              label: 'Any',
                              value: 'undefined',
                          },
                          {
                              label: 'Yes',
                              value: 'true',
                          },
                          {
                              label: 'No',
                              value: 'false',
                          },
                      ],
                  },

                  ...(targets.includes('client')
                      ? {}
                      : {
                            clientReviewStatus: {
                                label: 'Client review status',
                                dbField: 'clientReviewStatus',
                                options: [
                                    {
                                        label: 'Any',
                                        value: 'undefined',
                                    },
                                    {
                                        label: 'Submitted',
                                        value: 'submitted',
                                    },
                                ],
                            },
                        }),
              },
          }

    if (activeStatusKeys?.length) {
        for (const key of activeStatusKeys) {
            filters.filters[key].default = 'true'
        }
    }

    if (roleWithStaffAssigned) {
        filters.filters.roleWithStaffAssigned.default = roleWithStaffAssigned
    }

    if (clientReviewStatus && !targets.includes('client')) {
        filters.filters.clientReviewStatus.default = clientReviewStatus
    }

    if (title) {
        filters.urlKey = title
    }

    return (
        <ResourceList
            searchPlaceholder={'Search tasks'}
            searchOnly={searchOnly}
            itemsNav={
                (auth.isAdmin || auth.isAssistant) &&
                !clientId &&
                !assignedTeamMemberId
            }
            renderMainButton={auth.isAdmin || auth.isStaff}
            newItemPath={auth.isAdmin || auth.isStaff ? newItemPath : undefined}
            newItemLabel={newItemLabel || 'New task'}
            apiUrl={'/tasks'}
            getItemsFromResponse={(data) => data?.results}
            itemClickPath={`/tasks/:item.id`}
            initialQuery={{
                target: targetStr || undefined,
                sortBy: searchOnly ? 'order:asc' : undefined,
                q: searchOnly ? '@@@@@@@@@@@' : undefined,
                statusComplete: searchOnly ? 'false' : undefined,
                assignedTeamMemberId,
                clientId,
                fields,
                populate,
                tags: tag || undefined,
            }}
            paginated
            paginationLimit={10}
            hideFilterTags={hideFilterTags}
            hideCount={searchOnly}
            loadMoreButton
            filters={filters}
            onResponseLoaded={onResponseLoaded}
            emptyText={searchOnly ? '' : undefined}
            fields={[
                {
                    column: 1,
                    getValue: (item) => <TaskItem task={item} />,
                },
            ]}
            actions={[
                {
                    title: 'Edit',
                    icon: ICONS.EDIT_GRAY,
                    link: '/tasks/:item.id/edit',
                    getHide: (item) =>
                        item.isAppTask ||
                        (!auth.isAdmin &&
                            !auth.isAssistant &&
                            !(
                                auth.isStaff &&
                                auth.user.id === item.creatorId &&
                                [
                                    (TASK_STATUSES.APPROVAL,
                                    TASK_STATUSES.RECREATION),
                                ].includes(item.status)
                            )),
                },
                {
                    title: 'Delete',
                    onClick: (item) => onDelete(item),
                    hide: !auth.isAdmin && !auth.isAssistant,
                    type: 'alert',
                    icon: ICONS.TRASH_RED,
                },
            ]}
        />
    )
}
