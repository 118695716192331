import { formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'

function PricesList(props) {
    const { items, loading } = props
    return (
        <ResourceList
            smallTitle
            items={items}
            itemsLoading={loading}
            newItemPath={'/services/:serviceId/prices/new'}
            title={'Prices'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => formatMoney(item.amount),
                    wrapperEl: 'h3',
                },
                {
                    column: 2,
                    wrapperEl: 'p',
                    getValue: (item) =>
                        `${item.pricingModel} ${
                            item.pricingModel === 'recurring'
                                ? `, every ${item.recurringCount} ${item.recurringPeriod}s`
                                : ''
                        }`,
                    getClassName: () => 'text-caps',
                },
            ]}
        />
    )
}

export default PricesList
