import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import { formatDateTime, getUserDisplayName } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'

export default function PasswordHistory() {
    const { passwordId } = useParams()
    const auth = useAuth()

    return (
        <>
            {auth.isAdmin && (
                <ResourceList
                    smallTitle
                    title="Access history"
                    apiUrl={`/user-logs`}
                    paginated
                    initialQuery={{
                        logType: 'PASSWORD_ACCESS',
                        PASSWORD_ID: passwordId,
                        sortBy: 'creationDate:desc',
                    }}
                    getItemsFromResponse={(data) => data?.results}
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => item.creationDate,
                            getClassName: () => 'subtitle',
                        },
                        {
                            column: 1,
                            getValue: (item) => getUserDisplayName(item.user),
                        },
                    ]}
                />
            )}

            <ResourceList
                smallTitle
                title="Edit history"
                apiUrl={`/vault/${passwordId}`}
                initialQuery={{ history: true }}
                getItemsFromResponse={(data) => data?.prevPasswords}
                fields={[
                    {
                        column: 1,
                        wrapperEl: 'div',
                        getValue: (item) => item.password,
                    },
                    {
                        column: 1,
                        wrapperEl: 'div',
                        getValue: (item) => formatDateTime(item.invalidateDate),
                        getClassName: () => 'subtitle',
                    },
                ]}
            />
        </>
    )
}
