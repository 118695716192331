import useAuth from '../../common/hooks/useAuth'
import TabsOverview from '../../components/navigation/TabsOverview'
import ReferralsList from './referrals/ReferralsList'
import ExpensesList from './expenses/ExpensesList'
import LateFeeList from './late-fees/LateFeeList'
import ServiceRevenuePage from './services/ServiceRevenuePage'
import ServicesList from './services/ServicesList'
import BillingChartPage from './chart/BillingChartPage'
import StripeClientsList from './stripe-clients/StripeClientsList'
import WholesaleBillingPage from './wholesale/WholesaleBillingPage'

function BillingOverview({ parentPage }) {
    const auth = useAuth()

    let pages = {}
    if (auth.isAdmin || auth.isAccountant) {
        pages = {
            overview: <BillingChartPage />,
            clients: <StripeClientsList />,
            wholesale: <WholesaleBillingPage />,
            expenses: <ExpensesList />,
            referrals: <ReferralsList />,
            services: <ServicesList />,
            service_revenue: <ServiceRevenuePage />,
            late_fees: <LateFeeList />,
        }
    }
    if (auth.isCollections) {
        pages.clients = <StripeClientsList />
    }
    if (auth.isAssistant) {
        pages.wholesale = <WholesaleBillingPage />
    }
    if (auth.isLateFeeAssistant) {
        pages.late_fees = <LateFeeList />
    }

    return (
        <TabsOverview
            defaultTab={auth.isAssistant ? 'wholesale' : 'clients'}
            pagesMap={pages}
            parentPage={parentPage}
        />
    )
}

export default BillingOverview
