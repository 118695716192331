import { useNavigate, useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import useData from '../../../common/hooks/useData'
import { formatDateTime } from '../../../common/helpers'
import { ICONS } from '../../../common/constants'
import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import Button from '../../../components/common/Button'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ArticlesList() {
    const { shopId, blogId } = useParams()
    const navigate = useNavigate()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const [actionLoading, setActionLoading] = useState(false)
    const { blog } = useData(
        `/v1/blogs/${shopId}/${blogId}?fields=shop.fullName,title`,
        'blog',
    )

    async function handlePublish(item) {
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/blogs/${shopId}/${blogId}/articles/${item.id}`,
            { publishedAt: new Date() },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Published')
        matchMutate(/\/v1\/blogs/)
    }

    async function handleUnpublish(item) {
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/blogs/${shopId}/${blogId}/articles/${item.id}`,
            { publishedAt: null },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Changed to draft')
        matchMutate(/\/v1\/blogs/)
    }

    const title =
        blog?.shop?.fullName && blog?.title
            ? `${blog.shop.fullName} / ${blog.title}`
            : 'Blog posts'

    return (
        <>
            <SectionContainer>
                <Button
                    text="Bulk create"
                    alignRight
                    outline
                    small
                    href={`/content/blogs/${shopId}/${blogId}/articles/bulk-create`}
                />
            </SectionContainer>
            <ResourceList
                title={title}
                subtitle={'This list contains up to 250 items.'}
                apiUrl={`/blogs/${shopId}/${blogId}/articles`}
                initialQuery={{
                    fields: 'id,title,published_at',
                }}
                itemClickPath={`/content/blogs/${shopId}/${blogId}/articles/:item.id`}
                emptyText={'No blog posts found.'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.title,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) => (
                            <>
                                {item.published_at ? (
                                    <>
                                        <Tag color={'success'}>Published</Tag>
                                        {formatDateTime(item.published_at)}
                                    </>
                                ) : (
                                    <Tag outline>Draft</Tag>
                                )}
                                {item.scheduledAt ? (
                                    <>
                                        <br />
                                        <Tag outline color={'primary'}>
                                            Scheduled
                                        </Tag>
                                        {formatDateTime(item.scheduledAt)}
                                    </>
                                ) : null}
                            </>
                        ),
                        getClassName: () => 'subtitle',
                    },
                ]}
                newItemLabel={'New post'}
                newItemPath={`/content/blogs/${shopId}/${blogId}/articles/new`}
                renderMainButton
                actions={[
                    {
                        title: 'Edit',
                        onClick: (item) =>
                            navigate(
                                `/content/blogs/${shopId}/${blogId}/articles/${item.id}/edit`,
                            ),
                        icon: ICONS.EDIT_GRAY,
                    },
                    {
                        title: 'Draft',
                        getHide: (item) => !item.published_at,
                        onClick: handleUnpublish,
                        icon: ICONS.EYE_OFF_GRAY,
                        loading: actionLoading,
                    },
                    {
                        title: 'Publish',
                        getHide: (item) => item.published_at,
                        onClick: handlePublish,
                        icon: ICONS.EYE_GRAY,
                        loading: actionLoading,
                    },
                    {
                        title: 'Schedule',
                        getHide: (item) => item.published_at,
                        onClick: (item) =>
                            navigate(
                                `/content/blogs/${shopId}/${blogId}/articles/${item.id}/schedule`,
                            ),
                        icon: ICONS.CALENDAR_GRAY,
                    },
                ]}
            />
        </>
    )
}
