function ResourceListTableColumns(props) {
    const { tableColumns } = props
    return (
        <div className="table-columns">
            {(tableColumns || []).map((column) => (
                <span
                    style={{
                        flexBasis: column.width
                            ? `${column.width}%`
                            : undefined,
                    }}
                    key={column.title}
                >
                    <span>{column.title}</span>
                    <span>{column.subtitle}</span>
                </span>
            ))}
        </div>
    )
}

export default ResourceListTableColumns
