import Button from './Button'

function Switch(props) {
    const { isOn, onChange, text1, text2 } = props
    return (
        <div className="switch">
            <Button
                text={text1}
                inline
                outline={isOn}
                onClick={() => onChange(false)}
            />
            <Button
                text={text2}
                inline
                outline={!isOn}
                onClick={() => onChange(true)}
            />
        </div>
    )
}

export default Switch
