import { useLocation, useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Button from '../../../components/common/Button'
import {
    formatDateTime,
    formatMoney,
    sanitizeHTML,
} from '../../../common/helpers'
import Toggle from '../../../components/common/Toggle'
import ImageSlider from '../../../components/admin/ImageSlider'
import CustomDetails from '../../../components/admin/CustomDetails'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { COLOR_VALUES, ICONS } from '../../../common/constants'
import Grid from '../../../components/common/Grid'
import BorderWrapper from '../../../components/common/BorderWrapper'
import Tag from '../../../components/common/Tag'
import Swatches from '../../../components/common/Swatches'
import InfoList from '../../../components/common/InfoList'
import PageNav from '../../../components/common/PageNav'
import AppProductItem from './AppProductItem'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

export default function AppProductPage() {
    const { productId } = useParams()
    const location = useLocation()

    const { product, productError, productLoading } = useData(
        `/v1/shop/products/${productId}?fields=title,descriptionHtml&populate=productVariants.retailPrice,productVariants.wholesalePrice,productVariants.minimumOrderValue,productVariants.minimumOrderType&withTotalQuantity=true`,
        'product',
    )

    const navSortBy = location.state?.prevSearch?.match(/sortBy=(.*?):/)?.[1]
    const { nav } = useData(
        product && navSortBy && location.state?.prevSearch
            ? `/v1/shop/products/nav?${
                  location.state.prevSearch.split('?')?.[1]
              }&sortKeyValue=${product[navSortBy]}&currId=${product.id}`
            : null,
        'nav',
    )

    if (productLoading) {
        return <Spinner marginTop />
    }
    if (productError) {
        return (
            <SectionContainer>
                <ErrorMessage>{productError}</ErrorMessage>
            </SectionContainer>
        )
    }

    const hasAllDetails = false

    const colorOption = (product.options || []).findIndex(
        (option) => option.toLowerCase() === 'color',
    )
    const colorVariants = (product.variants || []).map(
        (variant) => variant[`option${colorOption + 1}`],
    )

    const nextProductContent = (
        <div className="item-view-horizontal">
            {Boolean(nav) && <AppProductItem item={nav.nextData} />}
        </div>
    )

    return (
        <>
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack spaceBetween gap="sm">
                        <div>
                            <h2>{product.title}</h2>
                        </div>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Add product',
                                    onClick: () => {},
                                    icon: ICONS.PLUS_GRAY,
                                },
                                {
                                    title: 'Edit',
                                    onClick: () => {},
                                    icon: ICONS.EDIT_GRAY,
                                },
                                {
                                    title: 'Duplicate',
                                    onClick: () => {},
                                    icon: ICONS.COPY_GRAY,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                <ImageSlider
                    hasZoom
                    slides={[
                        { imageUrl: '' },
                        { imageUrl: '' },
                        { imageUrl: '' },
                        { imageUrl: '' },
                    ]}
                    // contentTop={<h2 className="m-0">{product.title}</h2>}
                    contentTopRight={
                        <small>
                            {product.productVariants?.[0]?.retailPrice
                                ? formatMoney(
                                      product.productVariants?.[0]?.retailPrice,
                                  )
                                : 'No retail price'}
                        </small>
                    }
                />
            </SectionContainer>

            <SectionContainer>
                <div>
                    <span>
                        Retail:{' '}
                        {product.productVariants?.[0]?.retailPrice ? (
                            formatMoney(
                                product.productVariants?.[0]?.retailPrice,
                                false,
                                'shopify',
                            )
                        ) : (
                            <span className="alert">{'No price'}</span>
                        )}
                    </span>
                    &nbsp;
                    <span>
                        Wholesale:{' '}
                        {product.productVariants?.[0]?.wholesalePrice ? (
                            formatMoney(
                                product.productVariants?.[0]?.wholesalePrice,
                                false,
                                'shopify',
                            )
                        ) : (
                            <span className="alert">{'No price'}</span>
                        )}
                    </span>
                    <span>{`
                    
                    ${
                        product.productVariants?.[0]?.minimumOrderType ===
                            'quantity' &&
                        product.productVariants?.[0]?.minimumOrderValue
                            ? `MOQ: ${product.productVariants?.[0]?.minimumOrderValue}`
                            : ''
                    }
                    
                    ${
                        product.productVariants?.[0]?.minimumOrderType ===
                            'price' &&
                        product.productVariants?.[0]?.minimumOrderValue
                            ? `MOV: ${formatMoney(
                                  product.productVariants?.[0]
                                      ?.minimumOrderValue,
                                  false,
                                  'shopify',
                              )}`
                            : ''
                    }`}</span>
                    <br />
                    {`MOQ: ${
                        product.variants?.[0]?.minimumOrderType ===
                            'quantity' &&
                        product.variants?.[0]?.minimumOrderValue
                            ? product.variants[0].minimumOrderValue
                            : '-'
                    }`}
                    <div>
                        {product.totalQuantity > 0
                            ? `Quantity: ${product.totalQuantity}`
                            : 'Out of stock, preorder available'}
                    </div>
                </div>
            </SectionContainer>

            <SectionContainer>
                <BorderWrapper contentBottom={'Commission Payout Amount'}>
                    <Grid cols={2}>
                        <span>Vendor: -</span>
                        <span>aCo: -</span>
                    </Grid>
                </BorderWrapper>
            </SectionContainer>

            <SectionContainer>
                <strong>{product.title}</strong>
                <div>
                    <br />
                    {product.tags?.map((tag) => (
                        <Tag key={tag} outline>
                            {tag}
                        </Tag>
                    ))}
                    <br />
                    <br />
                </div>
                <Swatches
                    colorData={colorVariants.map((color) => ({
                        colorValue: COLOR_VALUES[color.toLowerCase()],
                    }))}
                />

                <pre>
                    {(product.metafields || []).find(
                        (m) => m.key === 'features',
                    )?.value || ''}
                </pre>

                {product.descriptionHtml ? (
                    <CustomDetails>
                        <pre
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(product.descriptionHtml),
                            }}
                        ></pre>
                    </CustomDetails>
                ) : null}

                <InfoList
                    noIcons
                    rows={(product.metafields || [])
                        .filter((m) => m.key !== 'features')
                        .map((m) => ({
                            icon: null,
                            content: (
                                <span className="text-caps">
                                    {m.key.replace(/_/g, ' ')}:&nbsp;{m.value}
                                </span>
                            ),
                        }))}
                />
            </SectionContainer>

            <SectionContainer>
                <Toggle
                    title="Quantity"
                    headerChildrenHtml={`Total: ${product.totalQuantity || 0}`}
                ></Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle
                    title="Summary Excerpt"
                    headerChildrenHtml={`500 characters`}
                ></Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle
                    title="Social media content"
                    headerChildrenHtml={`300 characters`}
                ></Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle
                    title="Product details"
                    headerChildrenHtml={
                        hasAllDetails ? (
                            <>
                                <img
                                    src={ICONS.CHECK_WHITE}
                                    width={16}
                                    height={16}
                                    alt="check"
                                />
                                &nbsp;Complete
                            </>
                        ) : (
                            <>
                                <img
                                    src={ICONS.ALERT_WHITE}
                                    width={16}
                                    height={16}
                                    alt="check"
                                />
                                &nbsp;Incomplete
                            </>
                        )
                    }
                >
                    <div>
                        <div>
                            <span>Collections:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Category:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Height:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Depth:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Width:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Weight:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Material:</span>
                            <span>-</span>
                        </div>
                        <div>
                            <span>Tags:</span>
                            <span>-</span>
                        </div>
                    </div>
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Sales channels">
                    <Grid cols={1}>
                        <div>
                            <strong>Facebook Marketplace for Shops</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>
                                Facebook Marketplace for Individuals
                            </strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Amazon</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Ebay</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Poshmark</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Instagram</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>TikTok</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Google</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>WhatNot</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Graigslist</strong>
                            <br />
                            <span className="alert">Not active</span>
                        </div>
                        <div>
                            <strong>Social Media Posting</strong>
                            <br />
                            <span className="alert">Not approved</span>
                        </div>
                    </Grid>
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                {/* <ProductSyncInfo productId={productId} /> */}
            </SectionContainer>

            <SectionContainer>
                {product.createdAt ? (
                    <div>
                        Created at:{' '}
                        {formatDateTime(product.createdAt, true) || '-'}
                    </div>
                ) : null}
            </SectionContainer>

            <SectionContainer>
                <Button
                    icon={ICONS.EDIT_WHITE}
                    text="Edit product"
                    href={`/shop/products/${productId}/edit`}
                />
            </SectionContainer>

            <SectionContainer>
                {/* {product.owner?.todo ? (
                    <div>
                        <h5 className="m-0">
                            {product.owner.todo.acronym}
                        </h5>
                        <div>
                            {product.owner.todo.profile?.excerpt}
                        </div>
                        <Button
                            text="Follow"
                            onClick={() => {}}
                            small
                            outline
                        />
                    </div>
                ) : (
                    <div>
                        <h5 className="m-0">Vendor: -</h5>
                        <div>-</div>
                        <Button
                            text="Follow"
                            onClick={() => {}}
                            small
                            disabled
                            outline
                        />
                    </div>
                )} */}

                <br />
                <span className="reviews-container">
                    {Array.from({ length: 5 }).map((_, i) =>
                        product.starRating > i ? (
                            <img
                                src={ICONS.STAR_FILLED_WHITE}
                                width={16}
                                height={16}
                                alt="star"
                                key={i}
                            />
                        ) : (
                            <img
                                src={ICONS.STAR_WHITE}
                                width={16}
                                height={16}
                                alt="star"
                                key={i}
                            />
                        ),
                    )}
                    <small className="text-subdued">
                        &nbsp;
                        {`(${
                            product.reviewCount === 1
                                ? `${product.reviewCount} review`
                                : `${product.reviewCount || 0} reviews`
                        })`}
                    </small>
                </span>
                {/* <div>
                    {product.owner?.todo?.country || 'Country: -'}
                </div> */}
            </SectionContainer>

            {nav ? (
                <PageNav
                    prevId={nav.prevId}
                    nextId={nav.nextId}
                    nextContent={nextProductContent}
                />
            ) : null}
        </>
    )
}
