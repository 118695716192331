import { useStore } from '../Context'

const useToast = () => {
    const [_state, dispatch] = useStore()

    const setToast = (text, type = 'success') => {
        const toastDate = Date.now()
        dispatch({
            type: 'SET_TOAST',
            payload: { text, date: toastDate, messageType: type },
        })

        setTimeout(() => {
            dispatch({
                type: 'CLEAR_TOAST',
                payload: toastDate,
            })
        }, 5000)
    }

    return setToast
}

export default useToast
