import React from 'react'

export default function ErrorMessage({ children, section }) {
    return (
        <div
            className={`error-message${
                section ? ' error-message-section' : ''
            }`}
        >
            {children}
        </div>
    )
}
