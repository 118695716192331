import { ALLOWED_NOTIF_TYPES } from '../constants'
import { getProcessedNotif } from '../helpers'
import useAuth from './useAuth'
import useData from './useData'

export default function useNotifs(hide, filter = {}) {
    const auth = useAuth()
    const notifTypes = auth?.user?.role
        ? ALLOWED_NOTIF_TYPES[auth.user.role] || []
        : []

    let url = `/v1/notifs?unread=true&sortBy=creationDate:desc&limit=500`
    if (filter.notifType && notifTypes.includes(filter.notifType)) {
        url += `&notifType=${filter.notifType}`
    } else {
        url += `&notifType=${notifTypes.join(',')}`
    }
    if (filter.field1) {
        url += `&field1=${filter.field1}`
    }
    if (filter.field2) {
        url += `&field2=${filter.field2}`
    }
    if (filter.field3) {
        url += `&field3=${filter.field3}`
    }

    const { notifs, notifsLoading, notifsError } = useData(
        auth?.user?.role && !hide ? url : null,
        'notifs',
        (data) => {
            const result = data?.results
                ? data.results.map((item) => getProcessedNotif(item))
                : []

            // onSuccess && onSuccess(result)
            return result
        },
        true,
        true,
        () => {},
        [],
        60 * 1000,
    )

    return {
        notifs,
        notifsLoading,
        notifsError,
    }
}
