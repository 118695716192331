import { useEffect, useState } from 'react'
import useAuth from '../../../common/hooks/useAuth'
import {
    getContactDetailsUpdateBody,
    getUserDisplayName,
} from '../../../common/helpers'
import { DEFAULT_TEAM_ROLES } from '../../../common/constants'
import DataFormNew from '../../../components/common/DataFormNew'
import useDebounceFn from '../../../common/hooks/useDebounceFn'
import fetchAPI from '../../../common/fetchAPI'
import Input from '../../../components/common/data-form/Input'
import InlineStack from '../../../components/common/InlineStack'
import SectionContainer from '../../../components/common/SectionContainer'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'

function CreateContact() {
    const auth = useAuth()
    const canCreateClients =
        auth.isAdmin || auth.isAssistant || auth.isClientManager
    const canCreateCompanies = auth.isAdmin || auth.isAssistant
    const canCreateStaff = auth.isAdmin || auth.isAssistant
    const [typeValue, setTypeValue] = useState('contact')
    const [isFriend, setIsFriend] = useState(false)
    const [isFamily, setIsFamily] = useState(false)
    const debounceGetAcronymPreview = useDebounceFn(getAcronymPreview, 500)

    const setToast = useToast()
    const { definitions, definitionsError } = useData(
        auth.isAdmin
            ? `/v1/metafields/definitions?scope=app&resource[]=User&resource[]=Lead`
            : null,
        'definitions',
    )
    useEffect(
        function () {
            if (definitionsError) {
                setToast(definitionsError, 'alert')
            }
        },
        [definitionsError],
    )

    let type
    if (typeValue !== 'contact') {
        type = typeValue
    } else {
        if (isFriend) {
            type = 'friend'
        } else if (isFamily) {
            type = 'family'
        } else {
            type = 'contact'
        }
    }

    async function getAcronymPreview(name) {
        if (!name) return 'Acronym preview: -'
        const { responseData, error } = await fetchAPI(
            `/v1/organizations/generate-acronym?name=${name}`,
        )
        if (error) return 'Error while getting preview.'
        return `Acronym preview: ${responseData.acronym}`
    }

    function getBody(data) {
        const isContact = ['contact', 'lead', 'friend', 'family'].includes(type)
        const body = {
            ...data,
            ...(isContact || type === 'company' ? {} : { role: type }),
        }

        let contactDetails
        if (isContact || type === 'user') {
            contactDetails = getContactDetailsUpdateBody(data)
        }
        if (contactDetails) {
            body.contactDetails = contactDetails
        }
        delete body.phones
        delete body.alternativeFirstName
        delete body.alternativeLastName
        delete body.nickname
        delete body.marketingOptIn
        delete body.facebook
        delete body.tiktok
        delete body.youtube
        delete body.twitter
        delete body.pinterest
        delete body.snapchat
        delete body.linkedin
        delete body.nextdoor
        delete body.github
        delete body.website

        if (type === 'lead') {
            body.contactType = 'lead'
        }
        if (type === 'contact') {
            body.contactType = 'contact'
        }
        if (type === 'friend') {
            body.contactType = 'friend'
        }
        if (type === 'family') {
            body.contactType = 'family'
        }

        body.metafields = []
        for (const key of Object.keys(body)) {
            if (!key.startsWith('metafield_')) continue

            if (body[key] === undefined) continue

            const metafieldKey = key.replace('metafield_', '')
            body.metafields.push({
                key: metafieldKey,
                value: body[key],
            })
            delete body[key]
        }

        if (!body.metafields.length) {
            delete body.metafields
        }

        return body
    }

    const userInputs = [
        {
            key: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            autoComplete: 'new-password',
        },
        {
            key: 'firstName',
            label: 'First name',
            required: true,
        },
        {
            key: 'lastName',
            label: 'Last name',
            required: true,
        },
        {
            key: 'alternativeFirstName',
            label: 'Alternative first name',
            toggleGroup: 'Other names',
        },
        {
            key: 'alternativeLastName',
            label: 'Alternative last name',
            toggleGroup: 'Other names',
        },
        {
            key: 'nickname',
            label: 'Nickname',
            toggleGroup: 'Other names',
        },
        {
            label: 'Phone',
            key: 'phones',
            isCollapsible: true,
            type: 'addItems',
            defaultValue: [
                {
                    phone: '',
                    phoneType: 'main',
                },
            ],
            innerInputs: [
                {
                    key: 'phone',
                    type: 'tel',
                    label: 'Phone number',
                },
                {
                    key: 'phoneType',
                    type: 'select',
                    label: 'Phone type',
                    options: [
                        {
                            value: 'other',
                            label: '',
                        },
                        {
                            value: 'business',
                            label: 'Business',
                        },
                        {
                            value: 'mobile',
                            label: 'Mobile',
                        },
                        {
                            value: 'home',
                            label: 'Home',
                        },
                    ],
                },
            ],
        },
        {
            key: 'facebook',
            label: 'Facebook profile URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'tiktok',
            label: 'Tiktok URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'youtube',
            label: 'Youtube URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'twitter',
            label: 'Twitter URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'pinterest',
            label: 'Pinterest URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'snapchat',
            label: 'Snapchat URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'linkedin',
            label: 'LinkedIn URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'nextdoor',
            label: 'Nextdoor URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'github',
            label: 'GitHub Handle',
            toggleGroup: 'Social media',
        },
        {
            key: 'website',
            label: 'Website URL',
        },
        ...(definitions?.length
            ? definitions
                  .filter((d) => d.resource === 'User')
                  .map((d) => ({
                      key: `metafield_${d.key}`,
                      label: d.title,
                      type: d.valueType,
                      toggleGroup: 'Metafields',
                  }))
            : []),

        {
            key: 'password',
            label: 'Password',
            required: true,
            type: 'password',
            autoComplete: 'new-password',
        },
    ]

    const contactInputs = [
        {
            key: 'firstName',
            label: 'First name',
            required: true,
        },
        {
            key: 'lastName',
            label: 'Last name',
            required: true,
        },
        {
            key: 'alternativeFirstName',
            label: 'Alternative first name',
            toggleGroup: 'Other names',
        },
        {
            key: 'alternativeLastName',
            label: 'Alternative last name',
            toggleGroup: 'Other names',
        },
        {
            key: 'nickname',
            label: 'Nickname',
            toggleGroup: 'Other names',
        },
        {
            key: 'email',
            label: 'Email',
            type: 'email',
            autoComplete: 'new-password',
        },
        {
            label: 'Phone',
            key: 'phones',
            type: 'addItems',
            isCollapsible: true,
            defaultValue: [
                {
                    phone: '',
                    phoneType: 'main',
                },
            ],
            innerInputs: [
                {
                    key: 'phone',
                    type: 'tel',
                    label: 'Phone number',
                },
                {
                    key: 'phoneType',
                    type: 'select',
                    label: 'Phone type',
                    options: [
                        {
                            value: 'other',
                            label: '',
                        },
                        {
                            value: 'business',
                            label: 'Business',
                        },
                        {
                            value: 'mobile',
                            label: 'Mobile',
                        },
                        {
                            value: 'home',
                            label: 'Home',
                        },
                    ],
                },
            ],
        },
        {
            key: 'marketingOptIn',
            label: 'Opted in for marketing',
            type: 'checkbox',
        },
        {
            key: 'facebook',
            label: 'Facebook profile URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'tiktok',
            label: 'Tiktok URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'youtube',
            label: 'Youtube URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'twitter',
            label: 'Twitter URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'pinterest',
            label: 'Pinterest URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'snapchat',
            label: 'Snapchat URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'linkedin',
            label: 'LinkedIn URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'nextdoor',
            label: 'Nextdoor URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'github',
            label: 'GitHub Handle',
            toggleGroup: 'Social media',
        },
        {
            key: 'website',
            label: 'Website URL',
        },
        ...(definitions?.length
            ? definitions
                  .filter((d) => d.resource === 'Lead')
                  .map((d) => ({
                      key: `metafield_${d.key}`,
                      label: d.title,
                      type: d.valueType,
                      toggleGroup: 'Metafields',
                  }))
            : []),
        {
            key: 'notes',
            label: 'Notes',
            type: 'textarea',
            rows: 3,
        },
    ]

    const staffInputs = [
        {
            key: 'firstName',
            label: 'First name',
            required: true,
        },
        {
            key: 'lastName',
            label: 'Last name',
            required: true,
        },
        {
            key: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            autoComplete: 'new-password',
        },
        ...(definitions?.length
            ? definitions
                  .filter((d) => d.resource === 'User')
                  .map((d) => ({
                      key: `metafield_${d.key}`,
                      label: d.title,
                      type: d.valueType,
                      toggleGroup: 'Metafields',
                  }))
            : []),
        {
            key: 'password',
            label: 'Password',
            required: true,
            type: 'password',
            autoComplete: 'new-password',
        },
        {
            key: 'jobType',
            label: 'Job title',
            type: 'resourceDropdown',
            required: true,
            url:
                canCreateStaff && type === 'staff'
                    ? '/v1/settings/jobTypes'
                    : null,
            getItems: (data) => data?.settingValue?.split('\n'),
            getItemLabel: (item) => item,
            getItemValue: (item) => item,
        },
        {
            key: 'teamRoles',
            label: 'Team roles',
            getItemsFromResults: (data) => [
                ...(data?.settingValue?.split('\n') || []),
                ...DEFAULT_TEAM_ROLES,
            ],
            type: 'liveSearchAddItems',
            canAddNewItem: true,
            url: '/settings/teamRoles',
            getItemText: (item) => item,
            fields: [
                {
                    column: 1,
                    getValue: (item) => item,
                },
            ],
            defaultValue: [],
        },
        {
            key: 'startDate',
            label: 'Started',
            type: 'date',
        },
        {
            key: 'supervisorId',
            label: 'Supervisor',
            type: 'resourceDropdown',
            url:
                canCreateStaff && type === 'staff'
                    ? '/v1/users?role=staff&fields=firstName,lastName,email'
                    : null,
            getItems: (data) => data?.results,
            getItemLabel: (item) => getUserDisplayName(item),
            getItemValue: (item) => item.id,
        },
    ]

    const companyInputs = [
        {
            key: 'name',
            label: 'Name',
            required: true,
            autoComplete: 'new-password',
            getPreviewText: debounceGetAcronymPreview,
        },
        {
            key: 'organizationType',
            type: 'hidden',
            defaultValue: 'company',
            value: 'company',
        },
    ]

    let redirectUrl = '/contacts'
    if (type === 'lead') {
        redirectUrl += '#leads'
    } else if (type === 'contact') {
        redirectUrl += auth.isClient ? '#all' : '#contacts'
    } else if (type === 'family') {
        redirectUrl += '#family'
    } else if (type === 'friend') {
        redirectUrl += '#friends'
    }

    let inputs
    switch (type) {
        case 'user':
            inputs = userInputs
            break
        case 'contact':
        case 'lead':
        case 'family':
        case 'friend':
            inputs = contactInputs
            break
        case 'staff':
            inputs = staffInputs
            break
        case 'company':
            inputs = companyInputs
            break
        default:
            inputs = []
    }

    let url
    switch (type) {
        case 'contact':
        case 'lead':
        case 'family':
        case 'friend':
            url = '/v1/contacts'
            break
        case 'user':
            url = '/v1/users'
            break
        case 'staff':
            url = '/v1/users'
            break
        case 'company':
            url = '/v1/organizations'
            break
        default:
    }

    return (
        <SectionContainer>
            <form>
                <Input
                    type="select"
                    id="user-type"
                    value={typeValue}
                    onChange={(v) => setTypeValue(v)}
                    label="Type"
                    options={[
                        ...(canCreateClients
                            ? [
                                  {
                                      value: 'user',
                                      label: 'Client',
                                      default: true,
                                  },
                              ]
                            : []),
                        { value: 'contact', label: 'Contact' },
                        { value: 'lead', label: 'Lead' },
                        ...(canCreateStaff
                            ? [
                                  {
                                      value: 'staff',
                                      label: 'Team member',
                                      default: !canCreateClients,
                                  },
                              ]
                            : []),
                        ...(canCreateCompanies
                            ? [
                                  {
                                      value: 'company',
                                      label: 'Company',
                                  },
                              ]
                            : []),
                    ]}
                />

                {['contact', 'friend', 'family'].includes(typeValue) && (
                    <InlineStack gap={'lg'}>
                        <Input
                            type="checkbox"
                            id="user-type-friend"
                            checked={isFriend}
                            onChange={(v) => {
                                v && setIsFamily(false)
                                setIsFriend(v)
                            }}
                            label="Friend"
                        />
                        <Input
                            type="checkbox"
                            id="user-type-family"
                            checked={isFamily}
                            onChange={(v) => {
                                v && setIsFriend(false)
                                setIsFamily(v)
                            }}
                            label="Family"
                        />
                    </InlineStack>
                )}
            </form>
            <DataFormNew
                key={type}
                url={url}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={redirectUrl}
                inputs={inputs}
                getBody={getBody}
                mainButton
                saveAndNewButton
            />
        </SectionContainer>
    )
}

export default CreateContact
