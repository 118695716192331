import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import useData from '../../common/hooks/useData'
import Break from '../../components/common/Break'
import ErrorMessage from '../../components/common/ErrorMessage'
import InfoList from '../../components/common/InfoList'
import MessageSection from '../../components/common/MessageSection'
import Spinner from '../../components/common/Spinner'

export default function AccountInfo({ userId }) {
    const auth = useAuth()
    const isOwnProfile = !userId

    const { orgs, orgsLoading, orgsError } = useData(
        userId ? `/v1/organizations?fields=name&memberUserId=${userId}` : null,
        'orgs',
        (data) => data?.results || [],
    )

    const orgIds = (isOwnProfile ? auth.user.orgsData : orgs).map((o) => o.id)

    const url = isOwnProfile
        ? `/v1/accounts/me`
        : `/v1/accounts?${orgIds.map((id) => `ownerId=${id}`).join('&')}`

    const { accounts, accountsLoading, accountsError } = useData(
        url,
        'accounts',
    )

    if (accountsLoading || orgsLoading) {
        return <Spinner />
    }

    if (accountsError || orgsError) {
        return <ErrorMessage>{accountsError || orgsError}</ErrorMessage>
    }

    if (!orgIds?.length || !accounts?.length) {
        return 'No accounts'
    }

    return (
        <>
            {auth.isAdmin && (
                <>
                    {' '}
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Only users viewing their profile, admins and assistants see this. Accounts belong to the first organization of the user (company or default type)."
                    ></MessageSection>
                    <Break />
                </>
            )}

            <InfoList
                rows={[
                    ...(
                        accounts?.filter((a) => a?.accountType === 'vendor') ||
                        []
                    ).map(() => ({
                        icon: ICONS.PACKAGE_WHITE,
                        content: 'Vendor',
                        link: isOwnProfile
                            ? `/shop#vendor_info`
                            : `/shop/db-vendors/${userId}`,
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'wholesaleBuyer',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.SHOPPING_CART_WHITE,
                        content: 'Wholesale Buyer',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'retailBuyer',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.SHOPPING_CART_WHITE,
                        content: 'Retail Buyer',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'dropshipper',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.SHOPPING_CART_WHITE,
                        content: 'Dropshipper',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'dropshipShop',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.SHOPPING_CART_WHITE,
                        content: 'Dropship',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'destasher',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.SHOPPING_CART_WHITE,
                        content: 'Destash',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'agencyClient',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.FOLDER_WHITE,
                        content: 'aCo Client',
                    })),
                    ...(
                        accounts?.filter(
                            (a) => a?.accountType === 'serviceProvider',
                        ) || []
                    ).map(() => ({
                        icon: ICONS.FOLDER_WHITE,
                        content: 'Service provider',
                    })),
                ]}
            />
        </>
    )
}
