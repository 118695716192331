import { useParams } from 'react-router-dom'
import { COMPANY_TYPES, COUNTRIES, US_STATES } from '../../../common/constants'
import DataFormNew from '../../../components/common/DataFormNew'
import useAuth from '../../../common/hooks/useAuth'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditOrganization() {
    const { organizationId } = useParams()
    const auth = useAuth()

    const inputs = [
        {
            key: 'name',
            label: 'Company',
            disabled: true,
        },
        {
            key: 'emailAddress',
            label: 'Email',
            required: !auth.isAdmin && !auth.isAssistant,
            type: 'email',
        },
        {
            key: 'companyType',
            label: 'Company Type',
            required: !auth.isAdmin && !auth.isAssistant,
            type: 'select',
            options: [
                {
                    value: '',
                    label: '',
                },
                ...COMPANY_TYPES.map((text) => ({
                    value: text,
                    label: text,
                })),
            ],
            infoBottom: auth.isClient
                ? 'If you do not have a registered business with the Secretary of State, and you do business under your personal name, your business is operating as a sole proprietor. Please select this option.'
                : '',
        },
        {
            key: 'phoneNumber',
            label: 'Phone',
            required: !auth.isAdmin && !auth.isAssistant,
            type: 'tel',
        },
        {
            key: 'mainAddressAddress',
            label: 'Address',
            required: !auth.isAdmin && !auth.isAssistant,
        },
        {
            key: 'mainAddressCity',
            label: 'City',
            required: !auth.isAdmin && !auth.isAssistant,
        },
        {
            key: 'mainAddressZip',
            label: 'Zip',
            required: !auth.isAdmin && !auth.isAssistant,
        },
        {
            key: 'mainAddressState',
            label: 'State',
            type: 'select',
            options: [
                {
                    value: '',
                    label: '',
                },
                ...US_STATES.map(({ text }) => ({ value: text, label: text })),
            ],
            required: !auth.isAdmin && !auth.isAssistant,
            shouldBeText: (data) => data.mainAddressCountry !== 'United States',
        },
        {
            key: 'mainAddressCountry',
            label: 'Country',
            type: 'select',
            options: COUNTRIES.map(({ text }) => ({
                value: text,
                label: text,
            })),
            required: !auth.isAdmin && !auth.isAssistant,
            defaultValue: 'United States',
        },
        {
            key: 'companyEin',
            label: 'EIN',
        },
        {
            key: 'contactInstagram',
            label: 'Instagram profile URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactFacebook',
            label: 'Facebook profile URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactTiktok',
            label: 'Tiktok URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactYoutube',
            label: 'Youtube URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactTwitter',
            label: 'Twitter URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactPinterest',
            label: 'Pinterest URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactSnapchat',
            label: 'Snapchat URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactLinkedin',
            label: 'LinkedIn URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactNextdoor',
            label: 'Nextdoor URL',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactGithub',
            label: 'GitHub Handle',
            toggleGroup: 'Social media',
        },
        {
            key: 'contactWebsite',
            label: 'Website URL',
        },
    ]

    function mapItemToData(item) {
        return {
            name: item.name,
            emailAddress: item.contactDetails?.email,
            companyType: item.companyInfo?.companyType,
            phoneNumber: item.contactDetails?.phone,
            mainAddressAddress: item.mainAddress?.address,
            mainAddressCity: item.mainAddress?.city,
            mainAddressZip: item.mainAddress?.zip,
            mainAddressState: item.mainAddress?.state,
            mainAddressCountry: item.mainAddress?.country,
            companyEin: item.companyInfo?.ein,
            contactInstagram: item.contactDetails?.instagram,
            contactFacebook: item.contactDetails?.facebook,
            contactTiktok: item.contactDetails?.tiktok,
            contactYoutube: item.contactDetails?.youtube,
            contactTwitter: item.contactDetails?.twitter,
            contactPinterest: item.contactDetails?.pinterest,
            contactSnapchat: item.contactDetails?.snapchat,
            contactLinkedin: item.contactDetails?.linkedin,
            contactNextdoor: item.contactDetails?.nextdoor,
            contactGithub: item.contactDetails?.github,
            contactWebsite: item.contactDetails?.website,
        }
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/organizations/${organizationId}?fields=name&populate=contactDetails,mainAddress,companyInfo`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={
                    auth.isClient
                        ? `/profile`
                        : `/organizations/${organizationId}`
                }
                inputs={inputs}
                getBody={(data) => {
                    const body = { ...data }
                    delete body.name
                    return body
                }}
                mapItemToData={mapItemToData}
                mutationRegexes={[
                    /\/v1\/users/,
                    /\/v1\/organizations/,
                    /\/v1\/users\/me\/profile-completion/,
                ]}
                mainButton
                onSuccess={async () =>
                    auth.isClient && (await auth.refetchAuth())
                }
            />
        </SectionContainer>
    )
}
