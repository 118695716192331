import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
        autoComplete: 'new-password',
        infoBottom: "The resulting posts will be post-fixed with ' - [number]'",
    },

    {
        key: 'files',
        label: 'Featured image',
        type: 'file',
        accept: 'image/jpeg',
        required: true,
        multiple: true,
    },
]

export default function BulkCreateArticles() {
    const { shopId, blogId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/blogs/${shopId}/${blogId}/articles/bulk`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/content/blogs/${shopId}/${blogId}`}
                inputs={inputs}
                mainButton
                initData={{ files: [], title: '' }}
                getBody={(data) => {
                    const MAX_FILE_SIZE = 10 * 1024 * 1024
                    if (
                        Array.from(data.files).some(
                            (f) => f.size > MAX_FILE_SIZE,
                        )
                    ) {
                        throw new Error(`Max file size is 10MB.`)
                    }
                    const formData = new FormData()
                    const body = { ...data }
                    delete body.files
                    for (let i = 0; i < data.files.length; i++) {
                        formData.append(`files[${i}]`, data.files[i])
                    }
                    formData.append('data', JSON.stringify(body))
                    return formData
                }}
            />
        </SectionContainer>
    )
}
