import { useParams } from 'react-router-dom'
import { getUserDisplayName } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import ContactCard from '../../admin/clients/ContactCard'
import { useState } from 'react'
import useToast from '../../../common/hooks/useToast'
import useData from '../../../common/hooks/useData'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import { ICONS } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import LiveSearch from '../../../components/common/data-form/LiveSearch'
import SectionContainer from '../../../components/common/SectionContainer'

export default function AssignOrganizationMembers() {
    const { organizationId } = useParams()
    const [newMemberId, setNewMemberId] = useState(null)
    const setToast = useToast()
    const [loading, setLoading] = useState(false)
    const auth = useAuth()

    const {
        organization,
        organizationError,
        organizationLoading,
        organizationMutate,
    } = useData(
        `/v1/organizations/${organizationId}?populate=members.user.email,members.user.firstName,members.user.lastName&fields=name`,
        'organization',
        null,
        null,
        null,
        (data) =>
            setNewMemberId({
                memberIds: data.members.map((item) => item.userId),
            }),
    )

    async function handleAddMember(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/organizations/${organizationId}/members`,
            { userId: newMemberId },
            'POST',
        )
        setLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Member added')
        organizationMutate()
        setNewMemberId(null)
    }

    async function handleRemoveMember(item) {
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/organizations/${organizationId}/members/${item.id}`,
            {},
            'DELETE',
        )
        setLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Member deleted')
        organizationMutate()
    }

    if (organizationLoading) {
        return <Spinner />
    }

    if (organizationError) {
        return (
            <SectionContainer>
                <ErrorMessage>{organizationError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <form onSubmit={handleAddMember}>
                    <label htmlFor="form-member">New member</label>
                    <LiveSearch
                        id="form-member"
                        getResultValue={(result) => getUserDisplayName(result)}
                        url="/v1/users?role=user&fields=firstName,lastName,email"
                        getItemsFromResults={(data) => data?.results || []}
                        onItemClick={(item) => {
                            setNewMemberId(item.id)
                        }}
                    />
                    <Button
                        text={'Add member'}
                        type={'submit'}
                        loading={loading}
                    />
                </form>
            </SectionContainer>

            <ResourceList
                key={
                    organization?.members?.map((m) => m.id).join(',') ||
                    'members'
                }
                title={'Members'}
                smallTitle
                items={organization.members || []}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <ContactCard user={item.user} summary />
                        ),
                    },
                ]}
                actions={[
                    {
                        title: 'Remove',
                        onClick: handleRemoveMember,
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        getHide: () => !auth.isAdmin,
                    },
                ]}
            />
        </>
        // <form onSubmit={onSubmit}>

        //     <br />

        //     <div>
        //         <Button text={'Save'} type="submit" />
        //     </div>

        //     <MainButton
        //         // disabled={actionLoading}
        //         functionality="SAVE"
        //         // loading={actionLoading}
        //     />
        // </form>
    )
}
