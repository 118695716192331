const isDev = process.env.NODE_ENV === 'development'

const useSessionStorage = localStorage.getItem('use_session_auth') === 'true'

const authStorage = {
    getItem: (key) => {
        return isDev && useSessionStorage
            ? sessionStorage.getItem(key)
            : localStorage.getItem(key)
    },
    setItem: (key, value) => {
        isDev && useSessionStorage
            ? sessionStorage.setItem(key, value)
            : localStorage.setItem(key, value)
    },
    removeItem: (key) => {
        isDev && useSessionStorage
            ? sessionStorage.removeItem(key)
            : localStorage.removeItem(key)
    },
}

export default authStorage
