import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import { TASK_STATUSES } from '../../../common/constants'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'

function RejectTask() {
    const { taskId } = useParams()

    const { task, taskError, taskLoading, taskMutate } = useData(
        `/v1/tasks/${taskId}`,
        'task',
    )

    const [data, setData] = useState({})

    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError] = useError()
    const navigate = useNavigate()
    const setToast = useToast()

    const onSubmit = async (e) => {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setActionLoading(true)
        const body = {
            rejectionMessage: data.rejectionMessage,
            status: TASK_STATUSES.REJECTED,
        }
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(url, body, 'PATCH')
        setActionLoading(false)
        if (error) return setError(error)

        taskMutate({ ...task, ...responseData })

        navigate(`/tasks/${taskId}`)
        setToast('Done')
    }

    if (taskLoading) return <Spinner marginTop />
    if (taskError)
        return (
            <SectionContainer>
                <ErrorMessage>{taskError}</ErrorMessage>
            </SectionContainer>
        )
    // if (userError) return <ErrorMessage section>{userError}</ErrorMessage>

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <h2>Reject &ldquo;{task.title}&ldquo;</h2>
            <form onSubmit={onSubmit}>
                <label htmlFor="form-message">Message</label>
                <TextInput
                    type="text"
                    name="message"
                    id="form-message"
                    onChange={(v) =>
                        setData({
                            ...data,
                            rejectionMessage: v,
                        })
                    }
                    value={data.rejectionMessage}
                    autoComplete="off"
                />

                <div>
                    <Button
                        text={'Reject'}
                        type="submit"
                        isLoading={actionLoading}
                    />
                </div>
            </form>
        </SectionContainer>
    )
}

export default RejectTask
