import Spinner from '../components/common/Spinner'
import { useUploads } from './UploadContext'
import { ICONS } from './constants'

export default function UploadNotifs() {
    const { progress } = useUploads()

    if (progress === null) return null

    return (
        <div className="upload-notifs">
            {progress === 0 ? (
                <span className="icon-complete">
                    <img
                        src={ICONS.CHECK_WHITE}
                        alt="upload complete"
                        width="18"
                        height="18"
                    />
                </span>
            ) : (
                <>
                    <span className="icon-progress">{progress}</span>
                    <Spinner />
                </>
            )}
        </div>
    )
    // return <div className="total-price">{progress}</div>
}
