import { useSearchParams } from 'react-router-dom'
import DataFormNew from '../../components/common/DataFormNew'
import ErrorMessage from '../../components/common/ErrorMessage'
import useData from '../../common/hooks/useData'
import Spinner from '../../components/common/Spinner'
import SectionContainer from '../../components/common/SectionContainer'

const fields = [
    {
        key: 'companyEin',
        label: 'EIN',
        pattern: '^d{2}[- ]{0,1}d{7}$',
        placeholder: '12-3456789',

        infoBottom: (
            <>
                If you don&apos;t have an EIN,&nbsp;
                <a
                    href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
                    target="_blank"
                    rel="noreferrer"
                >
                    apply here.
                </a>
                &nbsp;You will need to click the big blue apply now button on
                the page. It&apos;s free to get one and takes 5 minutes or less.
            </>
        ),
    },
]

export default function EditProfileCompanyInfoEin() {
    const [searchParams] = useSearchParams()

    const orgId = searchParams.get('organizationId')

    const { org, orgLoading, orgError } = useData(
        `/v1/organizations/${orgId}?fields=name&populate=companyInfo`,
        'org',
    )

    if (orgLoading) {
        return <Spinner />
    }

    if (orgError) {
        return (
            <SectionContainer>
                <ErrorMessage>{orgError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <h2>{org.name}</h2>
            <DataFormNew
                url={`/v1/organizations/${orgId}?fields=name&populate=companyInfo.ein`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/`}
                inputs={fields}
                mapItemToData={(item) => ({
                    companyEin: item?.companyInfo.ein,
                })}
                mutationRegexes={[
                    /\/v1\/users/,
                    /\/v1\/organizations/,
                    /\/v1\/users\/me\/profile-completion/,
                ]}
                mainButton
            />
        </SectionContainer>
    )
}
