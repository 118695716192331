import { Link } from 'react-router-dom'
import ActionsDropdown from '../common/ActionsDropdown'

function CardLayout(props) {
    let className = 'card-layout'
    if (props.type === 'files') className += ' file'
    if (props.selectable) className += ' selectable'
    if (props.selected) className += ' selected'
    return (
        <div className={className} style={props.style || {}}>
            {props.href && !props.selectable ? (
                <Link
                    to={props.href}
                    state={{
                        prevSearch: props.itemsNavSearch
                            ? props.itemsNavSearch
                            : null,
                    }}
                >
                    <div className="content">{props.children}</div>
                </Link>
            ) : (
                <div
                    className={`no-href ${props.clickable ? 'clickable' : ''}`}
                >
                    <div className="content">{props.children}</div>
                </div>
            )}
            <ActionsDropdown listItem actions={props.actions} />
        </div>
    )
}

export default CardLayout
