import { useParams, useSearchParams } from 'react-router-dom'
import { COUNTRIES, US_STATES } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateAddress() {
    const { userId: userIdParam, contactId: contactIdParam } = useParams()
    const auth = useAuth()
    const [searchParams] = useSearchParams()
    const defaultAddressType = searchParams.get('type')

    const userId = auth.isClient ? auth.user.id : userIdParam

    const inputs = [
        {
            key: 'addressTypes',
            label: 'Address Type',
            type: 'select',
            options: [
                { value: '', label: '' },
                { value: 'billing', label: 'Billing' },
                { value: 'shipping', label: 'Shipping' },
            ],
            defaultValue: defaultAddressType,
        },
        {
            key: 'address',
            label: 'Address',
            required: true,
        },
        {
            key: 'city',
            label: 'City',
            required: true,
        },
        {
            key: 'zip',
            label: 'Zip',
            required: true,
        },
        {
            key: 'state',
            label: 'State',
            type: 'select',
            options: US_STATES.map(({ text }) => ({
                value: text,
                label: text,
            })),
            required: true,
            shouldBeText: (data) => data.country !== 'United States',
        },
        {
            key: 'country',
            label: 'Country',
            type: 'select',
            options: COUNTRIES.map(({ text }) => ({
                value: text,
                label: text,
            })),
            required: true,
            defaultValue: 'United States',
        },
    ]

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/addresses/${contactIdParam || userId}`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={inputs}
                getBody={(data) => ({
                    ...data,
                    addressTypes: [data.addressTypes],
                    ownerId: contactIdParam || userId,
                    ownerType: 'User',
                })}
                mutationRegexes={[
                    /\/v1\/addresses*/,
                    /\/v1\/user*/,
                    /\/v1\/me*/,
                    /\/v1\/users\/me\/profile-completion/,
                ]}
                onSuccess={async () => await auth.refetchAuth()}
                mainButton
            />
        </SectionContainer>
    )
}

export default CreateAddress
