import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import Button from '../../../components/common/Button'
import useAuth from '../../../common/hooks/useAuth'
import useToast from '../../../common/hooks/useToast'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Input from '../../../components/common/data-form/Input'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

export default function CreateAccounts() {
    const navigate = useNavigate()
    const auth = useAuth()

    const { accounts, accountsLoading, accountsError, accountsMutate } =
        useData(`/v1/accounts/me`, 'accounts')

    const { companies, companiesLoading, companiesError } = useData(
        `/v1/organizations?organizationType=company&fields=name&memberUserId=${auth.user.id}&populate=companyInfo`,
        'companies',
        (data) => data?.results || [],
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState({})
    const [companyId, setCompanyId] = useState(
        auth.onlyCompany ? auth.onlyCompany.id : '',
    )

    const setToast = useToast()

    async function handleSubmit(e) {
        setError('')
        e.preventDefault()

        if (data.vendor && data.isConsignment) {
            setError('You can choose only one between Vendor and Consignment')
            return
        }

        if (
            !data.wholesaleBuyer &&
            !data.vendor &&
            !data.isConsignment &&
            !data.retailBuyer &&
            !data.dropshipShop &&
            !data.dropshipper &&
            !data.destasher &&
            !data.agencyClient
        ) {
            navigate('/')
            return
        }

        setLoading(true)

        const { error } = await fetchAPI(
            `/v1/accounts/me/register`,
            {
                accounts: Object.entries(data)
                    .filter(([_key, value]) => value)
                    .map(([key]) => key),
                companyId: companyId,
            },
            'POST',
        )

        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        await auth.refetchAuth()
        accountsMutate()
        setToast('Saved')
        navigate('/')
    }

    function handleNonAllowedAccountType() {
        setError(
            <>
                Please{' '}
                <Link to="/profile/company/new">add a company first</Link>.
            </>,
        )
    }

    function handleNonAllowedCompanyType() {
        setError(<>This account is not allowed for Sole Proprietors.</>)
    }

    if (accountsLoading || companiesLoading) {
        return <Spinner marginTop />
    }

    if (accountsError || companiesError) {
        return (
            <SectionContainer>
                <ErrorMessage>{accountsError || companiesError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!companies?.length) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    Please{' '}
                    <Link to="/profile/company/new">add a company first</Link>.
                </ErrorMessage>
            </SectionContainer>
        )
    }

    const selectedCompany = companies.find((c) => c.id === companyId)

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {/* {Boolean(auth.onlyCompany) && <h2>{auth.onlyCompany?.name}</h2>} */}

            <form onSubmit={handleSubmit}>
                {Boolean(companies?.length > 1) && (
                    <div>
                        <Input
                            label="Company"
                            type="select"
                            id="company"
                            required
                            options={[
                                { value: '', label: 'Select Company' },
                                ...companies.map((c) => ({
                                    value: c.id,
                                    label: c.name,
                                })),
                            ]}
                            value={companyId}
                            onChange={(companyId) => setCompanyId(companyId)}
                        />
                    </div>
                )}
                <div>
                    Please select which types of accounts you would like access
                    to.
                    <br />
                    <br />
                </div>
                <div>
                    <CheckboxInput
                        value={
                            data.wholesaleBuyer ||
                            accounts.some(
                                (a) => a.accountType === 'wholesaleBuyer',
                            )
                        }
                        id="is-buyer"
                        label="Wholesale Buyer"
                        onChange={(wholesaleBuyer) =>
                            setData({ ...data, wholesaleBuyer })
                        }
                        disabled={
                            !auth.user.orgsData?.length ||
                            selectedCompany?.companyInfo?.companyType ===
                                'Sole Proprietor' ||
                            accounts.some(
                                (a) => a.accountType === 'wholesaleBuyer',
                            )
                        }
                        info="You work for or own a retail store that wishes to buy products at wholesale prices"
                    />
                    <br />
                </div>
                <div>
                    <CheckboxInput
                        value={
                            data.dropshipShop ||
                            accounts.some(
                                (a) => a.accountType === 'dropshipShop',
                            )
                        }
                        id="is-dropship"
                        label="Dropshipping Seller"
                        onChange={(dropshipShop) =>
                            setData({ ...data, dropshipShop })
                        }
                        disabled={
                            !auth.user.orgsData?.length ||
                            accounts.some(
                                (a) => a.accountType === 'dropshipShop',
                            )
                        }
                        info={
                            'You wish to sync your products from our store to yours, we manage shipping and fulfillment. $49.99.'
                        }
                    />
                    <br />
                </div>

                <div>
                    <CheckboxInput
                        value={
                            data.vendor ||
                            accounts.some((a) => a.accountType === 'vendor')
                        }
                        id="is-vendor"
                        label="Vendor or Artist"
                        onChange={(vendor) => setData({ ...data, vendor })}
                        disabled={
                            !auth.user.orgsData?.length ||
                            accounts.some((a) => a.accountType === 'vendor')
                        }
                        info="You're an artist or brand that creates products and wishes to sell them in our online marketplace or in one of our multibrand showrooms"
                    />
                    <br />
                </div>

                {data.vendor ? (
                    <div>
                        <CheckboxInput
                            value={
                                data.dropshipper ||
                                accounts.some(
                                    (a) => a.accountType === 'dropshipper',
                                )
                            }
                            id="is-dropshipper"
                            label="Dropshipping Vendor or Creator"
                            onChange={(dropshipper) =>
                                setData({ ...data, dropshipper })
                            }
                            disabled={
                                !auth.user.orgsData?.length ||
                                accounts.some(
                                    (a) => a.accountType === 'dropshipper',
                                )
                            }
                            info="You would like to be featured in our online marketpace to dropship to consumers on behalf of boutiques"
                        />
                        <br />
                    </div>
                ) : null}
                <div>
                    <CheckboxInput
                        value={
                            data.isConsignment ||
                            accounts.some(
                                (a) =>
                                    a.accountType === 'vendor' &&
                                    a.isConsignment,
                            )
                        }
                        id="is-consignment"
                        label="Consignment"
                        onChange={(isConsignment) =>
                            setData({ ...data, isConsignment })
                        }
                        disabled={
                            !auth.user.orgsData?.length ||
                            accounts.some((a) => a.accountType === 'vendor')
                        }
                        info="You're an individual with items in great-to-like new condition that you need help selling or would like to list in our online marketplace or sell in our stores"
                    />
                    <br />
                </div>
                <div>
                    <CheckboxInput
                        value={
                            data.retailBuyer ||
                            accounts.some(
                                (a) => a.accountType === 'retailBuyer',
                            )
                        }
                        id="is-retail"
                        label="Retail Buyer"
                        onChange={(retailBuyer) =>
                            setData({ ...data, retailBuyer })
                        }
                        disabled={
                            !auth.user.orgsData?.length ||
                            accounts.some(
                                (a) => a.accountType === 'retailBuyer',
                            )
                        }
                        info="You love to shop at our retail store or online and do not own a store"
                    />
                    <br />
                </div>
                <div>
                    <CheckboxInput
                        value={
                            data.destasher ||
                            accounts.some((a) => a.accountType === 'destasher')
                        }
                        id="is-destash"
                        label="Destash"
                        onChange={(destasher) =>
                            setData({ ...data, destasher })
                        }
                        disabled={
                            !auth.user.orgsData?.length ||
                            accounts.some((a) => a.accountType === 'destasher')
                        }
                        info="You own a retail store or sell items wholesale and have overstock or dead stock (items you can't sell) that you would like help selling"
                    />
                    <br />
                </div>
                <div>
                    <CheckboxInput
                        value={
                            data.agencyClient ||
                            accounts.some(
                                (a) => a.accountType === 'agencyClient',
                            )
                        }
                        id="is-client"
                        label="Agency client"
                        onChange={(agencyClient) =>
                            setData({ ...data, agencyClient })
                        }
                        disabled={accounts.some(
                            (a) => a.accountType === 'agencyClient',
                        )}
                        info="You're interested in hiring aCo to help you with web design, marketing, or social media services"
                    />
                    <br />
                </div>

                <div>
                    <Button isLoading={loading} text={'Save'} type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}
