import { useParams, useSearchParams } from 'react-router-dom'
import {
    formatDateForInput,
    getContactDetailsUpdateBody,
    getUserDisplayName,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'
import { DEFAULT_TEAM_ROLES } from '../../../common/constants'

const userInputs = [
    {
        key: 'firstName',
        label: 'First name',
        required: true,
    },
    {
        key: 'lastName',
        label: 'Last name',
        required: true,
    },
    {
        key: 'email',
        label: 'Email',
        type: 'email',
        disabled: true,
    },
    {
        key: 'mainPhone',
        label: 'Phone',
        type: 'tel',
    },
    {
        key: 'alternativeFirstName',
        label: 'Alternative first name',
        toggleGroup: 'Other names',
    },
    {
        key: 'alternativeLastName',
        label: 'Alternative last name',
        toggleGroup: 'Other names',
    },
    {
        key: 'nickname',
        label: 'Nickname',
        toggleGroup: 'Other names',
    },
    {
        key: 'facebook',
        label: 'Facebook profile URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'tiktok',
        label: 'Tiktok URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'youtube',
        label: 'Youtube URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'twitter',
        label: 'Twitter URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'pinterest',
        label: 'Pinterest URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'snapchat',
        label: 'Snapchat URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'linkedin',
        label: 'LinkedIn URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'nextdoor',
        label: 'Nextdoor URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'github',
        label: 'GitHub Handle',
        toggleGroup: 'Social media',
    },
    {
        key: 'website',
        label: 'Website URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'internalNotes',
        label: 'Internal notes',
        type: 'textarea',
        rows: 3,
    },
    {
        key: 'externalNotes',
        label: 'External notes',
        type: 'textarea',
        rows: 3,
    },
    {
        key: 'marketingOptIn',
        label: 'Opted in for marketing',
        type: 'checkbox',
    },
]

const staffInputs = [
    ...userInputs,
    {
        key: 'jobType',
        label: 'Job title',
        type: 'resourceDropdown',
        required: true,
        url: '/v1/settings/jobTypes',
        getItems: (data) => data?.settingValue?.split('\n'),
        getItemLabel: (item) => item,
        getItemValue: (item) => item,
    },
    {
        key: 'teamRoles',
        label: 'Team roles',
        getItemsFromResults: (data) => [
            ...(data?.settingValue?.split('\n') || []),
            ...DEFAULT_TEAM_ROLES,
        ],
        type: 'liveSearchAddItems',
        canAddNewItem: true,
        url: '/settings/teamRoles',
        getItemText: (item) => item,
        fields: [
            {
                column: 1,
                getValue: (item) => item,
            },
        ],
        // defaultValue: [],
    },
    {
        key: 'supervisorId',
        label: 'Supervisor',
        type: 'resourceDropdown',
        url: '/v1/users?role=staff&fields=email,firstName,lastName',
        getItems: (data) => data?.results,
        getItemLabel: (item) => getUserDisplayName(item),
        getItemValue: (item) => item.id,
    },
    {
        key: 'startDate',
        label: 'Started',
        type: 'date',
    },
    {
        key: 'defaultHourlyRate',
        label: 'Default hourly rate',
        type: 'number',
        min: 0,
        step: 0.01,
    },
]

const contactInputs = [
    {
        key: 'firstName',
        label: 'First name',
    },
    {
        key: 'lastName',
        label: 'Last name',
    },
    {
        key: 'email',
        label: 'Email',
        type: 'email',
    },
    {
        key: 'mainPhone',
        label: 'Phone',
        type: 'tel',
    },
    {
        key: 'alternativeFirstName',
        label: 'Alternative first name',
        toggleGroup: 'Other names',
    },
    {
        key: 'alternativeLastName',
        label: 'Alternative last name',
        toggleGroup: 'Other names',
    },
    {
        key: 'nickname',
        label: 'Nickname',
        toggleGroup: 'Other names',
    },
    {
        key: 'facebook',
        label: 'Facebook profile URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'tiktok',
        label: 'Tiktok URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'youtube',
        label: 'Youtube URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'twitter',
        label: 'Twitter URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'pinterest',
        label: 'Pinterest URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'snapchat',
        label: 'Snapchat URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'linkedin',
        label: 'LinkedIn URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'nextdoor',
        label: 'Nextdoor URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'github',
        label: 'GitHub Handle',
        toggleGroup: 'Social media',
    },
    {
        key: 'website',
        label: 'Website URL',
        toggleGroup: 'Social media',
    },
    {
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
        rows: 3,
    },
    {
        key: 'marketingOptIn',
        label: 'Opted in for marketing',
        type: 'checkbox',
    },
]

function EditContact() {
    const { contactId } = useParams()
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type') || 'contact'
    const auth = useAuth()

    function mapItemToData(item) {
        const data = {
            // All
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            email: item.email || '',
            mainPhone: item.contactDetails?.phone || '',
            alternativeFirstName:
                item.contactDetails?.alternativeFirstName || '',
            alternativeLastName: item.contactDetails?.alternativeLastName || '',
            nickname: item.contactDetails?.nickname || '',
            facebook: item.contactDetails?.facebook || '',
            tiktok: item.contactDetails?.tiktok || '',
            youtube: item.contactDetails?.youtube || '',
            twitter: item.contactDetails?.twitter || '',
            pinterest: item.contactDetails?.pinterest || '',
            snapchat: item.contactDetails?.snapchat || '',
            linkedin: item.contactDetails?.linkedin || '',
            nextdoor: item.contactDetails?.nextdoor || '',
            github: item.contactDetails?.github || '',
            website: item.contactDetails?.website || '',
            marketingOptIn: item.contactDetails?.marketingOptIn,

            // User or staff
            ...((type === 'staff' || type === 'user') && {
                internalNotes: item.internalNotes || '',
                externalNotes: item.externalNotes || '',
            }),

            // Staff
            ...(type === 'staff' && {
                jobType: item.jobType || '',
                teamRoles: item.teamRoles || [],
                supervisorId: item.supervisorId || '',
                startDate: formatDateForInput(item.startDate) || '',
                defaultHourlyRate: item.defaultHourlyRate || '',
            }),

            // Contact
            ...(type !== 'user' &&
                type !== 'staff' && {
                    mainPhone: item.contactDetails?.phone || '',
                    notes: item.notes || '',
                }),
        }
        return data
    }

    function getBody(data, originalItem) {
        const body = { ...data }

        if (type === 'staff' && !data.teamRoles?.length) {
            throw new Error(
                'Team members must be assigned to at least one team role',
            )
        }

        if (body.mainPhone) {
            const phoneData =
                originalItem.contactDetails?.phones?.find(
                    (p) => p.phoneType === 'main',
                ) || originalItem.contactDetails?.phones?.[0]

            if (phoneData) {
                body.phones = [
                    {
                        phone: body.mainPhone,
                        dbId: phoneData._id,
                        phoneType: phoneData.phoneType || 'main',
                    },
                ]
            } else {
                body.phones = [{ phone: body.mainPhone, phoneType: 'main' }]
            }
        }

        const contactDetails = getContactDetailsUpdateBody(
            body,
            originalItem.contactDetails,
        )
        if (contactDetails) {
            body.contactDetails = contactDetails
        }
        delete body.mainPhone
        delete body.phones
        delete body.alternativeFirstName
        delete body.alternativeLastName
        delete body.nickname
        delete body.marketingOptIn
        delete body.facebook
        delete body.tiktok
        delete body.youtube
        delete body.twitter
        delete body.pinterest
        delete body.snapchat
        delete body.linkedin
        delete body.nextdoor
        delete body.github
        delete body.website

        if (type === 'staff') {
            body.startDate = data.startDate || null
            body.supervisorId = data.supervisorId || null
            body.defaultHourlyRate = Number(data.defaultHourlyRate || 0)
        }

        return body
    }

    let inputs
    let url
    switch (type) {
        case 'staff':
            inputs = staffInputs
            url = `/v1/users/${contactId}?fields=email,firstName,lastName,jobType,teamRoles,supervisorId,startDate,internalNotes,externalNotes,defaultHourlyRate&populate=contactDetails`
            break
        case 'user':
            inputs = userInputs
            url = `/v1/users/${contactId}?fields=email,firstName,lastName,internalNotes,externalNotes&populate=contactDetails`
            break
        default:
            inputs = contactInputs
            url = `/v1/contacts/${contactId}`
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={url}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/${type === 'contact' ? 'contacts' : 'users'}/${contactId}`}
                inputs={inputs}
                getBody={getBody}
                mapItemToData={mapItemToData}
                mutationRegexes={[/\/v1\/contacts/, /\/v1\/users/]}
                mainButton
            />
        </SectionContainer>
    )
}

export default EditContact
