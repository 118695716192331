import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import AssignableClientsList from './AssignableClientsList'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateClientAssignment() {
    const { teamMemberId } = useParams()
    const [loading, setLoading] = useState(true)
    const [selectedIds, setSelectedIds] = useState([])
    const [error, setError] = useError()
    const navigate = useNavigate()
    const setToast = useToast()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData: memberResponse, error: memberError } =
                await fetchAPI(
                    `/v1/users/${teamMemberId}?fields=assignedClientIds`,
                    {},
                    'GET',
                )
            setLoading(false)
            if (memberError) return setError(memberError)
            setSelectedIds(memberResponse.assignedClientIds)
        }

        getData()
    }, [])

    const onSelectionsSubmit = async () => {
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/users/${teamMemberId}`,
            {
                assignedClientIds: selectedIds,
            },
            'PATCH',
        )
        setLoading(false)
        if (error) return setError(error)
        setToast('Done')
        navigate(-1)
    }

    if (loading) return <Spinner />

    return (
        <>
            <SectionContainer>
                {error && <ErrorMessage section>{error}</ErrorMessage>}
                <Button
                    type="button"
                    text="Save"
                    outline
                    alignRight
                    small
                    onClick={onSelectionsSubmit}
                />
            </SectionContainer>
            <AssignableClientsList
                assignedClients={selectedIds}
                onChange={setSelectedIds}
            />
        </>
    )
}

export default CreateClientAssignment
