// v18
import { createContext, useContext, useState } from 'react'
import useToast from './hooks/useToast'
import { API_URL } from './constants'
import authStorage from './authStorage'

const Store = createContext(undefined)
Store.displayName = 'Uploads'

const uploadChannel = new BroadcastChannel('upload-channel')

export const useUploads = () => {
    return useContext(Store)
}

export function UploadsProvider({ children }) {
    const [progress, setProgress] = useState(null)
    const setToast = useToast()

    function handleSWUpdate(event) {
        if (event.data?.type === 'UPLOADED_FILE') {
            setProgress(event.data.filesUploaded)
        }
        if (event.data?.type === 'UPLOAD_ERROR') {
            setToast(
                event.data.message || 'Unknown error while uploading',
                'alert',
            )
            setProgress(event.data.filesUploaded)
        }

        if (event.data?.type === 'UPLOAD_FINISHED') {
            uploadChannel.removeEventListener('message', handleSWUpdate)
            setProgress(0)
            setTimeout(function () {
                setProgress(null)
            }, 3000)
        }
    }

    function uploadFiles(files, url) {
        if (progress !== null) {
            return { error: 'Upload already in progress' }
        }
        if ('serviceWorker' in navigator) {
            setProgress(files.length)
            navigator.serviceWorker.ready.then((registration) => {
                uploadChannel.addEventListener('message', handleSWUpdate)
                registration.active.postMessage({
                    message: 'START_UPLOAD',
                    files,
                    url,
                    accessToken: authStorage.getItem('access_token'),
                    refreshToken: authStorage.getItem('refresh_token'),
                    refreshUrl: `${API_URL}/v1/auth/refresh-tokens`,
                })
            })
            return {}
        } else {
            console.error('Service worker not available')
            return { error: 'Service worker not available' }
        }
    }

    return (
        <Store.Provider value={{ progress, uploadFiles }}>
            {children}
        </Store.Provider>
    )
}
