import { useState } from 'react'
import Input from './data-form/Input'
import useData from '../../common/hooks/useData'
import PrivateFile from './PrivateFile'
import Grid from './Grid'
import BlockStack from './BlockStack'
import ScrollableContent from './ScrollableContent'
import CheckboxInput from './data-form/CheckboxInput'
import Button from './Button'
import ErrorMessage from './ErrorMessage'
import Spinner from './Spinner'
import fetchAPI from '../../common/fetchAPI'
import useToast from '../../common/hooks/useToast'
import StorageFile from './StorageFile'
import InlineStack from './InlineStack'

const getImageDimensions = (file) =>
    new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = function (e) {
            const img = new Image()
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                })
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(file)
    })

function FilePickerModal({
    onClose,
    onConfirm,
    maxFiles,
    isPublic,
    confirmLabel,
}) {
    const [selectedFileIds, setSelectedFileIds] = useState([])
    const [uploadLoading, setUploadLoading] = useState(null)
    const [inputKey, setInputKey] = useState(Date.now())
    const [isOptionChecked, setIsOptionChecked] = useState(
        localStorage.getItem('file_picker_submit_on_pick') === 'true',
    )
    const setToast = useToast()
    const { files, filesError, filesLoading, filesMutate, filesValidating } =
        useData(
            `/v1/files/list/me`,
            'files',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
        )

    function handleCheckboxChange(id) {
        setSelectedFileIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((i) => i !== id)
            } else {
                if (prev.length >= maxFiles) {
                    return [...prev.slice(1), id]
                } else {
                    return [...prev, id]
                }
            }
        })
    }

    async function onUpload(files) {
        if (!files?.length) {
            return
        }

        setUploadLoading(true)

        const fileDimensions = await Promise.all(
            files.map(async (file) => {
                if (!file.type.startsWith('image')) {
                    return null
                }
                const { width, height } = await getImageDimensions(file)
                return { width, height }
            }),
        )

        const { responseData: urlResponseData, error: urlError } =
            await fetchAPI(
                `/v1/files/list/me`,
                {
                    files: files.map((file, i) => ({
                        fileName: file.name,
                        width: fileDimensions[i]?.width,
                        height: fileDimensions[i]?.height,
                    })),
                },
                'POST',
            )

        if (urlError) {
            setToast(urlError, 'alert')
            return
        }

        const finalUrls = []

        for (const { url, fileName, tokenFileName } of urlResponseData) {
            const file = files.find(({ name }) => name === fileName)
            const fileIndex = files.indexOf(file)
            const dimensions = fileDimensions[fileIndex]

            try {
                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': file.type,
                        'x-goog-meta-filename': file.name,
                        ...(dimensions
                            ? {
                                  'x-goog-meta-width': dimensions.width,
                                  'x-goog-meta-height': dimensions.height,
                              }
                            : {}),
                    },
                    body: file,
                })
                if (!uploadResponse?.ok) {
                    throw new Error('File upload failed')
                }

                const { responseData, error } = await fetchAPI(
                    `/v1/files/list/me/${tokenFileName}/make-public`,
                    {},
                    'POST',
                )

                if (error) {
                    throw new Error('File make public failed')
                }
                finalUrls.push(responseData.publicUrl)
            } catch (error) {
                console.error(error)
                setToast(`File upload failed for ${fileName}`, 'alert')
            }
        }

        // const newFiles = []

        // for (const file of files) {
        //     const { width, height } = await getImageDimensions(file)

        //     setToast('Upload started')

        //     const formData = new FormData()
        //     formData.append(`file`, file)
        //     formData.append(
        //         `data`,
        //         JSON.stringify({
        //             properties: {
        //                 width: width,
        //                 height: height,
        //             },
        //             isPublic,
        //         }),
        //     )

        //     const { responseData, error } = await fetchAPI(
        //         `/v1/files/list/me`,
        //         formData,
        //         'POST',
        //         {},
        //     )
        //     if (error) {
        //         setToast(error, 'alert')
        //         return
        //     }
        //     newFiles.push(responseData)
        // }

        // filesMutate([...(files || []), ...newFiles])
        // setInputKey(Date.now())
        filesMutate()
        setUploadLoading(false)
        setToast('Uploaded successfully')

        if (isOptionChecked) {
            const backendFileItems = finalUrls.map((url, i) => ({
                // TODO: non-public etc.
                url,
                publicUrl: url,
                dbId: url,
                id: url,
                height: fileDimensions[i]?.height,
                width: fileDimensions[i]?.width,
                mimeType: files[i].type,
                name: files[i].name,
            }))
            onConfirm(backendFileItems)
        }
        /*
            
            */
    }

    // async function onUpload(files) {
    //     if (!files?.length) {
    //         return
    //     }

    //     setUploadLoading(true)

    //     const newFiles = []

    //     for (const file of files) {
    //         const { width, height } = await getImageDimensions(file)

    //         setToast('Upload started')

    //         const formData = new FormData()
    //         formData.append(`file`, file)
    //         formData.append(
    //             `data`,
    //             JSON.stringify({
    //                 properties: {
    //                     width: width,
    //                     height: height,
    //                 },
    //                 isPublic,
    //             }),
    //         )

    //         const { responseData, error } = await fetchAPI(
    //             `/v1/files/list/me`,
    //             formData,
    //             'POST',
    //             {},
    //         )
    //         if (error) {
    //             setToast(error, 'alert')
    //             return
    //         }
    //         newFiles.push(responseData)
    //     }

    //     filesMutate([...(files || []), ...newFiles])
    //     setInputKey(Date.now())
    //     setUploadLoading(false)
    //     setToast('Uploaded successfully')
    // }

    function handleClose() {
        onClose()
    }

    function handleConfirm() {
        onConfirm(files.filter(({ id }) => selectedFileIds.includes(id)))
    }

    function handleOptionChange(v) {
        localStorage.setItem('file_picker_submit_on_pick', v)
        setIsOptionChecked(v)
    }

    return (
        <div className="modal file-picker-modal">
            <div className={`modal-container show`}>
                <div className="modal-header">
                    <h4>Pick a file</h4>

                    <InlineStack gap={'tiny'}>
                        <CheckboxInput
                            small
                            id="use-on-input"
                            label={'Submit on pick'}
                            checked={isOptionChecked}
                            onChange={handleOptionChange}
                        />
                        <button
                            className="modal-close-btn"
                            onClick={handleClose}
                        >
                            &#10005;
                        </button>
                    </InlineStack>
                </div>
                <div className="modal-content">
                    <Input
                        key={inputKey}
                        label={'Upload a file'}
                        type="file"
                        id="form-attachment-browser"
                        multiple
                        onChange={(files) => onUpload([...files])}
                    />

                    {/* <Break /> */}
                    <ScrollableContent>
                        {Boolean(filesError) && (
                            <ErrorMessage>{filesError}</ErrorMessage>
                        )}
                        {(filesLoading || filesValidating || uploadLoading) && (
                            <Spinner marginBottom />
                        )}

                        <Grid cols={2}>
                            {(files || []).map((file) => (
                                <BlockStack gap={'tiny'} key={file.id}>
                                    <CheckboxInput
                                        label={file.name}
                                        hideLabel
                                        noBr
                                        value={selectedFileIds.includes(
                                            file.id,
                                        )}
                                        onChange={() =>
                                            handleCheckboxChange(file.id)
                                        }
                                    />
                                    <div
                                        onClick={() =>
                                            handleCheckboxChange(file.id)
                                        }
                                    >
                                        <StorageFile {...file} />
                                        {/* <PrivateFile driveFileId={id} /> */}
                                    </div>
                                    <small className="text-subdued">
                                        {file.name}
                                    </small>
                                </BlockStack>
                            ))}
                        </Grid>
                    </ScrollableContent>

                    {Boolean(selectedFileIds.length) && (
                        <Button
                            noMargin
                            text={confirmLabel || 'Select'}
                            onClick={handleConfirm}
                        />
                    )}
                </div>
            </div>
            <div className="modal-overlay"></div>
        </div>
    )
}

export default function useFilePicker() {
    const [pickerHtml, setPickerHtml] = useState(false)

    function openPicker({ maxFiles, isPublic, confirmLabel } = {}) {
        return new Promise(function (resolve) {
            const pickerHtml = (
                <FilePickerModal
                    confirmLabel={confirmLabel}
                    isPublic={isPublic}
                    maxFiles={maxFiles}
                    onClose={() => {
                        resolve()
                        setPickerHtml(null)
                    }}
                    onConfirm={(results) => {
                        resolve(results)
                        setPickerHtml(null)
                    }}
                />
            )

            setPickerHtml(pickerHtml)
        })
    }

    return {
        openPicker,
        pickerHtml,
    }
}
