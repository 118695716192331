import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useScrollAnchor() {
    // Get hash with react router
    const { hash } = useLocation()
    useEffect(
        function () {
            if (hash) {
                const timeout = setTimeout(function () {
                    const id = hash.replace('#', '')
                    const element = document.getElementById(id)
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' })
                    }
                    // window.history.replaceState(
                    //     '',
                    //     document.title,
                    //     window.location.pathname + window.location.search,
                    // )
                }, 500)

                return function () {
                    clearTimeout(timeout)
                }
            }
        },
        [hash],
    )
}
