import { useNavigate, useParams } from 'react-router-dom'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useData from '../../../common/hooks/useData'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import { copyToClipboard, formatDateTime } from '../../../common/helpers'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { ICONS } from '../../../common/constants'
import Button from '../../../components/common/Button'
import ResourceList from '../../../components/admin/ResourceList'
import Toggle from '../../../components/common/Toggle'
import useToast from '../../../common/hooks/useToast'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'
import Break from '../../../components/common/Break'

function getSubmitterName(fields) {
    const keys = Object.keys(fields)
    let email
    let firstName
    let lastName
    for (const k of keys) {
        if (k.toLowerCase().includes('first name')) {
            firstName = fields[k]
        } else if (k.toLowerCase().includes('last name')) {
            lastName = fields[k]
        } else if (k.toLowerCase().includes('email')) {
            email = fields[k]
        }
    }

    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    } else if (firstName) {
        return firstName
    } else if (lastName) {
        return lastName
    } else if (email) {
        return email
    } else {
        return '(unknown)'
    }
}

export default function ContactFormPage() {
    const { contactFormId } = useParams()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const navigate = useNavigate()
    const { setModal, isModalLoading, setModalLoading } = useModal()
    const { form, formLoading, formError } = useData(
        `/v1/contact-forms/${contactFormId}`,
        'form',
    )

    async function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-forms/${contactFormId}/submissions/${item.id}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    matchMutate(/\/v1\/contact-forms/)
                    setToast('Deleted message')
                }}
            />,
            'Are you sure you want to delete this message?',
        )
    }

    if (formLoading) {
        return <Spinner />
    }

    if (formError) {
        return (
            <SectionContainer>
                <ErrorMessage>{formError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>{form.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Copy embed code',
                                    onClick: () => {
                                        copyToClipboard(form.embedHtml)
                                        setToast('Copied to clipboard')
                                    },
                                    icon: ICONS.COPY_GRAY,
                                },
                                {
                                    title: 'Edit',
                                    icon: ICONS.EDIT_GRAY,
                                    onClick: () =>
                                        navigate(
                                            `/contact-forms/${contactFormId}/edit`,
                                        ),
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                <Button
                    text="Copy embed code"
                    icon={ICONS.COPY_ACTIVE}
                    onClick={() => {
                        copyToClipboard(form.embedHtml)
                        setToast('Copied to clipboard')
                    }}
                    small
                    outline
                />
            </SectionContainer>
            <Break />
            <ResourceList
                smallTitle
                title="Messages"
                apiUrl={`/contact-forms/${contactFormId}/submissions`}
                initialQuery={{
                    sortBy: 'creationDate:desc',
                }}
                paginated
                emptyText={`No messages yet`}
                getItemsFromResponse={(data) => data?.results}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `From ${getSubmitterName(item.fields)}`,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) => formatDateTime(item.creationDate),
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) => (
                            <Toggle small title="Info">
                                {Object.entries(item.fields).map(
                                    ([k, v], i) => (
                                        <div key={i}>
                                            {k}: {v || '-'}
                                        </div>
                                    ),
                                )}
                            </Toggle>
                        ),
                    },
                ]}
                actions={[
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: (item) => onDelete(item),
                    },
                ]}
            />
            <br />

            <SectionContainer>
                <div>
                    <h2 className="h3">Whitelisted domains</h2>
                    <ul>
                        {form.whitelistedDomains.map((domain) => (
                            <li key={domain}>{domain}</li>
                        ))}
                    </ul>
                </div>
            </SectionContainer>
        </>
    )
}
