import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

export default function CreateBlog() {
    const auth = useAuth()

    const inputs = [
        {
            key: 'shopId',
            label: 'Shop',
            type: 'resourceDropdown',
            required: true,
            url: `/v1/wholesale/shops?userId=${auth.user.id}&fields=fullName`,
            getItems: (data) => data || [],
            getItemLabel: (item) => item.fullName,
            getItemValue: (item) => item.id,
        },
        {
            key: 'title',
            label: 'Title',
            required: true,
            autoComplete: 'new-password',
        },
    ]

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/blogs`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/content#blogs`}
                inputs={inputs}
                mainButton
            />
        </SectionContainer>
    )
}
