import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function TempIowaCalendarEmbed() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/pages/events')
    }, [])

    return <div></div>
}

export default TempIowaCalendarEmbed
