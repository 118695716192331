import { useParams } from 'react-router-dom'

import DataFormNew from '../../../components/common/DataFormNew'
import useAuth from '../../../common/hooks/useAuth'
import SectionContainer from '../../../components/common/SectionContainer'

const adminShopInputs = [
    {
        key: 'fullName',
        label: 'Display name',
        required: true,
    },
    {
        key: 'name',
        label: 'Shopify subdomain',
        required: true,
        infoTop: 'Without ".myshopify.com"',
    },
    {
        key: 'vendorsLocationId',
        label: 'Vendors location ID (optional)',
        infoTop:
            'Can be found on the URL of Shopify Settings > Click on location',
    },
    {
        key: 'isWholesale',
        label: 'Wholesale shop',
        type: 'checkbox',
        noBr: true,
    },
    {
        key: 'markupPercentage',
        label: 'Retail markup percentage (optional)',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
    },
    {
        key: 'isSecondary',
        label: 'Secondary shop',
        type: 'checkbox',
        noBr: true,
    },
    {
        key: 'secondaryMarkupPercentage',
        label: "Store's markup percentage (optional)",
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
    },
    {
        key: 'productCreatedByApp',
        type: 'checkbox',
        label: 'All products created in app will be created in store',
        toggleGroup: 'Product sync strategy',
    },
]

const userShopInputs = [
    {
        key: 'fullName',
        label: 'Display name',
        required: true,
    },
    {
        key: 'name',
        label: 'Shopify subdomain',
        required: true,
        infoTop: 'Without ".myshopify.com"',
    },
]

const adminTokenInputs = [
    {
        key: 'accessToken',
        label: 'Access token',
        required: true,
    },
]

const userTokenInputs = [
    {
        key: 'accessToken',
        label: 'Access token',
        required: true,
        infoTop:
            'Create a custom app with read_products and read_content permissions and get the access token. For products and orders sync, also enable read_orders.',
    },
]

export default function EditShopifyShop() {
    const { shopifyShopId } = useParams()
    const auth = useAuth()

    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={
                        auth.isAdmin
                            ? `/v1/shop/shops/${shopifyShopId}?fields=fullName,name,vendorsLocationId,markupPercentage,isWholesale,isSecondary,secondaryMarkupPercentage,syncStrategies`
                            : `/v1/shop/shops/${shopifyShopId}?fields=fullName,name`
                    }
                    method="PATCH"
                    submitText="Save"
                    submitToast="Saved"
                    submitNavArg={'/shop#setup__'}
                    inputs={auth.isAdmin ? adminShopInputs : userShopInputs}
                    mainButton
                    mapItemToData={(item) => ({
                        fullName: item.fullName,
                        name: item.name,
                        ...(auth.isAdmin
                            ? {
                                  vendorsLocationId: item.vendorsLocationId,
                                  markupPercentage: item.markupPercentage,
                                  isWholesale: item.isWholesale,
                                  isSecondary: item.isSecondary,
                                  secondaryMarkupPercentage:
                                      item.secondaryMarkupPercentage,
                                  productCreatedByApp: item.syncStrategies.some(
                                      (s) =>
                                          s.field === 'all' &&
                                          s.strategy === 'created_by_app',
                                  ),
                              }
                            : {}),
                    })}
                    getBody={(data) => {
                        const result = { ...data }
                        if (auth.isAdmin) {
                            if (data.productCreatedByApp) {
                                result.syncStrategies = [
                                    {
                                        field: 'all',
                                        strategy: 'created_by_app',
                                    },
                                ]
                            } else {
                                result.syncStrategies = []
                            }
                            delete result.productCreatedByApp
                        }
                        return result
                    }}
                />
            </SectionContainer>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/shop/shops/${shopifyShopId}?fields=name`}
                    method="PATCH"
                    submitText="Save"
                    submitToast="Saved"
                    submitNavArg={'/shop#setup__'}
                    inputs={auth.isAdmin ? adminTokenInputs : userTokenInputs}
                    mainButton
                    mapItemToData={() => ({
                        accessToken: '',
                    })}
                />
            </SectionContainer>
        </>
    )
}
