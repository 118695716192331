import useAuth from '../../common/hooks/useAuth'
import SectionContainer from '../../components/common/SectionContainer'
import TabsOverview from '../../components/navigation/TabsOverview'
import BlogsList from './blogs/BlogsList'
import FacebookConnectedAccountsList from './connected-accounts/FacebookConnectedAccountsList'
import SocialPostPlansList from './social-plans/SocialPostPlansList'
import { Link } from 'react-router-dom'

export default function ContentOverview({ parentPage }) {
    const auth = useAuth()
    const pages = {}

    let defaultTab

    if (auth.isClient) {
        pages.shop_post_plans__ = <SocialPostPlansList />
        pages.connect__ = <FacebookConnectedAccountsList />
        pages.blogs = <BlogsList />
        defaultTab = 'shop_post_plans__'
    } else if (auth.isAdmin) {
        pages.blogs = <BlogsList />
        pages.all_plans = <SocialPostPlansList allUsers />
        pages.hashtags__ = (
            <SectionContainer>
                <Link to="/settings">Moved to Settings</Link>
            </SectionContainer>
        )
        defaultTab = 'hashtags__'
    } else if (auth.isStaff) {
        pages.blogs = <BlogsList />
        defaultTab = 'blogs'
    }

    return (
        <TabsOverview
            defaultTab={defaultTab}
            pagesMap={pages}
            parentPage={parentPage}
        />
    )
}
