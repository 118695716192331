import { TASK_STATUS_LABELS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { getNextStatuses } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Tag from '../../../components/common/Tag'

export default function TaskStatusTag({ task }) {
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    const auth = useAuth()

    async function setItemStatus(status, item) {
        const url = `/v1/tasks/${item.id}`
        const { error } = await fetchAPI(url, { status }, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        matchMutate(/^\/v1\/tasks/)
    }

    const nextStatuses = getNextStatuses(task.status, auth.user.role)

    return (
        <Tag
            {...(nextStatuses?.length
                ? {
                      onChange: (v) => setItemStatus(v, task),
                      options: Object.entries(TASK_STATUS_LABELS)
                          .filter(([k, v]) => nextStatuses.includes(k))
                          .map(([k, v]) => ({
                              label: v.label,
                              color: v.tagColor,
                              value: k,
                          })),
                  }
                : {})}
            color={TASK_STATUS_LABELS[task.status].tagColor}
            initValue={task.status}
        >
            {TASK_STATUS_LABELS[task.status].label}
        </Tag>
    )
}
