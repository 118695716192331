import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import TabsOverview from '../../../components/navigation/TabsOverview'
import ContactsOverview from '../../contacts/ContactsOverview'
import ContentOverview from '../../content/ContentOverview'
import ShopOverview from '../../shop/ShopOverview'
import Settings from '../../settings/Settings'
import UserPagesList from './UserPagesList'

export default function UserPagesOverview() {
    const pages = {}
    pages.all = <UserPagesList />
    pages.contacts = <ContactsOverview parentPage={'contacts'} />
    pages.content = <ContentOverview parentPage={'content'} />
    pages.shop = <ShopOverview parentPage={'shop'} />
    pages.settings = <Settings parentPage={'settings'} />

    return <TabsOverview defaultTab={'all'} pagesMap={pages} />
}
