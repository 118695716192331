import { useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import { formatDate, formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import Tag from '../../../components/common/Tag'

function isPastDue(invoice) {
    return (
        invoice.dueDate &&
        invoice.dueDate * 1000 < new Date().getTime() &&
        invoice.status === 'open'
    )
}

function InvoicesList(props) {
    const { userId, source, subId } = useParams()
    return (
        <ResourceList
            smallTitle
            renderMainButton={props.renderMainButton === false ? false : true}
            apiUrl={`/payment/clients/${userId}/invoices`}
            initialQuery={
                props.inSubscription
                    ? {
                          source,
                          sub: subId,
                      }
                    : undefined
            }
            getItemsFromResponse={(data) => data?.invoices}
            mapItems={(invoice, response) => ({
                ...invoice,
                email: response.email,
            })}
            newItemPath={
                props.inSubscription
                    ? '/users/:userId/invoices/new?source=:source&sub=:subId'
                    : '/users/:userId/invoices/new'
            }
            title={'Invoices'}
            itemClickPath={
                '/users/:userId/subscriptions/:item.source/:item.subscription/invoices/:item.id'
            }
            actions={[
                {
                    title: 'Subscription',
                    link: '/users/:userId/subscriptions/:item.source/:item.subscription',
                    icon: ICONS.LINK_GRAY,
                },
                {
                    title: 'Edit',
                    link: '/users/:userId/subscriptions/:item.source/:item.subscription/invoices/:item.id/edit',
                    getDisabled: (item) => item.source !== 'manual',
                    icon: ICONS.EDIT_GRAY,
                },
            ]}
            fields={[
                {
                    column: 1,
                    hideIfUrlHas: 'userId',
                    getValue: (item) => item.email,
                },
                {
                    column: 1,
                    wrapperEl: 'h3',
                    getValue: (item) => formatMoney(item.total),
                    getClassName: (item) =>
                        `${item.status === 'paid' ? 'success' : ''} ${
                            isPastDue(item) ? 'alert' : ''
                        }  ${item.status === 'draft' ? 'warning' : ''} ${
                            item.status === 'void' ? 'disabled' : ''
                        }`,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.createdDate
                            ? `Created on ${formatDate(item.createdDate)}`
                            : '',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.dueDate && item.status !== 'paid'
                            ? `Due ${formatDate(item.dueDate)}`
                            : '',
                    getClassName: (item) => `${isPastDue(item) ? 'alert' : ''}`,
                },
                {
                    column: 2,
                    getValue: (item) =>
                        isPastDue(item) ? 'Past due' : item.status,
                    getClassName: () => 'text-caps',
                },

                {
                    column: 2,
                    getValue: (item) => (
                        <Tag outline>
                            {item.source === 'manual'
                                ? 'Manual billing'
                                : item.source}
                        </Tag>
                    ),

                    hideIfUrlHas: 'source',
                },
            ]}
        />
    )
}

export default InvoicesList
