import { HTML_CONTRACT_TYPES, ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'

function HtmlContractsList() {
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    async function onDelete(contract) {
        setModal(
            <ConfirmModalContent
                text={`${
                    contract.contractType
                        ? ` This might affect app functionality for contracts of type: "${
                              HTML_CONTRACT_TYPES[contract.contractType].title
                          }"`
                        : ''
                }`}
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/html-contracts/${contract.id}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    matchMutate(/\/v1\/html-contracts/)
                    setToast('Deleted contract')
                }}
            />,
            'Are you sure?',
        )
    }

    return (
        <ResourceList
            apiUrl={'/html-contracts'}
            itemClickPath={'/html-contracts/:item.id/edit'}
            newItemPath={'/html-contracts/new'}
            renderMainButton
            newItemLabel={'New contract'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        HTML_CONTRACT_TYPES[item.contractType]?.title || '',
                    getClassName: () => 'subtitle',
                },
            ]}
            actions={[
                // {
                //     title: 'Download',
                //     onClick: (item) => onDownloadPdf(item),
                //     icon: ICONS.DOWNLOAD_GRAY,
                //     loading: downloadLoading,
                // },
                {
                    title: 'Delete',
                    icon: ICONS.TRASH_RED,
                    type: 'alert',
                    onClick: (item) => onDelete(item),
                },
            ]}
        />
    )
}

export default HtmlContractsList
