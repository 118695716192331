import { formatDate } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import Tag from '../../../components/common/Tag'

function SubsList(props) {
    return (
        <ResourceList
            smallTitle
            renderMainButton={props.renderMainButton === false ? false : true}
            apiUrl={'/payment/clients/:userId/subscriptions'}
            getItemsFromResponse={(data) => data?.subscriptions}
            mapItems={(sub, response) => ({
                ...sub,
                email: response.email,
            })}
            newItemPath={'/users/:userId/subscriptions/new'}
            title={'Subscriptions'}
            itemClickPath={'/users/:userId/subscriptions/:item.source/:item.id'}
            fields={[
                {
                    column: 1,
                    hideIfUrlHas: 'userId',
                    getValue: (item) => item.email,
                },
                {
                    column: 1,
                    wrapperEl: 'h3',
                    getValue: (item) => {
                        return item.items
                            .map(
                                (subItem) =>
                                    subItem.servicePrice.marketingService.title,
                            )
                            .join(', ')
                    },
                    getClassName: (item) =>
                        `${item.status === 'active' ? 'success' : ''} ${
                            item.status === 'canceled' ? 'alert' : ''
                        }`,
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <Tag outline>
                            {item.source === 'manual'
                                ? 'Manual billing'
                                : item.source}
                        </Tag>
                    ),
                },
                {
                    column: 2,
                    getValue: (item) =>
                        item.canceledAt
                            ? `Canceled ${formatDate(item.canceledAt)}`
                            : null,
                },
            ]}
        />
    )
}

export default SubsList
