import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Tag from '../../../components/common/Tag'

export default function TaskClientStatusTag({ task }) {
    const auth = useAuth()
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    async function setItemClientReviewStatus(clientReviewStatus, item) {
        const url = `/v1/tasks/${item.id}`
        const { error } = await fetchAPI(url, { clientReviewStatus }, 'PATCH')
        // setMarkCompleteLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        matchMutate(/^\/v1\/tasks/)
    }

    let clientReviewStatusColor = null
    let clientReviewStatusText = null
    switch (task.clientReviewStatus) {
        case 'submitted':
            clientReviewStatusColor = 'warning'
            clientReviewStatusText = 'Client reviewing'
            break
        case 'rejected':
            clientReviewStatusColor = 'alert'
            clientReviewStatusText = 'Client rejected'
            break
        case 'approved':
            clientReviewStatusColor = 'success'
            clientReviewStatusText = 'Client approved'
            break
        default:
            clientReviewStatusColor = 'outline'
            clientReviewStatusText = 'None'
            break
    }

    return (
        <Tag
            initValue={task.clientReviewStatus}
            onChange={
                auth.isAdmin || auth.isAssistant
                    ? (v) => setItemClientReviewStatus(v, task)
                    : undefined
            }
            options={[
                {
                    label: 'Client reviewing',
                    color: 'warning',
                    value: 'submitted',
                },
                {
                    label: 'Client rejected',
                    color: 'alert',
                    value: 'rejected',
                },
                {
                    label: 'Client approved',
                    color: 'success',
                    value: 'approved',
                },
                {
                    label: 'None',
                    // color: 'success',
                    value: '',
                },
            ]}
            color={clientReviewStatusColor}
        >
            {clientReviewStatusText}
        </Tag>
    )
}
