import React, { useEffect, useState } from 'react'
import TextInput from './TextInput'

function PasswordInputBase(props) {
    const [isShown, setIsShown] = useState(false)

    const handleHideToggle = (e) => {
        e.preventDefault()
        setIsShown(!isShown)
    }

    return (
        <>
            {props.label ? (
                <label
                    htmlFor={props.id}
                    className={`${props.required ? 'required' : ''}`}
                >
                    {props.label}
                </label>
            ) : null}
            <div className="password-input">
                <TextInput
                    id={props.id}
                    type={isShown ? 'text' : 'password'}
                    required={props.required}
                    value={props.value}
                    autoComplete={props.autoComplete}
                    name={props.name}
                    placeholder={props.placeholder || ''}
                    onChange={props.onChange}
                />

                <button
                    onClick={handleHideToggle}
                    type="button"
                    className="hide-btn"
                >
                    {isShown ? 'Hide' : 'Show'}
                </button>
            </div>
        </>
    )
}

export default function PasswordInput({
    value,
    onChange,
    defaultValue,
    id,
    ...rest
}) {
    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || '')
        }
    }, [])

    function handleChange(v) {
        onChange(v)
    }
    const textValue = value || ''

    return (
        <PasswordInputBase
            value={textValue}
            onChange={handleChange}
            {...rest}
            id={id}
            required={rest.required}
            label=""
        />
    )
}
