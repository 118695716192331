import { Link } from 'react-router-dom'
import useAuth from '../../common/hooks/useAuth'
import MessageSection from '../../components/common/MessageSection'
import Tag from '../../components/common/Tag'

export default function Timesheets({ userId }) {
    const auth = useAuth()
    return (
        <>
            {auth.isAdmin && (
                <MessageSection
                    fullWidth
                    small
                    type="info"
                    title="Only admins see this."
                ></MessageSection>
            )}

            <Link to={`/users/${userId}/timesheets`}>
                Open timesheets&nbsp;<Tag outline>Beta</Tag>
            </Link>
        </>
    )
}
