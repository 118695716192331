import DataFormNew from '../../../components/common/DataFormNew'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import useToast from '../../../common/hooks/useToast'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'fullName',
        label: 'Display name',
        required: true,
    },
    {
        key: 'name',
        label: 'Shopify subdomain',
        required: true,
        infoTop: 'Without ".myshopify.com"',
    },
    {
        key: 'accessToken',
        label: 'Access token',
        required: true,
        infoTop:
            'Create a custom app with read_products and read_content permissions and get the access token. For products and orders sync, also enable read_orders.',
    },
]

export default function CreateShopifyShop() {
    const auth = useAuth()
    const setToast = useToast()
    return (
        <>
            {auth.isAdmin && (
                <SectionContainer>
                    <Button
                        href={'/settings/shopify-shops/new'}
                        text={'Create app shop'}
                        small
                        alignRight
                        outline
                    />
                </SectionContainer>
            )}
            <SectionContainer>
                <DataFormNew
                    url={`/v1/shop/shops`}
                    submitText="Save"
                    submitToast=""
                    submitNavArg={'/shop#setup__'}
                    inputs={inputs}
                    mainButton
                    onSuccess={(data) => {
                        setToast(
                            `Shop created with scopes: ${data.scopes.join(
                                ', ',
                            )}`,
                        )
                    }}
                />
            </SectionContainer>
        </>
    )
}
