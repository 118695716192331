import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import { isoToDatetimeLocal } from '../../../common/helpers'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'date',
        type: 'datetime-local',
        label: 'Date / Time',
    },
]

export default function ScheduleArticle() {
    const { shopId, blogId, articleId } = useParams()

    return (
        <SectionContainer>
            <div className="text-subdued">
                The post may be published up to 1 hour after the selected time.
            </div>
            <br />
            <DataFormNew
                url={`/v1/blogs/${shopId}/${blogId}/articles/${articleId}/schedule`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                inputs={inputs}
                submitNavArg={`/content/blogs/${shopId}/${blogId}`}
                mapItemToData={(data) => ({
                    date: data?.date ? isoToDatetimeLocal(data.date) : '',
                })}
                getBody={(data) => ({
                    date: data.date ? new Date(data.date) : null,
                })}
                mutationRegexes={[/\/v1\/blogs/]}
                mainButton
            />
        </SectionContainer>
    )
}
