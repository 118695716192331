import { useState } from 'react'
import { ICONS } from '../../common/constants'
import ActionsDropdown from './ActionsDropdown'
import { Link, useNavigate } from 'react-router-dom'

// (?<![a-zA-Z])tag(?![a-zA-Z])

export default function Tag({
    outline,
    children,
    square,
    noMargin,
    onClose,
    onChange,
    options,
    color,
    initValue,
    onClick,
    href,
}) {
    const navigate = useNavigate()

    let className = 'tag'
    if (outline) className += ' outline'

    if (square) className += ' square'
    if (noMargin) className += ' m-0'

    if (color) className += ` ${color}`
    if (onChange) className += ` with-options`

    function handleChange(e, option) {
        e.preventDefault()
        e.stopPropagation()
        onChange(option.value)
    }

    function handleClick() {
        if (onClick) onClick()
    }

    const availableOptions = options?.filter(
        (option) => option.value !== initValue,
    )

    const tagContentHtml = (
        <>
            {href ? (
                <span
                    role="button"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(href)
                    }}
                >
                    {children}
                </span>
            ) : (
                children
            )}
            {onClose && (
                <>
                    <img
                        onClick={onClose}
                        role="button"
                        title="Close"
                        width={12}
                        height={12}
                        src={
                            color === 'primary' ||
                            color === 'gray' ||
                            color === 'outline' ||
                            color === 'warning' ||
                            color === 'success' ||
                            color === 'alert'
                                ? ICONS.X_WHITE
                                : ICONS.X_ACTIVE
                        }
                        alt="close"
                    />
                </>
            )}
            {onChange && Boolean(availableOptions?.length) && (
                <img
                    onClick={(e) => e.preventDefault()}
                    width={12}
                    height={12}
                    style={{ transform: 'rotate(90deg)' }}
                    src={
                        color === 'primary' ||
                        color === 'warning' ||
                        color === 'gray' ||
                        color === 'success' ||
                        color === 'outline' ||
                        color === 'alert'
                            ? ICONS.CHEVRON_RIGHT_WHITE
                            : ICONS.CHEVRON_RIGHT_ACTIVE
                    }
                    alt="arrow"
                />
            )}
        </>
    )

    return (
        <span
            className={className}
            onClick={handleClick}
            role={onClick ? 'button' : undefined}
        >
            {onChange && Boolean(availableOptions?.length) ? (
                <ActionsDropdown
                    activator={tagContentHtml}
                    actions={[
                        ...availableOptions.map((option) => ({
                            title: option.label,
                            onClick: (e) => handleChange(e, option),
                            color: option.color,
                        })),
                    ]}
                />
            ) : (
                tagContentHtml
            )}
        </span>
    )
}
