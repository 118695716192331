import useModal from '../../../common/hooks/useModal'
import Button from '../../../components/common/Button'
import Chat from '../../../components/common/Chat'
import SectionContainer from '../../../components/common/SectionContainer'

export default function AdminChat() {
    const { setModal } = useModal()

    return (
        <SectionContainer>
            <p>This is a demo for the chat, only admins can access it.</p>
            <Button
                text={'Open chat'}
                onClick={() =>
                    setModal(
                        <Chat
                            resource={'Task'}
                            resourceId={'66b8e532a577dd9303f02401'}
                        />,
                        '',
                        'modal-full',
                    )
                }
            />
        </SectionContainer>
    )
}
