// .acronym ok
import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import useData from '../../common/hooks/useData'
import Break from '../../components/common/Break'
import Button from '../../components/common/Button'
import ErrorMessage from '../../components/common/ErrorMessage'
import InfoList from '../../components/common/InfoList'
import InlineStack from '../../components/common/InlineStack'
import MessageSection from '../../components/common/MessageSection'
import Spinner from '../../components/common/Spinner'
import ContactInfo from './ContactInfo'

export default function BasicInfo({
    userId,
    contactId,
    labels,
    contactDetails,
    canSeeContactInfo,
}) {
    const auth = useAuth()
    const isOwnProfile = !userId

    const { companies, companiesLoading, companiesError } = useData(
        userId || isOwnProfile
            ? `/v1/organizations?organizationType=company&fields=name&memberUserId=${
                  userId || auth.user.id
              }&populate=companyInfo,mainAddress,contactDetails`
            : null,
        'companies',
        (data) => data?.results || [],
    )

    return (
        <>
            {auth.isAdmin && (
                <>
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Everyone has access to this. Only admins and assistants can add a company if missing. Only admins, assistants and sales can see user contact info and addresses."
                    ></MessageSection>
                    <Break />
                </>
            )}

            {canSeeContactInfo && (
                <ContactInfo
                    userId={userId}
                    contactId={contactId}
                    contactDetails={contactDetails}
                />
            )}

            {!contactId && (
                <>
                    <InlineStack spaceBetween>
                        <h3 className="h6 text-subdued">Business info</h3>
                        {Boolean(companies?.length) && (
                            <Button
                                outline
                                tiny
                                alignRight
                                text="Edit"
                                icon={ICONS.EDIT_ACTIVE}
                                href={
                                    isOwnProfile
                                        ? `/profile/companies`
                                        : `/users/${userId}/companies`
                                }
                            />
                        )}
                    </InlineStack>

                    {companiesLoading ? (
                        <>
                            <br />
                            <Spinner />
                            <br />
                        </>
                    ) : null}

                    {companiesError ? (
                        <ErrorMessage>{companiesError}</ErrorMessage>
                    ) : null}

                    {companies?.length ? (
                        <>
                            <InfoList
                                rows={[
                                    {
                                        icon: ICONS.FOLDER_WHITE,
                                        content: companies?.[0].name,
                                        strong: true,
                                    },
                                    {
                                        content: companies?.[0].acronym,
                                        strong: true,
                                    },
                                    {
                                        icon: ICONS.HOME_WHITE,
                                        content:
                                            [
                                                companies?.[0]?.mainAddress
                                                    ?.address,
                                                companies?.[0]?.mainAddress
                                                    ?.city,
                                                companies?.[0]?.mainAddress
                                                    ?.state,
                                                companies?.[0]?.mainAddress
                                                    ?.zip,
                                                companies?.[0]?.mainAddress
                                                    ?.country,
                                            ]
                                                .filter((item) => !!item)
                                                .join(', ') || '-',
                                    },
                                    {
                                        icon: ICONS.PHONE_WHITE,
                                        content:
                                            companies?.[0]?.contactDetails
                                                ?.phones?.[0]?.phone || '-',
                                    },
                                    {
                                        icon: ICONS.MAIL_WHITE,
                                        content:
                                            companies?.[0]?.contactDetails
                                                ?.emails?.[0]?.email || '-',
                                    },
                                ]}
                            />
                        </>
                    ) : (
                        <Button
                            small
                            alignRight
                            href={`/profile/companies/new`}
                            text="+ Add company"
                        />
                    )}
                    <br />
                </>
            )}

            {!auth.isClient && !isOwnProfile && (
                <>
                    <InlineStack spaceBetween>
                        <h3 className="h6 text-subdued">Labels</h3>
                        <Button
                            outline
                            tiny
                            alignRight
                            text="Edit"
                            icon={ICONS.EDIT_ACTIVE}
                            href={`/users/${userId}/edit-labels`}
                        />
                    </InlineStack>
                    <InfoList
                        rows={[
                            {
                                icon: ICONS.LABEL_WHITE,
                                content: labels?.length
                                    ? labels?.join(', ')
                                    : '-',
                            },
                        ]}
                    />
                    <br />
                </>
            )}
        </>
    )
}
