function FormSteps(props) {
    const { step, totalSteps } = props

    return (
        <div className="form-steps">
            {[...Array(totalSteps).keys()].map((key) => {
                const number = key + 1
                const isComplete = step > number
                const isActive = step === number
                return (
                    <div
                        key={number}
                        className={`form-steps-step ${
                            isComplete && 'complete'
                        } ${isActive ? 'active' : ''}`}
                    >
                        {!isComplete ? (
                            number
                        ) : (
                            <img
                                src="/img/icons/check-white.svg"
                                alt="complete step"
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default FormSteps
