import ResourceList from '../../../components/admin/ResourceList'
import { formatMoney } from '../../../common/helpers'

function VendorCashList() {
    return (
        <ResourceList
            apiUrl={'/wholesale/billing-cash'}
            isTable
            tableColumns={[
                {
                    title: 'Vendor',
                    sumTitle: 'Total',
                },
                {
                    title: 'Cash & manual',
                    getSum: (items) =>
                        formatMoney(
                            items.reduce((a, b) => a + b.amount, 0),
                            false,
                            'shopify',
                        ),
                },
            ]}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <span>{item.vendor}</span>
                            <span>
                                {formatMoney(item.amount, false, 'shopify')}
                            </span>
                        </>
                    ),
                    getClassName: () => 'table-row',
                },
            ]}
        />
    )
}

export default VendorCashList
