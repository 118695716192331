import ResourceList from '../../../components/admin/ResourceList'
import OrgCard from '../../contacts/OrgCard'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['name'],
            asc: true,
        },
    },
}

export default function OrganizationsList() {
    return (
        <ResourceList
            apiUrl={'/organizations'}
            initialQuery={{
                fields: 'name,organizationType,members',
            }}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/organizations/new'}
            renderMainButton={true}
            paginated
            itemClickPath={`/organizations/:item.id`}
            filters={filterFields}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => <OrgCard org={item} />,
                },
            ]}
        />
    )
}
