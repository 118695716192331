import useData from '../../common/hooks/useData'
import Spinner from '../../components/common/Spinner'
import ErrorMessage from '../../components/common/ErrorMessage'
import { TASK_STATUSES } from '../../common/constants'

export default function TaskOverview() {
    const { summary, summaryError, summaryLoading } = useData(
        `/v1/tasks/summary`,
        'summary',
    )

    if (summaryLoading) return <Spinner />
    if (summaryError) return <ErrorMessage section>{summaryError}</ErrorMessage>

    return (
        <div className="task-overview">
            <div>
                <h3>Priority</h3>
                <div>
                    <h4>
                        <small>Low</small>
                    </h4>
                    <p>
                        {
                            (summary || []).find(
                                (item) =>
                                    Object.keys(item.filter)[0] ===
                                        'priority' &&
                                    Object.values(item.filter)[0] === 'low',
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>Medium</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] ===
                                        'priority' &&
                                    Object.values(item.filter)[0] === 'medium',
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>High</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] ===
                                        'priority' &&
                                    Object.values(item.filter)[0] === 'high',
                            )?.count
                        }
                    </p>
                </div>
            </div>
            <div className="current-totals">
                <h3>Assigned Person</h3>
                <div>
                    <h4>
                        <small>Client</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'target' &&
                                    Object.values(item.filter)[0] === 'client',
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>Team</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'target' &&
                                    Object.values(item.filter)[0] === 'role',
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>Team member</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'target' &&
                                    Object.values(item.filter)[0] === 'team',
                            )?.count
                        }
                    </p>
                </div>
            </div>
            <div className="current-totals">
                <h3>Status</h3>
                <div>
                    <h4>
                        <small>Open</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'status' &&
                                    Object.values(item.filter)[0] ===
                                        TASK_STATUSES.OPEN,
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>In progress</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'status' &&
                                    Object.values(item.filter)[0] ===
                                        TASK_STATUSES.ACCEPTED,
                            )?.count
                        }
                    </p>
                </div>
                <div>
                    <h4>
                        <small>Completed</small>
                    </h4>
                    <p>
                        {
                            summary.find(
                                (item) =>
                                    Object.keys(item.filter)[0] === 'status' &&
                                    Object.values(item.filter)[0] ===
                                        TASK_STATUSES.COMPLETE,
                            )?.count
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}
