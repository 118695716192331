import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import { getUserDisplayName } from '../../../common/helpers'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import ContactCard from '../../admin/clients/ContactCard'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'

function GMailSettings() {
    const [gmailImpersonateEmail, setGmailImpersonateEmail] = useState('')
    const [contactReceiverAdminId, setContactReceiverAdminId] = useState('')
    const [testEmail, setTestEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const setToast = useToast()

    const {
        impersonateSettingLoading,
        impersonateSettingError,
        impersonateSettingMutate,
    } = useData(
        '/v1/settings/gmailImpersonateEmail',
        'impersonateSetting',
        (data) => data?.settingValue || '',
        false,
        false,
        (data) => {
            setGmailImpersonateEmail(data?.settingValue || '')
        },
    )

    const {
        contactAdminSettingLoading,
        contactAdminSettingError,
        contactAdminSettingMutate,
    } = useData(
        '/v1/settings/contactReceiverAdminId',
        'contactAdminSetting',
        (data) => data?.settingValue || '',
        false,
        false,
        (data) => {
            setContactReceiverAdminId(data?.settingValue || '')
        },
    )

    async function onSaveGmailAccountEmail(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/gmailImpersonateEmail`,
            { settingValue: gmailImpersonateEmail },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error)
        }
        setToast('Saved')
        impersonateSettingMutate()
    }

    async function onSaveContactReceiverAdminId(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/contactReceiverAdminId`,
            { settingValue: contactReceiverAdminId },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error)
        }
        setToast('Saved')
        contactAdminSettingMutate()
    }

    async function onSendTestEmail(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/test-email`,
            {
                to: testEmail,
            },
            'POST',
        )
        setLoading(false)
        if (error) {
            return setError(error)
        }
        setToast('Sent')
    }

    if (impersonateSettingLoading || contactAdminSettingLoading) {
        return <Spinner />
    }

    if (impersonateSettingError || contactAdminSettingError) {
        return (
            <ErrorMessage>
                {impersonateSettingError || contactAdminSettingError}
            </ErrorMessage>
        )
    }

    return (
        <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <form>
                <label htmlFor="form-email-account">
                    Send emails from this Workspace email
                </label>
                <TextInput
                    type="email"
                    name="email_account"
                    id="form-email-account"
                    placeholder="help@aco.digital"
                    value={gmailImpersonateEmail || ''}
                    onChange={(v) => setGmailImpersonateEmail(v)}
                />

                <Button
                    onClick={onSaveGmailAccountEmail}
                    isLoading={loading}
                    text={'Save email'}
                    small
                    alignRight
                />
                <br />
            </form>

            <form>
                <label htmlFor="form-contact-admin">
                    Admin account for notifications
                </label>
                <ResourceList
                    apiUrl={'/users'}
                    initialQuery={{
                        isBlocked: false,
                        role: 'admin',
                        fields: 'firstName,lastName,email,billingIds,role',
                    }}
                    getSelectedItemText={(item) => getUserDisplayName(item)}
                    getItemsFromResponse={(data) => data?.results}
                    // filters={userFilterFields}
                    isSelectable
                    selectLimit={1}
                    paginated
                    loadMoreButton
                    paginationLimit={5}
                    preselectedIds={
                        contactReceiverAdminId ? [contactReceiverAdminId] : []
                    }
                    onSelectedItemsChange={(ids) =>
                        setContactReceiverAdminId(ids?.[0] || '')
                    }
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => (
                                <ContactCard summary user={item} />
                            ),
                        },
                    ]}
                />

                <br />
                <Button
                    onClick={onSaveContactReceiverAdminId}
                    isLoading={loading}
                    text={'Save admin'}
                    small
                    alignRight
                />
                <br />
            </form>

            <form>
                <label htmlFor="form-test-email">Send test email to</label>
                <TextInput
                    type="email"
                    name="test_email"
                    id="form-test-email"
                    placeholder="test@aco.digital"
                    value={testEmail}
                    onChange={(v) => setTestEmail(v)}
                />

                <Button
                    onClick={onSendTestEmail}
                    isLoading={loading}
                    text={'Send'}
                    small
                    alignRight
                />
            </form>
        </>
    )
}

export default GMailSettings
