import Button from '../Button'
import Grid from '../Grid'
import PrivateFile from '../PrivateFile'

export default function DriveFilesInput({ value, onChange, ...rest }) {
    function handleChange(e, id) {
        e.preventDefault()
        onChange(value.filter((v) => v !== id))
    }

    return (
        <div className="drive-files-input">
            {value.length ? (
                <Grid cols={3}>
                    {value.map((id) => (
                        <div key={id}>
                            <Button
                                text={<>&#10005;</>}
                                small
                                plain
                                title="Remove"
                                onClick={(e) => handleChange(e, id)}
                            />
                            <PrivateFile openFullOnClick driveFileId={id} />
                        </div>
                    ))}
                </Grid>
            ) : (
                <div>No files left</div>
            )}
        </div>
    )
}
