import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ShopifyProductTagsSetting() {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()

    const { settingError, settingLoading, settingMutate } = useData(
        '/v1/settings/availableShopifyProductTags',
        'setting',
        (data) => data,
        false,
        false,
        (data) => {
            setData(data.settingValue || '')
        },
    )

    async function onSubmit(e) {
        e.preventDefault()

        setLoading(true)
        const { responseData, error } = await fetchAPI(
            '/v1/settings/availableShopifyProductTags',
            {
                settingValue: data,
            },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Saved')
        settingMutate(responseData)
        navigate(-1)
    }

    if (settingLoading) return <Spinner marginTop />
    if (settingError && settingError !== 'Setting not found') {
        return <ErrorMessage section>{settingError}</ErrorMessage>
    }

    return (
        <SectionContainer>
            <form onSubmit={onSubmit}>
                <label htmlFor="form-setting">Available tags</label>
                <TextInput
                    rows={6}
                    id="form-setting"
                    onChange={(v) => setData(v)}
                    value={data}
                />
                <small className="input-info">
                    Use a new line for each tag. These are corresponding to tags
                    in Shopify products and can be chosen by vendors when
                    creating a product.
                </small>
                <Button type="submit" text="Save tags" isLoading={loading} />
            </form>
        </SectionContainer>
    )
}
