import { useEffect } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import useToast from '../../../common/hooks/useToast'
import { loadStripe } from '@stripe/stripe-js'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
// process.env.REACT_APP_CONTEXT === 'NETLIFY_DEV' ||
// process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
//     : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV

const stripePromise = loadStripe(apiKey)

function StripeIntentCompleteCallback() {
    return (
        <Elements stripe={stripePromise}>
            <StripeIntentCompleteCallbackInner />
        </Elements>
    )
}

function StripeIntentCompleteCallbackInner() {
    const stripe = useStripe()
    const setToast = useToast()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const clientSecret = searchParams.get('setup_intent_client_secret')

    useEffect(
        function () {
            if (!stripe || !clientSecret) {
                return
            }

            async function handleCallback() {
                try {
                    const { setupIntent } =
                        await stripe.retrieveSetupIntent(clientSecret)
                    switch (setupIntent.status) {
                        case 'succeeded':
                            setToast(
                                'Success! Your payment method has been saved.',
                            )
                            navigate('/')
                            break
                        case 'processing':
                            break
                        case 'requires_payment_method':
                            setToast(
                                'Failed to process payment details. Please try another payment method.',
                                'alert',
                            )
                            navigate('/profile/billing-info/edit')

                            // TODO naviagate
                            break
                        default:
                            break
                    }
                } catch (error) {
                    console.error(error)
                    setToast(
                        'There was a problem processing your payment. Please try again and if the issue persists, contact Support.',
                        'alert',
                    )
                }
            }

            handleCallback()
        },
        [stripe],
    )

    if (!clientSecret) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    There was a problem processing your payment. Please try
                    again and if the issue persists, contact Support.{' '}
                    <Link to="/">Back to Home.</Link>
                </ErrorMessage>
            </SectionContainer>
        )
    }

    return <Spinner />
}

export default StripeIntentCompleteCallback
