import { useParams } from 'react-router-dom'
import { COMPONENT_DATA } from './ComponentData'
import { useState } from 'react'
import useToast from '../../../common/hooks/useToast'
import useData from '../../../common/hooks/useData'
import fetchAPI from '../../../common/fetchAPI'
import Button from '../../../components/common/Button'
import MainButton from '../../../components/admin/MainButton'
import Input from '../../../components/common/data-form/Input'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

export default function ComponentPage() {
    const { componentName } = useParams()
    const [actionLoading, setActionLoading] = useState(false)
    const component = COMPONENT_DATA[componentName]
    const [data, setData] = useState({})
    const setToast = useToast()

    const { notesError, notesLoading, notesMutate } = useData(
        `/v1/settings/component-notes/${componentName}`,
        'notes',
        null,
        null,
        null,
        (items) => setData(items || {}),
    )

    async function saveNotes() {
        setActionLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/settings/component-notes/${componentName}`,
            {
                notes: data,
            },
            'PATCH',
        )

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Saved')
        notesMutate(responseData)
        setActionLoading(false)
    }

    if (notesError) {
        return (
            <SectionContainer>
                <ErrorMessage>{notesError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (notesLoading) {
        return <Spinner />
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack spaceBetween gap="sm">
                        <div>
                            <h2>&lt;{componentName}/&gt;</h2>
                        </div>
                        <div>
                            <Button
                                small
                                text="Save"
                                onClick={saveNotes}
                                isLoading={actionLoading}
                            />
                        </div>
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                {component.versions.map((item, i) => (
                    <div key={i}>
                        <h2>{item.title}</h2>
                        <div>{item.component}</div>
                        <div>
                            <Input
                                // label="Notes"
                                placeholder="Notes"
                                type="textarea"
                                id={`notes-${i}`}
                                value={data[item.title] || ''}
                                onChange={(v) =>
                                    setData({ ...data, [item.title]: v })
                                }
                            />
                        </div>
                        <br />
                    </div>
                ))}

                <MainButton
                    disabled={actionLoading}
                    functionality="SAVE"
                    loading={actionLoading}
                    onClick={saveNotes}
                />
            </SectionContainer>
            {/* // <section>{component}</section> */}
        </>
    )
}
