import { useEffect } from 'react'
import ResourceList from '../../admin/ResourceList'

export default function ResourceListInput({
    value,
    onChange,
    defaultValue,
    ...rest
}) {
    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || [])
        }
    }, [])

    const itemsValue = value || []
    return (
        <>
            <ResourceList
                preselectedIds={itemsValue}
                onSelectedItemsChange={onChange}
                isSelectable
                {...rest}
            />
            <br />
        </>
    )
}
