import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateService() {
    const initialData = {
        pricingModel: 'recurring',
        recurringPeriod: 'week',
    }
    const [loading, _setLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState(initialData)
    const navigate = useNavigate()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const onSubmit = async (e) => {
        if (
            !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(data.amount)
        ) {
            setError('Invalid amount')
            return e.preventDefault()
        }
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/services`,
                        {
                            title: data.title,
                            price: {
                                pricingModel: data.pricingModel,
                                recurringPeriod: data.recurringPeriod,
                                amount: String(data.amount * 100),
                            },
                        },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to create this service?',
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-title" className="required">
                        Title
                    </label>
                    <TextInput
                        type="text"
                        value={data.title}
                        id="form-title"
                        name="title"
                        required
                        onChange={(v) =>
                            setData({
                                ...data,
                                title: v,
                            })
                        }
                    />
                    <label htmlFor="form-pricing">Pricing</label>
                    <SelectInput
                        name="pricing"
                        id="form-pricing"
                        value={data.pricingModel}
                        onChange={(v) =>
                            setData({
                                ...data,
                                pricingModel: v,
                            })
                        }
                        options={[{ value: 'recurring', label: 'Recurring' }]}
                    />

                    <label htmlFor="form-period">Period</label>

                    <SelectInput
                        name="period"
                        id="form-period"
                        value={data.recurringPeriod}
                        onChange={(v) =>
                            setData({
                                ...data,
                                recurringPeriod: v,
                            })
                        }
                        options={[
                            { value: 'year', label: 'Year' },
                            { value: 'month', label: 'Month' },
                            { value: 'week', label: 'Week' },
                        ]}
                    />

                    <label htmlFor="form-amount" className="required">
                        Amount ($)
                    </label>
                    <TextInput
                        type="number"
                        value={data.amount}
                        id="form-amount"
                        name="amount"
                        required
                        onChange={(v) =>
                            setData({
                                ...data,
                                amount: v,
                            })
                        }
                    />
                </div>
                <div>
                    <Button text="Create" isLoading={loading} type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreateService
