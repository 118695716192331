import ImageModalContent from './ImageModalContent'
import useModal from '../../common/hooks/useModal'

function isFileImage(fileName) {
    return (
        fileName?.endsWith('.jpg') ||
        fileName?.endsWith('.jpeg') ||
        fileName?.endsWith('.png')
    )
}

export default function StorageFile({
    url,
    width,
    height,
    publicUrl,
    name,
    mimeType,
    openFullOnClick,
}) {
    const { setModal } = useModal()

    const onClick = () => {
        if (openFullOnClick) {
            if (isFileImage(name)) {
                setModal(<ImageModalContent src={url} />)
            } else {
                const a = document.createElement('a')
                a.style = 'display: none'
                a.href = url
                a.download = name
                a.click()
            }
        }
    }

    return (
        <>
            {!!url && isFileImage(name) && (
                <img
                    className="private-file is-image"
                    crossOrigin="Anonymous"
                    src={url}
                    alt=""
                    onClick={onClick}
                    width={width}
                    height={height}
                />
            )}

            {!isFileImage(name) && name && (
                <p className="private-file is-other" onClick={onClick}>
                    {name}
                </p>
            )}
        </>
    )
}
