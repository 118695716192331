import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'

function ShortLinkCallback() {
    const { shortCode } = useParams()

    const { urlError } = useData(
        `/v1/shortlinks/${shortCode}`,
        'url',
        (data) => data,
        false,
        false,
        (data) => {
            window.location.href = data.originalUrl
        },
    )

    if (urlError) {
        return <div>Error</div>
    }

    return (
        <style jsx>
            {`
                body {
                    display: none;
                }
            `}
        </style>
    )
}

export default ShortLinkCallback
