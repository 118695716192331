import useData from '../../../common/hooks/useData'
import ResourceList from '../../../components/admin/ResourceList'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'

export default function ShopifyGraphQLProductsList() {
    const { shops, shopsLoading, shopsError } = useData(
        `/v1/wholesale/shops?fields=name&isWholesale=true`,
        'shops',
        (data) => data?.map((item) => item.name) || [],
    )

    if (shopsLoading) return <Spinner />
    if (shopsError) {
        return <ErrorMessage section>{shopsError}</ErrorMessage>
    }

    const filterFields = {
        sort: {
            id: {
                label: 'Product ID',
                dbFields: ['id'],
            },
        },
        filters: {
            shop: {
                label: 'Shop',
                dbField: 'shop',
                options: [
                    ...shops.map((shop) => ({
                        label: shop,
                        value: shop,
                    })),
                ],
            },
        },
    }

    return (
        <ResourceList
            apiUrl={'/wholesale/products-graphql'}
            initialQuery={{ shop: shops[0] }}
            getItemsFromResponse={(data) => data?.results}
            paginated={true}
            cursorPagination
            filters={filterFields}
            paginationLimit={5}
            itemClickPath={
                '/showroom/products/:filter.shop/:item.legacyResourceId'
            }
            newItemPath={'/showroom/products/new'}
            renderMainButton
            newItemLabel="New product"
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                },
            ]}
            emptyText="Add your first product by clicking the '+' button below"
            // actions={[
            //     {
            //         title: 'Edit',
            //         link: '/showroom/products/:filter.shop/:item.legacyResourceId/edit',
            //         icon: ICONS.EDIT_GRAY,
            //         getHide: () => !auth.isClient,
            //     },
            // ]}
        />
    )
}
