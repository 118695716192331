import { useEffect, useRef } from 'react'
import { WEEK_DAYS } from '../../../common/constants'
import { formatMoney } from '../../../common/helpers'

function Hours(props) {
    const { entries, isPast } = props

    const total = Math.floor(entries.reduce((a, b) => a + b.minutes, 0) / 60)
    return (
        <span className={total === 0 && isPast ? 'text-subdued' : ''}>
            {total.toFixed(1)}
            &nbsp;hours
        </span>
    )
}

function Money(props) {
    const { entries, isPast } = props

    const total = entries
        .map((entry) => entry.costAmount)
        .reduce((a, b) => a + b, 0)
    return (
        <span className={total === 0 && isPast ? 'text-subdued' : ''}>
            {formatMoney(total, false, 'api')}
        </span>
    )
}

function TimesheetDaysList(props) {
    const { dateItems, currIndex = 0, onCurrIndexChange } = props

    const listRef = useRef(null)

    const todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    useEffect(
        function () {
            if (currIndex === -1) return
            const list = listRef.current
            const activeItem = list.children[currIndex]
            if (activeItem) {
                activeItem.scrollIntoView({
                    behavior: 'smooth',
                })
            }
        },
        [currIndex],
    )

    return (
        <div className="timesheet-days-list">
            <ol ref={listRef}>
                {dateItems.map(({ date, entries }, i) => (
                    <li
                        role="button"
                        key={date.toISOString()}
                        className={`${currIndex === i ? ' active' : ''}${
                            date > todayStart ? ' disabled' : ''
                        }`}
                        onClick={() =>
                            date <= todayStart && onCurrIndexChange(i)
                        }
                    >
                        <span>
                            {WEEK_DAYS[date.getDay()].abbreviation}&nbsp;
                            {date.getDate()}
                        </span>
                        <Hours entries={entries} isPast={date <= todayStart} />
                        <Money entries={entries} isPast={date <= todayStart} />
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default TimesheetDaysList
