import Button from './Button'
import TextInput from './data-form/TextInput'

function InlineForm(props) {
    const { id, label, value, onChange, onSubmit, loading } = props

    function handleSubmit(e) {
        e.preventDefault()

        onSubmit(id, value)
    }

    return (
        <form className="inline-form" onSubmit={handleSubmit}>
            <label htmlFor={`form-${id}`}>{label}</label>
            <TextInput
                id={`t-${id}`}
                value={value || ''}
                onChange={(v) => onChange(v)}
            />
            <Button
                small
                outline
                type="submit"
                text="Save"
                isLoading={loading}
            />
        </form>
    )
}

export default InlineForm
