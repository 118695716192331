import { ICONS } from '../../common/constants'

export default function ResourceListItemViewsToggle({
    itemViews,
    itemsView,
    setItemsView,
}) {
    return (
        <div className="resource-list-item-views-toggle">
            {itemViews.includes('list') &&
                (itemsView === 'list' ? (
                    <img
                        title="list view"
                        src={ICONS.LIST_ACTIVE}
                        alt="list"
                        width="24"
                        height="24"
                    />
                ) : (
                    <img
                        role="button"
                        onClick={() => setItemsView('list')}
                        title="list view"
                        src={ICONS.LIST_WHITE}
                        alt="list"
                        width="24"
                        height="24"
                    />
                ))}
            {itemViews.includes('square') && (
                <>
                    {itemsView === 'square' ? (
                        <img
                            title="square view"
                            src={ICONS.SQUARE_ACTIVE}
                            alt="square"
                            width="24"
                            height="24"
                        />
                    ) : (
                        <img
                            role="button"
                            onClick={() => setItemsView('square')}
                            title="square view"
                            src={ICONS.SQUARE_WHITE}
                            alt="square"
                            width="24"
                            height="24"
                        />
                    )}
                </>
            )}
            {itemViews.includes('double') && (
                <>
                    {itemsView === 'double' ? (
                        <img
                            title="double view"
                            src={ICONS.GRID_ACTIVE}
                            alt="double"
                            width="24"
                            height="24"
                        />
                    ) : (
                        <img
                            role="button"
                            onClick={() => setItemsView('double')}
                            title="double view"
                            src={ICONS.GRID_WHITE}
                            alt="double"
                            width="24"
                            height="24"
                        />
                    )}
                </>
            )}
        </div>
    )
}
