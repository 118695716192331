import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MONTHS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import FormGroupTitle from '../../../components/common/data-form/FormGroupTitle'
import MessageSection from '../../../components/common/MessageSection'
import TextInput from '../../../components/common/data-form/TextInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

function processHashtag(hashtag) {
    if (!hashtag) return null
    return hashtag
        .replace(/[^a-zA-Z0-9]/g, '')
        .replace(/\s/g, '')
        .toLowerCase()
}

export default function EditSocialPostPlanHashtags() {
    const { socialPostPlanId } = useParams()
    const [error, setError] = useError()
    const [actionLoading, setActionLoading] = useState(false)
    const navigate = useNavigate()

    const setToast = useToast()
    const auth = useAuth()

    const initialData = {
        socialPostProductDefaultHashtag: null,
        socialPostProductCompanyTag: null,
        socialPostProductAcronymTag: null,
        socialPostProductMonthTagJan: null,
        socialPostProductMonthTagFeb: null,
        socialPostProductMonthTagMar: null,
        socialPostProductMonthTagApr: null,
        socialPostProductMonthTagMay: null,
        socialPostProductMonthTagJun: null,
        socialPostProductMonthTagJul: null,
        socialPostProductMonthTagAug: null,
        socialPostProductMonthTagSep: null,
        socialPostProductMonthTagOct: null,
        socialPostProductMonthTagNov: null,
        socialPostProductMonthTagDec: null,
    }
    const [data, setData] = useState(initialData)

    const { defaults, defaultsError, defaultsLoading } = useData(
        `/v1/settings/${Object.keys(initialData).join(',')}?array=true`,
        'defaults',
        (data) =>
            data &&
            data
                .map((item, index) => [
                    Object.keys(initialData)[index],
                    item?.settingValue || null,
                ])
                .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {}),
    )

    const { plan, planError, planLoading, planMutate } = useData(
        `/v1/social-posts/plans/${socialPostPlanId}`,
        'plan',
        (data) => data,
        false,
        false,
        (data) =>
            setData({
                ...data,
                ...(data.settings.hashtags || {}),
            }),
        null,
        null,
        true,
    )

    async function onSubmit(e) {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/social-posts/plans/${socialPostPlanId}`,
            {
                settings: {
                    ...plan.settings,
                    hashtags: data,
                },
            },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setError(error)
            return
        }
        planMutate()
        setToast('Plan hashtags updated')
        navigate(`/content#shop_post_plans__`)
    }

    if (defaultsLoading || planLoading) {
        return <Spinner />
    }

    if (defaultsError || planError) {
        return (
            <SectionContainer>
                <ErrorMessage>{defaultsError || planError}</ErrorMessage>
            </SectionContainer>
        )
    }

    const firstCompany = auth.user.orgsData.find(
        (o) => o.organizationType === 'company',
    )

    return (
        <>
            <SectionContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}

                {!plan?.settings?.enableHashtags && (
                    <MessageSection type="warning">
                        Hashtags are disabled for this plan
                    </MessageSection>
                )}

                <form onSubmit={onSubmit}>
                    <FormGroupTitle>
                        Tags configuration - General
                    </FormGroupTitle>

                    <>
                        <CheckboxInput
                            id="form-use-default-tag"
                            label="Use default first hashtags"
                            value={
                                data.socialPostProductDefaultHashtag === null
                            }
                            onChange={(useDefault) =>
                                setData({
                                    ...data,
                                    socialPostProductDefaultHashtag: useDefault
                                        ? null
                                        : data.socialPostProductDefaultHashtag ||
                                          '',
                                })
                            }
                            info={
                                defaults.socialPostProductDefaultHashtag ||
                                'No default'
                            }
                        />
                        <br />
                    </>

                    {data.socialPostProductDefaultHashtag !== null && (
                        <>
                            <label htmlFor="form-default-tag">
                                First hashtags
                            </label>
                            <TextInput
                                placeholder="#hashtag1 #hashTag2"
                                id="form-default-tag"
                                type="text"
                                value={data.socialPostProductDefaultHashtag}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        socialPostProductDefaultHashtag: v,
                                    })
                                }
                            />
                        </>
                    )}

                    {defaults.socialPostProductCompanyTag === 'true' &&
                        Boolean(firstCompany) && (
                            <>
                                <CheckboxInput
                                    id="form-use-company-tag"
                                    label="Use default company hashtag"
                                    value={
                                        data.socialPostProductCompanyTag ===
                                        null
                                    }
                                    onChange={(useDefault) => {
                                        return setData({
                                            ...data,
                                            socialPostProductCompanyTag:
                                                useDefault
                                                    ? null
                                                    : data.socialPostProductCompanyTag ||
                                                      '',
                                        })
                                    }}
                                    info={`#${processHashtag(
                                        firstCompany.name,
                                    )}`}
                                />
                                <br />

                                {data.socialPostProductCompanyTag !== null && (
                                    <>
                                        <label htmlFor="form-company-tag">
                                            Company hashtag
                                        </label>
                                        <TextInput
                                            id="form-company-tag"
                                            placeholder="#mycompany"
                                            type="text"
                                            value={
                                                data.socialPostProductCompanyTag
                                            }
                                            onChange={(v) =>
                                                setData({
                                                    ...data,
                                                    socialPostProductCompanyTag:
                                                        v,
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}

                    {defaults.socialPostProductAcronymTag === 'true' &&
                        Boolean(firstCompany?.acronym) && (
                            <>
                                <CheckboxInput
                                    id="form-use-acronym-tag"
                                    label="Use default acronym hashtag"
                                    value={
                                        data.socialPostProductAcronymTag ===
                                        null
                                    }
                                    onChange={(useDefault) =>
                                        setData({
                                            ...data,
                                            socialPostProductAcronymTag:
                                                useDefault
                                                    ? null
                                                    : data.socialPostProductAcronymTag ||
                                                      '',
                                        })
                                    }
                                    info={`#${processHashtag(
                                        firstCompany.acronym,
                                    )}`}
                                />
                                <br />

                                {data.socialPostProductAcronymTag !== null && (
                                    <>
                                        <label htmlFor="form-acronym-tag">
                                            Acronym hashtag
                                        </label>
                                        <TextInput
                                            id="form-acronym-tag"
                                            placeholder="#mco"
                                            type="text"
                                            value={
                                                data.socialPostProductAcronymTag
                                            }
                                            onChange={(v) =>
                                                setData({
                                                    ...data,
                                                    socialPostProductAcronymTag:
                                                        v,
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}

                    <FormGroupTitle>Tags configuration - Months</FormGroupTitle>
                    {MONTHS.map((month, i) => (
                        <div key={i}>
                            <>
                                <CheckboxInput
                                    id={`form-use-month-${i}`}
                                    label={`Use default ${month.name} hashtags`}
                                    value={
                                        data[
                                            `socialPostProductMonthTag${month.abbreviation}`
                                        ] === null
                                            ? true
                                            : false
                                    }
                                    onChange={(useDefault) =>
                                        setData({
                                            ...data,
                                            [`socialPostProductMonthTag${month.abbreviation}`]:
                                                useDefault
                                                    ? null
                                                    : data[
                                                          `socialPostProductMonthTag${month.abbreviation}`
                                                      ] || '',
                                        })
                                    }
                                    info={
                                        defaults[
                                            `socialPostProductMonthTag${month.abbreviation}`
                                        ] || 'No default'
                                    }
                                />
                                <br />
                            </>

                            {data[
                                `socialPostProductMonthTag${month.abbreviation}`
                            ] !== null && (
                                <>
                                    <label htmlFor={`form-month${i}`}>
                                        {month.name} hashtags
                                    </label>
                                    <TextInput
                                        placeholder="#hashtag1 #hashTag2"
                                        id={`form-month-${i}`}
                                        type="text"
                                        value={
                                            data[
                                                `socialPostProductMonthTag${month.abbreviation}`
                                            ]
                                        }
                                        onChange={(v) =>
                                            setData({
                                                ...data,
                                                [`socialPostProductMonthTag${month.abbreviation}`]:
                                                    v,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </div>
                    ))}

                    <br />
                    <br />

                    <Button
                        text="Save"
                        isLoading={actionLoading}
                        type="submit"
                    />
                </form>
            </SectionContainer>
        </>
    )
}
