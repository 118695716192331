import useAuth from '../../common/hooks/useAuth'
import Break from '../../components/common/Break'
import MessageSection from '../../components/common/MessageSection'

export default function MembershipInfo({ isPremiumUser, billingIds }) {
    const auth = useAuth()
    return (
        <>
            {auth.isAdmin && (
                <>
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Only admins see this."
                    ></MessageSection>
                    <Break />
                </>
            )}

            {isPremiumUser ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://dashboard.stripe.com/${
                        process.env.NODE_ENV === 'development' ? 'test/' : ''
                    }customers/${
                        billingIds.find((item) => item.source === 'stripe')?.id
                    }`}
                >
                    Premium account - Stripe
                </a>
            ) : (
                'Free membership'
            )}
        </>
    )
}
