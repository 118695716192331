import authStorage from './authStorage'
import { API_URL } from './constants'

export default async function refreshAccessToken() {
    const refreshToken = authStorage.getItem('refresh_token')
    const response = await fetch(API_URL + `/v1/auth/refresh-tokens`, {
        method: 'POST',
        body: JSON.stringify({
            refreshToken: refreshToken,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    const data = await response.json()
    const { access, refresh } = data
    authStorage.setItem('access_token', access.token)
    authStorage.setItem('refresh_token', refresh.token)
    return access.token
}

export function getTokenExpirationDate(token) {
    if (!token) {
        return null
    }
    const payloadBase64 = token.split('.')[1]
    const decodedPayload = JSON.parse(atob(payloadBase64))
    if (!decodedPayload.exp) {
        return null
    }
    const expirationDate = new Date(decodedPayload.exp * 1000)
    return expirationDate
}
