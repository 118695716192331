import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import useAuth from '../../../common/hooks/useAuth'
import { formatMoney } from '../../../common/helpers'
import DBOrdersList from '../orders/AdminDBOrdersList'
import Toggle from '../../../components/common/Toggle'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ProductPage() {
    const { productId, shop } = useParams()
    const auth = useAuth()

    const { product, productError, productLoading } = useData(
        `/v1/wholesale/products-graphql/${productId}?shop=${shop}`,
        'product',
    )

    if (productLoading) return <Spinner marginTop />
    if (productError) {
        return (
            <SectionContainer>
                <ErrorMessage>{productError}</ErrorMessage>
            </SectionContainer>
        )
    }

    const minPrice = product?.priceRangeV2?.minVariantPrice?.amount
    const maxPrice = product?.priceRangeV2?.maxVariantPrice?.amount

    const priceText =
        minPrice === maxPrice
            ? formatMoney(minPrice, false, 'shopify')
            : `${formatMoney(minPrice, false, 'shopify')} - ${formatMoney(
                  maxPrice,
                  false,
                  'shopify',
              )}`

    const cost =
        product.variants.edges?.[0]?.node?.inventoryItem?.unitCost?.amount

    const collections = product.collections?.edges
        ?.map((edge) => edge?.node?.title)
        ?.join(', ')

    return (
        <>
            {product?.featuredImage?.url ? (
                <SectionContainer>
                    <img
                        className="max-width-100"
                        src={product.featuredImage.url}
                        alt={product.title}
                    />
                </SectionContainer>
            ) : null}

            <SectionContainer>
                <header className="info-header">
                    <h2>{product.title}</h2>
                </header>
            </SectionContainer>

            <SectionContainer>
                <div>
                    {product.description
                        ? `${product.description.substring(0, 300)}${
                              product.description.length > 300 ? '...' : ''
                          }`
                        : null}
                </div>
                <br />
                <div>
                    {!auth.isClient && product.tags?.length
                        ? product.tags.map((tag) => (
                              <Tag outline key={tag}>
                                  {tag}
                              </Tag>
                          ))
                        : null}
                </div>
            </SectionContainer>

            <SectionContainer className="info-details">
                {!auth.isClient && <div>Vendor:&nbsp;{product.vendor}</div>}
                {product.productType ? (
                    <div>Type:&nbsp;{product.productType}</div>
                ) : null}

                <div>
                    {!auth.isClient && cost
                        ? `${formatMoney(cost, false, 'shopify')} cost / `
                        : ''}
                    {!auth.isClient ? `Priced ${priceText}` : ''}
                </div>
                <div>
                    {!auth.isClient && collections
                        ? `Collections: ${collections}`
                        : null}
                </div>
            </SectionContainer>

            {auth.isAdmin && (
                <SectionContainer>
                    <Toggle title="Orders">
                        <DBOrdersList title="" product={productId} />
                    </Toggle>
                </SectionContainer>
            )}
        </>
    )
}
