import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { copyToClipboard } from '../../../common/helpers'
import useData from '../../../common/hooks/useData'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Toggle from '../../../components/common/Toggle'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function CreateHtmlContract() {
    const navigate = useNavigate()
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    const [data, setData] = useState({
        contractType: '',
        title: '',
        htmlContent: '',
    })
    const [saveLoading, setSaveLoading] = useState(false)

    const {
        contractVariables,
        contractVariablesError,
        contractVariablesLoading,
    } = useData(
        `/v1/html-contracts/variables/${data.contractType}`,
        'contractVariables',
    )

    async function onSubmit(e) {
        e.preventDefault()

        setSaveLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/html-contracts`,
            data,
            'POST',
        )
        setSaveLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        matchMutate(/\/v1\/html-contracts/)
        setToast('Contract created')
        navigate(`/html-contracts/${responseData.id}/edit`)
    }

    if (contractVariablesError) {
        return (
            <SectionContainer>
                <ErrorMessage>{contractVariablesError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <Toggle title="Available tags">
                <br />
                {contractVariablesLoading && <Spinner marginTop />}
                {contractVariables?.map((tag) => (
                    <div
                        key={tag}
                        role="button"
                        onClick={() => copyToClipboard(`{{${tag}}}`)}
                    >
                        <span>{`{{${tag}}}`}&nbsp;</span>
                        <img
                            width={16}
                            height={16}
                            src="/img/icons/clipboard-active.svg"
                            alt="copy icon"
                        />
                    </div>
                ))}
                <br />
            </Toggle>

            <Break />

            <form onSubmit={onSubmit}>
                <label htmlFor="form-type">Type</label>
                <SelectInput
                    onChange={(v) =>
                        setData({
                            ...data,
                            contractType: v,
                        })
                    }
                    id="form-type"
                    value={data.contractType}
                    options={[
                        {
                            value: '',
                            label: '',
                        },
                        {
                            value: 'vendorRegistration',
                            label: 'Vendor registration (Deprecated)',
                        },
                        {
                            value: 'vendorRegistrationIowa',
                            label: 'Vendor registration (Iowa)',
                        },
                        {
                            value: 'vendorRegistrationOnline',
                            label: 'Vendor registration (Online)',
                        },
                        {
                            value: 'vendorRegistrationIowaOnline',
                            label: 'Vendor registration (Iowa + Online)',
                        },
                        {
                            value: 'vendorConsignmentRegistration',
                            label: 'Consignment registration',
                        },
                    ]}
                />

                <label htmlFor="form-title" className="required">
                    Title
                </label>
                <TextInput
                    required
                    type="text"
                    id="form-title"
                    value={data.title}
                    onChange={(v) =>
                        setData({
                            ...data,
                            title: v,
                        })
                    }
                />

                <label htmlFor="form-html-content">HTML</label>
                <TextInput
                    type="textarea"
                    rows={15}
                    id="form-html-content"
                    onChange={(v) =>
                        setData({
                            ...data,
                            htmlContent: v,
                        })
                    }
                    value={data.htmlContent || ''}
                />

                <Button isLoading={saveLoading} type="submit" text="Save" />
            </form>
        </SectionContainer>
    )
}

export default CreateHtmlContract
