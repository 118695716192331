// .company ready

import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SelectInput from '../../../components/common/data-form/SelectInput'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'

function Contact() {
    const [error, setError] = useError()
    const [submitLoading, setSubmitLoading] = useState(false)
    const setToast = useToast()

    const [data, setData] = useState({
        reason: '',
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    })

    async function onSubmit(e) {
        e.preventDefault()
        setError('')

        setSubmitLoading(true)
        const { error } = await fetchAPI(`/v1/settings/contact`, data, 'POST')
        setSubmitLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Message sent')
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <label htmlFor="form-reason" className="required">
                    Why are you contacting us?
                </label>
                <SelectInput
                    required
                    value={data.reason}
                    id="form-reason"
                    onChange={(v) =>
                        setData({
                            ...data,
                            reason: v,
                        })
                    }
                    options={[
                        { value: '', label: 'Select a reason' },
                        { value: 'support', label: 'Support' },
                        { value: 'general', label: 'General inquiry' },
                        { value: 'media', label: 'Media inquiry' },
                        { value: 'idea', label: 'Idea' },
                        { value: 'bug', label: 'Report a Bug' },
                        { value: 'vendor', label: 'Vendor Inquiry' },
                        { value: 'refund', label: 'Refunds' },
                        { value: 'request-service', label: 'Request service' },
                    ]}
                />

                <label htmlFor="form-name" className="required">
                    Name
                </label>
                <TextInput
                    id="form-name"
                    required
                    value={data.name || ''}
                    onChange={(v) => setData({ ...data, name: v })}
                />

                <label htmlFor="form-email" className="required">
                    Email
                </label>
                <TextInput
                    type="email"
                    id="form-email"
                    required
                    value={data.email || ''}
                    onChange={(v) => setData({ ...data, email: v })}
                />

                <label htmlFor="form-phone" className="required">
                    Phone
                </label>
                <TextInput
                    id="form-phone"
                    required
                    value={data.phone || ''}
                    onChange={(v) => setData({ ...data, phone: v })}
                />

                <label htmlFor="form-company">Company</label>

                <TextInput
                    id="form-company"
                    value={data.company || ''}
                    onChange={(v) => setData({ ...data, company: v })}
                />

                <label htmlFor="form-message" className="required">
                    Message
                </label>

                <TextInput
                    type="textarea"
                    id="form-message"
                    required
                    value={data.message || ''}
                    onChange={(v) => setData({ ...data, message: v })}
                    rows={5}
                />

                <div>
                    <Button
                        text={'Submit'}
                        type="submit"
                        isLoading={submitLoading}
                    />
                </div>
            </form>
        </SectionContainer>
    )
}

export default Contact
