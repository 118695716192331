import ResourceList from '../../../components/admin/ResourceList'

function ServiceCategoriesList() {
    return (
        <ResourceList
            renderMainButton={true}
            apiUrl={'/services/categories'}
            initialQuery={{
                sortBy: 'title:asc',
            }}
            newItemPath={'/service-categories/new'}
            itemClickPath={'/service-categories/:item.id/edit'}
            getItemsFromResponse={(data) => data?.results}
            fields={[
                {
                    column: 1,
                    wrapperEl: 'h3',
                    getValue: (item) => item.title,
                },
                {
                    column: 1,
                    wrapperEl: 'p',
                    getValue: (item) => `Order: ${item.order || 0}`,
                },
            ]}
        />
    )
}

export default ServiceCategoriesList
