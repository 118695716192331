import { COUNTRIES, US_STATES } from '../../../common/constants'
import { useStore } from '../../../common/Context'
import useAuth from '../../../common/hooks/useAuth'
import { getContactDetailsUpdateBody } from '../../../common/helpers'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'firstName',
        label: 'First name',
        required: true,
    },
    {
        key: 'lastName',
        label: 'Last name',
        required: true,
    },

    {
        key: 'phone',
        label: 'Phone',
        required: true,
    },
    {
        key: 'address',
        label: 'Address',
        required: true,
    },
    {
        key: 'city',
        label: 'City',
        required: true,
    },
    {
        key: 'zip',
        label: 'Zip',
        required: true,
    },
    {
        key: 'state',
        label: 'State',
        type: 'select',
        options: [
            {
                value: '',
                label: '',
            },
        ].concat(
            ...US_STATES.map(({ text }) => ({ value: text, label: text })),
        ),
        required: true,
        shouldBeText: (data) => data.country !== 'United States',
    },
    {
        key: 'country',
        label: 'Country',
        type: 'select',
        options: COUNTRIES.map(({ text }) => ({
            value: text,
            label: text,
        })),
        required: true,
    },
]

function EditProfile() {
    const auth = useAuth()

    function mapItemToData() {
        return {
            firstName: auth.user.firstName || '',
            lastName: auth.user.lastName || '',
            phone:
                auth.user.contactDetails?.phones?.find(
                    (item) => item.phoneType === 'main',
                )?.phone || '',
            address: auth.user.mainAddress?.address || '',
            city: auth.user.mainAddress?.city || '',
            state: auth.user.mainAddress?.state || '',
            country: auth.user.mainAddress?.country || 'United States',
            zip: auth.user.mainAddress?.zip || '',
        }
    }

    function getBody(data) {
        const body = { ...data }

        body.phones = [{ phone: body.phone, phoneType: 'main' }]
        const contactDetails = getContactDetailsUpdateBody(
            {
                ...body,
                email: auth.user.email,
            },
            auth.user.contactDetails,
        )
        body.contactDetails = contactDetails
        delete body.phone
        delete body.phones

        body.mainAddress = {
            address: body.address,
            city: body.city,
            state: body.state,
            zip: body.zip,
            country: body.country,
            addressTypes: ['main'],
        }
        delete body.address
        delete body.city
        delete body.state
        delete body.zip
        delete body.country

        return body
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/users/me`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/`}
                inputs={inputs}
                getBody={getBody}
                mapItemToData={mapItemToData}
                mutationRegexes={[/\/v1\/users/]}
                mainButton
                onSuccess={() => {
                    auth.refetchAuth()
                }}
            />
        </SectionContainer>
    )
}

export default EditProfile
