import { COUNTRIES, US_STATES } from '../../../common/constants'
import Break from '../Break'
import Button from '../Button'
import AddItemsInput from './AddItemsInput'

const fieldTypes = [
    {
        value: 'text',
        label: 'Text',
    },
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'tel',
        label: 'Tel',
    },
    {
        value: 'number',
        label: 'Number',
    },
    {
        value: 'textarea',
        label: 'Textarea',
    },
    {
        value: 'select',
        label: 'Select',
    },
]

export default function AddItemsFieldSet({
    title,
    data,
    setData,
    deleteFieldSet,
}) {
    const value = !title
        ? data.fields.filter((f) => !f.groupName)
        : data.fields.filter((f) => f.groupName === title)

    function handleChange(v) {
        const newValueForSet = v
        const newValueForOtherSets = data.fields.filter((f) =>
            title ? f.groupName !== title : f.groupName,
        )

        setData({
            ...data,
            fields: [...newValueForSet, ...newValueForOtherSets],
        })
    }

    return (
        <>
            <label>{title ? `Field group: ${title}` : 'Fields'}</label>

            {title ? (
                <small className="input-info">
                    Users will be able to add multiple entries of this field
                    group.
                </small>
            ) : null}
            {title ? (
                <>
                    <Button
                        destructive
                        text="Delete group"
                        outline
                        tiny
                        onClick={deleteFieldSet}
                    />
                    <br />
                </>
            ) : null}
            <Break />
            <Break />
            <Break />
            <AddItemsInput
                onChange={handleChange}
                value={value}
                presets={[
                    {
                        label: 'First name',
                        fieldType: 'text',
                        required: true,
                    },
                    {
                        label: 'Last name',
                        fieldType: 'text',
                        required: true,
                    },
                    {
                        label: 'Email',
                        fieldType: 'email',
                        required: true,
                    },
                    {
                        label: 'Phone',
                        fieldType: 'tel',
                    },
                    {
                        label: 'Address',
                        fieldType: 'text',
                    },
                    {
                        label: 'City',
                        fieldType: 'text',
                    },
                    {
                        label: 'Province',
                        fieldType: 'text',
                    },
                    {
                        label: 'State',
                        fieldType: 'select',
                        options: [
                            '',
                            ...US_STATES.map(({ text }) => text),
                        ].join(','),
                    },
                    {
                        label: 'Country',
                        fieldType: 'select',
                        options: COUNTRIES.map(({ text }) => text).join(','),
                    },
                    {
                        label: 'ZIP',
                        fieldType: 'text',
                    },
                ]}
                innerInputs={[
                    {
                        key: 'label',
                        type: 'text',
                        label: 'Label',
                        required: true,
                        collapseInput: true,
                    },
                    {
                        key: 'fieldType',
                        type: 'select',
                        label: 'Field type',
                        options: fieldTypes,
                    },
                    {
                        key: 'required',
                        type: 'select',
                        label: 'Required',
                        options: [
                            {
                                label: 'No',
                                value: false,
                            },
                            {
                                label: 'Yes',
                                value: true,
                            },
                        ],
                    },
                    {
                        key: 'options',
                        type: 'text',
                        label: 'Options (for "select" - comma separated)',
                    },
                    {
                        key: 'placeholder',
                        type: 'text',
                        label: 'Placeholder',
                    },
                    {
                        key: 'isAppUserField',
                        type: 'select',
                        label: 'Use as app user field',
                        infoBottom:
                            'Use field as a user field and register a user in the aCo app. This will also notify the user via email. Compatible fields: Email, First name, Last name, Address, City, State, Zip, Country, Phone, Company (when present on the main field group, will be used for all field group users). Any other fields will be stored as user metafields.',
                        options: [
                            {
                                label: 'No',
                                value: false,
                            },
                            {
                                label: 'Yes',
                                value: true,
                            },
                        ],
                    },
                    {
                        key: 'groupName',
                        type: 'hidden',
                        value: title,
                    },
                ]}
            />
        </>
    )
}
