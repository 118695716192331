import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import PricesList from './PricesList'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

function ServicePage() {
    /**
     * Show billing: gow much per period, plus 1-15 and 16-31
     */
    const { serviceId, source } = useParams()
    const [service, setService] = useState({})
    const [loading, setLoading] = useState(true)
    // const [actionLoading, setActionLoading] = useState(false)

    const [error, setError] = useError()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/services/${source}/${serviceId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)
            setService(responseData)
        }
        getData()
    }, [])

    if (loading) return <Spinner marginTop />

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </SectionContainer>
            )}

            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>Service {service.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Edit',
                                    icon: ICONS.EDIT_GRAY,
                                    onClick: () => {
                                        return alert('Not implemented')
                                    },
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>

            {service?.prices?.length ? (
                <PricesList items={service.prices} loading={loading} />
            ) : null}
        </>
    )
}

export default ServicePage
