import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'email',
        label: 'PayPal email',
        required: true,
    },
]

function CreateClientPayPalBilling() {
    const auth = useAuth()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/payment/billing-methods/${auth.user.id}`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={inputs}
                getBody={(data) => ({
                    ...data,
                    userId: auth.user.id,
                    method: 'paypal',
                })}
                mainButton
                onSuccess={() => {
                    auth.refetchAuth()
                }}
            />
        </SectionContainer>
    )
}

export default CreateClientPayPalBilling
