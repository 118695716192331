import { useNavigate } from 'react-router-dom'
import { ICONS } from '../../common/constants'
import fetchAPI from '../../common/fetchAPI'
import useAuth from '../../common/hooks/useAuth'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import useToast from '../../common/hooks/useToast'
import ResourceList from '../../components/admin/ResourceList'
import TabsOverview from '../../components/navigation/TabsOverview'
import ContactCard from '../admin/clients/ContactCard'
import { useStore } from '../../common/Context'
import authStorage from '../../common/authStorage'
import OrgCard from './OrgCard'
import FavoriteContacts from './FavoriteContacts'
import useModal from '../../common/hooks/useModal'
import Chat from '../../components/common/Chat'
import ConfirmModalContent from '../../components/common/ConfirmModalContent'
import MessageSection from '../../components/common/MessageSection'
import InlineStack from '../../components/common/InlineStack'
import Button from '../../components/common/Button'
import { useState } from 'react'
import { getUserDisplayName } from '../../common/helpers'

const permissions = {
    user: {
        admin: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,teamRoles,isPremiumUser,role,startDate,activeStatus,contactDetails,orgsData',
            populate: 'contactDetails,orgsData',
        },
        assistant: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,teamRoles,isPremiumUser,role,startDate,activeStatus,contactDetails,orgsData',
            populate: 'contactDetails,orgsData',
        },
        sales: {
            fields: 'firstName,lastName,email,onHold,isPremiumUser,role,activeStatus,contactDetails,orgsData',
            populate: 'contactDetails,orgsData',
        },
        collections: {
            fields: 'firstName,lastName,email,onHold,onCollections,isPremiumUser,role,activeStatus,contactDetails,orgsData',
            populate: 'contactDetails,orgsData',
        },
        staff: {
            fields: 'firstName,lastName,email,isPremiumUser,role,activeStatus',
        },
    },
    contact: {
        all: {
            fields: 'firstName,lastName,email,contactType,onBlackList',
            populate:
                'contactDetails,creatorId$creator$firstName,creatorId$creator$lastName,creatorId$creator$email',
        },
    },
    company: {
        all: {
            fields: 'name,organizationType,members',
        },
    },
    companyMembers: {
        fields: 'firstName,lastName,email',
    },
}

const basicFilterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
}

const adminUserFilterFields = {
    ...basicFilterFields,
    filters: {
        onCollections: {
            label: 'Collections',
            dbField: 'onCollections',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        isBlocked: {
            label: 'Blocked',
            dbField: 'isBlocked',
            options: [
                {
                    label: 'No',
                    value: 'false',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'Any',
                    value: 'undefined',
                },
            ],
        },
        onBlackList: {
            label: 'Blacklisted',
            dbField: 'onBlackList',
            options: [
                {
                    label: 'No',
                    value: 'false',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'Any',
                    value: 'undefined',
                },
            ],
        },
        isPremiumUser: {
            label: 'Subscription',
            dbField: 'isPremiumUser',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Free',
                    value: 'false',
                },
                {
                    label: 'Premium',
                    value: 'true',
                },
            ],
        },
    },
}

const assistantUserFilterFields = {
    ...basicFilterFields,
    filters: {
        isBlocked: {
            label: 'Blocked',
            dbField: 'isBlocked',
            options: [
                {
                    label: 'No',
                    value: 'false',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'Any',
                    value: 'undefined',
                },
            ],
        },
        onBlackList: {
            label: 'Blacklisted',
            dbField: 'onBlackList',
            options: [
                {
                    label: 'No',
                    value: 'false',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'Any',
                    value: 'undefined',
                },
            ],
        },
        onCollections: {
            label: 'Collections',
            dbField: 'onCollections',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

const staffUserFilterFields = {
    ...basicFilterFields,
    filters: {
        onCollections: {
            label: 'Collections',
            dbField: 'onCollections',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

const userUserFilterFields = {
    ...basicFilterFields,
}

const adminContactsFilterFields = {
    ...basicFilterFields,
    filters: {
        onBlackList: {
            label: 'Blacklisted',
            dbField: 'onBlackList',
            options: [
                {
                    label: 'No',
                    value: 'false',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'Any',
                    value: 'undefined',
                },
            ],
        },
    },
}

const contactsFilterFields = {
    ...basicFilterFields,
}

const companyFilterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['name'],
            asc: true,
        },
    },
}

const allFilterFields = {
    search: true,
}

function PendingInviteMessages() {
    const setToast = useToast()
    const auth = useAuth()
    const matchMutate = useMatchMutate()
    const [loading, setLoading] = useState(false)
    const [listKey, setListKey] = useState(Date.now())

    async function acceptInvite(id) {
        setLoading(true)
        const url = `/v1/users/invites/${id}/accept`
        const { error } = await fetchAPI(url, {}, 'POST')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite accepted')
        setListKey(Date.now())
        matchMutate(/\/v1\/users\/invites*/)
    }

    async function declineInvite(id) {
        setLoading(true)
        const url = `/v1/users/invites/${id}`
        const { error } = await fetchAPI(url, {}, 'DELETE')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite declined')
        setListKey(Date.now())
        matchMutate(/\/v1\/users\/invites*/)
    }

    return (
        <ResourceList
            key={listKey}
            emptyText={'No invites'}
            minimal
            apiUrl={'/users/invites'}
            initialQuery={{ invitedId: auth.user.id }}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <MessageSection
                            small
                            type="info"
                            title={`You were invited to connect to ${getUserDisplayName(item.creator)}`}
                        >
                            <InlineStack justifyEnd gap={'sm'}>
                                <Button
                                    outline
                                    text="Decline"
                                    white
                                    small
                                    isLoading={loading}
                                    onClick={() => declineInvite(item.id)}
                                />
                                <Button
                                    text="Accept"
                                    small
                                    isLoading={loading}
                                    onClick={() => acceptInvite(item.id)}
                                />
                            </InlineStack>
                        </MessageSection>
                    ),
                },
            ]}
        />
    )
}

function List({ type, chat }) {
    const setToast = useToast()
    const mutate = useMatchMutate()
    const navigate = useNavigate()
    const [_state, dispatch] = useStore()
    const auth = useAuth()
    const isContact = [
        'contacts',
        'leads',
        'friends',
        'family',
        'clients',
    ].includes(type)
    const { setModal, isModalLoading, setModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    async function handleChat(item) {
        setModal(
            <Chat roomType={'direct'} otherUserIds={[item.id]} />,
            '',
            'modal-full',
        )
    }

    async function toggleOnCollections(item) {
        if (item.role !== 'user') return
        const actionUrl = item.onCollections
            ? `/v1/payment/clients/${item.id}/revert-on-collections`
            : `/v1/payment/clients/${item.id}/on-collections`
        const { error } = await fetchAPI(actionUrl, {}, 'POST')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Done')
        mutate(/^\/v1\/users*/)
    }

    async function handleInvite(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/users/invite`,
                        {
                            email: item.email,
                            contactId: item.id,
                        },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Invite sent')
                    matchMutate(/\/v1\/users\/invites*/)
                }}
            />,
            'Send a connection invite?',
        )
    }

    async function onLoginAsClient(item) {
        const { responseData, error } = await fetchAPI(
            `/v1/auth/login-as-user`,
            {
                userId: item.id,
            },
            'POST',
        )

        if (error) return setToast(error, 'alert')

        const { user, tokens } = responseData
        authStorage.setItem('access_token', tokens.access.token)
        authStorage.setItem('refresh_token', tokens.refresh.token)

        dispatch({ type: 'LOGIN', payload: user })
        navigate('/')
        window.location.reload()
    }

    const isTypeOrg = ['companies', 'families', 'groups'].includes(type)

    let fields = {}
    let populate = {}

    let filterFields
    if (isTypeOrg) {
        fields = permissions.company.all.fields
        filterFields = companyFilterFields
    } else if (isContact) {
        fields = permissions.contact.all.fields
        filterFields = auth.isAdmin
            ? adminContactsFilterFields
            : contactsFilterFields
    } else if (type === 'companyMembers') {
        fields = permissions.companyMembers.fields
        populate = undefined
    } else if (auth.isAdmin) {
        fields = permissions.user.admin.fields
        populate = permissions.user.admin.populate
        filterFields = adminUserFilterFields
    } else if (auth.isAssistant) {
        fields = permissions.user.assistant.fields
        populate = permissions.user.assistant.populate
        filterFields = assistantUserFilterFields
    } else if (auth.isSales) {
        fields = permissions.user.sales.fields
        populate = permissions.user.sales.populate
        filterFields = userUserFilterFields
    } else if (auth.isCollections) {
        fields = permissions.user.collections.fields
        populate = permissions.user.collections.populate
        filterFields = assistantUserFilterFields
    } else if (auth.isStaff) {
        fields = permissions.user.staff.fields
        populate = permissions.user.staff.populate
        filterFields = staffUserFilterFields
    }

    let initialQuery = {}

    if (!isContact && !isTypeOrg) {
        initialQuery = { fields, populate }
        initialQuery.role = 'user'
        initialQuery.new = true
        initialQuery.type = type
    }
    if (type === 'companyMembers') {
        delete initialQuery.new
        delete initialQuery.type
        delete initialQuery.role
    }

    if (isTypeOrg) {
        initialQuery = { fields }
    }

    let apiUrl
    if (type === 'all') {
        apiUrl = '/users/all'
    } else if (isTypeOrg) {
        apiUrl = '/organizations'
    } else if (isContact) {
        apiUrl = '/contacts'
    } else if (type === 'companyMembers') {
        apiUrl = '/users/me/org-members'
    } else {
        apiUrl = '/users'
    }

    let newItemLabel = 'New contact'
    if (type === 'companies') {
        newItemLabel = 'New company'
        initialQuery.organizationType = 'company'
    } else if (type === 'families') {
        newItemLabel = 'New family'
        initialQuery.organizationType = 'family'
    } else if (type === 'groups') {
        newItemLabel = 'New group'
        initialQuery.organizationType = 'group'
    }
    if (type === 'companyMembers') {
        newItemLabel = undefined
    }

    if (type === 'leads') {
        delete initialQuery.type
        initialQuery.contactType = 'lead'
    }
    if (type === 'contacts') {
        delete initialQuery.type
        initialQuery.contactType = 'contact'
    }
    if (type === 'friends') {
        delete initialQuery.type
        initialQuery.contactType = 'friend'
    }
    if (type === 'family') {
        delete initialQuery.type
        initialQuery.contactType = 'family'
    }
    if (type === 'clients') {
        delete initialQuery.type
        initialQuery.contactType = 'client'
    }

    let newItemPath

    if (type === 'companyMembers') {
        newItemPath = undefined
    } else if (isTypeOrg) {
        newItemPath = `/organizations/new`
    } else {
        newItemPath = '/contacts/new'

        if (type === 'leads') {
            newItemPath += '?type=lead'
        } else if (type === 'friends') {
            newItemPath += '?type=friend'
        } else if (type === 'family') {
            newItemPath += '?type=family'
        } else if (type === 'clients') {
            newItemPath += '?type=client'
        } else if (type === 'contact') {
            newItemPath += '?type=contact'
        }
    }

    return (
        <ResourceList
            getIncludedHtml={type === 'all' ? () => <FavoriteContacts /> : null}
            key={type}
            paginated
            initialQuery={{
                ...(type === 'all' ? {} : { ...initialQuery }),
            }}
            renderMainButton={Boolean(newItemPath)}
            newItemLabel={newItemLabel}
            newItemPath={newItemPath}
            apiUrl={apiUrl}
            getItemsFromResponse={(data) => data?.results}
            filters={{
                ...(type === 'all' ? allFilterFields : filterFields),
                urlKey: 'contacts',
            }}
            fields={
                isTypeOrg
                    ? [
                          {
                              column: 1,
                              getValue: (item) => <OrgCard org={item} />,
                          },
                      ]
                    : [
                          {
                              column: 1,
                              getValue: (item) =>
                                  item.organizationType ? (
                                      <OrgCard org={item} />
                                  ) : (
                                      <ContactCard
                                          withNameLink
                                          hideLinks={type === 'companyMembers'}
                                          user={{
                                              ...item,
                                          }}
                                      />
                                  ),
                          },
                      ]
            }
            actions={
                isTypeOrg
                    ? []
                    : [
                          {
                              title: 'Chat',
                              onClick: handleChat,
                              icon: ICONS.MESSAGE_SQUARE_GRAY,
                              getHide: () => !chat,
                          },
                          {
                              title: 'Invite',
                              onClick: handleInvite,
                              getHide: (item) =>
                                  item.role ||
                                  item.organizationType ||
                                  !item.email,
                          },
                          {
                              title: 'Edit',
                              link: '/contacts/:item.id/profile/edit?type=user',
                              icon: ICONS.EDIT_GRAY,
                              getHide: (item) =>
                                  item.organizationType ||
                                  item.role !== 'user' ||
                                  auth.isClient ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Edit',
                              link: '/contacts/:item.id/profile/edit?type=staff',
                              icon: ICONS.EDIT_GRAY,
                              getHide: (item) =>
                                  item.organizationType ||
                                  item.role !== 'staff' ||
                                  (!auth.isAdmin && !auth.isAssistant) ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Edit',
                              link: '/contacts/:item.id/profile/edit?type=contact',
                              icon: ICONS.EDIT_GRAY,
                              getHide: (item) =>
                                  item.organizationType ||
                                  item.role ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Create task',
                              link: '/users/:item.id/tasks/new',
                              getHide: (item) =>
                                  item.organizationType ||
                                  !(
                                      ((auth.isAdmin || !auth.isAssistant) &&
                                          item.role) ||
                                      (auth.isStaff && item.role === 'client')
                                  ) ||
                                  type === 'companyMembers',
                              icon: ICONS.CLIPBOARD_GRAY,
                          },
                          {
                              title: 'Toggle Collections',
                              onClick: (item) => toggleOnCollections(item),
                              getHide: (item) =>
                                  item.organizationType ||
                                  item.role !== 'user' ||
                                  (!auth.isAdmin && !auth.isCollections) ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Email',
                              onClick: (item) =>
                                  (window.location.href = `mailto:${item.email}`),
                              icon: ICONS.MAIL_GRAY_2,
                              getHide: (item) =>
                                  item.organizationType ||
                                  !item.email ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Call',
                              onClick: (item) =>
                                  (window.location.href = `tel:${item.contactDetails?.phone}`),
                              icon: ICONS.PHONE_GRAY_2,
                              getHide: (item) =>
                                  item.organizationType ||
                                  !item.contactDetails?.phone ||
                                  type === 'companyMembers',
                          },
                          {
                              title: 'Login as user',
                              onClick: onLoginAsClient,
                              icon: ICONS.LOGIN_GRAY,
                              getHide: (item) =>
                                  item.organizationType ||
                                  !auth.isAdmin ||
                                  !item.role ||
                                  type === 'companyMembers',
                          },
                      ]
            }
        />
    )
}

export default function ContactsOverview({ parentPage }) {
    const auth = useAuth()

    const pages = {}
    let defaultTab

    if (auth.isAdmin) {
        pages.all = <List type={'all'} />
        pages.users = <List type={'users'} />
        pages.app_clients = <List type={'appClients'} />
        pages.vendors = <List type={'vendors'} />
        pages.buyers = <List type={'buyers'} />
        pages.clients = <List type={'clients'} />
        pages.leads = <List type={'leads'} />
        pages.team = <List type={'team'} />
        pages.friends = <List type={'friends'} />
        pages.family = <List type={'family'} />
        pages.contacts = <List type={'contacts'} />
        pages.admin = <List type={'admin'} />
        pages.companies = <List type={'companies'} />
        pages.families = <List type={'families'} />
        pages.groups = <List type={'groups'} />
        defaultTab = 'all'
    } else if (auth.isAssistant) {
        pages.all = <List type={'all'} />
        pages.users = <List type={'users'} />
        pages.app_clients = <List type={'appClients'} />
        pages.vendors = <List type={'vendors'} />
        pages.buyers = <List type={'buyers'} />
        pages.clients = <List type={'clients'} />
        pages.leads = <List type={'leads'} />
        pages.friends = <List type={'friends'} />
        pages.family = <List type={'family'} />
        pages.contacts = <List type={'contacts'} />
        pages.team = <List type={'team'} />
        pages.companies = <List type={'companies'} />
        pages.families = <List type={'families'} />
        pages.groups = <List type={'groups'} />
        defaultTab = 'all'
    } else if (auth.isStaff) {
        pages.all = <List type={'all'} />
        pages.users = <List type={'users'} />
        pages.app_clients = <List type={'appClients'} />
        pages.leads = <List type={'leads'} />
        pages.friends = <List type={'friends'} />
        pages.family = <List type={'family'} />
        pages.contacts = <List type={'contacts'} />
        pages.clients = <List type={'clients'} />
        defaultTab = 'all'
    } else {
        pages.all = <List type={'all'} />
        pages.clients = <List type={'clients'} />
        pages.leads = <List type={'contacts'} />
        pages.friends = <List type={'friends'} />
        pages.family = <List type={'family'} />
        if (auth.user.orgsData?.some((o) => o.organizationType === 'company')) {
            pages.my_company = <List type={'companyMembers'} chat />
        }
        defaultTab = 'leads'
    }
    pages.invites = <PendingInviteMessages />

    return (
        <>
            <TabsOverview
                defaultTab={defaultTab}
                pagesMap={pages}
                parentPage={parentPage}
            />
        </>
    )
}
