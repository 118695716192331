import ResourceList from '../../../components/admin/ResourceList'

export default function LogsList() {
    return (
        <ResourceList
            apiUrl={'/settings/logs'}
            fields={[
                {
                    column: 1,
                    wrapperEl: 'p',
                    getValue: (item) => `${item.timestamp}`,
                    getClassName: () => 'subtitle',
                },

                {
                    column: 1,
                    wrapperEl: 'pre',
                    getValue: (item) => `${item.message}`,
                },
            ]}
        />
    )
}
