import { useEffect, useState } from 'react'
import Switch from './Switch'
import TextInput from './data-form/TextInput'

function HtmlEditor(props) {
    const { onChange, value = '', zoom = 1 } = props

    const [initValue, setInitValue] = useState()

    const [isTextMode, setIsTextMode] = useState(false)

    useEffect(
        function () {
            if (initValue) return
            function handleIframeContentChange(e) {
                onChange(e.detail.value)
            }
            window.document.addEventListener(
                'iframecontentchange',
                handleIframeContentChange,
            )
            setInitValue(value)

            return function () {
                window.document.removeEventListener(
                    'iframecontentchange',
                    handleIframeContentChange,
                )
                window.removeEventListener('load', handleIframeContentChange)
            }
        },
        [value],
    )

    const IFRAME_INNER_STYLE = `
    <style>
        body {
            margin: 0;
            padding: 0;
            zoom: ${zoom};
        }
        .page-break {
            width: 100%;
            height: 1px;
            background-color: #000;
        }
    </style>
`

    if (!initValue) return null

    return (
        <div className="html-editor">
            <div>
                <Switch
                    text1={'Text'}
                    text2={'HTML'}
                    isOn={isTextMode}
                    onChange={setIsTextMode}
                />
            </div>
            <div>
                {isTextMode ? (
                    <TextInput
                        type="textarea"
                        id="form-html-content"
                        onChange={(v) => onChange(v)}
                        value={value || ''}
                    />
                ) : (
                    <iframe
                        className="html-editor"
                        title="html-editor"
                        srcDoc={`${IFRAME_INNER_STYLE}<div contenteditable oninput="window.parent.document.dispatchEvent(new CustomEvent('iframecontentchange', {detail: {value: event.target.innerHTML}}))">${initValue}</div>`}
                    ></iframe>
                )}
            </div>
        </div>
    )
}

export default HtmlEditor
