import { ICONS } from '../../../common/constants'
import useData from '../../../common/hooks/useData'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import AppProductItem from '../products/AppProductItem'
import SectionContainer from '../../../components/common/SectionContainer'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
            iconAsc: ICONS.BELL_WHITE,
            iconDesc: ICONS.CHECK_WHITE,
            asc: true,
        },
    },
}

export default function PublicVendorPage() {
    const { vendorAccount, vendorAccountError, vendorAccountLoading } = useData(
        '/v1/shop/404',
        'vendorAccount',
    )

    if (vendorAccountError) {
        return (
            <SectionContainer>
                <ErrorMessage>{vendorAccountError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (vendorAccountLoading) {
        return <Spinner />
    }

    // const vendorAccount = {
    //     organization: {
    //         name: 'Vendor Name',
    //         acronym: 'VN',
    //         profile: {
    //             excerpt: 'Vendor Excerpt',
    //             about: 'Vendor About',
    //         },
    //         mainAddress: {
    //             state: 'State',
    //             country: 'Country',
    //         },
    //     },
    //     events: [
    //         {
    //             name: 'Event Name',
    //             date: formatDateTime(new Date(), true),
    //         },
    //     ],
    // }

    return (
        <>
            <SectionContainer>Under construction</SectionContainer>
            {/* <header className="info-header client-page-info-header">
                <div>
                    <h2>
                        {vendorAccount?.organization?.name} (
                        {vendorAccount?.organization?.acronym})
                    </h2>
                </div>
                <div>
                    <Button text="Follow" outline small />
                </div>
            </header>
            <section>
                <br />
                <img
                    src={vendorAccount.organization?.logoUrl}
                    width={24}
                    height={24}
                    alt="logo"
                />
            </section>
            <section>
                <div>
                    {vendorAccount.organization.mainAddress?.state},{' '}
                    {vendorAccount.organization.mainAddress?.country}
                </div>
                <br />
                <div>{vendorAccount.organization.profile?.excerpt}</div>
            </section>
            {vendorAccount.organization.profile?.about ? (
                <section>
                    <h2>About us</h2>
                    <div>{vendorAccount.organization.profile.about}</div>
                </section>
            ) : null}
            {vendorAccount?.events?.length ? (
                <section>
                    <h2>Upcoming Events</h2>
                    <div>
                        {vendorAccount.events.map((event, i) => (
                            <div key={i}>
                                <div>{event.name}</div>
                                <div>{event.date}</div>
                            </div>
                        ))}
                    </div>
                </section>
            ) : null}
            <ResourceList
                apiUrl={'/shop/products'}
                initialQuery={{
                    ownerId: vendorAccount?.organization?.id,
                    fields: 'title,ownerId',
                }}
                itemViews={['list', 'square', 'double']}
                getItemsFromResponse={(data) => data?.results}
                paginated={true}
                filters={filterFields}
                paginationLimit={10}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <AppProductItem withBuy item={item} />
                        ),
                        getClassName: () => 'title',
                    },
                ]}
            /> */}
        </>
    )
}
