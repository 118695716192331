import { useNavigate, useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import Button from '../../../components/common/Button'
import MainButton from '../../../components/admin/MainButton'
import { useState } from 'react'
import Input from '../../../components/common/data-form/Input'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditShopifyGraphQLProduct() {
    const { productId, shop } = useParams()
    const [error, setError] = useError()
    const matchMutate = useMatchMutate()
    const [actionLoading, setActionLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()
    const [data, setData] = useState({
        title: '',
        descriptionHtml: '',
    })

    const { product, productError, productLoading, productMutate } = useData(
        `/v1/wholesale/products-graphql/${productId}?shop=${shop}`,
        'product',
        null,
        null,
        null,
        (item) =>
            item &&
            setData({
                title: item.title,
                descriptionHtml: item.descriptionHtml,
            }),
    )

    const onSubmit = async (e) => {
        setError('')
        e.preventDefault()

        setActionLoading(true)
        const url = `/v1/wholesale/products-graphql/${product.legacyResourceId}?shop=${shop}`
        const { error } = await fetchAPI(url, data, 'PATCH')
        setActionLoading(false)
        if (error) return setError(error)

        productMutate()
        matchMutate(/\/v1\/wholesale\/products-graphql*/)
        setToast('Saved')
        navigate(`/shop#inventory`)
    }

    if (productLoading) return <Spinner marginTop />
    if (productError) {
        return (
            <SectionContainer>
                <ErrorMessage>{productError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <Input
                    label={'Title'}
                    required
                    id="form-title"
                    value={data.title}
                    onChange={(v) => setData({ ...data, title: v })}
                    autoComplete="new-password"
                />

                <Input
                    label={'Description'}
                    type="html"
                    rows={8}
                    id="form-description"
                    value={data.descriptionHtml}
                    required
                    onChange={(v) => setData({ ...data, descriptionHtml: v })}
                />

                <div>
                    <Button
                        text={'Save'}
                        type="submit"
                        isLoading={actionLoading}
                    />
                </div>

                <MainButton
                    disabled={actionLoading}
                    functionality="SAVE"
                    loading={actionLoading}
                />
            </form>
        </SectionContainer>
    )
}
