import { ICONS } from '../../common/constants'
import SectionContainer from './SectionContainer'

function MessageSection(props) {
    let className = 'message-section'
    if (props.type === 'warning') className += ' warning'
    if (props.type === 'success') className += ' success'
    if (props.type === 'info') className += ' info'
    if (props.small) className += ' size-small'
    if (props.fullWidthButton) className += ' full-btn'
    if (props.fullWidth) className += ' full-width'

    let title
    if (props.title) {
        title = props.title
    } else if (props.type === 'success') {
        title = 'Success'
    }

    let icon
    if (props.type === 'success') {
        icon = ICONS.CHECK_WHITE
    } else if (props.type === 'warning') {
        icon = ICONS.ALERT_WHITE
    } else if (props.type === 'info') {
        icon = ICONS.HELP_WHITE
    }

    return (
        <div className={className}>
            {title ||
            props.onDismiss ||
            ['success', 'warning'].includes(props.type) ? (
                <header>
                    {icon ? (
                        <img src={icon} width={16} height={16} alt="check" />
                    ) : null}
                    <h2>{title}</h2>

                    {props.onDismiss ? (
                        <button className="close-btn" onClick={props.onDismiss}>
                            &#10005;
                        </button>
                    ) : null}
                </header>
            ) : null}
            <div className="message-body">{props.children}</div>
        </div>
    )
}

export default MessageSection
