import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { formatDate, formatMoney } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

function ExpensePage() {
    const { expenseId } = useParams()
    const [expense, setExpense] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useError()
    const navigate = useNavigate()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/expenses/${expenseId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)
            setExpense(responseData)
        }
        getData()
    }, [])

    const onDelete = async () => {
        setError('')
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/expenses/${expenseId}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to delete this expense?',
        )
    }

    if (loading) return <Spinner marginTop />

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </SectionContainer>
            )}
            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>Expense {expense.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Delete',
                                    onClick: () => {
                                        onDelete()
                                    },
                                    type: 'alert',
                                    icon: ICONS.TRASH_RED,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>

            <SectionContainer className="info-details">
                <p>{formatMoney(expense.amount)}</p>
                <p>Created {formatDate(expense.creationDate)}</p>
            </SectionContainer>
        </>
    )
}

export default ExpensePage
