import { prettifyDate } from '../../common/helpers'
import ResourceList from '../admin/ResourceList'

export default function Timeline({ url }) {
    return (
        <div>
            <h2 className="m-0">Timeline</h2>
            <ResourceList
                minimal
                paginated
                paginationLimit={20}
                emptyText={'No events yet'}
                apiUrl={url}
                getItemsFromResponse={(data) => data?.results || []}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <>
                                {item.content}
                                <br />
                                <span className="text-subdued">
                                    {prettifyDate(new Date(item.creationDate))}
                                </span>
                            </>
                        ),
                    },
                ]}
            />
        </div>
    )
}
