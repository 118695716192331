import { ICONS } from '../../../common/constants'
import ResourceList from '../../../components/admin/ResourceList'
import SectionContainer from '../../../components/common/SectionContainer'
import AppProductItem from '../../shop/products/AppProductItem'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
            iconAsc: ICONS.BELL_WHITE,
            iconDesc: ICONS.CHECK_WHITE,
            asc: true,
        },
    },
}

export default function WishlistProductsList() {
    const wishlistProductIds = localStorage.getItem('wishList')

    if (!wishlistProductIds) {
        return <SectionContainer>No products in wishlist</SectionContainer>
    }

    return (
        <ResourceList
            itemClickPath={'/shop/products/:item.id'}
            apiUrl={'/shop/products'}
            initialQuery={{
                fields: 'title,ownerId',
                ids: wishlistProductIds,
            }}
            itemViews={['list', 'square', 'double']}
            getItemsFromResponse={(data) => data?.results}
            paginated={true}
            filters={filterFields}
            paginationLimit={10}
            fields={[
                {
                    column: 1,
                    getValue: (item) => <AppProductItem withBuy item={item} />,
                    getClassName: () => 'title',
                },
            ]}
        />
    )
}
