import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import { useState } from 'react'
import TranslationItem from './TranslationItem'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

function Tab({ label, isActive, onClick }) {
    return (
        <button className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {label.split('_').join(' ')}
        </button>
    )
}

function TabsRow({ labels, activeLabel, onTabClick }) {
    return (
        <div className="tabs">
            {labels.map((label, index) => (
                <Tab
                    key={index}
                    label={label}
                    isActive={label === activeLabel}
                    onClick={() => onTabClick(label)}
                />
            ))}
        </div>
    )
}

function getFirstTabs(data, tabs) {
    const newTabs = [...tabs]
    if (!Object.keys(data).length) {
        return newTabs
    }
    const firstKey = Object.keys(data)[0]
    if (data[firstKey] && Object.keys(data[firstKey]).length === 0) {
        return newTabs
    }
    newTabs.push(firstKey)
    return getFirstTabs(data[firstKey], newTabs)
}

function TranslationsList() {
    const setToast = useToast()
    const [loadingKeys, setLoadingKeys] = useState({})
    const { categories, categoriesError, categoriesLoading } = useData(
        `/v1/translations/categories-tree`,
        'categories',
        null,
        null,
        null,
        (data) => setActiveTabs(getFirstTabs(data, [])),
    )
    const [activeTabs, setActiveTabs] = useState([])

    function handleTabClick(level, label) {
        const newActiveTabs = []
        // Up to the new label, use the same tabs
        for (let i = 0; i < level; i++) {
            newActiveTabs.push(activeTabs[i])
        }
        // Add the new label
        newActiveTabs.push(label)

        // Add the first tab of the new label, and the next ones etc.
        let currItem = categories
        for (const tab of newActiveTabs) {
            currItem = currItem[tab]
        }

        // Add the next first tabs
        const nextFirstTabs = getFirstTabs(currItem, [])
        newActiveTabs.push(...nextFirstTabs)

        setActiveTabs(newActiveTabs)
    }

    function renderTree(data, level = 0) {
        const labels = Object.entries(data)
            .filter(([_key, value]) => Object.keys(value).length)
            .map(([key]) => key)

        const activeLabel = activeTabs[level]

        return (
            <div style={{ overflowX: 'hidden' }} key={level}>
                <TabsRow
                    labels={labels}
                    activeLabel={activeLabel}
                    onTabClick={(label) => handleTabClick(level, label)}
                />
                {data[activeLabel] && renderTree(data[activeLabel], level + 1)}
            </div>
        )
    }

    async function onSubmit(key, value) {
        setLoadingKeys((curr) => ({ ...curr, [key]: true }))
        const { error } = await fetchAPI(
            `/v1/translations/${encodeURIComponent(key)}`,
            { value },
            'PATCH',
        )
        setLoadingKeys((curr) => ({ ...curr, [key]: false }))

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Translation updated')
    }

    if (categoriesLoading) {
        return <Spinner />
    }

    if (categoriesError) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {categoriesError.message || 'An error occurred'}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {renderTree(categories)}
            {activeTabs?.length ? (
                <ResourceList
                    key={activeTabs.join('.')}
                    // paginated
                    apiUrl={`/translations`}
                    initialQuery={{
                        parentKey: activeTabs.join('.'),
                    }}
                    // filters={{
                    //     search: true,
                    // }}
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => (
                                <TranslationItem
                                    translation={item}
                                    onSubmit={onSubmit}
                                    loading={loadingKeys[item.key]}
                                />
                            ),
                        },
                    ]}
                />
            ) : null}
        </SectionContainer>
    )
}

export default TranslationsList
