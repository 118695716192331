import ResourceList from '../../../components/admin/ResourceList'
import useToast from '../../../common/hooks/useToast'
import useAuth from '../../../common/hooks/useAuth'
import {
    getDisplayDateTimeFromTime,
    getZonedTimeFromUTCTime,
} from '../../../common/helpers'
import { useLocation } from 'react-router-dom'
import Tag from '../../../components/common/Tag'

const filterFields = {
    sort: {
        name: {
            label: 'Order number',
            dbFields: ['orderNumber'],
        },
        creationDate: {
            label: 'Creation date',
            dbFields: ['creationDate'],
        },
    },
}

function getFulfillRequestData(order) {
    if (!order.extras?.requestedFulfillmentLines?.length) return []
    const result = []
    for (const item of order.extras.requestedFulfillmentLines) {
        const { lineItemId, quantity } = item
        if (!quantity) continue
        const line = order.lineItems.find(
            (lineItem) => lineItem.lineItemId === lineItemId,
        )
        if (!line?.fulfillableQuantity) continue
        result.push({ ...item, line })
    }
    return result
}

function getLineIdsMap(order) {
    const result = order.lineItems.reduce(
        (a, b) => ({
            ...a,
            [b.lineItemId]: b,
        }),
        {},
    )
    return result
}

function canFulfillOrder(order) {
    return (
        order.status !== 'cancelled' &&
        order.creationDate < Date.now() - 1000 * 60 * 60 * 24 &&
        order.financialStatus === 'paid' &&
        order.lineItems.some((line) => line.fulfillableQuantity > 0)
    )
}

export default function DBOrdersList() {
    const setToast = useToast()
    const auth = useAuth()
    // const { setModal, isModalLoading, setModalLoading } = useModal()
    const location = useLocation()

    function fulfillAllItems(_order) {
        setToast('This feature is not available yet.', 'warning')
    }

    const isBuyerPage = location.hash === '#my_orders'

    // TODO
    const TEMP_WITH_FULFILLMENT = false

    return (
        <ResourceList
            // renderMainButton={false}
            apiUrl={'/wholesale/db-orders'}
            getItemsFromResponse={(data) => data.results}
            initialQuery={
                isBuyerPage
                    ? // TODO re-enable
                      { fulfillmentOrders: TEMP_WITH_FULFILLMENT, buyer: true }
                    : { fulfillmentOrders: TEMP_WITH_FULFILLMENT }
            }
            paginated
            paginationLimit={5}
            filters={filterFields}
            fields={[
                {
                    column: 1,
                    getValue: (item) => `${item.shopName} #${item.orderNumber}`,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        `Created ${getDisplayDateTimeFromTime(
                            getZonedTimeFromUTCTime(
                                null,
                                new Date(item.creationDate).getTime(),
                            ),
                        )}`,
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <div>
                            {item.fulfillmentStatus ? (
                                <Tag outline>{item.fulfillmentStatus}</Tag>
                            ) : (
                                ''
                            )}
                            {item.financialStatus ? (
                                <Tag outline>{item.financialStatus}</Tag>
                            ) : (
                                ''
                            )}
                            {item.cancelDate ? (
                                <Tag color={'alert'}>Cancelled</Tag>
                            ) : (
                                ''
                            )}
                            {TEMP_WITH_FULFILLMENT &&
                            getFulfillRequestData(item)?.length ? (
                                <Tag color={'alert'}>Requires fulfillment</Tag>
                            ) : (
                                ''
                            )}
                        </div>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) =>
                        TEMP_WITH_FULFILLMENT &&
                        !isBuyerPage &&
                        getFulfillRequestData(item).length ? (
                            <div className="list">
                                <h5>Requested items to fulfill</h5>
                                <ul>
                                    {getFulfillRequestData(item).map(
                                        (requestedItem) => {
                                            return (
                                                <li
                                                    key={
                                                        requestedItem.lineItemId
                                                    }
                                                >
                                                    <span>
                                                        {requestedItem.quantity}
                                                    </span>
                                                    x&nbsp;
                                                    {requestedItem.line.name}
                                                </li>
                                            )
                                        },
                                    )}
                                </ul>
                            </div>
                        ) : null,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        !isBuyerPage && canFulfillOrder(item) ? (
                            <div className="list">
                                <h5>
                                    Your unfulfilled items&nbsp;&nbsp;
                                    <Tag color={'warning'}>To fulfill</Tag>
                                </h5>
                                <ul>
                                    {item.lineItems
                                        .filter(
                                            (line) =>
                                                line.fulfillableQuantity > 0,
                                        )
                                        .map((line) => (
                                            <li key={line.lineItemId}>
                                                <span>{line.quantity}</span>
                                                x&nbsp;
                                                {line.name}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        ) : null,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        !isBuyerPage ? (
                            <div className="list">
                                <h5>Your items</h5>
                                <ul>
                                    {item.lineItems.map((line) => (
                                        <li key={line.lineItemId}>
                                            <span>{line.quantity}</span>x&nbsp;
                                            {line.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        TEMP_WITH_FULFILLMENT &&
                        !isBuyerPage &&
                        item.fulfillmentOrders?.length
                            ? item.fulfillmentOrders.map(
                                  (fulfillmentOrder, i) => (
                                      <div key={i} className="list">
                                          <h5>Fulfillment request</h5>
                                          <ul>
                                              {fulfillmentOrder.fulfillmentLines.map(
                                                  (line) => (
                                                      <li key={line.lineItemId}>
                                                          <span>
                                                              {
                                                                  line.remainingQuantity
                                                              }
                                                              x&nbsp;
                                                              {
                                                                  getLineIdsMap(
                                                                      item,
                                                                  )?.[
                                                                      line
                                                                          .lineItemId
                                                                  ]?.name
                                                              }
                                                          </span>
                                                      </li>
                                                  ),
                                              )}
                                          </ul>
                                          <p>
                                              <Tag color={'alert'}>
                                                  Needs fulfillment
                                              </Tag>
                                          </p>
                                      </div>
                                  ),
                              )
                            : null,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        isBuyerPage ? (
                            <div className="list">
                                {/* <h5>Your items</h5> */}
                                <ul>
                                    {item.lineItems.map((line) => (
                                        <li key={line.lineItemId}>
                                            <span>{line.quantity}</span>
                                            &nbsp;x&nbsp;{line.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.shippingAddress ? (
                            <div>
                                <details className="details">
                                    <summary>
                                        <h5>Shipping address</h5>
                                    </summary>
                                    <div>
                                        <p>
                                            <strong>First name&nbsp;</strong>
                                            {item.shippingAddress.firstName}
                                        </p>
                                        <p>
                                            <strong>Last name&nbsp;</strong>
                                            {item.shippingAddress.lastName}
                                        </p>
                                        <p>
                                            <strong>Address 1&nbsp;</strong>
                                            {item.shippingAddress.address1}
                                        </p>
                                        <p>
                                            <strong>City&nbsp;</strong>
                                            {item.shippingAddress.city}
                                        </p>
                                        <p>
                                            <strong>Province&nbsp;</strong>
                                            {item.shippingAddress.province}
                                        </p>
                                        <p>
                                            <strong>Country&nbsp;</strong>
                                            {item.shippingAddress.country}
                                        </p>
                                        <p>
                                            <strong>Zip&nbsp;</strong>
                                            {item.shippingAddress.zip}
                                        </p>
                                        <p>
                                            <strong>Address 2&nbsp;</strong>
                                            {item.shippingAddress.address2}
                                        </p>
                                        <p>
                                            <strong>Phone&nbsp;</strong>
                                            {item.shippingAddress.phone}
                                        </p>
                                    </div>
                                </details>
                            </div>
                        ) : null,
                },

                {
                    column: 1,

                    getValue: (item) =>
                        !!item.fulfillments.length && (
                            <>
                                <p>
                                    Tracking number&nbsp;
                                    {item.fulfillments[0].trackingNumber}
                                </p>
                                <p>
                                    Shipment status&nbsp;
                                    <Tag
                                        color={
                                            item.fulfillments[0]
                                                .shipmentStatus === 'delivered'
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {item.fulfillments[0].shipmentStatus ===
                                        'delivered'
                                            ? 'Delivered'
                                            : 'Pending'}
                                    </Tag>
                                </p>
                            </>
                        ),
                },
            ]}
            // actions={[
            //     {
            //         title: 'Fulfill all items',
            //         onClick: (item) => fulfillAllItems(item),
            //         getHide: (item) =>
            //             !auth.isWholesaleClient || !canFulfillOrder(item),
            //     },
            //     // {
            //     //     title: 'Fulfill all & get shipping label',
            //     //     onClick: (item) => fulfillAllOpenOrders(item),
            //     //     hide: !TEMP_WITH_FULFILLMENT && !auth.isWholesaleClient,
            //     //     getDisabled: (item) => !item?.fulfillmentOrders?.length,
            //     // },
            // ]}
        />
    )
}
