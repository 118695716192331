import React, { useState } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'

import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useData from '../../../common/hooks/useData'
import { getUserDisplayName } from '../../../common/helpers'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateInvoice() {
    const { userId } = useParams()
    const [searchParams] = useSearchParams()
    const subId = searchParams.get('sub')
    const source = searchParams.get('source')
    const isAmountOnly = searchParams.get('isAmountOnly')
    const status = searchParams.get('status')
    const navigate = useNavigate()
    const matchMutate = useMatchMutate()

    const initialData = {
        status: status || 'paid',
        paymentMethod: '',
        amount: '',
        created: new Date().toISOString().slice(0, 10),
    }
    const [error, setError] = useError()
    const [data, setData] = useState(initialData)
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()

    const { user, userError, userLoading } = useData(
        `/v1/users/${userId}?fields=email,firstName,lastName`,
        'user',
    )

    const onSubmit = async (e) => {
        if (
            !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(data.amount)
        ) {
            setError('Invalid amount')
            return e.preventDefault()
        }
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const url = subId
                        ? `/v1/payment/clients/${userId}/invoices/manual`
                        : `/v1/payment/invoices`

                    const { error } = await fetchAPI(
                        url,
                        {
                            user: userId,
                            subscription: subId || undefined,
                            status: data.status,
                            dueDate: data.due || null,
                            creationDate: data.created || null,
                            periodStartDate: data.start || undefined,
                            periodEndDate: data.end || undefined,
                            amount: String(data.amount * 100),
                            isAmountOnly: isAmountOnly || false,
                        },
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Created')
                    navigate(-1)
                    matchMutate(/\/v1\/payment\/balance/)
                }}
            />,
            'Create invoice?',
        )
    }

    if (userLoading) return <Spinner />
    if (userError) {
        return (
            <SectionContainer>
                <ErrorMessage>{userError}</ErrorMessage>
            </SectionContainer>
        )
    }

    let title = 'New '
    if (isAmountOnly) {
        if (status) {
            if (status === 'open') {
                title += 'owed to aCo '
            } else if (status === 'paid') {
                title += 'paid to aCo '
            }
        }
        title += 'Amount for '
    } else {
        if (status) title += `${status} `
        title += 'Invoice for '
    }
    title += getUserDisplayName(user)

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <h2>{title}</h2>
            <form onSubmit={onSubmit}>
                <div>
                    {subId ? (
                        <>
                            <label htmlFor="form-source">Source</label>
                            <SelectInput
                                name="source"
                                id="form-source"
                                value={source}
                                disabled
                                options={[{ value: source, label: source }]}
                            />

                            <label htmlFor="form-sub">Subscription</label>
                            <SelectInput
                                name="sub"
                                id="form-sub"
                                value={subId}
                                disabled
                                options={[{ value: subId, label: subId }]}
                            />
                        </>
                    ) : null}

                    <label htmlFor="form-amount">Amount</label>
                    <TextInput
                        name="amount"
                        id="form-amount"
                        type="number"
                        step="0.01"
                        value={data.amount}
                        onChange={(v) =>
                            setData({
                                ...data,
                                amount: v,
                            })
                        }
                    />

                    {isAmountOnly ? (
                        <>
                            <label htmlFor="form-method">Payment method</label>
                            <SelectInput
                                id="form-method"
                                value={data.paymentMethod}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        paymentMethod: v,
                                    })
                                }
                                options={[
                                    { value: '', label: '' },
                                    { value: 'ACH', label: 'ACH' },
                                    { value: 'PayPal', label: 'PayPal' },
                                    { value: 'Cash', label: 'Cash' },
                                    { value: 'Check', label: 'Check' },
                                ]}
                            />
                        </>
                    ) : null}

                    {status ? null : (
                        <>
                            <label htmlFor="form-status">Status</label>
                            <SelectInput
                                name="status"
                                id="form-status"
                                value={data.status}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        status: v,
                                    })
                                }
                                options={[
                                    { value: 'paid', label: 'Paid' },
                                    { value: 'open', label: 'Open' },
                                    { value: 'void', label: 'Void' },
                                ]}
                            />
                        </>
                    )}

                    <label htmlFor="form-created">Created</label>
                    <TextInput
                        name="created"
                        id="form-created"
                        type="date"
                        value={data.created}
                        onChange={(v) =>
                            setData({
                                ...data,
                                created: v,
                            })
                        }
                    />

                    <label htmlFor="form-due">Due</label>
                    <TextInput
                        name="due"
                        id="form-due"
                        type="date"
                        value={data.due}
                        onChange={(v) =>
                            setData({
                                ...data,
                                due: v,
                            })
                        }
                    />

                    {subId ? (
                        <>
                            <label htmlFor="form-start">Period start</label>
                            <TextInput
                                name="start"
                                id="form-start"
                                type="date"
                                value={data.start}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        start: v,
                                    })
                                }
                            />
                            <label htmlFor="form-end">Period end</label>
                            <TextInput
                                name="end"
                                id="form-end"
                                type="date"
                                value={data.end}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        end: v,
                                    })
                                }
                            />
                        </>
                    ) : null}
                </div>

                <div>
                    <Button text="Save" type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreateInvoice
