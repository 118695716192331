function ButtonGroup({
    imageSrc,
    imageWidth = 100,
    imageHeight = 100,
    col2,
    background,
    label,
    row,
    grid,
    children,
}) {
    let className = `button-group`
    if (col2) className += ` button-group-col-2`
    if (grid) className += ` button-group-grid`
    if (row) className += ` button-group-row`
    if (background) className += ` button-group-background`
    if (imageSrc) className += ` with-image`

    return (
        <div className={className}>
            {label ? <header>{label}</header> : null}
            {imageSrc ? (
                <img
                    src={imageSrc}
                    width={imageWidth}
                    height={imageHeight}
                    alt={label || ''}
                />
            ) : null}
            <div>{children}</div>
        </div>
    )
}

export default ButtonGroup
