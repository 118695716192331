import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import PrivateFile from '../../../components/common/PrivateFile'
import { useParams } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ClientOrgMemberInfo(props) {
    const { open } = props
    const { userId } = useParams()

    const { user, userError, userLoading } = useData(
        `/v1/users/${userId}?fields=email&populate=allCompanyOrgs,allCompanyOrgs.companyInfo`,
        'user',
    )

    if (userError) {
        return (
            <SectionContainer>
                <Toggle title="Member info">
                    <ErrorMessage>{userError}</ErrorMessage>
                </Toggle>
            </SectionContainer>
        )
    }

    if (userLoading) {
        return (
            <SectionContainer>
                <Toggle title="Member info">
                    <Spinner />
                </Toggle>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <Toggle title={`Member info`} open={open || false}>
                {user.allCompanyOrgs?.map((org, i) => {
                    const memberInfo = org?.companyInfo?.membersInfo?.find(
                        (m) => m.userId === user.id,
                    )

                    return (
                        <div key={i}>
                            <h3>{org.name}</h3>
                            <div>
                                <small>
                                    <strong>Title</strong>
                                </small>
                                <br />
                                <small>
                                    {memberInfo?.title || (
                                        <span className="alert">None</span>
                                    )}
                                </small>
                            </div>
                            <div>
                                <h4>Submitted proof of work</h4>
                                {memberInfo?.workProofFileIds?.length ? (
                                    memberInfo.workProofFileIds.map((id, i) => (
                                        <PrivateFile
                                            key={i}
                                            openFullOnClick
                                            driveFileId={id}
                                        />
                                    ))
                                ) : (
                                    <span className="warning">None</span>
                                )}
                            </div>
                        </div>
                    )
                })}
            </Toggle>
        </SectionContainer>
    )
}
