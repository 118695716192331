import { useState } from 'react'
import { ICONS } from '../../../common/constants'
import ResourceList from '../../../components/admin/ResourceList'
import BlockStack from '../../../components/common/BlockStack'
import Tag from '../../../components/common/Tag'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import fetchAPI from '../../../common/fetchAPI'
import InlineStack from '../../../components/common/InlineStack'

export default function AppMetafieldDefinitionsList() {
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const { setModal, isModalLoading, setModalLoading } = useModal()

    async function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/metafields/definitions/${item.id}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    matchMutate(/\/v1\/metafields\/definitions/)
                    setToast('Deleted definition')
                }}
            />,
            'Are you sure you want to delete this definition? Values will be kept.',
        )
    }

    return (
        <ResourceList
            newItemPath={'/metafields/app-definitions/new'}
            apiUrl={'/metafields/definitions'}
            initialQuery={{
                scope: 'app',
            }}
            title={'App Metafield Definitions'}
            smallTitle
            subtitle={
                'Definitions for metafields that can be used in the app. These definitions (not their values) are visible to all users.'
            }
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <BlockStack gap={'sm'}>
                            <span>{item.title}</span>
                            <InlineStack gap={'sm'}>
                                <Tag noMargin color={'primary'}>
                                    {item.resource === 'Lead'
                                        ? 'Contact'
                                        : item.resource}
                                </Tag>
                                <Tag noMargin outline>
                                    {item.valueType}
                                </Tag>
                            </InlineStack>
                        </BlockStack>
                    ),
                },
            ]}
            actions={[
                {
                    title: 'Delete',
                    icon: ICONS.TRASH_RED,
                    type: 'alert',
                    onClick: (item) => onDelete(item),
                },
            ]}
        />
    )
}
