import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HorizontalScroll from '../common/HorizontalScroll'

function getCurrPage(location, parentPage) {
    if (parentPage) {
        if (location.hash.split('#').length === 2) {
            return null
        }
        return location.hash.split('#')[2]
    }
    return location.hash.split('#')[1]
}

function getNewHash(newPage, location, parentPage) {
    if (parentPage) {
        return `#${location.hash.split('#')[1]}#${newPage}`
    }
    return `#${newPage}`
}

export default function TabsOverview({ defaultTab, pagesMap, parentPage }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [currPage, setCurrPage] = useState(
        getCurrPage(location, parentPage) || defaultTab,
    )

    useEffect(
        function () {
            setCurrPage(getCurrPage(location, parentPage) || defaultTab)
        },
        [location.hash],
    )

    return (
        <>
            <div className="tabs">
                <HorizontalScroll>
                    {Object.keys(pagesMap).map((pageName) => (
                        <button
                            className={currPage === pageName ? 'active' : ''}
                            key={pageName}
                            onClick={() => {
                                setCurrPage(pageName)
                                navigate(
                                    `${location.pathname}${getNewHash(pageName, location, parentPage)}`,
                                )
                            }}
                        >
                            {pageName.endsWith('__') && (
                                <img
                                    src="/img/icons/settings-white.svg"
                                    alt="settings icon"
                                    width="13"
                                    height="13"
                                />
                            )}
                            {pageName.replace('__', '').replace(/_/g, ' ')}
                        </button>
                    ))}
                </HorizontalScroll>
            </div>
            <div>{pagesMap[currPage]}</div>
        </>
    )
}
