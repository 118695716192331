import Button from '../common/Button'

function ResourceListAddButton(props) {
    const { newItemBtnUrl, newItemBtnText, loading } = props

    return (
        <div className="resource-list-new-item-btn">
            <Button
                type="button"
                disabled={loading}
                href={newItemBtnUrl}
                text={newItemBtnText || '+ Add'}
                small
            />
        </div>
    )
}

export default ResourceListAddButton
