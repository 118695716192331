import { useNavigate } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import MainButton from '../../../components/admin/MainButton'
import ResourceList from '../../../components/admin/ResourceList'
import Toggle from '../../../components/common/Toggle'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ServicesList() {
    const navigate = useNavigate()

    const { groupings, groupingsLoading, groupingsError } = useData(
        '/v1/services/categories',
        'groupings',
        (data) =>
            (data?.results || []).map((category) => ({
                title: category.title,
                order: category.order,
                ids: category.services,
            })),
    )

    if (groupingsLoading) {
        return <Spinner />
    }
    if (groupingsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{groupingsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <MainButton
                label="New service"
                functionality="CREATE"
                onClick={() => navigate('/services/new')}
            />

            {groupings
                .sort((a, b) => a.order - b.order)
                .map((groupingData) => (
                    <SectionContainer key={groupingData.title}>
                        <Toggle title={groupingData.title} open>
                            <ResourceList
                                apiUrl={'/services'}
                                itemClickPath={
                                    '/services/:item.source/:item.id'
                                }
                                filterItems={(item) =>
                                    groupingData.ids.includes(item.id)
                                }
                                fields={[
                                    {
                                        column: 1,
                                        wrapperEl: 'h3',
                                        getValue: (item) => item.title,
                                    },
                                    {
                                        column: 1,
                                        wrapperEl: 'h3',
                                        getValue: (item) => item.la,
                                    },
                                    {
                                        column: 1,
                                        wrapperEl: 'p',
                                        getValue: (item) => (
                                            <Tag outline>
                                                {item.source === 'manual'
                                                    ? 'Manual billing'
                                                    : item.source}
                                            </Tag>
                                        ),
                                    },
                                ]}
                            />
                        </Toggle>
                    </SectionContainer>
                ))}

            <SectionContainer>
                <Toggle title={'Others'} open>
                    <ResourceList
                        apiUrl={'/services'}
                        itemClickPath={'/services/:item.source/:item.id'}
                        filterItems={(item) =>
                            !groupings
                                .map((grouping) => grouping.ids)
                                .flat()
                                .includes(item.id)
                        }
                        fields={[
                            {
                                column: 1,
                                wrapperEl: 'h3',
                                getValue: (item) => item.title,
                            },
                            {
                                column: 1,
                                wrapperEl: 'h3',
                                getValue: (item) => item.la,
                            },
                            {
                                column: 1,
                                wrapperEl: 'p',
                                getValue: (item) => (
                                    <Tag outline>
                                        {item.source === 'manual'
                                            ? 'Manual billing'
                                            : item.source}
                                    </Tag>
                                ),
                            },
                        ]}
                    />
                </Toggle>
            </SectionContainer>
        </>
    )
}
