import { useParams } from 'react-router-dom'
import { COUNTRIES, US_STATES } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'address',
        label: 'Address',
        required: true,
    },
    {
        key: 'city',
        label: 'City',
        required: true,
    },
    {
        key: 'zip',
        label: 'Zip',
        required: true,
    },
    {
        key: 'state',
        label: 'State',
        type: 'select',
        options: US_STATES.map(({ text }) => ({ value: text, label: text })),
        required: true,
        shouldBeText: (data) => data.country !== 'United States',
    },
    {
        key: 'country',
        label: 'Country',
        type: 'select',
        options: COUNTRIES.map(({ text }) => ({
            value: text,
            label: text,
        })),
        required: true,
    },
]

function CreateAddress() {
    const {
        userId: userIdParam,
        addressId,
        contactId: contactIdParam,
    } = useParams()
    const auth = useAuth()

    const userId = auth.isClient ? auth.user.id : userIdParam

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/addresses/${
                    contactIdParam || (auth.isClient ? auth.user.id : userId)
                }/${addressId}`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={inputs}
                getBody={(data) => ({
                    ...data,
                    addressTypes: [data.addressTypes || ''],
                })}
                mapItemToData={(item) => ({
                    addressTypes: item.addressTypes[0],
                    address: item.address,
                    city: item.city,
                    zip: item.zip,
                    state: item.state,
                    country: item.country,
                })}
                mutationRegexes={[
                    /\/v1\/addresses*/,
                    /\/v1\/user*/,
                    /\/v1\/me*/,
                ]}
                mainButton
            />
        </SectionContainer>
    )
}

export default CreateAddress
