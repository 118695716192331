export default function ImportProductItem({ item, showShopInfo }) {
    return (
        <div className="import-product-item">
            {item.featuredImage?.url ? (
                <img
                    width="40"
                    height="40"
                    src={item.featuredImage.url}
                    alt={item.title}
                    className="product-list-image"
                />
            ) : (
                <div></div>
            )}
            <div className="">
                <div>{item.title}</div>
                {showShopInfo && (
                    <>
                        <div className="text-subdued">{item.shopName}</div>
                        <div className="text-subdued">{item.vendor}</div>
                    </>
                )}
            </div>
        </div>
    )
}
