import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
        autoComplete: 'new-password',
    },
    {
        key: 'bodyHtml',
        label: 'Content',
        required: true,
        autoComplete: 'new-password',
        type: 'html',
    },
    {
        key: 'summaryHtml',
        label: 'Excerpt',
        autoComplete: 'new-password',
        type: 'textarea',
    },
    {
        key: 'tags',
        label: 'Tags',
        autoComplete: 'new-password',
        type: 'text',
        infoTop: 'Comma-separated',
    },
    {
        key: 'files',
        label: 'Featured image',
        type: 'file',
        accept: 'image/jpeg',
        required: true,
    },
    {
        key: 'published',
        type: 'select',
        label: 'Publish status',
        options: [
            { value: 'false', label: 'Draft' },
            { value: 'true', label: 'Published' },
        ],
    },
]

export default function CreateArticle() {
    const { shopId, blogId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/blogs/${shopId}/${blogId}/articles`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/content/blogs/${shopId}/${blogId}`}
                inputs={inputs}
                mainButton
                getBody={(data) => {
                    const MAX_FILE_SIZE = 10 * 1024 * 1024
                    if (
                        data.files?.length &&
                        data.files[0].size > MAX_FILE_SIZE
                    ) {
                        throw new Error(`Max file size is 10MB.`)
                    }
                    const formData = new FormData()
                    if (data.files?.[0]) {
                        formData.append(`file`, data.files[0])
                    }
                    delete data.files
                    formData.append('data', JSON.stringify(data))

                    return formData
                }}
            />
        </SectionContainer>
    )
}
