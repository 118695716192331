import { useNavigate } from 'react-router-dom'
import {
    ICONS,
    TASK_PRIORITY_LABELS,
    TASK_STATUSES,
} from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import {
    copyToClipboard,
    formatDateTime,
    getHoursAndMinutesFromMinutes,
    getInnerTextFromHTML,
    getUserDisplayName,
    markdownToJsx,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import InlineStack from '../../../components/common/InlineStack'
import Tag from '../../../components/common/Tag'
import TaskStatusTag from './TaskStatusTag'
import TaskClientStatusTag from './TaskClientStatusTag'
import InlineStackItem from '../../../components/common/InlineStackItem'

export default function TaskItem({ task }) {
    const auth = useAuth()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const navigate = useNavigate()

    const hasBugTag = task?.tags?.map((t) => t.toLowerCase()).includes('bug')
    const hasClientTag = task?.tags
        ?.map((t) => t.toLowerCase())
        .includes('client')

    async function toggleBug(e) {
        e.preventDefault()
        e.stopPropagation()
        const url = `/v1/tasks/${task.id}`
        const body = {
            tags: hasBugTag
                ? task.tags.filter((t) => t.toLowerCase() !== 'bug')
                : [...task.tags, 'Bug'],
        }
        const { error } = await fetchAPI(url, body, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        matchMutate(/^\/v1\/tasks/)
    }

    async function toggleClient(e) {
        e.preventDefault()
        e.stopPropagation()
        const url = `/v1/tasks/${task.id}`
        const body = {
            tags: hasClientTag
                ? task.tags.filter((t) => t.toLowerCase() !== 'client')
                : [...task.tags, 'Client'],
        }
        const { error } = await fetchAPI(url, body, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        matchMutate(/^\/v1\/tasks/)
    }

    async function setItemPriority(priority, item) {
        // setMarkCompleteLoading(true)
        const url = `/v1/tasks/${item.id}`
        const { error } = await fetchAPI(url, { priority }, 'PATCH')
        // setMarkCompleteLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        matchMutate(/^\/v1\/tasks/)
    }

    const priorityTag = (
        <Tag
            initValue={task.priority}
            outline
            onChange={(v) => setItemPriority(v, task)}
            options={
                !auth.isAdmin
                    ? []
                    : Object.entries(TASK_PRIORITY_LABELS).map(([k, v]) => ({
                          label: v.label,
                          value: k,
                          color: v.tagColor,
                      }))
            }
            color={TASK_PRIORITY_LABELS[task.priority].tagColor}
        >
            {TASK_PRIORITY_LABELS[task.priority].label}
            {/* {item.priority} */}
        </Tag>
    )

    const assignedHtml =
        (auth.isAdmin || auth.isStaff) &&
        ['team', 'role'].includes(task.target) &&
        task.assignedTeamMember ? (
            <small className="text-subdued">
                {`Assigned to: ${getUserDisplayName(task.assignedTeamMember)}${
                    task.target === 'role'
                        ? ` - (${task.teamRoles.join(', ')})`
                        : ''
                }`}
            </small>
        ) : null

    const creatorHtml =
        !auth.isClient && task.creator ? (
            <small className="text-subdued">
                {`Created by: ${getUserDisplayName(
                    task.creator,
                )} on ${formatDateTime(task.createdAt, true)}`}
            </small>
        ) : null

    const commentsHtml = task.commentsCount ? (
        <span className="icon-with-badge">
            <div
                role="button"
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    navigate(`/tasks/${task.id}#comments`)
                }}
            >
                <img
                    src={ICONS.MESSAGE_SQUARE_GRAY}
                    alt="mail"
                    width="24"
                    height="24"
                />
                <span className="icon-badge">{task.commentsCount}</span>
            </div>
        </span>
    ) : null

    let bugHtml
    if (Array.isArray(task.tags) && auth.isAdmin) {
        bugHtml = (
            <img
                onClick={toggleBug}
                role="button"
                src={hasBugTag ? ICONS.BUG_ACTIVE : ICONS.BUG_GRAY}
                width={24}
                height={24}
                alt={hasBugTag ? 'Disable bug' : 'Enable bug'}
            />
        )
    } else if (Array.isArray(task.tags) && hasBugTag) {
        bugHtml = (
            <img src={ICONS.BUG_ACTIVE} width={24} height={24} alt="Bug" />
        )
    }

    let clientHtml
    if (Array.isArray(task.tags) && auth.isAdmin) {
        clientHtml = (
            <img
                onClick={toggleClient}
                role="button"
                src={hasClientTag ? ICONS.USER_ACTIVE : ICONS.USER_GRAY}
                width={24}
                height={24}
                alt={hasClientTag ? 'Disable client' : 'Enable client'}
            />
        )
    } else if (Array.isArray(task.tags) && hasClientTag) {
        clientHtml = (
            <img src={ICONS.USER_ACTIVE} width={24} height={24} alt="Client" />
        )
    }

    let title = task.title
    if (hasBugTag && !task.title.toLowerCase().startsWith('bug:')) {
        title = (
            <>
                <span className="text-subdued">Bug:&nbsp;</span>
                {task.title}
            </>
        )
    }
    // if (hasClientTag && !task.title.toLowerCase().startsWith('client:')) {
    //     title = (
    //         <>
    //             <span className="text-subdued">Client task:&nbsp;</span>
    //             {task.title}
    //         </>
    //     )
    // }

    return (
        <div>
            <div>
                <InlineStack spaceBetween itemsStart>
                    <div>
                        <div>
                            {task.status !== TASK_STATUSES.COMPLETE &&
                                (auth.isAdmin || auth.isStaff) && (
                                    <Tag outline>{task.order}</Tag>
                                )}
                            {task.status !== TASK_STATUSES.COMPLETE &&
                                task.assignedTeamMemberId &&
                                (auth.isAdmin ||
                                    auth.isStaff ||
                                    auth.user.id ===
                                        task.assignedTeamMemberId) && (
                                    <Tag outline>User: {task.userOrder}</Tag>
                                )}
                        </div>
                        <div>{priorityTag}</div>
                    </div>

                    <div>
                        <InlineStack justifyEnd>
                            <TaskStatusTag task={task} />
                        </InlineStack>
                        <InlineStack justifyEnd>
                            <TaskClientStatusTag task={task} />
                        </InlineStack>
                    </div>
                </InlineStack>

                <h3>{title}</h3>
                <div className="task-item-description">
                    {task.descriptionHtml
                        ? getInnerTextFromHTML(task.descriptionHtml)
                        : ''}
                    {/* {task.description ? markdownToJsx(task.description) : ''} */}
                </div>
                <div>
                    {(auth.isAdmin || auth.isStaff) && task.target === 'client'
                        ? `Homework for ${getUserDisplayName(task.client)}`
                        : ''}
                </div>
            </div>

            <div>
                <p>{assignedHtml}</p>
                <p>{creatorHtml}</p>
            </div>

            <p className="subtitle">
                {task.timeQuoteMinutes
                    ? `Estimated: ${
                          getHoursAndMinutesFromMinutes(task.timeQuoteMinutes)
                              .hours
                      }h ${
                          getHoursAndMinutesFromMinutes(task.timeQuoteMinutes)
                              .minutes
                      }m`
                    : null}
            </p>

            <InlineStack spaceBetween gap={'sm'}>
                <InlineStack spaceBetween>
                    {task.tags?.length ? (
                        <div>
                            {task.tags
                                .filter((t) => t.toLowerCase() !== 'bug')
                                .filter((t) => t.toLowerCase() !== 'client')
                                .map((tag, i) => (
                                    <Tag
                                        key={i}
                                        square
                                        outline
                                        href={
                                            task.assignedTeamMemberId &&
                                            !auth.isClient
                                                ? `/users/${task.assignedTeamMemberId}?tag_task_list=${tag}&t_title=Tasks`
                                                : undefined
                                        }
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                        </div>
                    ) : null}
                    <InlineStack itemsCenter gap="sm">
                        {bugHtml}
                        {/* {clientHtml} */}
                        {commentsHtml}
                    </InlineStack>
                </InlineStack>
                <InlineStackItem shrink0>
                    <Button
                        outline
                        tiny
                        alignRight
                        text={'Copy URL'}
                        icon={ICONS.COPY_ACTIVE}
                        onClick={(e) => {
                            e.preventDefault()
                            copyToClipboard(
                                `${process.env.REACT_APP_PUBLIC_URL}/tasks/${task.id}`,
                            )
                            setToast('Copied to clipboard')
                        }}
                    />
                </InlineStackItem>
            </InlineStack>
        </div>
    )
}
