import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from '../../../common/Context'
import fetchAPI from '../../../common/fetchAPI'
import FormSteps from '../../../common/FormSteps'
import useAuth from '../../../common/hooks/useAuth'
import useError from '../../../common/hooks/useError'
import Button from '../../../components/common/Button'
import authStorage from '../../../common/authStorage'
import ErrorMessage from '../../../components/common/ErrorMessage'
import MessageSection from '../../../components/common/MessageSection'
import SectionContainer from '../../../components/common/SectionContainer'

function RegisterVerifyEmail() {
    const [state, dispatch] = useStore()
    const auth = useAuth()
    const navigate = useNavigate()

    const [error, setError] = useError()
    const [message, setMessage] = useState('')
    const [searchParams] = useSearchParams()
    const [actionLoading, setActionLoading] = useState(false)
    const isBuyer = searchParams.get('buyer')

    useEffect(() => {
        if (!(auth.isClient && !auth.isEmailValidated)) {
            navigate('/')
            return
        }

        const hasError = searchParams.get('error') === ''
        if (hasError) {
            setError(
                'There was a problem verifying your email. Please try again.',
            )
        }
    }, [])

    const onResend = async (e) => {
        e.preventDefault()

        setError('')
        setMessage('')
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/auth/send-verification-email${
                isBuyer || auth.user.isBuyer ? '?buyer=true' : ''
            }`,
            {},
            'POST',
        )
        setActionLoading(false)

        if (error) {
            setError(error)
            return
        }

        setMessage('Please check your inbox.')
    }

    const onLogout = (e) => {
        e.preventDefault()
        authStorage.removeItem('access_token')
        authStorage.removeItem('refresh_token')
        dispatch({ type: 'LOGOUT' })
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage section>{error}</ErrorMessage>}
            {message && (
                <MessageSection type="success">{message}</MessageSection>
            )}

            <form>
                <header>
                    <img
                        alt="logo"
                        srcSet="/img/agency-couturemulticolor-1.png 1x, /img/agency-couturemulticolor-1-sm.png 2x"
                        width="80"
                        height="98"
                    />
                    <h1>Register: Verify email</h1>
                    <FormSteps step={3} totalSteps={3} />
                    <p className="email">{state.user.email}</p>
                </header>
                <div>
                    <p>
                        An email has been sent to your address. Please visit the
                        link in the email, to verify your account.
                    </p>
                </div>
                <div>
                    <Button
                        text="Send verification email again"
                        onClick={onResend}
                        isLoading={actionLoading}
                    />
                </div>
            </form>
            <footer>
                <div>
                    <small>
                        Once you login you will have the opportunity to sign up
                        for drop-shipping, apply to be a vendor or sell like-new
                        consignment items in the store! You&apos;ll also be able
                        to see all in-store and online purchases as long as your
                        email is associated.
                    </small>
                    <br />
                    <br />
                </div>
                <div className="links">
                    <Button text="Logout" onClick={onLogout} link />
                </div>
            </footer>
        </SectionContainer>
    )
}

export default RegisterVerifyEmail
