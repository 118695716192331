import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
    },
    {
        key: 'resource',
        label: 'Resource',
        required: true,
        type: 'select',
        defaultValue: 'User',
        options: [
            { value: 'User', label: 'User' },
            { value: 'Lead', label: 'Contact' },
        ],
    },
    {
        key: 'key',
        label: 'Key',
        required: true,
    },
    {
        key: 'valueType',
        label: 'Value Type',
        required: true,
        type: 'select',
        defaultValue: 'text',
        options: [
            { value: 'text', label: 'Text' },
            { value: 'number', label: 'Number' },
            { value: 'file', label: 'File' },
            { value: 'date', label: 'Date' },
        ],
    },
    {
        key: 'scope',
        defaultValue: 'app',
        type: 'hidden',
    },
    {
        shouldHide: (data) => data.valueType !== 'file',
        key: 'isList',
        label: 'List',
        type: 'checkbox',
    },
]

export default function CreateAppMetafieldDefinition() {
    return (
        <SectionContainer>
            <p className="text-opaque">
                App metafield definitions are used to define the structure of
                metafields that are controlled by the app.
            </p>
            <DataFormNew
                url={`/v1/metafields/definitions`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/settings`}
                inputs={inputs}
                mutationRegexes={[/\/v1\/metafields\/definitions/]}
                mainButton
            />
        </SectionContainer>
    )
}
