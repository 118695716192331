import { useLocation, useNavigate } from 'react-router-dom'
import { ICONS } from '../../common/constants'

export default function PageNav({ prevId, nextId, nextContent }) {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className="page-nav">
            <div>
                <button
                    disabled={!prevId}
                    onClick={() =>
                        navigate(`/tasks/${prevId}`, {
                            state: {
                                prevSearch: location.state?.prevSearch,
                            },
                        })
                    }
                >
                    <img src={ICONS.CHEVRON_LEFT_WHITE} alt="Prev" />
                </button>
                <span className="next-content">{nextContent}</span>
                <button
                    disabled={!nextId}
                    onClick={() =>
                        navigate(`/tasks/${nextId}`, {
                            state: {
                                prevSearch: location.state?.prevSearch,
                            },
                        })
                    }
                >
                    <img
                        src={ICONS.CHEVRON_LEFT_WHITE}
                        style={{ transform: 'rotate(180deg)' }}
                        alt="Next"
                    />
                </button>
            </div>
        </div>
    )
}
