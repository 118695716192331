import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { cleanSlugInput, copyToClipboard } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import MessageSection from '../../../components/common/MessageSection'
import MainButton from '../../../components/admin/MainButton'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import Input from '../../../components/common/data-form/Input'
import LiveSearch from '../../../components/common/data-form/LiveSearch'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import SwitchInput from '../../../components/common/data-form/SwitchInput'

const initData = {
    slug: '',
    // categorySlug: '',
    title: '',
    content: '',
    isPublic: false,
    isPublished: false,
    isHtml: true,
    pageType: 'default',
    sassCode: '',
    tags: [],
    parentPageId: undefined,
}

export default function CreatePage() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState(initData)
    const setToast = useToast()
    const mutate = useMatchMutate()
    const [message, setMessage] = useState('')
    const [lastCreatedSlug, setLastCreatedSlug] = useState(null)

    function initializeAfterSave() {
        setData({
            ...initData,
            parentPageId: data.parentPageId,
            tags: data.tags,
            isPublished: data.isPublished,
        })
        window.scrollTo(0, 0)
    }

    async function onSubmit(e) {
        setError('')
        setMessage('')
        setLastCreatedSlug(null)
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        const body = { ...data }
        body.content = data.content

        setLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages`,
            body,
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setLastCreatedSlug(responseData.slug)
        setMessage(
            <>
                Created <Link to={`/pages/${responseData.slug}`}>new page</Link>
                . <Link to={`/edit-pages/${responseData.id}`}>Edit page.</Link>
            </>,
        )

        mutate(/\/v1\/pages/)

        initializeAfterSave()
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            {message && (
                <>
                    <MessageSection
                        type="success"
                        onDismiss={() => setMessage('')}
                    >
                        <div>{message}</div>
                        {lastCreatedSlug ? (
                            <Button
                                icon={ICONS.COPY_WHITE}
                                text="Copy URL"
                                outline
                                tiny
                                alignRight
                                white
                                onClick={() => {
                                    copyToClipboard(
                                        `${process.env.REACT_APP_PUBLIC_URL}/pages/${lastCreatedSlug}`,
                                    )
                                    setToast('Copied to clipboard')
                                }}
                            />
                        ) : null}
                    </MessageSection>
                    <Break />
                </>
            )}

            <form onSubmit={onSubmit} autoComplete="off">
                <label htmlFor="form-title">Title</label>
                <TextInput
                    id="form-title"
                    type="text"
                    value={data.title}
                    onChange={(v) =>
                        setData({
                            ...data,
                            title: v,
                            slug: cleanSlugInput(v),
                        })
                    }
                />

                {/* <CheckboxInput
                    value={data.isHtml}
                    id="form-is-html"
                    label="HTML"
                    onChange={(isHtml) => setData({ ...data, isHtml })}
                />
                <br /> */}

                {/* <label htmlFor="form-content">Content</label> */}
                {/* <TextInput
                    type="textarea"
                    rows={16}
                    id="form-content"
                    value={data.content}
                    onChange={(v) =>
                        setData({
                            ...data,
                            content: v,
                        })
                    }
                /> */}

                <Input
                    label={'Content'}
                    type="html"
                    id="form-content"
                    value={data.content}
                    onChange={(v) => setData({ ...data, content: v })}
                />

                <Button
                    text="Copy content"
                    type="button"
                    onClick={() => copyToClipboard(data.content)}
                    small
                    outline
                    icon={ICONS.COPY_ACTIVE}
                />

                <br />

                <label htmlFor="form-sass">Sass</label>
                <TextInput
                    type="textarea"
                    rows={16}
                    id="form-sass"
                    value={data.sassCode}
                    onChange={(v) =>
                        setData({
                            ...data,
                            sassCode: v,
                        })
                    }
                />

                <Button
                    text="Copy SASS"
                    type="button"
                    onClick={() => copyToClipboard(data.sassCode)}
                    small
                    outline
                    icon={ICONS.COPY_ACTIVE}
                />
                <br />

                {/* <label htmlFor="form-tags">Tags</label>
                <TextInput
                    type="textarea"
                    rows={3}
                    id="form-tags"
                    value={data.tags.join('\n')}
                    onChange={(v) =>
                        setData({
                            ...data,
                            tags: v ? v.split('\n') : [],
                        })
                    }
                /> */}

                <Input
                    id="form-tags"
                    type="liveSearchAddItems"
                    value={data.tags}
                    onChange={(items) =>
                        setData({
                            ...data,
                            tags: items,
                        })
                    }
                    label="Tags"
                    canAddNewItem
                    key={`tags`}
                    url="/v1/tags?resource=Page"
                    getItemText={(item) => item}
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => item,
                        },
                    ]}
                    infoBottom={'Tags are visible to all users.'}
                />

                <label htmlFor="form-slug">Slug</label>
                <TextInput
                    id="form-slug"
                    type="text"
                    required
                    value={data.slug}
                    onChange={(v) =>
                        setData({
                            ...data,
                            slug: cleanSlugInput(v),
                        })
                    }
                    pattern="^[a-z0-9]+(?:-[a-z0-9]+)*$"
                />

                <label htmlFor="form-parent-page">Parent page</label>
                <LiveSearch
                    id="form-parent-page"
                    getResultValue={(result) => result.title}
                    url="/v1/pages?pageType=glossary"
                    getItemsFromResults={(data) => data || []}
                    onItemClick={(item) => {
                        setData({
                            ...data,
                            parentPageId: item?.id,
                        })
                    }}
                />
                <br />

                <label htmlFor="form-is-published">Status</label>
                <SwitchInput
                    value={data.isPublished ? 'true' : 'false'}
                    id="form-is-published"
                    label="Publish page"
                    onChange={(isPublished) =>
                        setData({
                            ...data,
                            isPublished: isPublished === 'true',
                        })
                    }
                    options={[
                        {
                            value: 'true',
                            label: 'Publish',
                        },
                        {
                            value: 'false',
                            label: 'Draft',
                        },
                    ]}
                />

                <CheckboxInput
                    value={data.isPublic}
                    id="is-public"
                    label="Public page"
                    onChange={(isPublic) => setData({ ...data, isPublic })}
                    info={
                        'Public pages are accessible to anyone, even if they are not logged in.'
                    }
                />
                <br />

                <CheckboxInput
                    value={data.showOnClientMenu}
                    id="client-menu"
                    label="Show on client Menu"
                    onChange={(showOnClientMenu) =>
                        setData({ ...data, showOnClientMenu })
                    }
                />
                <br />

                <CheckboxInput
                    value={data.showOnTeamMenu}
                    id="team-menu"
                    label="Show on team Menu"
                    onChange={(showOnTeamMenu) =>
                        setData({ ...data, showOnTeamMenu })
                    }
                />
                <br />

                <CheckboxInput
                    value={data.pageType === 'glossary'}
                    id="page-type"
                    label="Group with alphabet"
                    info="Assign pages to it, and they will appear in glossary format in it."
                    onChange={(v) =>
                        setData({
                            ...data,
                            pageType: v ? 'glossary' : 'default',
                        })
                    }
                />
                <br />

                <div>
                    <Button text="Save" type="submit" isLoading={loading} />
                </div>

                <MainButton
                    disabled={loading}
                    functionality="SAVE"
                    loading={loading}
                />
            </form>
        </SectionContainer>
    )
}
