import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import useData from '../../../common/hooks/useData'
import { formatDateTime, formatMoney } from '../../../common/helpers'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'

function OrderPage() {
    const { dbOrderId } = useParams()

    const { order, orderError, orderLoading } = useData(
        `/v1/wholesale/db-orders/${dbOrderId}`,
        'order',
    )

    if (orderLoading) return <Spinner marginTop />
    if (orderError) {
        return (
            <SectionContainer>
                <ErrorMessage>{orderError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header">
                    <h2>#{order.orderNumber}</h2>
                </header>
            </SectionContainer>
            <SectionContainer className="info-details">
                <div>
                    {order.fulfillmentStatus ? (
                        <Tag outline>{order.fulfillmentStatus}</Tag>
                    ) : (
                        ''
                    )}
                    {order.financialStatus ? (
                        <Tag outline>{order.financialStatus}</Tag>
                    ) : (
                        ''
                    )}
                    {order.cancelDate ? (
                        <Tag color={'alert'}>Cancelled</Tag>
                    ) : (
                        ''
                    )}
                </div>
                <div>
                    Shop:&nbsp;
                    {order.shopName}
                </div>
                <div>
                    Tracking:&nbsp;
                    {order.fulfillments
                        .map((item) => item.trackingNumber)
                        .join(', ') || 'No tracking numbers'}
                </div>
                <div>
                    Paid with:&nbsp;
                    {order.gateway || '-'}
                </div>
                <div>
                    Shipping:&nbsp;
                    {formatMoney(order.shippingLinePrice, false, 'shopify')}
                    {order.totalRefundedShipping
                        ? ` (refunded: ${formatMoney(
                              order.totalRefundedShipping,
                              false,
                              'shopify',
                          )})`
                        : null}
                </div>
                <div>
                    Transaction fees:&nbsp;
                    {formatMoney(order.totalTransactionFees, false, 'shopify')}
                </div>
            </SectionContainer>
            <SectionContainer card>
                <h2>Line Items</h2>
                <div>
                    <ResourceList
                        title=""
                        items={order.lineItems}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <div>
                                        <h3>{item.name}</h3>
                                        <p>
                                            <strong>Quantity:</strong>&nbsp;
                                            {item.quantity}
                                        </p>
                                        <p>
                                            <strong>Fulfillable:</strong>
                                            &nbsp;
                                            {item.fulfillableQuantity}
                                        </p>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </SectionContainer>
            <SectionContainer card>
                <h2>Fulfillments</h2>

                <div>
                    <ResourceList
                        title=""
                        items={order.fulfillments}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <div>
                                        <h3>{item.name}</h3>
                                        <p>
                                            Tracking #:&nbsp;
                                            {item.trackingNumber || '(None)'}
                                            &nbsp;/&nbsp;Updated&nbsp;
                                            {formatDateTime(
                                                new Date(item.updateDate),
                                            )}
                                        </p>
                                        <p>
                                            <Tag outline>{item.status}</Tag>
                                        </p>
                                        <ol>
                                            {item.lineItems.map((line) => (
                                                <li key={line.id}>
                                                    {line.name} / {line.vendor}{' '}
                                                    / ${line.price}
                                                </li>
                                            ))}
                                        </ol>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </SectionContainer>
            <SectionContainer card>
                <h2>Fulfillment Requests</h2>
                <div>
                    <ul>
                        {order?.extras?.requestedFulfillmentLines?.map(
                            (item) => {
                                const line = order.lineItems.find(
                                    (line) =>
                                        line.lineItemId === item.lineItemId,
                                )
                                return (
                                    <li key={item.lineItemId}>
                                        {item.quantity}&nbsp;x&nbsp;
                                        {line?.name}
                                        &nbsp;({line?.vendor})
                                    </li>
                                )
                            },
                        )}
                    </ul>
                </div>
            </SectionContainer>

            {order.note && (
                <SectionContainer card>
                    <h2>Notes</h2>
                    <div>{order.note}</div>
                </SectionContainer>
            )}
        </>
    )
}

export default OrderPage
