import { useParams } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useData from '../../../common/hooks/useData'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

function CreatePhone() {
    const { userId, contactId } = useParams()
    const isOwnProfile = !userId && !contactId

    let url
    if (isOwnProfile) {
        url = `/v1/users/me?fields=firstName&populate=contactDetails`
    } else if (userId) {
        url = `/v1/users/${userId}?fields=firstName&populate=contactDetails`
    } else {
        url = `/v1/contacts/${contactId}?fields=firstName&populate=contactDetails`
    }

    const { user, userError, userLoading } = useData(url, 'user')

    if (userLoading) {
        return <Spinner />
    }

    if (userError) {
        return <ErrorMessage section>{userError}</ErrorMessage>
    }

    if (!user?.contactDetails) {
        return (
            <ErrorMessage section>
                There was an unexpected error, please contact Support.
            </ErrorMessage>
        )
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/contact-details/${user.contactDetails.id} `}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={
                    isOwnProfile
                        ? '/profile/contact-details'
                        : `/${contactId ? 'contacts' : 'users'}/${userId || contactId}/contact-details`
                }
                inputs={[
                    {
                        type: 'tel',
                        key: 'phone',
                        required: true,
                    },
                    {
                        type: 'hidden',
                        key: 'phoneType',
                        defaultValue: user.contactDetails.phones.some(
                            (p) => p.phoneType === 'main',
                        )
                            ? 'other'
                            : 'main',
                        value: 'other',
                    },
                ]}
                getBody={(data, item) => ({
                    phones: [
                        ...item.phones.map((p) => ({
                            ...p,
                            _id: undefined,
                        })),
                        data,
                    ],
                })}
                mutationRegexes={[
                    /\/v1\/users*/,
                    /\/v1\/users\/me/,
                    /\/v1\/contacts/,
                ]}
                mainButton
            />
        </SectionContainer>
    )
}

export default CreatePhone
