import ResourceList from '../../../components/admin/ResourceList'
import { formatMoney } from '../../../common/helpers'

function ProductsBillingList(props) {
    const tableColumns = [
        {
            title: 'Name',
            sumTitle: 'Subtotal',
        },
        {
            title: 'Gross',
            // subtitle: 'Incl. taxes',
            getSum: (items) =>
                formatMoney(
                    items.map((item) => item.price).reduce((a, b) => a + b, 0),
                    false,
                    'shopify',
                ),
            // subtitle: 'Incl. shipping + taxes',
        },
        {
            title: 'Cost',
            // subtitle: 'Incl. taxes',
            getSum: (items) =>
                formatMoney(
                    items.map((item) => item.cost).reduce((a, b) => a + b, 0),
                    false,
                    'shopify',
                ),
            // subtitle: 'Incl. shipping + taxes',
        },
        {
            title: 'Tax. + Ship. - Returns - Discount',
            getSum: (items) =>
                formatMoney(
                    items
                        .map(
                            (item) =>
                                item.taxes +
                                item.shipping -
                                item.returns -
                                item.discount,
                        )
                        .reduce((a, b) => a + b, 0),
                    false,
                    'shopify',
                ),
            // title: 'Cost',
        },
        {
            title: 'Net sales',
            // subtitle: 'Incl. taxes',
            getSum: (items) =>
                formatMoney(
                    items
                        .map(
                            (item) =>
                                item.price +
                                item.taxes +
                                item.shipping -
                                item.returns -
                                item.discount,
                        )
                        .reduce((a, b) => a + b, 0),
                    false,
                    'shopify',
                ),
            // subtitle: 'Incl. shipping + taxes',
        },
    ]

    return (
        <ResourceList
            items={props.billing}
            itemsLoading={props.billingLoading}
            isTable
            tableColumns={tableColumns}
            // title={
            //     typeof props.title === 'undefined' ? 'Products' : props.title
            // }
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <span>
                                {item.extras.name}
                                <br />
                                <span className="subtitle">
                                    {item.extras.shop}
                                </span>
                            </span>
                            <span>
                                {formatMoney(item.price, false, 'shopify')}
                            </span>
                            <span>
                                {formatMoney(item.cost, false, 'shopify')}
                            </span>
                            <span>
                                {formatMoney(
                                    item.taxes +
                                        item.shipping -
                                        item.returns -
                                        item.discount,
                                    false,
                                    'shopify',
                                )}
                            </span>
                            <span>
                                {formatMoney(
                                    item.price +
                                        item.taxes +
                                        item.shipping -
                                        item.returns -
                                        item.discount,
                                    false,
                                    'shopify',
                                )}
                            </span>
                        </>
                    ),
                    getClassName: () => 'table-row',
                },
            ]}
        />
    )
}

export default ProductsBillingList
