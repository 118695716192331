import { useEffect, useState } from 'react'
import Tag from '../Tag'
import Button from '../Button'
import LiveSearch from './LiveSearch'

export default function LiveSearchAddItemsInput({
    value,
    onChange,
    defaultValue,
    url,
    items,
    getItemText,
    canAddNewItem,
    getItemsFromResults,
    ...rest
}) {
    const [currQuery, setCurrQuery] = useState('')
    const [inputKey, setInputKey] = useState(Date.now())

    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || [])
        }
    }, [])

    return (
        <div
            className={`live-search-add-items-input${!canAddNewItem ? ' live-search-add-items-input-no-add' : ''}`}
        >
            <LiveSearch
                key={inputKey}
                showAll={false}
                getResultValue={(result) => getItemText(result)}
                url={url}
                items={items}
                getItemsFromResults={
                    getItemsFromResults || ((data) => data || [])
                }
                limit={5}
                onItemClick={(item) => {
                    setCurrQuery(getItemText(item))
                    onChange(Array.from(new Set([...value, item])))
                    setInputKey(Date.now())
                }}
                onChange={canAddNewItem ? (q) => setCurrQuery(q) : undefined}
                {...rest}
            />
            {canAddNewItem && (
                <Button
                    outline
                    link
                    dark
                    type="button"
                    small
                    className="button"
                    onClick={() => {
                        if (currQuery) {
                            onChange(Array.from(new Set([...value, currQuery])))
                            setInputKey(Date.now())
                            setCurrQuery('')
                        }
                    }}
                    text="Add +"
                />
            )}
            <div className="live-search-add-items-input-added-items">
                {value?.map((item, i) => (
                    <Tag
                        key={i}
                        color="primary"
                        onClose={() => {
                            onChange(value.filter((v) => v !== item))
                        }}
                    >
                        {getItemText(item)}
                    </Tag>
                ))}
            </div>
        </div>
    )
}
