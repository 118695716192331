import { useState } from 'react'
import Button from './Button'
import SelectInput from './data-form/SelectInput'

function SortModalContent({ onSubmit, filters, currSort }) {
    const [data, setData] = useState({
        sortKey: currSort.key,
    })

    return (
        <div className="sort-popover-content">
            <div className=" ">
                <div className=" ">
                    <label htmlFor="form-sort-popover">Sort</label>
                    <SelectInput
                        id="form-sort-popover"
                        value={data.sortKey}
                        onChange={(v) => setData({ ...data, sortKey: v })}
                        options={Object.entries(filters?.sort || {}).map(
                            ([k, v]) => ({
                                value: k,
                                label: v.label,
                            }),
                        )}
                    />
                </div>
            </div>
            <div className=" ">
                <Button
                    fullWidth
                    small
                    onClick={() => onSubmit(data)}
                    text={'Submit'}
                />
            </div>
        </div>
    )
}

export default SortModalContent
