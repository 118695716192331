// import { formatMoney } from '../../../common/helpers'
// import useData from '../../../common/hooks/useData'

import ErrorMessage from '../../../components/common/ErrorMessage'

function AcceptCostRequestTaskDetails(_props) {
    return <ErrorMessage section>Under construction</ErrorMessage>

    // const { taskMeta } = props

    // const { product, productError, productLoading } = useData(
    //     `/v1/wholesale/products-graphql/${taskMeta.productId}`,
    //     'product',
    // )

    // if (productLoading) return <div className="spinner"></div>

    // if (productError) {
    //     return <ErrorMessage section>{productError}</ErrorMessage>
    // }

    // const variants = JSON.parse(taskMeta.variants)

    // const displayItemsHtml = variants.map((variant) => {
    //     const { variantId, cost } = variant
    //     const variantInProduct = product.variants.edges.find(
    //         (edge) => edge.node.legacyResourceId === variantId,
    //     )?.node
    //     if (!variantInProduct) {
    //         return <div key={variantId}>Variant {variantId} not found</div>
    //     }
    //     const currentCost = variantInProduct.inventoryItem.unitCost?.amount
    //     let currentCostText
    //     if (!currentCost && Number(currentCost) !== 0) {
    //         currentCostText = 'None'
    //     } else {
    //         currentCostText = formatMoney(currentCost, false, 'shopify')
    //     }
    //     return (
    //         <div key={variantId}>
    //             <div>
    //                 {variantInProduct.title?.toLowerCase() === 'default title'
    //                     ? ''
    //                     : variantInProduct.title}
    //             </div>
    //             <div>
    //                 Product price:{' '}
    //                 {formatMoney(variantInProduct.price, false, 'shopify')}
    //             </div>
    //             <div>Current cost: {currentCostText}</div>
    //             <div
    //                 className={
    //                     Number(variantInProduct.price) < Number(cost)
    //                         ? 'alert'
    //                         : ''
    //                 }
    //             >
    //                 New cost: {formatMoney(cost, false, 'shopify')}
    //             </div>
    //         </div>
    //     )
    // })

    // return (
    //     <div>
    //         <div>
    //             <strong>{product.title}</strong>
    //         </div>
    //         <div>{displayItemsHtml}</div>
    //     </div>
    // )
}

export default AcceptCostRequestTaskDetails
