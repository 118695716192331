import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import Break from '../../components/common/Break'
import Button from '../../components/common/Button'
import MessageSection from '../../components/common/MessageSection'

export default function Notes({
    id,
    role,
    externalNotes,
    internalNotes,
    notes,
}) {
    const auth = useAuth()
    return (
        <>
            {auth.isAdmin && (
                <>
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Everyone can see and edit external notes. Only admins, assistants and client managers can see and edit internal notes. Everyone can see and edit contact notes."
                    ></MessageSection>
                    <Break />
                </>
            )}

            {role === 'user' || role === 'staff' ? (
                <>
                    <h3 className="h6 text-subdued">External notes</h3>
                    <div>
                        <pre className="condensed">{externalNotes || '-'}</pre>
                    </div>

                    <h3 className="h6 text-subdued">Internal notes</h3>
                    <div>
                        <pre className="condensed">{internalNotes || '-'}</pre>
                    </div>

                    <Button
                        outline
                        small
                        alignRight
                        text="Edit notes"
                        icon={ICONS.EDIT_ACTIVE}
                        href={`/users/${id}/notes/edit`}
                    />
                    <br />
                </>
            ) : (
                <pre className="condensed">{notes || '-'}</pre>
            )}
        </>
    )
}
