import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Toggle from '../../../components/common/Toggle'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import TextInput from '../../../components/common/data-form/TextInput'

function GoogleDriveSettings() {
    const [driveImpersonateEmail, setDriveImpersonateEmail] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const { emailSettingLoading, emailSettingError, emailSettingMutate } =
        useData(
            '/v1/settings/driveImpersonateEmail',
            'emailSetting',
            (data) => data?.settingValue || '',
            false,
            false,
            (data) => {
                setDriveImpersonateEmail(data?.settingValue || '')
            },
        )

    const {
        mainAppSharedDriveId,
        mainAppSharedDriveIdLoading,
        mainAppSharedDriveIdError,
    } = useData(
        '/v1/settings/mainAppSharedDriveId',
        'mainAppSharedDriveId',
        (data) => data?.settingValue || '',
    )

    const { mainAppDriveName, mainAppDriveNameLoading } = useData(
        mainAppSharedDriveId
            ? `/v1/files/drives/${mainAppSharedDriveId}`
            : null,
        'mainAppDriveName',
        (data) => data?.name,
    )

    const { mainAppDriveCheckError, mainAppDriveCheckLoading } = useData(
        mainAppSharedDriveId ? `/v1/files/check-main-app-drive` : null,
        'mainAppDriveCheck',
    )

    async function onSaveDriveAccountEmail(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/driveImpersonateEmail`,
            { settingValue: driveImpersonateEmail || '' },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error)
        }
        setToast('Saved')
        emailSettingMutate()
    }

    async function onDriveSearch(e) {
        e.preventDefault()
        if (!searchQuery) {
            setSearchResults([])
            return
        }
        setSearchLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/files/drives/?name=${searchQuery}`,
            {},
            'GET',
        )
        setSearchLoading(false)
        if (error) return setError(error)
        setSearchResults(responseData)
    }

    async function onPickDrive(id) {
        setError('')

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    setLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/settings/mainAppSharedDriveId`,
                        { settingValue: id },
                        'PATCH',
                    )
                    setModalLoading(false)
                    setLoading(false)

                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                }}
            />,
            'Are you sure you want to change the main shared drive?',
        )
    }

    if (
        emailSettingLoading ||
        mainAppSharedDriveIdLoading ||
        mainAppDriveNameLoading
    ) {
        return <Spinner />
    }

    if (emailSettingError || mainAppSharedDriveIdError) {
        return (
            <ErrorMessage section>
                {emailSettingError || mainAppSharedDriveIdError}
            </ErrorMessage>
        )
    }

    return (
        <>
            {error && <ErrorMessage section>{error}</ErrorMessage>}
            <h2>Service Account</h2>
            <form>
                <label htmlFor="form-drive-account">
                    Email that will be used on Drive actions
                </label>
                <TextInput
                    type="email"
                    name="drive_account"
                    id="form-drive-account"
                    placeholder="admin@aco.digital"
                    value={driveImpersonateEmail || ''}
                    onChange={(v) => setDriveImpersonateEmail(v)}
                />
                <Button
                    onClick={onSaveDriveAccountEmail}
                    isLoading={loading}
                    text={'Save email'}
                    small
                    alignRight
                />
            </form>
            <br />
            <h2>Main Shared Drive</h2>
            {mainAppDriveCheckError && (
                <ErrorMessage section>{mainAppDriveCheckError}</ErrorMessage>
            )}
            <span>Current App Drive:&nbsp;</span>
            {/* Current Main drive name + status */}
            <strong>
                {mainAppDriveNameLoading ? 'Loading...' : mainAppDriveName}
            </strong>
            &nbsp;&nbsp;
            {!mainAppDriveCheckLoading && (
                <Tag color={mainAppDriveCheckError ? 'alert' : 'success'}>
                    {mainAppDriveCheckError ? 'Disconnected' : 'Connected'}
                </Tag>
            )}
            {/* Change Drive toggle */}
            <Toggle title="Drive selection">
                <TextInput
                    type="text"
                    placeholder="Search by name"
                    onChange={(v) => setSearchQuery(v)}
                    value={searchQuery}
                />

                <Button
                    onClick={onDriveSearch}
                    isLoading={searchLoading}
                    text="Search"
                    small
                    alignRight
                />

                <ul>
                    {searchResults.map((drive) => (
                        <li
                            key={drive.id}
                            onClick={() => onPickDrive(drive.id, drive.name)}
                        >
                            {drive.name}
                        </li>
                    ))}
                </ul>
            </Toggle>
            <br />
        </>
    )
}

export default GoogleDriveSettings
