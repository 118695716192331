import useHashNav from '../../common/hooks/useHashNav'
import Toggle from './Toggle'

function toText(title) {
    const elem = document.createElement('div')
    elem.innerHTML = title
    const text = elem.textContent || elem.innerText || ''
    elem.remove()
    return text
}

export default function ToC({ titles }) {
    const [hash, setHash] = useHashNav()

    function handleClick() {
        // Reverse the hash to the previous one (title)
        // after scroll
        const currHash = hash
        setTimeout(function () {
            setHash(currHash)
        }, 0)
    }

    return (
        <div className={`toc`}>
            <Toggle small title="Table of contents">
                <ul>
                    {titles.map((title) => (
                        <li key={title.id}>
                            <a
                                role="button"
                                onClick={(e) => handleClick(e, title)}
                                href={`#${title.id}`}
                            >
                                {toText(title.title)}
                            </a>
                        </li>
                    ))}
                </ul>
            </Toggle>
        </div>
    )
}
