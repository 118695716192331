// .acronym ok
// import React, { useState } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'
// import useSWR from 'swr'
// import fetchAPI from '../../../common/fetchAPI'
// import useToast from '../../../common/hooks/useToast'
// import useError from '../../../common/hooks/useError'
// import swrFetcher from '../../../common/swrFetcher'
// import Button from '../../../components/common/Button'
// import useData from '../../../common/hooks/useData'
// import { getUserDisplayName } from '../../../common/helpers'

function AssignNewDrive() {
    return 'Under construction'
    // const { userId } = useParams()

    // const { user, userError, userLoading, userMutate } = useData(
    //     `/v1/users/${userId}?fields=email,firstName,lastName,drives,company,acronym`,
    //     'user',
    // )

    // const designUrl = user?.acronym
    //     ? `/v1/files/drives?name=${`${user.acronym} - DESIGN`}&exact=true`
    //     : null
    // const { data: designDrivesByName, error: designDrivesError } = useSWR(
    //     designUrl,
    //     swrFetcher,
    // )

    // const mainUrl =
    //     user?.acronym && user?.company
    //         ? `/v1/files/drives?name=${`${user.acronym} - ${user.company}`}&exact=true`
    //         : null
    // const { data: mainDrivesByName, error: mainDrivesError } = useSWR(
    //     mainUrl,
    //     swrFetcher,
    // )

    // const loading =
    //     (user?.acronym && !designDrivesByName && !designDrivesError) ||
    //     (user?.acronym && !mainDrivesByName && !mainDrivesError) ||
    //     userLoading

    // const [actionLoading, setActionLoading] = useState(false)
    // const [showSearch, setShowSearch] = useState(false)
    // const [searchQuery, setSearchQuery] = useState('')
    // const [searchLoading, setSearchLoading] = useState(false)
    // const [searchResults, setSearchResults] = useState([])
    // const [error, setError] = useError()
    // const setToast = useToast()

    // const navigate = useNavigate()

    // const onCreateDesignDrive = async () => {
    //     setError('')
    //     if (designDrivesByName?.length) {
    //         setToast(
    //             'There is already a design Drive with the company acronym',
    //             'alert',
    //         )
    //         return
    //     }
    //     if (!user.acronym) {
    //         setToast('Please add a client acronym first', 'alert')
    //         return
    //     }
    //     const driveName = `${user.acronym} - DESIGN`

    //     setActionLoading(true)
    //     const { error } = await fetchAPI(
    //         `/v1/files/drives`,
    //         { name: driveName },
    //         'POST',
    //     )
    //     setActionLoading(false)
    //     if (error) return setError(error)

    //     setToast(`Created drive "${driveName}"`)
    //     navigate(`/users/${user.id}/settings`)
    // }

    // const onCreateMainDrive = async () => {
    //     setError('')
    //     if (mainDrivesByName?.length) {
    //         setToast(
    //             'There is already a main Drive with the company acronym and name',
    //             'alert',
    //         )
    //         return
    //     }
    //     if (!user.acronym) {
    //         setToast('Please add a client acronym first', 'alert')
    //         return
    //     }
    //     if (!user.company) {
    //         setToast('Please add a client company name first', 'alert')
    //         return
    //     }
    //     const driveName = `${user.acronym} - ${user.company}`

    //     setActionLoading(true)
    //     const { error } = await fetchAPI(
    //         `/v1/files/drives`,
    //         { name: driveName },
    //         'POST',
    //     )
    //     setActionLoading(false)
    //     if (error) return setError(error)

    //     setToast(`Created drive "${driveName}"`)
    //     navigate(`/users/${user.id}/settings`)
    // }

    // const onSearch = async (e) => {
    //     e.preventDefault()
    //     if (!searchQuery) {
    //         setSearchResults([])
    //         return
    //     }
    //     setSearchLoading(true)
    //     const { responseData, error } = await fetchAPI(
    //         `/v1/files/drives/?name=${searchQuery}`,
    //         {},
    //         'GET',
    //     )
    //     setSearchLoading(false)
    //     if (error) return setError(error)
    //     setSearchResults(responseData)
    // }

    // const onPickDrive = async (id, name) => {
    //     setError('')
    //     const { responseData, error } = await fetchAPI(
    //         `/v1/users/${user.id}`,
    //         { drives: [...user.drives, id] },
    //         'PATCH',
    //     )
    //     if (error) {
    //         setError(error)
    //         return
    //     }
    //     userMutate({ ...user, ...responseData })
    //     setToast(`Assigned drive "${name}"`)
    //     navigate(`/users/${user.id}/settings`)
    // }

    // if (loading) return <div className="spinner"></div>
    // if (userError || designDrivesError) {
    //     return (
    //         <section className="error alert">
    //             {userError || designDrivesError}
    //         </section>
    //     )
    // }

    // return (
    //     <>
    //         {error && <ErrorMessage section>{error}</ErrorMessage>}

    //         <section>
    //             <h2>{getUserDisplayName(user)}</h2>

    //             <Button
    //                 onClick={onCreateDesignDrive}
    //                 isLoading={actionLoading}
    //                 text="Create and assign new Design Shared Drive"
    //             />

    //             <Button
    //                 onClick={onCreateMainDrive}
    //                 isLoading={actionLoading}
    //                 text="Create and assign new Main Shared Drive"
    //             />
    //         </section>

    //         <section>
    //             <Button
    //                 onClick={setShowSearch}
    //                 isLoading={actionLoading}
    //                 text="Assign extra Shared Drive"
    //             />
    //             {showSearch && (
    //                 <>
    //                     <input
    //                         type="text"
    //                         placeholder="Search by name"
    //                         onChange={(e) => setSearchQuery(e.target.value)}
    //                         value={searchQuery}
    //                     />

    //                     <Button
    //                         onClick={onSearch}
    //                         isLoading={searchLoading}
    //                         text="Search"
    //                     />

    //                     <ul>
    //                         {searchResults.map((drive) => (
    //                             <li
    //                                 key={drive.id}
    //                                 onClick={() =>
    //                                     onPickDrive(drive.id, drive.name)
    //                                 }
    //                             >
    //                                 {drive.name}
    //                             </li>
    //                         ))}
    //                     </ul>
    //                 </>
    //             )}
    //         </section>
    //     </>
    // )
}

export default AssignNewDrive
