import React, { useState } from 'react'
import { useStore } from '../../common/Context'
import fetchAPI from '../../common/fetchAPI'
import { ICONS } from '../../common/constants'
import useError from '../../common/hooks/useError'
import useToast from '../../common/hooks/useToast'
import Button from '../../components/common/Button'
import useAuth from '../../common/hooks/useAuth'
import Toggle from '../../components/common/Toggle'
import LateFeeSettings from './late-fees/LateFeeSettings'
import GMailSettings from './gmail/GMailSettings'
import TeamSettings from './users/TeamSettings'
import UserLabelSettings from './users/UserLabelSettings'
import GoogleDriveSettings from './drive/GoogleDriveSettings'
import SocialPostHashtagSettings from './social-posting/SocialPostHashtagSettings'
import UISettings from './misc/UISettings'
import authStorage from '../../common/authStorage'
import ErrorMessage from '../../components/common/ErrorMessage'
import useModal from '../../common/hooks/useModal'
import InputModalContent from '../../components/common/InputModalContent'
import useData from '../../common/hooks/useData'
import Spinner from '../../components/common/Spinner'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import SectionContainer from '../../components/common/SectionContainer'
import Break from '../../components/common/Break'
import AppMetafieldDefinitionsList from './metafields/AppMetafieldDefinitionsList'
import NofitSettings from './misc/NofitSettings'

function Settings() {
    const [state, dispatch] = useStore()
    const [error, setError] = useError()
    const setToast = useToast()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const matchMutate = useMatchMutate()
    const [clearCacheLoading, setClearCacheLoading] = useState(false)
    const [pinResetLoading, setPinResetLoading] = useState(false)
    const [taskOrderHealthLoading, setTaskOrderHealthLoading] = useState(false)

    const { hasPin, hasPinLoading, hasPinError, hasPinMutate } = useData(
        '/v1/users/me/has-pin',
        'hasPin',
        (data) => data?.hasPin,
    )

    async function onCheckTaskOrderHealth() {
        setTaskOrderHealthLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/tasks/order-health`,
            {},
            'GET',
        )
        setTaskOrderHealthLoading(false)
        if (error) return setError(error)
        setToast('Cleared cache')
        if (responseData.success) {
            setToast('Task order is valid for global and user order')
        } else if (responseData.error) {
            setToast(responseData.error, 'alert')
        }
    }

    async function onClearBillingCache() {
        setClearCacheLoading(true)
        const { error } = await fetchAPI(`/v1/payment/clear-cache`, {}, 'POST')
        setClearCacheLoading(false)
        if (error) return setError(error)
        matchMutate(/\/v1\/payment\/billing-overview/)
        setToast('Cleared cache')
    }

    async function onPinReset() {
        setPinResetLoading(true)
        const { error } = await fetchAPI(`/v1/auth/forgot-pin`, {}, 'POST')
        setPinResetLoading(false)
        if (error) return setError(error)
        setToast('An email has been sent to you with a link to reset your PIN')
    }

    async function onPinSet() {
        setModal(
            <InputModalContent
                label="PIN (4 numbers)"
                type="password"
                confirmText="Save"
                onConfirm={async (data) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/users/me`
                    const { error } = await fetchAPI(
                        url,
                        {
                            pin: data.text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                    hasPinMutate()
                }}
            />,
            'Create a PIN',
        )
    }

    async function onLogout() {
        authStorage.removeItem('access_token')
        authStorage.removeItem('refresh_token')
        dispatch({ type: 'LOGOUT' })
        window.location.reload()
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {/* Wholesale store */}
            {state.user.role === 'admin' && (
                <Toggle title="Shops">
                    {/* <Button
                            text="Manage app shops"
                            href="/shop#app_shops__"
                            icon={ICONS.LINK_WHITE}
                        /> */}
                    <Button
                        text="Manage product tags"
                        href="/settings/shopify-product-tags"
                        icon={ICONS.LINK_WHITE}
                    />
                    <Button
                        text="Manage custom locations"
                        href="/settings/custom-locations"
                        icon={ICONS.LINK_WHITE}
                    />

                    {/* <ShopifyProductTagsSetting /> */}
                </Toggle>
            )}

            {/* Late fees */}
            {state.user.role === 'admin' && (
                <Toggle title="Late fees">
                    <LateFeeSettings />
                </Toggle>
            )}

            {/* Google emails */}
            {state.user.role === 'admin' && (
                <Toggle title="GMail API">
                    <GMailSettings />
                </Toggle>
            )}

            {/* Google Drive */}
            {state.user.role === 'admin' && (
                <Toggle title="Drive API">
                    <GoogleDriveSettings />
                </Toggle>
            )}

            {state.user.role === 'admin' && (
                <Toggle title="Social">
                    <SocialPostHashtagSettings />
                </Toggle>
            )}

            {/* Timezone */}

            <Toggle title="User settings">
                {/* <TimezoneSetting /> */}
                <UISettings />
                <NofitSettings />
            </Toggle>

            {/* Access */}

            <Toggle title="Access">
                <h3 className="m-0">PIN</h3>
                <p>
                    Set a PIN to access the Vault and other secure app features.
                </p>

                {hasPinError && <ErrorMessage>{hasPinError}</ErrorMessage>}
                {hasPinLoading && <Spinner />}
                {!hasPinError && !hasPinLoading && (
                    <Button
                        outline
                        small
                        alignRight
                        text={hasPin ? 'Reset PIN' : 'Set PIN'}
                        onClick={hasPin ? onPinReset : onPinSet}
                        isLoading={pinResetLoading}
                    ></Button>
                )}
            </Toggle>

            {/* Team roles / Job types */}
            {state.user.role === 'admin' && (
                <Toggle title="Team definitions">
                    <TeamSettings />
                </Toggle>
            )}

            {/* User labels */}
            {state.user.role === 'admin' && (
                <Toggle title="Users">
                    <UserLabelSettings />
                </Toggle>
            )}

            {auth.isAdmin && (
                <Toggle title="Metafields">
                    <AppMetafieldDefinitionsList />
                </Toggle>
            )}

            {/* Misc */}
            {(auth.isCollections || auth.isAdmin) && (
                <Toggle title="Misc.">
                    <Break />
                    <Button
                        outline
                        text="Menu tiles"
                        href={'/settings/menu-tiles'}
                    ></Button>
                    <Button
                        outline
                        text="Check Task order health"
                        onClick={onCheckTaskOrderHealth}
                        isLoading={taskOrderHealthLoading}
                    ></Button>
                    <Button
                        outline
                        text="Clear billing cache"
                        onClick={onClearBillingCache}
                        isLoading={clearCacheLoading}
                    ></Button>

                    {auth.isAdmin && (
                        <Button
                            href={'/service-categories'}
                            outline
                            text="Set Service Categories"
                        ></Button>
                    )}
                    {auth.isAdmin && (
                        <Button
                            href={'/settings/tos/edit'}
                            outline
                            text="Edit Terms of Service"
                        ></Button>
                    )}
                    {auth.isAdmin && (
                        <Button
                            href={'/translations'}
                            outline
                            text="Translations"
                        ></Button>
                    )}
                    {auth.isAdmin && (
                        <Button
                            href={'/settings/config'}
                            outline
                            text="Config object"
                        ></Button>
                    )}
                    <br />
                </Toggle>
            )}

            {/* Logout */}
            <Break />
            <Button onClick={onLogout} text={'Logout'} />
        </SectionContainer>
    )
}

export default Settings
