import { useEffect, useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import ButtonGroup from '../../../components/common/ButtonGroup'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useAuth from '../../../common/hooks/useAuth'
import MessageSection from '../../../components/common/MessageSection'
import { useParams } from 'react-router-dom'
import ImportProductItem from './ImportProductItem'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useData from '../../../common/hooks/useData'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ImportProducts() {
    const { shopifyShopId } = useParams()
    const setToast = useToast()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const [loading, setLoading] = useState(false)
    const [importLoading, setImportLoading] = useState(false)
    const [error, setError] = useState(false)
    const [selectedItemIds, setSelectedItemIds] = useState([])
    const [imports, setImports] = useState([])

    const { shop, shopError, shopLoading } = useData(
        `/v1/shop/shops/${shopifyShopId}?fields=ownerId`,
        'shop',
    )

    const org = auth.user.orgsData.find((o) => o.id === shop?.ownerId)

    useEffect(() => {
        getImports()
    }, [])

    const isAppShop = !shopifyShopId

    async function getImports() {
        setError(null)
        setLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/shop/products/sync/preview${
                !isAppShop ? `?ownShopId=${shopifyShopId}` : ''
            }`,
        )
        setLoading(false)
        if (error) {
            setError(error)
            return
        }
        setImports(responseData)
        setSelectedItemIds([])
    }

    function handleImportAll() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const items = imports.map((item) => ({
                        id: item.id,
                        shopId: item.shopId,
                    }))

                    setSelectedItemIds([])
                    setImportLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/shop/products/sync`,
                        {
                            groups: items.map((item) => [item]),
                            ownerId: org.id,
                        },
                        'POST',
                    )
                    setImportLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)

                    setToast(`Imported ${items.length} items`)
                    await getImports()
                }}
            />,
            'Are you sure you want to import all items?',
        )
    }

    async function handleImportGroup() {
        const ids = selectedItemIds
        const items = imports
            .filter((item) => ids.includes(item.id))
            .map((item) => ({
                id: item.id,
                shopId: item.shopId,
            }))
        setSelectedItemIds([])
        setImportLoading(true)
        const { error } = await fetchAPI(
            `/v1/shop/products/sync`,
            { groups: [items], ownerId: org.id },
            'POST',
        )
        setImportLoading(false)
        if (error) return setToast(error, 'alert')
        setToast(`Imported ${ids.length} items`)
        await getImports()
    }

    function handleImportAllOwnShop() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const items = imports.map((item) => ({
                        id: item.id,
                        shopId: shopifyShopId,
                    }))

                    setSelectedItemIds([])
                    setImportLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/shop/products/sync`,
                        {
                            groups: items.map((item) => [item]),
                            ownShopId: shopifyShopId,
                        },
                        'POST',
                    )
                    setImportLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)

                    setToast(`Imported ${items.length} items`)
                    await getImports()
                }}
            />,
            'Are you sure you want to import all items?',
        )
    }

    if (!auth.user.orgsData.length) {
        return (
            <SectionContainer>
                <MessageSection
                    title="Please add a company first, or enable product management on your personal profile."
                    type="warning"
                    fullWidth
                    fullWidthButton
                    small
                >
                    <Button
                        small
                        outline
                        fullWidth
                        white
                        href={`/profile/company/new`}
                        text="Add company"
                    />
                    <Button
                        small
                        outline
                        fullWidth
                        white
                        onClick={() => {}}
                        // TODO
                        text="Enable on my profile"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    if (shopLoading) {
        return <Spinner marginTop />
    }
    if (shopError) {
        return (
            <SectionContainer>
                <ErrorMessage>{shopError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (loading) {
        return (
            <>
                <Spinner />
                <div className="text-center">
                    <small>Searching for products...</small>
                </div>
            </>
        )
    }

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!org) {
        return (
            <SectionContainer>
                <ErrorMessage>Company information not found</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer className=" ">
                <h4>
                    Found {imports.length} items that are not synced into the
                    app.
                </h4>
                {isAppShop && (
                    <>
                        <p className="text-subdued">
                            Group duplicate products to import them into the
                            app. Each group represents one product and will be
                            synced into all connected stores. You can also
                            import all products at once, separately.
                        </p>
                        <p className="text-subdued">
                            Important: Once a product is synced into the app,
                            the Shopify products linked to it on all connected
                            stores will be synced with it.
                        </p>
                    </>
                )}
                {isAppShop ? (
                    <ButtonGroup grid row>
                        <Button
                            text="Import all separately"
                            small
                            alignRight
                            outline
                            isLoading={importLoading}
                            onClick={handleImportAll}
                        />
                        {selectedItemIds?.length > 0 && (
                            <Button
                                text="Group selected & Import"
                                small
                                alignRight
                                onClick={handleImportGroup}
                                isLoading={importLoading}
                            />
                        )}
                    </ButtonGroup>
                ) : (
                    <>
                        <Button
                            text="Sync all now"
                            small
                            alignRight
                            outline
                            isLoading={importLoading}
                            onClick={handleImportAllOwnShop}
                        />
                    </>
                    // <ButtonGroup grid row>
                    //     {selectedItemIds?.length > 0 && (
                    //         <Button
                    //             text="Import selected"
                    //             small
                    //             alignRight
                    //             onClick={handleImportOwnShop}
                    //             isLoading={importLoading}
                    //         />
                    //     )}
                    // </ButtonGroup>
                )}
            </SectionContainer>
            <ResourceList
                key={imports.length}
                getSelectedItemText={(item) => item.title}
                isSelectable={isAppShop}
                onSelectedItemsChange={(items) => setSelectedItemIds(items)}
                items={imports}
                // selectedIdField={'id'}
                emptyText={'All products are synced into the app.'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <ImportProductItem
                                showShopInfo={isAppShop}
                                item={item}
                            />
                        ),
                    },
                ]}
            />
        </>
    )
}
