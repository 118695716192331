import { useParams } from 'react-router-dom'
import DataFormNew from '../../components/common/DataFormNew'
import SectionContainer from '../../components/common/SectionContainer'

const initFields = [
    {
        key: 'my_first_metafield',
        value: 'Example value',
    },
]

export default function EditUserMetafields() {
    const { userId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/users/${userId}/metafields`}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={[
                    {
                        type: 'addItems',
                        // defaultValue: initFields,
                        key: 'metafields',
                        label: (
                            <>
                                All user metafields
                                <br />
                                <br />
                                <br />
                            </>
                        ),
                        innerInputs: [
                            {
                                key: 'key',
                                type: 'text',
                                label: 'Key',
                                required: true,
                                infoBottom:
                                    "Use only lowercase letters, numbers, and underscores. Don't use spaces.",
                                regex: /^[a-z0-9_]+$/,
                            },
                            {
                                key: 'value',
                                type: 'text',
                                label: 'Value',
                            },
                        ],
                    },
                ]}
                getBody={(data, item) => ({
                    metafields: [
                        ...data.metafields,
                        ...item.results
                            .filter(
                                (m) =>
                                    !data.metafields.some(
                                        (dm) => m.key === dm.key,
                                    ),
                            )
                            .map(({ key }) => ({
                                key,
                                value: '',
                            })),
                    ],
                })}
                mapItemToData={(item) => ({
                    metafields: (item?.results?.length
                        ? item.results
                        : initFields
                    ).map(({ key, value }) => ({
                        key,
                        value,
                    })),
                })}
                fetchItemFirst
                mutationRegexes={[/\/v1\/user.*\/metafields/]}
                mainButton
            />
            <br />
        </SectionContainer>
    )
}
