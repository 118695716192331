import React from 'react'
import { useStore } from '../../common/Context'

function Toasts() {
    const [state, dispatch] = useStore()

    if (!state.toasts?.length) return <></>

    return (
        <div className={`toasts ${state.toasts.length ? 'show' : ''} `}>
            {state.toasts.map((toast) => {
                return (
                    <div
                        key={toast.date}
                        className={`toast ${toast.messageType.toLowerCase()}`}
                    >
                        <span>{toast.text}</span>

                        <button
                            onClick={() =>
                                dispatch({
                                    type: 'CLEAR_TOAST',
                                    payload: toast.date,
                                })
                            }
                            className={`clear-toast`}
                        >
                            &#10005;
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default Toasts
