import { useEffect, useState } from 'react'
import useAuth from '../../common/hooks/useAuth'

export default function Ad() {
    const auth = useAuth()
    const [isSet, setIsSet] = useState(false)

    useEffect(() => {
        if (auth?.isPremiumUser) return
        if (auth?.isAdmin) return
        if (isSet) return
        try {
            ;(window.adsbygoogle = window.adsbygoogle || []).push({})
        } catch (error) {
            console.error(error)
        }
        setIsSet(true)
    }, [])

    if (auth?.isPremiumUser) return null
    if (auth?.isAdmin) return null

    return (
        <>
            <ins
                className="adsbygoogle ad"
                style={{ height: 60, width: '100%', display: 'block' }}
                data-ad-client="ca-pub-1004776563063175"
                data-ad-slot="8690619677"
                // data-full-width-responsive="true"
                // data-ad-format="auto"
            ></ins>
        </>
    )
}
