import { useEffect, useRef } from 'react'

export default function useDebounceFn(setterFn, delay = 500) {
    const debounceTimeoutRef = useRef(undefined)

    const debouncedSetterFn = function (data) {
        return new Promise((resolve) => {
            clearTimeout(debounceTimeoutRef.current)
            debounceTimeoutRef.current = setTimeout(() => {
                const result = setterFn(data)
                resolve(result)
            }, delay)
        })
    }

    useEffect(function () {
        return () => {
            clearTimeout(debounceTimeoutRef.current)
        }
    }, [])

    return debouncedSetterFn
}
