// v18

import { useCallback } from 'react'
import { useState, useEffect, useRef } from 'react'

export default function useScrollTop() {
    const [scrollingUp, setScrollingUp] = useState(false)

    const scrollTop = useRef(0)
    const timeout = useRef(null)

    const onScroll = useCallback((e) => {
        const isScrollingUp =
            scrollTop.current > e.target.documentElement.scrollTop
        scrollTop.current = e.target.documentElement.scrollTop

        if (isScrollingUp) {
            setScrollingUp(true)
            clearTimeout(timeout?.current)
            timeout.current = setTimeout(() => {
                setScrollingUp(false)
            }, 3000)
        } else {
            setScrollingUp(false)
            clearTimeout(timeout?.current)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return scrollingUp
}
