import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { initialState, reducer } from './common/reducer'
import { StoreProvider } from './common/Context'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { UploadsProvider } from './common/UploadContext'
import ReactGA from 'react-ga4'
import ErrorBoundary from './common/ErrorBoundary'
import AppError from './common/AppError'

ReactGA.initialize('G-TK00CG20KN')

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <StoreProvider initialState={initialState} reducer={reducer}>
                <UploadsProvider>
                    <ErrorBoundary fallback={<AppError />}>
                        <App />
                    </ErrorBoundary>
                </UploadsProvider>
            </StoreProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
)

serviceWorkerRegistration.register()
