import { Navigate, useLocation } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import NotFound from '../pages/not-found/NotFound'

function isRoleAllowedInRoute(auth, route) {
    // Admin is allowed to all routes
    if (auth.isAdmin) return true

    // Client is allowed to all user routes
    if (auth.isClient) {
        return route.client
    }

    // Staff
    if (auth.isCollectionsOnly && route.noCollections) return false
    if (auth.isCollections && route.collections) return true
    if (auth.isAssistant && route.assistant) return true
    if (auth.isAccountant && route.accountant) return true
    if (auth.isSocialEditor && route.socialEditor) return true
    if (auth.isClientManager && route.clientManager) return true
    if (auth.isLateFeeAssistant && route.lateFeeAssistant) return true
    return route.staff
}

function RequireAuth({ children, routeItem }) {
    const auth = useAuth()
    const { pathname } = useLocation()

    if (!auth.user) {
        return <Navigate to={'/login'} state={{ redirectUrl: pathname }} />
    }

    if (
        auth.isClient &&
        !auth.user.isEmailVerified &&
        routeItem?.url !== 'register/verify-email' &&
        routeItem?.url !== 'register/details'
    ) {
        return <Navigate to={'/register/verify-email'} />
    }

    if (!isRoleAllowedInRoute(auth, routeItem)) return <NotFound />

    return children
}

export default RequireAuth
