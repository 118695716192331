import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import { useEffect, useState } from 'react'
import Button from '../../../components/common/Button'
import useNotifs from '../../../common/hooks/useNotifs'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import InlineStack from '../../../components/common/InlineStack'
import { ICONS } from '../../../common/constants'
import { prettifyDate } from '../../../common/helpers'
import SectionContainer from '../../../components/common/SectionContainer'

function NotifsListWidget() {
    const setToast = useToast()
    const navigate = useNavigate()
    const matchMutate = useMatchMutate()

    const [currNotif, setCurrNotif] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [markedLoading, setMarkedLoading] = useState(null)

    const { notifs, notifsError, notifsLoading } = useNotifs()
    const currIndex = (notifs || [])
        .map((notif) => notif.id)
        .indexOf(currNotif?.id)

    useEffect(() => {
        if (!notifs?.length) return
        setCurrNotif(notifs?.[0])
    }, [notifs?.length])

    async function onMarkRead() {
        setMarkedLoading(true)
        const { error } = await fetchAPI(
            `/v1/notifs/${currNotif.id}/read`,
            {},
            'PATCH',
        )
        setMarkedLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        matchMutate(/\/v1\/notifs/)
    }

    async function onOpen() {
        await onMarkRead()
        navigate(currNotif.link)
    }

    function onPrev() {
        setCurrNotif(notifs[currIndex - 1])
        setIsOpen(false)
    }

    function onNext() {
        setCurrNotif(notifs[currIndex + 1])
        setIsOpen(false)
    }

    if (notifsError) {
        return <ErrorMessage section>{notifsError}</ErrorMessage>
    }

    if ((!notifs?.length || !currNotif) && !notifsLoading) {
        return null
    }

    const hasPrev = notifs?.length > 1 && currNotif.id !== notifs?.[0].id
    const hasNext =
        notifs?.length > 1 && currNotif.id !== notifs?.[notifs?.length - 1].id

    return (
        <SectionContainer>
            <div className="notifs-list-widget">
                {notifsLoading ? (
                    <Spinner />
                ) : (
                    <div className="curr-notif">
                        <details open={isOpen}>
                            <summary onClick={(e) => e.preventDefault()}>
                                <InlineStack
                                    itemsCenter
                                    spaceBetween
                                    className="nav-controls"
                                >
                                    <InlineStack spaceBetween>
                                        <div className="notifs-bell-container">
                                            {notifs?.length > 0 &&
                                                notifs?.length < 100 && (
                                                    <span>{notifs.length}</span>
                                                )}
                                            {notifs?.length >= 100 &&
                                                notifs?.length < 1000 && (
                                                    <span>99+</span>
                                                )}
                                            {notifs?.length >= 1000 &&
                                                notifs?.length < 10000 && (
                                                    <span>
                                                        {notifs.length
                                                            .toString()
                                                            .slice(0, 1) + 'k'}
                                                    </span>
                                                )}
                                            {notifs?.length >= 10000 && (
                                                <span>9k+</span>
                                            )}
                                            <img
                                                src={ICONS.BELL_WHITE}
                                                alt="bell icon"
                                                width="24"
                                                height="24"
                                            />
                                        </div>
                                    </InlineStack>

                                    <InlineStack itemsCenter>
                                        <Button
                                            href="/notifications"
                                            onClick={onOpen}
                                            text="View all"
                                            tiny
                                            inline
                                            link
                                        />
                                        <small className="text-subdued">
                                            &nbsp;&nbsp;&nbsp;
                                            {`${currIndex + 1}/${notifs.length}`}
                                        </small>

                                        <button
                                            onClick={onPrev}
                                            disabled={!hasPrev}
                                            className="prev-btn"
                                        >
                                            <img
                                                alt="collapse"
                                                src="/img/icons/chevron-left-white.svg"
                                                width={16}
                                                height={16}
                                            ></img>
                                        </button>

                                        <button
                                            onClick={onNext}
                                            disabled={!hasNext}
                                            className="next-btn"
                                        >
                                            <img
                                                alt="collapse"
                                                src="/img/icons/chevron-left-white.svg"
                                                width={16}
                                                height={16}
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                }}
                                            ></img>
                                        </button>
                                    </InlineStack>
                                </InlineStack>
                                <InlineStack inline spaceBetween itemsStart>
                                    <h4
                                        onClick={() => setIsOpen(!isOpen)}
                                        className="notif-title"
                                    >
                                        {currNotif.title}
                                    </h4>
                                </InlineStack>
                            </summary>
                            <div className="notif-subtitle">
                                {currNotif.subtitle}
                            </div>
                        </details>
                        <div className="notif-date"></div>
                        <InlineStack spaceBetween itemsCenter>
                            <InlineStack gap={'sm'} itemsCenter>
                                <small className="text-subdued">
                                    {prettifyDate(
                                        new Date(currNotif.creationDate),
                                    )}
                                </small>
                                {Boolean(currNotif.subtitle) && (
                                    <Button
                                        onClick={() => setIsOpen(!isOpen)}
                                        text={isOpen ? 'Hide' : 'Show more'}
                                        tiny
                                        inline
                                        link
                                    />
                                )}
                            </InlineStack>

                            <div className="controls">
                                <InlineStack justifyEnd itemsCenter gap={'sm'}>
                                    <Button
                                        icon={ICONS.X_WHITE}
                                        plain
                                        tiny
                                        title="Dismiss"
                                        onClick={onMarkRead}
                                        isLoading={markedLoading}
                                    />
                                    {currNotif.link ? (
                                        <Button
                                            onClick={onOpen}
                                            text="Open"
                                            tiny
                                            isLoading={markedLoading}
                                            outline
                                        />
                                    ) : null}
                                </InlineStack>
                            </div>
                        </InlineStack>
                    </div>
                )}
            </div>
        </SectionContainer>
    )
}

export default NotifsListWidget
