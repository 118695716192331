import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import ResourceList from '../../../components/admin/ResourceList'
import { formatDateTime, getProcessedNotif } from '../../../common/helpers'
import { ALLOWED_NOTIF_TYPES } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import { useState } from 'react'
import Tag from '../../../components/common/Tag'

const ADMIN_NOTIF_TYPES = [
    'vendorAccountSubmission',
    'vendorProductCreation',
    'commentAdded',
    'orgMemberInvite',
    'connection',
]

const USER_NOTIF_TYPES = ['commentAdded', 'orgMemberInvite', 'connection']

function NotifsList() {
    const setToast = useToast()
    const navigate = useNavigate()
    const matchMutate = useMatchMutate()
    const auth = useAuth()
    const [markAllLoading, setMarkAllLoading] = useState(false)

    const filters = {
        search: true,
        sort: {
            creationDate: {
                label: 'Creation date',
                dbFields: ['creationDate'],
                asc: false,
            },
        },
        filterCombos: {
            all: {
                label: 'All',
                fields: {
                    notifType: auth.isAdmin
                        ? ADMIN_NOTIF_TYPES.join(',')
                        : USER_NOTIF_TYPES.join(','),
                },
            },
            new: {
                label: 'New',
                fields: {
                    notifType: auth.isAdmin
                        ? ADMIN_NOTIF_TYPES.join(',')
                        : USER_NOTIF_TYPES.join(','),
                    unread: 'true',
                },
            },
            commentAdded: {
                label: 'Comments',
                fields: {
                    notifType: 'commentAdded',
                },
            },
            orgMemberInvite: {
                label: 'New invitation',
                fields: {
                    notifType: 'orgMemberInvite',
                },
            },
            connection: {
                label: 'New connection',
                fields: {
                    notifType: 'connection',
                },
            },
            ...(auth.isAdmin
                ? {
                      vendorAccountSubmission: {
                          label: 'Vendor account submission',
                          fields: {
                              notifType: 'vendorAccountSubmission',
                          },
                      },
                      vendorProductCreation: {
                          label: 'Product created by vendor',
                          fields: {
                              notifType: 'vendorProductCreation',
                          },
                      },
                  }
                : {}),
        },
        filters: {
            unread: {
                label: 'New',
                dbField: 'unread',
                options: [
                    {
                        label: 'All',
                        value: 'undefined',
                    },
                    {
                        label: 'Yes',
                        value: 'true',
                    },
                ],
            },
            notifType: {
                label: 'Type',
                dbField: 'notifType',
                options: [
                    {
                        label: 'All',
                        value: (auth.isAdmin
                            ? ADMIN_NOTIF_TYPES
                            : USER_NOTIF_TYPES
                        ).join(','),
                    },
                    {
                        label: 'New comment',
                        value: 'commentAdded',
                    },
                    {
                        label: 'New invitation',
                        value: 'orgMemberInvite',
                    },
                    {
                        label: 'New connection',
                        value: 'connection',
                    },
                    ...(auth.isAdmin
                        ? [
                              {
                                  label: 'Vendor account submission',
                                  value: 'vendorAccountSubmission',
                              },
                              {
                                  label: 'Product created by vendor',
                                  value: 'vendorProductCreation',
                              },
                          ]
                        : []),
                ],
            },
        },
    }

    async function onMarkRead(id) {
        const { error } = await fetchAPI(`/v1/notifs/${id}/read`, {}, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Marked as read')
        matchMutate(/\/v1\/notifs/)
    }

    // async function onMarkAllRead() {
    //     setMarkAllLoading(true)
    //     const { error } = await fetchAPI(
    //         `/v1/notifs/read-all?unread=true&notifType=${ALLOWED_NOTIF_TYPES[
    //             auth.user.role
    //         ].join(',')}`,
    //         {},
    //         'PATCH',
    //     )
    //     setMarkAllLoading(false)
    //     if (error) {
    //         setToast(error, 'alert')
    //         return
    //     }
    //     setToast('Marked all as read')
    //     matchMutate(/\/v1\/notifs/)
    // }

    return (
        <ResourceList
            filters={filters}
            // paginated
            emptyText={"You don't have any notifications yet."}
            apiUrl={'/notifs'}
            getItemsFromResponse={(data) =>
                data?.results
                    ? data.results.map((item) => getProcessedNotif(item))
                    : []
            }
            onItemClick={(item) => item.link && navigate(item.link)}
            fields={[
                {
                    column: 1,
                    getValue: (item) =>
                        !item.readDate ? <Tag color={'alert'}>New</Tag> : null,
                },
                {
                    column: 1,
                    getValue: (item) => item.title,
                },
                {
                    column: 1,
                    getValue: (item) => item.subtitle,
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => formatDateTime(item.creationDate),
                    getClassName: () => 'subtitle',
                },
            ]}
            actions={[
                {
                    title: 'Mark as read',
                    onClick: (item) => onMarkRead(item.id),
                    getDisabled: (item) => item.readDate,
                },
            ]}
        />
    )
}

export default NotifsList
