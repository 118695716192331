import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from '../../common/Context'
import fetchAPI from '../../common/fetchAPI'
import authStorage from '../../common/authStorage'
import Spinner from '../../components/common/Spinner'

// This is a unprotected page that loads
// fresh from email URL click

const VerifyEmailCallback = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const navigate = useNavigate()
    const [_state, dispatch] = useStore()

    React.useEffect(() => {
        const updateUser = async () => {
            const { responseData, error } = await fetchAPI(
                `/v1/auth/verify-email?token=${token}`,
                {},
                'POST',
                {},
                false,
            )

            if (error) {
                window.location.href = '/register/verify-email?error'
                return
            }

            const { user, tokens } = responseData
            authStorage.setItem('access_token', tokens.access.token)
            authStorage.setItem('refresh_token', tokens.refresh.token)

            dispatch({ type: 'LOGIN', payload: user })
            navigate('/')
        }

        updateUser()
    }, [])

    return <Spinner />
}

export default VerifyEmailCallback
