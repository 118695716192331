import { ICONS } from '../../../common/constants'
import { formatDate, formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'

// const filterFields = [
//     {
//         type: 'sort',
//         name: 'Created at',
//         fields: ['creationDate'],
//         asc: false,
//     },
// ]

export default function ExpensesList() {
    return (
        <ResourceList
            title="Expenses"
            renderMainButton={true}
            newItemLabel={'New expense'}
            apiUrl={'/expenses'}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/expenses/new'}
            itemClickPath={'/expenses/:item.id'}
            // filters={filterFields}
            paginated={true}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.teamMember
                            ? `For ${item.teamMember.firstName} ${item.teamMember.lastName}`
                            : null,
                },
                {
                    column: 1,
                    getValue: (item) => formatMoney(item.amount || 0),
                },
                {
                    column: 2,
                    getValue: (item) => item.expenseModel,
                    getClassName: () => 'text-caps',
                },
                {
                    column: 2,
                    getValue: (item) =>
                        item.expenseModel === 'fixed '
                            ? `Created ${formatDate(item.creationDate)}`
                            : null,
                },
            ]}
            actions={[
                {
                    title: 'Edit',
                    onClick: () => alert('Not implemented'),
                    icon: ICONS.EDIT_GRAY,
                },
            ]}
        />
    )
}
