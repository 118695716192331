import { useParams } from 'react-router-dom'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import ResourceList from '../../../components/admin/ResourceList'
import AppProductItem from '../products/AppProductItem'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
        },
    },
}

export default function ShopProductsList() {
    const auth = useAuth()
    const { shopifyShopId } = useParams()

    const { shop, shopError, shopLoading } = useData(
        `/v1/shop/shops/${shopifyShopId}?fields=ownerId`,
        'shop',
    )

    if (shopLoading) {
        return <Spinner marginTop />
    }
    if (shopError) {
        return <ErrorMessage section>{shopError}</ErrorMessage>
    }

    const org = auth.user.orgsData.find((o) => o.id === shop?.ownerId)
    if (!org) {
        return (
            <ErrorMessage section>Company information not found</ErrorMessage>
        )
    }

    return (
        <>
            <Button
                text={'Import products'}
                alignRight
                outline
                small
                href={`/shop/shopify-shops/${shopifyShopId}/products/import`}
            />

            <ResourceList
                title={org?.name}
                smallTitle
                apiUrl={'/shop/products'}
                initialQuery={{
                    shopId: shopifyShopId,
                    fields: 'title,ownerId',
                    ownerId: shop.ownerId,
                    populate:
                        'enabledShopifyShopsInfo.shopifyShop.syncStrategies,enabledShopifyShopsInfo.shopifyShop.ownerId',
                }}
                getItemsFromResponse={(data) => data?.results}
                paginated={true}
                filters={filterFields}
                paginationLimit={10}
                newItemPath={'/shop/products/new'}
                renderMainButton
                newItemLabel="New product"
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <AppProductItem item={item} />,
                        getClassName: () => 'title',
                    },
                ]}
            />
        </>
    )
}
