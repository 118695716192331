import { Link } from 'react-router-dom'
import { ICONS } from '../../common/constants'
import InlineStack from '../../components/common/InlineStack'
import BlockStack from '../../components/common/BlockStack'
import ContactThumbnail from '../admin/clients/ContactThumbnail'

export default function OrgCard({ org: data, noLink }) {
    const innerHtml = (
        <div className={`contact-card`}>
            <InlineStack itemsStart gap={'sm'}>
                {/* TODO: component */}
                <ContactThumbnail data={data} isOrg />
                {/* <div className="contact-thumbnail">
                    <div className={`photo`}>
                        <img
                            src={ICONS.FOLDER_GRAY}
                            alt="org"
                            width={16}
                            height={16}
                        />
                    </div>
                </div> */}
                <BlockStack gap={'tiny'}>
                    <div className="name">{data.name}</div>
                    <small className="text-subdued text-caps">
                        {data.organizationType}
                        {data?.members?.length !== undefined &&
                            ` - ${data.members.length} ${
                                data.members.length === 1 ? 'member' : 'members'
                            }`}
                    </small>
                    <small className="text-subdued"></small>
                </BlockStack>
            </InlineStack>
        </div>
    )

    if (noLink) return <div>{innerHtml}</div>

    return <Link to={`/organizations/${data.id}`}>{innerHtml}</Link>
}
