import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import {
    formatDateTime,
    getUserDisplayName,
    openUrlInNewTab,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import Tag from '../../../components/common/Tag'
import InlineStack from '../../../components/common/InlineStack'
import BlockStack from '../../../components/common/BlockStack'
import InlineStackItem from '../../../components/common/InlineStackItem'
import ContactThumbnail from './ContactThumbnail'

function ContactCard({
    user: data,
    withContactInfo = true,
    withNameLink = false,
    withUserLink = false,
    summary = false,
    hideTags = false,
    hideLinks = false,
}) {
    const auth = useAuth()

    function openContactApp(e, url) {
        e.preventDefault()
        e.stopPropagation()
        openUrlInNewTab(url)
    }

    if (!data) return null
    const isContact = data.contactType

    const company = data.orgsData?.find(
        (org) => org.organizationType === 'company',
    )

    const photoHtml = <ContactThumbnail data={data} isContact={isContact} />

    return (
        <>
            <div
                className={`contact-card${summary ? ' summary' : ''}${isContact ? ' is-contact' : ''}`}
            >
                <InlineStack itemsStart gap={'sm'}>
                    {!summary &&
                        (hideLinks ? (
                            <div>{photoHtml}</div>
                        ) : (
                            <Link
                                className=""
                                to={
                                    hideLinks
                                        ? '#'
                                        : `/${!isContact ? 'users' : 'contacts'}/${
                                              data.id
                                          }`
                                }
                            >
                                {photoHtml}
                            </Link>
                        ))}
                    <InlineStackItem grow1>
                        <BlockStack gap={'tiny'}>
                            <InlineStack itemsStart spaceBetween gap={'sm'}>
                                {withNameLink && !hideLinks ? (
                                    <Link
                                        to={
                                            hideLinks
                                                ? '#'
                                                : `/${
                                                      data.role || withUserLink
                                                          ? 'users'
                                                          : 'contacts'
                                                  }/${data.id}`
                                        }
                                        className="h3 m-0"
                                    >
                                        {getUserDisplayName(data)}
                                    </Link>
                                ) : (
                                    <div className="h3 m-0">
                                        {getUserDisplayName(data)}
                                    </div>
                                )}

                                <div className="status-container">
                                    {!hideTags && (
                                        <InlineStack gap={'tiny'}>
                                            {data.role === 'user' ? (
                                                data.activeStatus ? (
                                                    <Tag
                                                        noMargin
                                                        color={'success'}
                                                    >
                                                        Active
                                                    </Tag>
                                                ) : (
                                                    <Tag noMargin color="alert">
                                                        Inactive
                                                    </Tag>
                                                )
                                            ) : null}
                                            {data.isBlocked ? (
                                                <Tag noMargin color="alert">
                                                    Blocked
                                                </Tag>
                                            ) : null}
                                            {data.onHold ? (
                                                <Tag noMargin color="alert">
                                                    Hold
                                                </Tag>
                                            ) : null}
                                            {data.onBlackList ? (
                                                <Tag noMargin color="alert">
                                                    Blacklist
                                                </Tag>
                                            ) : null}
                                            {data.onCollections ? (
                                                <Tag noMargin color="alert">
                                                    Collections
                                                </Tag>
                                            ) : null}
                                        </InlineStack>
                                    )}
                                </div>
                            </InlineStack>
                            {Boolean(company) && (
                                <div className="company">
                                    {company?.name || ''}
                                    {company?.acronym
                                        ? ` (${company.acronym})`
                                        : null}
                                    <span className="text-caps">
                                        {data.role === 'staff' &&
                                            data.teamRoles.join(', ')}
                                    </span>
                                    {data.role === 'staff' &&
                                        Boolean(data.startDate) && (
                                            <>
                                                <br />
                                                {formatDateTime(
                                                    data.startDate,
                                                    true,
                                                )}
                                            </>
                                        )}
                                </div>
                            )}
                            {!summary && withContactInfo && (
                                <div className="contact-info contact-container">
                                    {data.email ? (
                                        <span>
                                            <Button
                                                icon={ICONS.MAIL_GRAY}
                                                link
                                                plain
                                                inline
                                                tiny
                                                onClick={(e) =>
                                                    openContactApp(
                                                        e,
                                                        `mailto:${data.email}`,
                                                    )
                                                }
                                                text={
                                                    <span className="text-very-subdued">
                                                        {data.email}
                                                    </span>
                                                }
                                            />
                                        </span>
                                    ) : null}
                                </div>
                            )}

                            {auth.isAdmin && isContact && data.creator && (
                                <small className="text-subdued">
                                    {`Added by ${getUserDisplayName(data.creator)}`}
                                </small>
                            )}
                        </BlockStack>
                    </InlineStackItem>
                </InlineStack>
            </div>
        </>
    )
}

export default ContactCard
