import ResourceList from '../../../components/admin/ResourceList'
import { getUserDisplayName } from '../../../common/helpers'

export default function DBVendorsList() {
    return (
        <ResourceList
            renderMainButton={false}
            apiUrl={'/wholesale/db-vendors'}
            // TODO: fix this
            itemClickPath={'/shop/db-vendors/:item.id'}
            fields={[
                {
                    column: 1,
                    getValue: (item) =>
                        `${getUserDisplayName(item)} (${
                            // TODO fix this
                            item.organizations?.acronym
                        })`,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item?.account?.alternateVendorNames
                            ?.map((name) => `or ${name}`)
                            ?.join(', ') || null,
                    getClassName: () => 'subtitle',
                },
            ]}
        />
    )
}
