import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'

function PdfContractsList() {
    const navigate = useNavigate()

    const [downloadLoading, setDownloadLoading] = useState()
    const setToast = useToast()

    const onDownloadPdf = async (fileName) => {
        setDownloadLoading(true)
        const { responseData, error, headers } = await fetchAPI(
            `/v1/files/pdf-contracts/${fileName}`,
        )
        setDownloadLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }
        const blob = await responseData.blob()

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = headers.get('Filename')
        a.click()
        window.URL.revokeObjectURL(url)
    }

    return (
        <ResourceList
            apiUrl={'/files/pdf-contracts'}
            onItemClick={(item) =>
                navigate(`/pdf-contracts/edit-addendum/${item}`)
            }
            fields={[
                {
                    column: 1,
                    getValue: (item) => item,
                },
            ]}
            actions={[
                {
                    title: 'Download',
                    onClick: (item) => onDownloadPdf(item),
                    icon: ICONS.DOWNLOAD_GRAY,
                    loading: downloadLoading,
                },
            ]}
        />
    )
}

export default PdfContractsList
