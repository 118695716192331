import React from 'react'
import { cartInitialState, cartReducer } from './cart-reducer'

const Store = React.createContext(undefined)
Store.displayName = 'Cart'

export const useCartStore = () => React.useContext(Store)

export const CartStoreProvider = ({ children }) => {
    const [globalState, dispatch] = React.useReducer(
        cartReducer,
        cartInitialState,
    )

    return (
        <Store.Provider value={[globalState, dispatch]}>
            {children}
        </Store.Provider>
    )
}
