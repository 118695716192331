// .company ready
import useAuth from '../../common/hooks/useAuth'
import Button from '../../components/common/Button'
import DataFormNew from '../../components/common/DataFormNew'
import InlineStack from '../../components/common/InlineStack'
import SectionContainer from '../../components/common/SectionContainer'

const fields = [
    {
        key: 'name',
        label: 'Company name',
        required: true,
    },
]

export default function CreateCompany() {
    const auth = useAuth()

    return (
        <SectionContainer>
            <InlineStack justifyEnd>
                <Button
                    text="View companies"
                    link
                    alignRight
                    href={'/profile/organizations'}
                />
            </InlineStack>
            <br />
            <DataFormNew
                url={`/v1/organizations`}
                method="POST"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/profile/companies`}
                inputs={fields}
                getBody={(item) => ({
                    name: item.name,
                    organizationType: 'company',
                    members: [{ userId: auth.user.id }],
                })}
                mutationRegexes={[
                    /\/v1\/users/,
                    /\/v1\/organizations/,
                    /\/v1\/users\/me\/profile-completion/,
                ]}
                mainButton
                onSuccess={async () => await auth.refetchAuth()}
            />
        </SectionContainer>
    )
}
