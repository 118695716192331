import { useState } from 'react'
import { Link } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import ProjectedPayoutsList from '../../admin/wholesale-payouts/ProjectedPayoutsList'
import FinancialBillingList from '../../admin/wholesale-products/FinancialBillingList'
import ProductsBillingList from '../../admin/wholesale-products/ProductsBillingList'
import DBVendorsList from './DBVendorsList'
import VendorCashList from './VendorCashList'
import WholesaleMonthlyBilling from './WholesaleMonthlyBilling'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function getMonthsForPayouts() {
    const now = new Date()
    const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)

    const firstMonth = [1, 2022]
    const lastMonth = [
        nextMonthDate.getMonth() + 2,
        nextMonthDate.getFullYear(),
    ]

    const result = []
    let month = firstMonth[0]
    let year = firstMonth[1]
    while (
        year < lastMonth[1] ||
        (year === lastMonth[1] && month <= lastMonth[0])
    ) {
        result.push([month, year])
        month++
        if (month > 12) {
            month = 1
            year++
        }
    }

    return result
}

export default function WholesaleBillingPage() {
    const [shop, setShop] = useState('')

    const { shops, shopsLoading, shopsError } = useData(
        `/v1/wholesale/shops?fields=name`,
        'shops',
        (data) => data?.map((item) => item.name) || [],
    )

    const { billing, billingLoading, billingError } = useData(
        `/v1/wholesale/billing${shop ? `?shop=${shop}` : ''}`,
        'billing',
    )

    if (billingLoading || shopsLoading) {
        return <Spinner marginTop />
    }
    if (billingError || shopsError) {
        return <ErrorMessage section>{billingError || shopsError}</ErrorMessage>
    }

    return (
        <div>
            <SectionContainer>
                <SelectInput
                    value={shop}
                    onChange={setShop}
                    options={[
                        { value: '', label: 'All Shops' },
                        ...shops.map((shop) => ({
                            value: shop,
                            label: shop,
                        })),
                    ]}
                />
            </SectionContainer>

            <SectionContainer>
                <Toggle open title="Financial summary">
                    <FinancialBillingList billing={billing} />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Sold products">
                    <ProductsBillingList
                        title=""
                        billing={Object.values(billing?.productData || [])}
                    />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Monthly Earning">
                    <small className="notes">
                        Values are not based on payouts
                    </small>
                    <WholesaleMonthlyBilling billing={billing} />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title={`Payouts`}>
                    <small className="notes">
                        Values have not been validated
                        <br />
                        Click on an item to see a detailed breakdown
                        <br />
                        <Link to={`/temp/payout-validation`}>
                            View raw Shopify data
                        </Link>
                    </small>
                    {getMonthsForPayouts().map(([month, year]) => (
                        <Toggle
                            key={`${month}-${year}`}
                            title={`${month} / ${year}`}
                        >
                            <ProjectedPayoutsList
                                shop={shop}
                                month={month}
                                year={year}
                            />
                        </Toggle>
                    ))}
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Vendors">
                    <DBVendorsList />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Cash & Manual payments">
                    <VendorCashList />
                </Toggle>
            </SectionContainer>
        </div>
    )
}
