import { useNavigate } from 'react-router-dom'
import { getUserDisplayName, openUrlInNewTab } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import { ICONS } from '../../../common/constants'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import InputModalContent from '../../../components/common/InputModalContent'
import fetchAPI from '../../../common/fetchAPI'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import { useEffect } from 'react'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import MessageSection from '../../../components/common/MessageSection'
import Button from '../../../components/common/Button'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import SectionContainer from '../../../components/common/SectionContainer'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
            asc: true,
        },
        website: {
            label: 'Website',
            dbFields: ['website'],
            asc: true,
        },
    },
}

export default function PasswordsList() {
    const auth = useAuth()
    const navigate = useNavigate()

    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const mutate = useMatchMutate()

    const { hasPin, hasPinLoading, hasPinError, hasPinMutate } = useData(
        '/v1/users/me/has-pin',
        'hasPin',
        (data) => data?.hasPin,
        false,
        false,
        (data) => data?.hasPin === false && showPinModal(),
    )

    function showPinModal() {
        setModal(
            <InputModalContent
                label="PIN (4 numbers)"
                type="password"
                confirmText="Save"
                onConfirm={async (data) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/users/me`
                    const { error } = await fetchAPI(
                        url,
                        {
                            pin: data.text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                    hasPinMutate()
                }}
            />,
            'Create a PIN',
        )
    }

    async function onPasswordCopy(item) {
        setModal(
            <InputModalContent
                label="PIN (4 numbers)"
                type="password"
                confirmText="OK"
                onConfirm={async (data) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { responseData, error } = await fetchAPI(
                        `/v1/vault/${item.id}`,
                        { pin: data.text },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    navigator.clipboard.writeText(responseData.password)
                    setToast('Copied')
                }}
            />,
            'Provide your PIN',
        )
    }

    async function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/vault/${item.id}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    mutate(/^\/v1\/vault*/)
                }}
            />,
            'Are you sure you want to delete this password?',
        )
    }

    if (hasPinLoading) {
        return <Spinner />
    }

    if (hasPinError) {
        return (
            <SectionContainer>
                <MessageSection title={hasPinError} type="alert" />
            </SectionContainer>
        )
    }

    if (!hasPin)
        return (
            <SectionContainer>
                <MessageSection
                    title="Please set a PIN"
                    type="warning"
                    small
                    fullWidthButton
                >
                    <Button
                        small
                        fullWidth
                        outline
                        white
                        onClick={showPinModal}
                        text="Create PIN"
                    />
                </MessageSection>
            </SectionContainer>
        )

    return (
        <ResourceList
            renderMainButton
            newItemLabel={'New password'}
            apiUrl={'/vault'}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/passwords/new'}
            paginated
            paginationLimit={20}
            filters={filterFields}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) => `Username: ${item.username}`,
                },
                {
                    column: 1,
                    getValue: (item) => item.website || '',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        (auth.isAdmin ||
                            (auth.isStaff &&
                                item.creatorId === auth.user.id)) &&
                        (item.allowedUserIds?.length ||
                            item.allStaffAllowed ||
                            item.allUsersAllowed)
                            ? `Shared with: ${
                                  item.allowedUserIds?.length
                                      ? `${item.allowedUserIds.length} users. `
                                      : ''
                              } ${
                                  item.allStaffAllowed
                                      ? 'All team members. '
                                      : ''
                              } ${item.allUsersAllowed ? 'All clients.' : ''}`
                            : '',
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        (auth.isAdmin || auth.isStaff) &&
                        item.creatorId !== auth.user.id
                            ? `Created by ${getUserDisplayName(item.creator)}`
                            : null,
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.notes ? (
                            <details>
                                <summary>Notes</summary>
                                <pre>{item.notes}</pre>
                            </details>
                        ) : null,
                    getClassName: () => 'password-notes-details',
                },
            ]}
            actions={[
                {
                    title: 'Copy password',
                    onClick: onPasswordCopy,
                    icon: ICONS.COPY_GRAY,
                },
                {
                    title: 'Edit',
                    onClick: (item) => navigate(`/passwords/${item.id}/edit`),
                    icon: ICONS.EDIT_GRAY,
                    getHide: (item) =>
                        (auth.isStaff || auth.isClient) &&
                        item.creatorId !== auth.user.id,
                },
                {
                    title: 'Open URL',
                    onClick: (item) => openUrlInNewTab(item.website),
                    icon: ICONS.LINK_GRAY,
                    getHide: (item) => !item.website,
                },
                {
                    title: 'History',
                    onClick: (item) =>
                        navigate(`/passwords/${item.id}/history`),
                    icon: ICONS.LINK_GRAY,
                    hide: !auth.isAdmin,
                },
                {
                    title: 'Delete',
                    onClick: onDelete,
                    icon: ICONS.TRASH_RED,
                    type: 'alert',
                    getHide: (item) =>
                        (auth.isStaff || auth.isClient) &&
                        item.creatorId !== auth.user.id,
                },
            ]}
        />
    )
}
