// .company ready

import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'company',
        label: 'Company',
    },
]

function EditClientCompany() {
    const { userId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/users/${userId}?fields=company`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={inputs}
                mainButton
                mutationRegexes={[/\/v1\/users/]}
                mapItemToData={(item) => ({ company: item.company })}
            />
        </SectionContainer>
    )
}

export default EditClientCompany
