// .company ready

import { useParams } from 'react-router-dom'
import {
    formatDateTime,
    formatMoney,
    getTimesheetEndDateFromStartDate,
    getUserDisplayName,
} from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'

function AdminTimesheetPage() {
    const { timesheetStartStr, userId } = useParams()

    const startDate = new Date()
    startDate.setFullYear(parseInt(timesheetStartStr.split('-')[0]))
    startDate.setMonth(parseInt(timesheetStartStr.split('-')[1]) - 1)
    startDate.setDate(parseInt(timesheetStartStr.split('-')[2]))
    startDate.setHours(0, 0, 0, 0)

    // const startDate = new Date(timesheetStartStr)
    const endDate = getTimesheetEndDateFromStartDate(startDate)
    const msBeforeEndDate = new Date(endDate.getTime() - 1)

    const dates = []
    for (let i = startDate.getDate(); i < msBeforeEndDate.getDate(); i++) {
        const date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            i,
            0,
        )
        dates.push(date)
    }
    dates.push(msBeforeEndDate)

    return (
        <ResourceList
            isTable
            tableColumns={[
                {
                    title: 'Start / end',
                    sumTitle: 'Total',
                },
                {
                    title: 'Client',
                },
                {
                    title: 'Hours / Earned',
                    getSum: (items) => {
                        return `${Math.floor(
                            items.reduce((a, b) => a + b.minutes, 0) / 60,
                        ).toFixed(1)} / ${formatMoney(
                            items
                                .map((item) => item.costAmount)
                                .reduce((a, b) => a + b, 0),
                            false,
                            'api',
                        )}`
                    },
                },
                {
                    title: 'Notes',
                },
            ]}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <span>
                                <small>
                                    {formatDateTime(item.startDate)}-
                                    {formatDateTime(item.endDate)}
                                </small>
                            </span>
                            <span>
                                <small>
                                    {item.clientId
                                        ? getUserDisplayName(item.client)
                                        : item.orgsData?.[0]?.name || ''}
                                </small>
                            </span>
                            <span>
                                <small>
                                    {(item.minutes / 60).toFixed(1)} /{' '}
                                    {formatMoney(item.costAmount, false, 'api')}
                                </small>
                            </span>
                            <span>
                                <small>{item.notes}</small>
                            </span>
                        </>
                    ),
                    getClassName: () => 'table-row',
                },
            ]}
            paginated
            apiUrl={'/timesheets'}
            renderMainButton
            newItemLabel={'New entry'}
            initialQuery={{
                teamMemberId: userId,
                between: `${dates[0].toISOString()},${dates[
                    dates.length - 1
                ].toISOString()}`,
            }}
            getItemsFromResponse={(data) => data?.results || []}
        />
    )
}

export default AdminTimesheetPage
