import useModal from '../../../common/hooks/useModal'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import { useState } from 'react'
import useData from '../../../common/hooks/useData'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import InlineForm from '../../../components/common/InlineForm'
import MessageSection from '../../../components/common/MessageSection'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function EditConfigObject() {
    const initialData = {
        stripeCustomerSubUpdateWebhookSecret: '',
        stripePremiumPriceId: '',
        stripeDonationProductId: '',
    }

    const setToast = useToast()
    const [error, setError] = useError()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const [data, setData] = useState(initialData)

    const { settingsError, settingsLoading, settingsMutate } = useData(
        `/v1/settings/${Object.keys(initialData).join(',')}?array=true`,
        'settings',
        (data) => data?.filter((v) => v) || [],
        false,
        false,
        (newData) =>
            setData(
                newData
                    .map((item, index) => [
                        Object.keys(initialData)[index],
                        item?.settingValue || null,
                    ])
                    .reduce((a, b) => ({ ...a, [b[0]]: b[1] || '' }), {}),
            ),
    )

    async function onSubmitItem(key, value) {
        setError('')

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/settings/${key}`,
                        {
                            settingValue: value,
                        },
                        'PATCH',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    settingsMutate()
                    setModal(null)
                    setToast('Saved')
                }}
            />,
            'Are you sure you want to save?',
        )
    }

    if (settingsLoading) {
        return <Spinner />
    }

    if (settingsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{settingsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <MessageSection title="Warning" type="warning">
                If a field value is not visible, it might be because it&apos;s
                encrypted.
            </MessageSection>
            <Break />

            {Object.keys(data).map((key) => (
                <div key={key}>
                    <InlineForm
                        id={key}
                        label={key}
                        value={data[key]}
                        onChange={(v) =>
                            setData({
                                ...data,
                                [key]: v,
                            })
                        }
                        onSubmit={onSubmitItem}
                    />
                    <br />
                </div>
            ))}
        </SectionContainer>
    )
}

export default EditConfigObject
