import { useLocation } from 'react-router-dom'

function useHashNav() {
    const { hash } = useLocation()

    function setHash(newHash) {
        location.replace(`#${newHash}`)
    }

    return [hash.replace('#', ''), setHash]
}

export default useHashNav
