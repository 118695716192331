import { useEffect, useRef } from 'react'
import useSWR from 'swr'
import swrFetcher from '../swrFetcher'

function useData(
    url,
    resourceName,
    getValue,
    revalidateOnFocus = false,
    revalidateOnReconnect = false,
    onSuccess,
    fallbackData,
    refreshInterval,
    revalidateOnMount,
    abortOnUmount = false,
) {
    const abortControllerRef = useRef(null)

    const options = {
        revalidateOnReconnect,
        revalidateOnFocus,
    }

    if (onSuccess) options.onSuccess = onSuccess
    if (refreshInterval) options.refreshInterval = refreshInterval
    if (revalidateOnMount) options.revalidateOnMount = true

    const {
        data: swrData,
        error: swrError,
        isValidating,
        mutate,
    } = useSWR(
        url,
        (url) => {
            abortControllerRef.current = new AbortController()
            return swrFetcher(url, abortControllerRef.current.signal)
        },
        options,
    )

    useEffect(() => {
        if (!swrData) {
            return
        }
        if (revalidateOnFocus || revalidateOnMount || revalidateOnReconnect) {
            return
        }
        if (onSuccess) {
            onSuccess(swrData)
            // onSuccess(getValue ? getValue(swrData) : swrData)
        }
        return function () {
            if (abortOnUmount && abortControllerRef.current) {
                abortControllerRef.current.abort(
                    'Component unmounted, aborting fetch',
                )
            }
        }
    }, [])

    if (!url && fallbackData) {
        if (onSuccess) onSuccess(fallbackData)
        return {
            [resourceName]: fallbackData,
            [`${resourceName}Error`]: null,
            [`${resourceName}Status`]: null,
            [`${resourceName}Loading`]: false,
            [`${resourceName}Mutate`]: () => {},
            [`${resourceName}Validating`]: false,
        }
    }

    const data = getValue ? getValue(swrData) : swrData

    const error = swrError ? swrError?.message || 'Unexpected error' : null
    const loading = url && !swrData && !swrError

    return {
        [resourceName]: data,
        [`${resourceName}Error`]: error,
        [`${resourceName}Status`]: swrError?.status,
        [`${resourceName}Loading`]: loading,
        [`${resourceName}Mutate`]: mutate,
        [`${resourceName}Validating`]: isValidating,
    }
}

export default useData
