import ErrorMessage from '../common/ErrorMessage'
import InlineStack from '../common/InlineStack'
import Spinner from '../common/Spinner'

function ResourceListSelectedItems(props) {
    const { loading, error, items, removeItem, getItemText } = props

    if (!items?.length && !loading && !error) return null

    return (
        <div className="resource-list-selected-items-container">
            {loading && <Spinner />}
            {Boolean(error) && <ErrorMessage section>{error}</ErrorMessage>}
            {Boolean(items?.length) && (
                <details open>
                    <summary>
                        <strong>{items.length} items selected</strong>
                    </summary>

                    <div className="resource-list-selected-items">
                        {items.map((item, i) => (
                            <button
                                key={i}
                                type="button"
                                title="Remove"
                                onClick={() => removeItem(item)}
                            >
                                <InlineStack itemsCenter>
                                    <span>{getItemText(item)}</span>
                                    <img
                                        src="/img/icons/x-white.svg"
                                        alt="close"
                                    />
                                </InlineStack>
                            </button>
                        ))}
                    </div>
                </details>
            )}
        </div>
    )
}

export default ResourceListSelectedItems
