import { formatMoney } from '../../common/helpers'
import useAuth from '../../common/hooks/useAuth'
import MessageSection from '../../components/common/MessageSection'

export default function TeamInfo({ jobType, teamRoles, defaultHourlyRate }) {
    const auth = useAuth()
    return (
        <>
            {auth.isAdmin && (
                <MessageSection
                    fullWidth
                    small
                    type="info"
                    title="Everyone sees this. Only admins can see the default hourly rate."
                ></MessageSection>
            )}

            <h3 className="h6 text-subdued m-0">Role</h3>
            <div className="text-caps">{jobType}</div>

            <h3 className="h6 text-subdued m-0">Teams</h3>
            <div className="text-caps">{(teamRoles || []).join(', ')}</div>

            <h3 className="h6 text-subdued m-0">Default hourly rate</h3>
            <div>
                {defaultHourlyRate && auth.isAdmin
                    ? formatMoney(defaultHourlyRate, false, 'app')
                    : '-'}
            </div>
        </>
    )
}
