// .company ready
import {
    daysBetween,
    formatDate,
    formatMoney,
    getUnitSumFromCalendar,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'

import ResourceList from '../../../components/admin/ResourceList'
import Tag from '../../../components/common/Tag'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
        activeStatus: {
            label: 'Active',
            dbFields: ['activeStatus', 'lastName', 'firstName', 'email'],
            asc: true,
        },
    },
    filters: {
        onCollections: {
            label: 'On hold',
            dbField: 'onHold',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        activeStatus: {
            label: 'Active',
            dbField: 'activeStatus',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

function getBillingIndicators(user) {
    // Date indications
    const paidToDate = formatDate(user.billingData.paidToDate) || ''
    const lastPaymentDate = formatDate(user.billingData.lastPaymentDate) || ''
    const lastOpenInvoiceDueDate =
        formatDate(user.billingData.lastOpenInvoiceDueDate) || ''
    const oldestPastDueInvoiceDueDate =
        formatDate(user.billingData.oldestPastDueInvoiceDueDate) || ''

    let dueDate
    let dueDays
    let warnDueDate = false
    if (user.billingData.oldestPastDueInvoiceDueDate) {
        dueDate = user.billingData.oldestPastDueInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
        warnDueDate = true
    } else if (
        user.billingData.lastOpenInvoiceDueDate * 1000 >
        new Date().getTime()
    ) {
        dueDate = user.billingData.lastOpenInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
    } else {
        dueDays = ''
    }

    // Money indications
    const amountOwed = user.billingData.amountOwed
        ? formatMoney(user.billingData.amountOwed)
        : ''
    const amountPastDue = user.billingData.amountPastDue
        ? formatMoney(user.billingData.amountPastDue)
        : ''

    let totalPaidInActivePeriod = 0
    totalPaidInActivePeriod = getUnitSumFromCalendar(
        {},
        '',
        user.billingData.paymentsCalendar,
    )

    return {
        paidToDate,
        lastPaymentDate,
        lastOpenInvoiceDueDate,
        oldestPastDueInvoiceDueDate,
        warnDueDate,
        dueDays,
        amountOwed,
        amountPastDue,
        totalPaidInActivePeriod,
    }
}

export default function StripeClientsList() {
    const auth = useAuth()
    return (
        <div className="billing-overview-list-container">
            <ResourceList
                paginated
                paginationLimit={5}
                apiUrl={'/payment/billing-overview-new'}
                getItemsFromResponse={(data) => data?.results}
                initialQuery={auth.isCollections ? { onCollections: true } : {}}
                itemClickPath={'/users/:item.id'}
                filters={filterFields}
                fields={[
                    {
                        column: 1,
                        getValue: () => (
                            <div className="client">
                                <div className="photo"></div>
                            </div>
                        ),
                        wrapperEl: 'div',
                    },
                    {
                        column: 1,
                        wrapperEl: 'div',

                        getValue: (item) => (
                            <div className="client">
                                <div>
                                    <div className="name">
                                        {item.firstName}&nbsp;
                                        {item.lastName}
                                    </div>
                                    <div>
                                        {(item.orgsData || [])
                                            .map((o) => o.name)
                                            .join(', ')}
                                    </div>
                                    <div className="email">
                                        <small>{`${item.email}`}</small>
                                    </div>
                                    {/* {client.customerName || client.customerEmail} */}
                                    {item.billingData?.customerDescription && (
                                        <div className="company">
                                            {
                                                item.billingData
                                                    .customerDescription
                                            }
                                        </div>
                                    )}
                                    {item.billingData.isActive ? (
                                        <Tag color={'success'}>Active</Tag>
                                    ) : (
                                        <Tag color="alert">Inactive</Tag>
                                    )}
                                    {item.onHold ? (
                                        <Tag color="alert">Hold</Tag>
                                    ) : null}
                                    {item.onBlackList ? (
                                        <Tag color="alert">Blacklist</Tag>
                                    ) : null}
                                    {item.onCollections ? (
                                        <Tag color="alert">Collections</Tag>
                                    ) : null}
                                </div>
                            </div>
                        ),
                    },

                    {
                        column: 1,
                        wrapperEl: 'div',

                        getValue: (item) => {
                            if (!item.billingData) return null
                            const {
                                lastPaymentDate,
                                lastOpenInvoiceDueDate,
                                oldestPastDueInvoiceDueDate,
                                totalPaidInActivePeriod,
                            } = getBillingIndicators(item)
                            return (
                                <div className="extras">
                                    <div>
                                        {item.billingData.collectionMethods
                                            ?.length ? (
                                            <p>
                                                <small>
                                                    Billing:&nbsp;
                                                    <strong>
                                                        {(
                                                            item.billingData
                                                                .collectionMethods ||
                                                            []
                                                        ).join(', ')}
                                                    </strong>
                                                </small>
                                            </p>
                                        ) : null}

                                        <p>
                                            <small>
                                                Last paid&nbsp;
                                                <strong>
                                                    {lastPaymentDate ||
                                                        '(never)'}
                                                </strong>
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Recent invoice due&nbsp;
                                                <strong>
                                                    {lastOpenInvoiceDueDate ||
                                                        '(never)'}
                                                </strong>
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Overdue since&nbsp;
                                                <strong>
                                                    {oldestPastDueInvoiceDueDate ||
                                                        '(never)'}
                                                </strong>
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Paid&nbsp;
                                                <strong>
                                                    {formatMoney(
                                                        totalPaidInActivePeriod ||
                                                            0,
                                                    )}
                                                </strong>
                                            </small>
                                        </p>
                                        <p>
                                            {/* <small>
                            {client.contracts.length
                                ? `Contract ${formatDate(
                                      client.contracts[0].startDate,
                                  )}-${formatDate(
                                      client.contracts[0].endDate,
                                  )} paid ${formatMoney(
                                      client.contracts[0].paid || 0,
                                  )}/${formatMoney(
                                      client.contracts[0].totalAmount || 0,
                                  )}${
                                      client.contracts.length > 1
                                          ? '+ more'
                                          : ''
                                  }`
                                : 'No contracts'}
                        </small> */}
                                        </p>
                                        <p>
                                            {item.billingData
                                                .oldestCancelDate ? (
                                                <small>
                                                    Canceled&nbsp;
                                                    <strong>
                                                        {formatDate(
                                                            item.billingData
                                                                .oldestCancelDate ||
                                                                0,
                                                        )}
                                                    </strong>
                                                </small>
                                            ) : null}
                                        </p>
                                    </div>
                                </div>
                            )
                        },
                    },
                    {
                        column: 2,
                        wrapperEl: 'div',

                        getValue: (item) => {
                            if (!item.billingData) return null
                            const {
                                paidToDate,
                                warnDueDate,
                                dueDays,
                                amountOwed,
                                amountPastDue,
                            } = getBillingIndicators(item)
                            return (
                                <div>
                                    <div
                                        className={`date ${
                                            warnDueDate ? 'alert' : ''
                                        }`}
                                    >
                                        Due&nbsp;
                                        {dueDays
                                            ? `${dueDays} days`
                                            : '' || '(never)'}
                                    </div>
                                    <div className="date">
                                        Paid to&nbsp;{paidToDate || '(never)'}
                                    </div>
                                    <div className="owed alert">
                                        {amountPastDue || '$0'} past due
                                    </div>
                                    <div className="owed">
                                        {amountOwed || '$0'} owed
                                    </div>
                                </div>
                            )
                        },
                    },
                ]}
            />
        </div>
    )
}
