import useAuth from '../../../common/hooks/useAuth'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import MessageSection from '../../../components/common/MessageSection'
import { ICONS } from '../../../common/constants'
import AppProductItem from './AppProductItem'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import { useState } from 'react'
import Input from '../../../components/common/data-form/Input'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
        },
    },
}

function isInOwnUserShopOnly(item, orgId) {
    const isInAppShop =
        item.enabledShopifyShopsInfo &&
        item.enabledShopifyShopsInfo.some((s) => !s.shopifyShop?.ownerId)

    const isInOwnUserShop =
        item.enabledShopifyShopsInfo &&
        item.enabledShopifyShopsInfo.some(
            (s) => s.shopifyShop?.ownerId === orgId,
        )

    return !isInAppShop && isInOwnUserShop
}

function WholesaleWarnings() {
    const auth = useAuth()

    const {
        profileCompletion,
        profileCompletionError,
        profileCompletionValidating,
        profileCompletionLoading,
    } = useData(
        auth.isClient ? '/v1/users/me/profile-completion' : null,
        'profileCompletion',
    )

    return (
        <section>
            {profileCompletionError ? (
                <ErrorMessage section>
                    There is an issue with loading profile information, please
                    contact Support.
                </ErrorMessage>
            ) : null}

            {profileCompletionValidating || profileCompletionLoading ? (
                <>
                    <br />
                    <Spinner />
                    <br />
                </>
            ) : null}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedProfileInfo === false && (
                    <MessageSection
                        small
                        title="There are some details missing from your profile"
                        type="warning"
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/details/edit`}
                            text="Edit my profile"
                        />
                    </MessageSection>
                )}

            {auth.user.orgsData.some((o) => o.organizationType === 'company') &&
                !profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgInfo === false && (
                    <MessageSection
                        type="warning"
                        small
                        fullWidthButton
                        title="There are some missing details about your company"
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/companies/${profileCompletion.completedCompanyOrgInfoOrgIds?.[0]}/edit`}
                            text="Fill in company info"
                        />
                    </MessageSection>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.isValidCompanyOrgMember === false && (
                    <MessageSection
                        title="Please add some info about your role in the company"
                        small
                        type="warning"
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/company-member-info/edit?organizationId=${profileCompletion?.isValidCompanyOrgMemberMissingOrgIds?.[0]}`}
                            text="Fill in info"
                        />
                    </MessageSection>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.hasOneAccountOnUserOrCompany === false && (
                    <MessageSection
                        title="Select the type of account you want"
                        type="warning"
                        small
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/accounts/new`}
                            text="Select accounts"
                        />
                    </MessageSection>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgTaxInfo === false && (
                    <MessageSection
                        title="Please fill in your EIN"
                        small
                        type="warning"
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/company-ein/edit?organizationId=${profileCompletion?.completedCompanyOrgTaxInfoMissingOrgIds?.[0]}`}
                            text="Go to form"
                        />
                    </MessageSection>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgTaxInfo === true &&
                profileCompletion?.completedCompanyOrgTaxForms === false && (
                    <MessageSection
                        title="Please complete your tax form"
                        small
                        type="warning"
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/tax-forms/edit?organizationId=${profileCompletion?.completedCompanyOrgTaxFormsMissingOrgIds?.[0]}`}
                            text="Go to form"
                        />
                    </MessageSection>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.signedVendorContractMissingOrgIds?.[0] && (
                    <MessageSection
                        title="There are some missing details about your Vendor account"
                        small
                        type="warning"
                        fullWidthButton
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/vendor/edit?organizationId=${profileCompletion?.signedVendorContractMissingOrgIds?.[0]}`}
                            text="Add vendor information"
                        />
                    </MessageSection>
                )}
        </section>
    )
}

export default function ProductsList({ showWholesaleWarnings }) {
    const auth = useAuth()
    const [orgId, setOrgId] = useState(
        auth.onlyCompany ? auth.onlyCompany.id : '',
    )
    const companies = auth.user.orgsData.map(
        (o) => o.organizationType === 'company',
    )

    return (
        <>
            {showWholesaleWarnings && (
                <SectionContainer>
                    <WholesaleWarnings />
                </SectionContainer>
            )}
            <SectionContainer>
                {auth.onlyCompany ? (
                    <div className="text-subdued">
                        {`You are viewing products for your company "${auth.onlyCompany.name}".`}
                    </div>
                ) : (
                    <Input
                        label="Company"
                        type="select"
                        id="company"
                        required
                        options={[
                            { value: '', label: 'Select Company' },
                            ...companies.map((c) => ({
                                value: c.id,
                                label: c.name,
                            })),
                        ]}
                        value={orgId}
                        onChange={(v) => setOrgId(v)}
                    />
                )}
            </SectionContainer>

            <ResourceList
                key={orgId}
                apiUrl={'/shop/products'}
                initialQuery={{
                    fields: 'title,ownerId',
                    populate:
                        'enabledShopifyShopsInfo.shopifyShop.syncStrategies,enabledShopifyShopsInfo.shopifyShop.ownerId',
                    ownerId: orgId,
                }}
                getItemsFromResponse={(data) => data?.results}
                paginated={true}
                filters={filterFields}
                paginationLimit={10}
                itemClickPath={'/shop/products/:item.id'}
                newItemPath={'/shop/products/new'}
                renderMainButton
                newItemLabel="New product"
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <AppProductItem item={item} />,
                        getClassName: () => 'title',
                    },
                ]}
                emptyText="Add your first product by clicking the '+' button below"
                actions={[
                    {
                        title: 'Edit',
                        link: '/shop/products/:item.id/edit',
                        icon: ICONS.EDIT_GRAY,
                        getHide: (item) =>
                            item.ownerId !== orgId ||
                            isInOwnUserShopOnly(item, orgId),
                    },
                    {
                        title: 'Quantities',
                        link: '/shop/products/:item.id/quantities/edit',
                        getHide: (item) =>
                            item.ownerId !== orgId ||
                            isInOwnUserShopOnly(item, orgId),
                    },
                ]}
            />
        </>
    )
}
