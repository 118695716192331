import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/common/Button'
import MainButton from '../../../components/admin/MainButton'
import { useState } from 'react'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import useData from '../../../common/hooks/useData'
import TextInput from '../../../components/common/data-form/TextInput'
import fetchAPI from '../../../common/fetchAPI'
import AddItemsFieldSet from '../../../components/common/data-form/AddItemsFieldSet'
import useModal from '../../../common/hooks/useModal'
import ContactFormPreview from './ContactFormPreview'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

const initFields = [
    {
        fieldType: 'text',
        label: 'My first field',
    },
]

export default function EditContactForm() {
    const { contactFormId } = useParams()
    const [data, setData] = useState({
        title: '',
        whitelistedDomains: '',
        fields: [],
        isSaveLead: false,
    })
    const [error, setError] = useError()
    const [loading, setLoading] = useState()
    const setToast = useToast()
    const navigate = useNavigate()
    const { setModal } = useModal()

    const { formLoading, formError } = useData(
        `/v1/contact-forms/${contactFormId}`,
        'form',
        undefined,
        undefined,
        undefined,
        (item) => {
            setExtraFieldSetNames(
                Array.from(
                    new Set(
                        item.fields
                            .filter((f) => f.groupName)
                            .map((f) => f.groupName),
                    ),
                ),
            )
            setData({
                whitelistedDomains: item.whitelistedDomains.join(','),
                recaptchaSiteKey: item.recaptchaSiteKey,
                recaptchaSecretKey: item.recaptchaSecretKey,
                title: item.title,
                isSaveLead: item.isSaveLead,
                fields: item.fields.map((f) => ({
                    label: f.label,
                    fieldType: f.fieldType,
                    required: f.required,
                    options: f.options ? f.options.join(',') : '',
                    placeholder: f.placeholder,
                    groupName: f.groupName,
                    isAppUserField: f.isAppUserField,
                })),
            })
        },
    )

    const [extraFieldSetNames, setExtraFieldSetNames] = useState([])
    const [newExtraFieldSetName, setNewExtraFieldSetName] = useState('')

    function handleExtraFieldSetAdd() {
        setExtraFieldSetNames([...extraFieldSetNames, newExtraFieldSetName])
        setNewExtraFieldSetName('')

        const newFieldData = initFields.map((f) => ({
            ...f,
            groupName: newExtraFieldSetName,
        }))
        setData({
            ...data,
            fields: [...data.fields, ...newFieldData],
        })
    }

    function handleExtraFieldSetRemoval(name) {
        setExtraFieldSetNames(extraFieldSetNames.filter((n) => n !== name))
        setData({
            ...data,
            fields: data.fields.filter((field) => field.groupName !== name),
        })
    }

    function handlePreview() {
        setModal(
            <ContactFormPreview data={data} />,
            "Preview: Appearance will depend on your site's CSS",
        )
    }

    async function handleSubmit(e) {
        setError('')
        e.preventDefault()

        if (!data.fields.length) {
            setError('You need to add at least one field.')
            return
        }

        setLoading(true)

        const { error } = await fetchAPI(
            `/v1/contact-forms/${contactFormId}`,
            {
                ...data,
                isSaveLead: data.isSaveLead,
                whitelistedDomains: data.whitelistedDomains.split(','),
                fields: data.fields.map((field) => ({
                    ...field,
                    options:
                        field.fieldType === 'select'
                            ? field.options?.split(',')
                            : undefined,
                })),
            },
            'PATCH',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Saved. You may need to copy a new embed code.')
        navigate(`/contact-forms/${contactFormId}`)
    }

    if (formLoading) {
        return <Spinner />
    }

    if (formError) {
        return (
            <SectionContainer>
                <ErrorMessage>{formError}</ErrorMessage>
            </SectionContainer>
        )
    }

    const hasFieldSets = data.fields.some((f) => f.groupName)
    if (hasFieldSets && !extraFieldSetNames.length) {
        return null
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={handleSubmit}>
                <label htmlFor={'form-title'} className="required">
                    Title
                </label>
                <TextInput
                    id={'form-title'}
                    value={data.title}
                    onChange={(v) =>
                        setData({
                            ...data,
                            title: v,
                        })
                    }
                    required
                />

                <label htmlFor={'form-domains'} className="required">
                    Whitelisted domains
                </label>
                <small className="input-info">
                    Separate domains with a comma. The contact form won&apos;t
                    accept submissions from other domains.
                </small>
                <TextInput
                    id={'form-domains'}
                    value={data.whitelistedDomains}
                    placeholder={'example.com,www.example.org'}
                    onChange={(v) =>
                        setData({
                            ...data,
                            whitelistedDomains: v,
                        })
                    }
                    required
                />

                <label htmlFor={'form-recaptcha'}>
                    ReCAPTCHA v2 Checkbox Site Key
                </label>
                <small className="input-info">
                    <a
                        href="https://www.google.com/recaptcha/admin/create"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Create one here
                    </a>{' '}
                    and add it to enable ReCAPTCHA.
                </small>
                <TextInput
                    id={'form-recaptcha'}
                    value={data.recaptchaSiteKey}
                    onChange={(v) =>
                        setData({
                            ...data,
                            recaptchaSiteKey: v,
                        })
                    }
                />

                <label htmlFor={'form-recaptcha-secret'}>
                    ReCAPTCHA v2 Checkbox Secret Key
                </label>
                <TextInput
                    id={'form-recaptcha-secret'}
                    value={data.recaptchaSecretKey}
                    onChange={(v) =>
                        setData({
                            ...data,
                            recaptchaSecretKey: v,
                        })
                    }
                />

                <CheckboxInput
                    id="form-save-lead"
                    label="Save submissions as leads"
                    value={data.isSaveLead}
                    onChange={(v) =>
                        setData({
                            ...data,
                            isSaveLead: v,
                        })
                    }
                    info={
                        'Save the form submission as a lead in the CRM. Field groups are not allowed. Requires at least a first name and last name field.'
                    }
                />
                <br />

                <AddItemsFieldSet data={data} setData={setData} />

                {!data.isSaveLead && (
                    <>
                        {extraFieldSetNames.map((name) => (
                            <div key={name}>
                                <AddItemsFieldSet
                                    title={name}
                                    data={data}
                                    setData={setData}
                                    deleteFieldSet={() =>
                                        handleExtraFieldSetRemoval(name)
                                    }
                                />
                            </div>
                        ))}

                        <label htmlFor="form-next-set">New field group</label>
                        <TextInput
                            id={'form-next-set'}
                            value={newExtraFieldSetName}
                            onChange={(v) => setNewExtraFieldSetName(v)}
                        />
                        <Button
                            text="Add field group"
                            outline
                            disabled={!newExtraFieldSetName}
                            onClick={handleExtraFieldSetAdd}
                        />
                    </>
                )}

                <Button
                    text="Preview"
                    isLoading={loading}
                    outline
                    type={'button'}
                    onClick={handlePreview}
                />

                <Button text="Save" isLoading={loading} type={'submit'} />

                <MainButton
                    disabled={loading}
                    functionality="SAVE"
                    loading={loading}
                />
            </form>
        </SectionContainer>
    )
}
