import { useParams } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useData from '../../../common/hooks/useData'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'alternativeFirstName',
        label: 'Alternative first name',
    },
    {
        key: 'alternativeLastName',
        label: 'Alternative last name',
    },
    {
        key: 'nickname',
        label: 'Nickname',
    },
    {
        key: 'marketingOptIn',
        label: 'Opted in for marketing',
        type: 'checkbox',
    },
    {
        key: 'facebook',
        label: 'Facebook profile URL',
    },
    {
        key: 'tiktok',
        label: 'Tiktok URL',
    },
    {
        key: 'youtube',
        label: 'Youtube URL',
    },
    {
        key: 'twitter',
        label: 'Twitter URL',
    },
    {
        key: 'pinterest',
        label: 'Pinterest URL',
    },
    {
        key: 'snapchat',
        label: 'Snapchat URL',
    },
    {
        key: 'linkedin',
        label: 'LinkedIn URL',
    },
    {
        key: 'nextdoor',
        label: 'Nextdoor URL',
    },
    {
        key: 'github',
        label: 'GitHub Handle',
    },
    {
        key: 'website',
        label: 'Website URL',
    },
]

function EditOtherContactInfo() {
    const { userId, contactId } = useParams()
    const isOwnProfile = !userId && !contactId

    let url
    if (isOwnProfile) {
        url = `/v1/users/me?fields=firstName&populate=contactDetails`
    } else if (userId) {
        url = `/v1/users/${userId}?fields=firstName&populate=contactDetails`
    } else {
        url = `/v1/contacts/${contactId}?fields=firstName&populate=contactDetails`
    }

    const { user, userError, userLoading } = useData(url, 'user')

    if (userLoading) {
        return <Spinner />
    }

    if (userError) {
        return <ErrorMessage section>{userError}</ErrorMessage>
    }

    if (!user?.contactDetails) {
        return (
            <ErrorMessage section>
                There was an unexpected error, please contact Support.
            </ErrorMessage>
        )
    }

    return (
        <SectionContainer>
            <DataFormNew
                mapItemToData={(item) => item}
                url={`/v1/contact-details/${user.contactDetails.id} `}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={
                    isOwnProfile
                        ? '/profile/contact-details'
                        : `/${contactId ? 'contacts' : 'users'}/${userId || contactId}/contact-details`
                }
                inputs={inputs}
                getBody={(data) => ({
                    ...inputs
                        .map((input) => input.key)
                        .reduce(
                            (a, b) => ({
                                ...a,
                                [b]: data[b],
                            }),
                            {},
                        ),
                })}
                mutationRegexes={[
                    /\/v1\/users*/,
                    /\/v1\/users\/me/,
                    /\/v1\/contacts/,
                ]}
                mainButton
            />
        </SectionContainer>
    )
}

export default EditOtherContactInfo
