// .company ready
import { useState } from 'react'
import { COUNTRIES, ICONS, US_STATES } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import ScrollableContent from '../../../components/common/ScrollableContent'
import SignatureInput from '../../../components/common/SignatureInput'
import TextInput from '../../../components/common/data-form/TextInput'
import FileInput from '../../../components/common/data-form/FileInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

const TERMS_HTML = (
    <>
        <div>
            <b>Events Location</b>
            <br />
        </div>
        <div>
            5435 Mills Civic Parkway #105, West Des Moines, Iowa 50266
            <br />
            Event offers both indoor and outdoor space.&nbsp;
        </div>
        <div>
            <br />
            <b>Building Details</b>
        </div>
        <div>
            Heated/Air-Conditioned building with Wifi
            <br />
            Steamer and Laundry may be used for an additional Fee
        </div>
        <div>
            High-traffic location off I-35 and Mills Civic Parkway at the
            entrance to West Glen
            <br />
        </div>
        <div>
            Family friendly restroom
            <b>
                <br />
                <br />
                Setup Time
            </b>
        </div>
        <div>
            Vendors are encouraged to bring their products in on Thursday before
            the event between 11-5PM. All other times have a fee.&nbsp;
            <br />
            <br />
        </div>
        <ul>
            <li>
                <b>Early setup on Monday </b>is available for an additional $30
                and you can leave products for the entire week. <br />
            </li>
            <li>
                <b>LATE SETUP </b>- Vendors who bring products in for setup on
                Friday, Saturday or Sunday of the event for setup will be
                charged an additional $30 fee for last minute setup with the
                team. This only applies to vendors who are bringing in products
                for new setup not for refreshing displays that are already
                prepared.&nbsp;
                <br />
            </li>
        </ul>
        <div>
            <br />
        </div>
        <div>
            <b>Requirements</b>
            <br />
        </div>
        <div>
            All products must be clearly tagged or labeled with pricing and the
            vendor&apos;s Paypal or Venmo QR Code. We can provide vendors with
            tags, printed price stickers, and apparel tags. Each label, tag, or
            sticker will be invoiced to the vendor and must be paid prior to
            labeling. We will count the number of products and help you label.
            No unlabeled products may be on the floor.&nbsp;
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Display Spaces</b>
            <br />
        </div>
        <div>
            Location within the store is on a first-come basis and displays may
            be rearranged or decorated by in-store staff. The store offers a
            number of professional retail display types to&nbsp;accommodate
            various product types. The registration fee allows you to bring
            multiple product types but you are encourage to bring no more than 3
            types of products in full runs. This means you will bring a minimum
            of THREE (3) of each style of item to make a retail display and
            accommodate&nbsp;sales.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            Example:
            <br />
        </div>
        <ul>
            <li>
                For <b>clothing</b>, bring no more than 2 styles with full runs
                (2 of each size).
                <br />
            </li>
            <li>
                For <b>jewelry</b>, bring a minimum of 3 MATCHING items per run.
                Multiple singles are not allowed. Jewelry displays
                may&nbsp;accommodate more styles than most other display
                spaces.&nbsp;
                <br />
            </li>
            <li>
                <b>Customizable items</b> like Tumblers, pens, t-shirts,
                freshies, keychains, earrings (if you sell items like tumblers
                or customizable items please bring a minimum of THREE SELLABLE
                samples and a minimum of 3 of each style)
                <br />
            </li>
            <li>
                <b>Furniture</b> is restricted to ONE item for registration,
                additional items will be $30 each
                <br />
            </li>
            <li>
                Additional product may be stored in the back or in your car to
                help facilitate a clean and professional display for shoppers.
                &nbsp;
                <br />
            </li>
        </ul>
        <div>
            <br />
        </div>
        <div>
            <b>Vendor Approval</b> <br />
            Space is limited and all vendors must be approved.&nbsp;
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Semi-Permanent Vendors</b>
            <br />
        </div>
        <div>
            For those vendors interested in becoming semi-permanent, a monthly
            fee is required for in-store presence for a minimum of 6 months.
            Semi-permanent vendors are eligible to participate in free-to-public
            market and networking events at no cost, however, bringing new items
            to these events without prior approval or payment is not permitted.
            We value the commitment of our semi-permanent vendors and look
            forward to supporting their growth within our community.
            <b>
                <br />
            </b>
            <br />
        </div>
        <div>
            <b>Payment</b>
            <br />
        </div>
        <div>
            Payment is required to save your spot at the event.{' '}
            <b id="docs-internal-guid-fa850fd2-7fff-3fbc-90bf-635ece5c98bd">
                <span>
                    <span>
                        <span>
                            <span>
                                YOU DO NOT have a spot in the&nbsp;event UNTIL
                                PAYMENT IS RECEIVED. We never hold spots without
                                payment and vendor approval.&nbsp;
                            </span>
                        </span>
                    </span>
                </span>
            </b>
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Refunds</b>
            <br />
        </div>
        <div>
            We will only refund your money if we cancel your spot because the
            products you sell are not a good fit for the event, see banned items
            for details. Refunds are at the sole description of XO STUDIO and
            will not be remitted for force majeur.&nbsp;
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Theft</b>
            <br />
        </div>
        <div>
            Please be advised that XO Studio has a security system and cameras
            in place, however, we cannot be held responsible for any lost,
            damaged, or stolen items caused by other vendors, shoppers, staff,
            or the public. It is recommended that vendors have proper insurance
            coverage in place to protect against damages or liabilities. By
            participating in this event, vendors accept full responsibility for
            their belongings and products.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Product Indemnification</b>
            <br />
        </div>
        <div>
            Indemnification: Vendor hereby agrees to indemnify and hold harmless
            XO STUDIO, Agency Couture, LLC, its staff, event planners and
            affiliates from any and all claims, demands, damages, costs,
            expenses, and liabilities, including reasonable attorneys&apos;
            fees, arising from or relating to any product defects, food
            contamination, sickness, negligence, illness, or death caused by
            Vendor&apos;s products. This indemnification shall survive the
            termination of this agreement.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Licenses and Permits</b>
            <br />
        </div>
        <div>
            Vendor shall be solely responsible for obtaining and maintaining any
            necessary permits and licenses required for the sale of their
            products. In the event that any such permits or licenses are
            required, Vendor shall, at its own expense, obtain and maintain such
            permits and licenses in full compliance with all applicable laws and
            regulations. Vendor agrees to indemnify, defend, and hold harmless
            XO STUDIO, Agency Couture, event planners, staff, and affiliates
            from and against any and all expenses, damages, legal fees, lost
            wages, and any other liability arising from Vendor&apos;s failure to
            obtain or maintain such permits or licenses. This indemnification
            obligation shall survive the termination of this agreement.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Force Majeur</b>
            <br />
        </div>
        <div>
            XO STUDIO, it&apos;s event planners, staff, successors and
            affiliates will not be held liable or required to submit refunds for
            acts of God or personal issues including but not limited to weather,
            sickness, traffic, building issues, licensing, permits, or
            cancellations of any kind.&nbsp;
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>
                Trash
                <br />
                <b id="docs-internal-guid-dfe582cf-7fff-7a7a-5c63-41b708ba8705">
                    <span>
                        <span>
                            <span>
                                <span>
                                    All trash must be picked up in your area at
                                    all times. Do not leave empty boxes,
                                    merchandise, shelves etc. in your spot. You
                                    must leave your area clean when you
                                    leave.&nbsp;All boxes, containers, or bags
                                    used for storing your products must be taken
                                    home or left in your car. Please do not
                                    leave them in store or they will be disposed
                                    of.{' '}
                                </span>
                            </span>
                        </span>
                    </span>
                </b>
                <br />
                <br />
                Registered Products
            </b>
        </div>
        <div>
            Please note that only products that have been registered beforehand
            may be sold at the event. This allows us to properly promote your
            brand and prepare adequate displays for your products. No items can
            be displayed without prior approval and any additional products not
            registered may incur additional charges. Thank you for your
            cooperation and understanding.
            <b>
                <br />
                <br />
                Positive Attitudes and Community of Helping One Another
            </b>
        </div>
        <div>
            We have a zero-tolerance policy for drama at our events. Any
            behavior that causes drama or negative remarks about the event on
            social media platforms will result in immediate dismissal from this
            and all future events. We strive to create a positive and
            professional atmosphere for all attendees and vendors and appreciate
            your cooperation in maintaining this standard.
            <br />
            <br />
            It is of utmost importance that all vendors and crafters show
            respect to their peers. Any disrespectful behavior towards fellow
            vendors or crafters will result in immediate dismissal from this and
            all future events. We are dedicated to creating a positive and
            professional atmosphere for all participants and appreciate your
            cooperation in maintaining a respectful environment.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>
                Questions
                <br />
                <b id="docs-internal-guid-69acde7e-7fff-c9cb-cdd1-e6411b76d1eb">
                    <span>
                        <span>
                            <span>
                                <span>
                                    Your questions can be answered via email,
                                    text or private messages. It is easier for
                                    us to respond this way with our busy
                                    schedules.&nbsp;
                                </span>
                            </span>
                        </span>
                    </span>
                </b>
            </b>
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Advertisement</b>
            <br />
        </div>
        <div>
            As a vendor, your participation in promoting the event is greatly
            appreciated. Please share the event page and help spread the word to
            your network. Fliers for the event will be provided for you to print
            and distribute. Each vendor is required to post about the event at
            least twice on Facebook, Instagram, and another platform of your
            choice. If you do not have a Facebook business page or Instagram
            account, our team is available to assist you in setting one up.
            Please stop by our store or give us a call at 515-679-6936 for
            assistance. Thank you for your support in making this a successful
            event.
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>MLMs/Direct Sale</b>
            <br />
        </div>
        <div>
            <b>
                <b id="docs-internal-guid-0c5eefd8-7fff-cb9d-3a7a-ee72625e3463">
                    <span>
                        <span>
                            <span>
                                <span>
                                    We welcome direct sale vendors to join us,
                                    but to ensure a diverse selection of
                                    products and to provide equal opportunities,
                                    we limit one consultant per company.
                                    Availability of vendor spaces at our events
                                    is limited and priority will be given to
                                    artists and professional brands. Register
                                    early to secure your spot. Please note that
                                    in the event that our vendor spaces become
                                    fully booked, we may need to cancel spots
                                    for direct sale consultants and allocate
                                    them to artists. We apologize for any
                                    inconvenience this may cause and appreciate
                                    your understanding as we strive to provide a
                                    diverse and unique shopping experience for
                                    our attendees. We want to assure our direct
                                    sale vendors that their spots are secured
                                    within 30 days prior to the event. This
                                    allows us to properly prepare and allocate
                                    resources for a successful show. Thank you
                                    for your cooperation and we look forward to
                                    having you as a part of our vendor
                                    community.{' '}
                                </span>
                            </span>
                        </span>
                    </span>
                </b>
            </b>
            <br />
        </div>
        <div>
            <br />
        </div>
        <div>
            <b>Banned Items</b>
            <br />
        </div>
        <div>
            XO STUDIO requires all products to conform to Facebook Community
            Standards and all local, state and federal laws. If you bring banned
            items your items will be removed from any displays and will not be
            promoted in any way. We may ask you to take them home or put them in
            the back for you to pick up later. <br />
            <br />
            XO STUDIO does not allow: <br />
        </div>
        <ul>
            <li>
                Weapons, Ammunition, Explosives, Guns
                <br />
            </li>
            <li>
                Organs
                <br />
            </li>
            <li>
                Medical Equipment
                <br />
            </li>
            <li>
                Masks
                <br />
            </li>
            <li>
                Military&nbsp;
                <span>
                    <span>
                        <span>
                            <span>Paraphernalia</span>
                        </span>
                    </span>
                </span>
                <br />
            </li>
            <li>
                Live Animals
                <br />
            </li>
            <li>
                Stolen Goods
                <br />
            </li>
            <li>
                Inspired Goods/Crafts (e.g. Disney, LV, Prada, Fake designer
                items, Counterfeit goods, Copyrighted or Trademarked material
                licensed or unlicensed, anything with a celebrity&apos;s face on
                it, or anything not designed by you)
                <br />
            </li>
            <li>
                Adult products or anything not family friendly
                <br />
            </li>
            <li>
                Alcohol (we may get licensed in the future for selling but are
                not currently)
                <br />
            </li>
            <li>
                Body Parts and Fluids
                <br />
            </li>
            <li>
                Discrimination
                <br />
            </li>
            <li>
                Currency
                <br />
            </li>
            <li>
                Documents
                <br />
            </li>
            <li>
                Gambling
                <br />
            </li>
            <li>
                Hazardous Materials and Goods
                <br />
            </li>
            <li>
                Human Exploitation
                <br />
            </li>
            <li>
                Recruiters for Jobs
                <br />
            </li>
            <li>
                Land
                <br />
            </li>
            <li>
                Violent Content
                <br />
            </li>
            <li>
                Hateful Content
                <br />
            </li>
            <li>
                Political Content
                <br />
            </li>
            <li>
                Prescription Drugs
                <br />
            </li>
            <li>
                Drug Paraphernalia&nbsp;
                <br />
            </li>
            <li>
                Recalled Products
                <br />
            </li>
            <li>
                Breast Feeding Products
                <br />
            </li>
            <li>
                The following MLMs: Pampered Chef, Pure Romance, Avon, Mary Kay,
                Norwex
                <br />
            </li>
            <li>
                Porn
                <br />
            </li>
            <li>
                Tobacco Products
                <br />
            </li>
            <li>
                CBD
                <br />
            </li>
            <li>
                Marijuana Paraphernalia
                <br />
            </li>
            <li>
                Mushrooms
                <span>
                    <br />
                </span>
            </li>
            <li>
                Used Products (Clothing, Dolls, Cosmetics)
                <br />
            </li>
            <li>
                Anything that is a sample and not available for immediate resale
                <br />
            </li>
            <li>
                Services may join our events but must be positioned in the back
                of the store or outside only
                <br />
            </li>
            <li>
                No popup displays without prior permission and additional fees
                may apply
                <br />
            </li>
            <li>
                <span>
                    Banners and marketing material - we&apos;re happy to help
                    you with marketing and displaying banners outside or inside
                    the store for an additional fee.&nbsp;
                </span>
                <br />
            </li>
        </ul>
        <div>
            Additional{' '}
            <a
                href="https://app.aco.digital/tos"
                target="_blank"
                rel="noopener noreferrer"
            >
                Terms and Conditions
            </a>{' '}
            apply. By registering you agree fully to all of our vendor and
            software Terms and Conditions.&nbsp;
            <br />
            Failure to adhere to these guidelines may result in immediate
            dismissal from this and all future events without a refund. We
            appreciate your understanding and cooperation in maintaining a
            professional and organized event for all participants. <br />
        </div>
    </>
)

function VendorEventRegistrationPage() {
    const auth = useAuth()
    const [data, setData] = useState({
        company: '',
        firstName: auth?.user?.firstName || '',
        lastName: auth?.user?.lastName || '',
        email: auth?.user?.email || '',
        phone:
            auth?.user?.contactDetails?.phones?.find(
                (item) => item.phoneType === 'main',
            )?.phone || '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        companyType: '',
        notes: '',
        accepted: false,
        signatureUri: null,
        productsCount: 1,
        daysCount: 1,
        photoFiles: null,
    })

    return (
        <div>
            <SectionContainer>
                <h1>Vendor Event Registration</h1>
            </SectionContainer>
            <SectionContainer>
                <h2>Step 1. Fill Out the Vendor Agreement</h2>
                <small className="text-subdued">
                    Please note, you only need to fill this out one time unless
                    it has been more than 30 days since the last time you
                    registered for an event and your contact information or what
                    you&apos;d like to bring has changed.
                </small>
                <p>
                    Vendors, sign up now! Fill out our registration form to
                    secure your display space at our upcoming event. Space is
                    limited, so don&apos;t wait! Looking forward to having you
                    join us!
                </p>
                <p>
                    Join us for a truly unforgettable experience at our
                    sip-and-shop event, happening from 11AM-6PM at 5435 Mills
                    Civic Pkwy #105, West Des Moines, IA. As a vendor, you have
                    the opportunity to showcase your products and connect with
                    like-minded individuals in a relaxed and festive atmosphere.
                    Don&apos;t miss out on this chance to grow your business and
                    connect with potential customers. Register now!
                </p>
                <form>
                    <h3>Contact Information</h3>
                    <label htmlFor="form-company" className="required">
                        Company
                    </label>
                    <TextInput
                        id="form-company"
                        type="text"
                        required
                        value={data.company}
                        onChange={(v) =>
                            setData({
                                ...data,
                                company: v,
                            })
                        }
                    />
                    <label htmlFor="form-first-name" className="required">
                        First name
                    </label>
                    <TextInput
                        id="form-first-name"
                        type="text"
                        required
                        value={data.firstName}
                        onChange={(v) =>
                            setData({
                                ...data,
                                firstName: v,
                            })
                        }
                    />
                    <label htmlFor="form-last-name" className="required">
                        Last name
                    </label>
                    <TextInput
                        id="form-last-name"
                        type="text"
                        required
                        value={data.lastName}
                        onChange={(v) =>
                            setData({
                                ...data,
                                lastName: v,
                            })
                        }
                    />
                    <label htmlFor="form-email" className="required">
                        Email
                    </label>
                    <TextInput
                        id="form-email"
                        type="email"
                        required
                        value={data.email}
                        onChange={(v) =>
                            setData({
                                ...data,
                                email: v,
                            })
                        }
                    />
                    <label htmlFor="form-phone" className="required">
                        Phone
                    </label>
                    <TextInput
                        id="form-phone"
                        type="tel"
                        required
                        value={data.phone}
                        onChange={(v) =>
                            setData({
                                ...data,
                                phone: v,
                            })
                        }
                    />
                    <label htmlFor="form-address" className="required">
                        Address
                    </label>
                    <small className="TextInput-info">
                        Required for payment processing
                    </small>
                    <TextInput
                        id="form-address"
                        type="text"
                        required
                        value={data.address}
                        onChange={(v) =>
                            setData({
                                ...data,
                                address: v,
                            })
                        }
                    />
                    <label htmlFor="form-city" className="required">
                        City
                    </label>
                    <TextInput
                        required
                        type="text"
                        id="form-city"
                        value={data.city}
                        onChange={(v) =>
                            setData({
                                ...data,
                                city: v,
                            })
                        }
                    />
                    <label htmlFor="form-state" className="required">
                        {data.country === 'United States' || !data.country
                            ? 'State'
                            : 'Province'}
                    </label>
                    {data.country === 'United States' || !data.country ? (
                        <SelectInput
                            required
                            id="form-state"
                            value={data.state}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    state: v,
                                })
                            }
                            options={US_STATES.map(({ text }) => ({
                                value: text,
                                label: text,
                            }))}
                        />
                    ) : (
                        <TextInput
                            required
                            type="text"
                            id="form-state"
                            value={data.state}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    state: v,
                                })
                            }
                        />
                    )}
                    <label htmlFor="form-zip" className="required">
                        Zip
                    </label>
                    <TextInput
                        required
                        type="text"
                        id="form-zip"
                        maxLength={10}
                        value={data.zip}
                        onChange={(v) =>
                            setData({
                                ...data,
                                zip: v,
                            })
                        }
                    />
                    <label htmlFor="form-country" className="required">
                        Country
                    </label>
                    <SelectInput
                        required
                        id="form-country"
                        value={data.country}
                        onChange={(v) =>
                            setData({
                                ...data,
                                country: v,
                            })
                        }
                        options={COUNTRIES.map(({ text }) => ({
                            value: text,
                            label: text,
                        }))}
                    />

                    <label htmlFor="form-company-type" className="required">
                        Type of company
                    </label>
                    <SelectInput
                        required
                        id="form-company-type"
                        value={data.companyType}
                        onChange={(v) =>
                            setData({
                                ...data,
                                companyType: v,
                            })
                        }
                        // All options
                        options={[
                            {
                                value: '',
                                label: '',
                            },
                            {
                                value: 'MLM',
                                label: 'MLM',
                            },
                            {
                                value: 'Artist',
                                label: 'Artist',
                            },
                            {
                                value: 'Professional Wholesale Brand',
                                label: 'Professional Wholesale Brand',
                            },
                            {
                                value: 'Crafter',
                                label: 'Crafter',
                            },
                            {
                                value: 'Service Provider',
                                label: 'Service Provider',
                            },
                            {
                                value: 'Food Services',
                                label: 'Food Services',
                            },
                        ]}
                    />

                    <label htmlFor="form-products-count" className="required">
                        How many products
                    </label>
                    <TextInput
                        required
                        min={1}
                        type="number"
                        id="form-products-count"
                        value={data.productsCount}
                        onChange={(v) =>
                            setData({
                                ...data,
                                productsCount: v,
                            })
                        }
                    />

                    <label htmlFor="form-days-count" className="required">
                        How many days
                    </label>
                    <TextInput
                        required
                        min={1}
                        type="number"
                        id="form-days-count"
                        value={data.daysCount}
                        onChange={(v) =>
                            setData({
                                ...data,
                                daysCount: v,
                            })
                        }
                    />

                    <label htmlFor="form-photos">
                        Upload photos of products
                    </label>
                    <FileInput
                        accept="image/jpeg"
                        multiple
                        id="form-photos"
                        onChange={(files) =>
                            setData({
                                ...data,
                                photoFiles: files,
                            })
                        }
                    />

                    {data.photoFiles !== null && data.photoFiles.length > 0 ? (
                        <>
                            <br />
                            <Button
                                text="Clear"
                                icon={ICONS.TRASH_RED}
                                outline
                                small
                                destructive
                                alignRight
                                onClick={() =>
                                    setData({
                                        ...data,
                                        photoFiles: null,
                                    })
                                }
                            />
                        </>
                    ) : null}
                    <br />

                    <label htmlFor="form-notes">
                        Additional notes or requests
                    </label>
                    <TextInput
                        rows={4}
                        id="form-notes"
                        value={data.notes}
                        onChange={(v) =>
                            setData({
                                ...data,
                                notes: v,
                            })
                        }
                    />
                    <small className="input-info">
                        Please let us know if you are signing up for only 1 or
                        two days of the event. Note that your registration will
                        be bumped in preference of other vendors if the event
                        fills up and you only register for one or two days vs.
                        all three.
                    </small>
                    <label>Terms and Conditions</label>
                    <ScrollableContent>{TERMS_HTML}</ScrollableContent>
                    <CheckboxInput
                        required
                        value={data.accepted}
                        id="form-accepted"
                        label="I accept the Terms and Conditions."
                        onChange={(accepted) => setData({ ...data, accepted })}
                    />
                    <br />
                    <SignatureInput
                        label="Signature"
                        onChange={(dataUri) =>
                            setData({
                                ...data,
                                signatureUri: dataUri,
                            })
                        }
                    />
                    <b>
                        Attention! Note that your spot is NOT RESERVED until you
                        PAY THE FULL AMOUNT DUE. After submitting this form,
                        please choose the dates you would like to reserve and
                        pay for them using the buttons below. You only need to
                        fill out this form one time.
                    </b>
                    <br />
                    <br />
                    <Button text="Submit" type="submit" />
                </form>
            </SectionContainer>
        </div>
    )
}

export default VendorEventRegistrationPage
