import { useState } from 'react'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import Input from '../../../components/common/data-form/Input'
import { useStore } from '../../../common/Context'

export default function UISettings() {
    const [rememberToggles, setRememberToggles] = useState(
        localStorage.getItem('forget_toggles_state') === 'false',
    )

    const [state, dispatch] = useStore()

    return (
        <section>
            <Input
                label="Theme"
                type="switch"
                id={'theme'}
                onChange={(v) => {
                    localStorage.setItem('theme', v)
                    dispatch({ type: 'SET_THEME', payload: v })
                }}
                options={[
                    { label: 'Light', value: 'light' },
                    { label: 'Dark', value: 'dark' },
                ]}
                value={state.theme}
            />

            <CheckboxInput
                info={'This will store the state of toggles (open / closed).'}
                id="remember_toggles"
                label="Remember toggles state"
                value={rememberToggles}
                onChange={(v) => {
                    if (v) {
                        localStorage.setItem('forget_toggles_state', 'false')
                    } else {
                        localStorage.removeItem('forget_toggles_state')
                        localStorage.removeItem('open_toggles')
                    }
                    setRememberToggles(v)
                }}
            />
        </section>
    )
}
