import { getUserDisplayName } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
}

const clientInputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
        autoComplete: 'new-password',
    },
    {
        key: 'username',
        label: 'Username',
        required: true,
        autoComplete: 'new-password',
    },
    {
        key: 'password',
        label: 'Password',
        required: true,
        type: 'password',
        autoComplete: 'new-password',
        infoBottom: "The app's administration can view and edit this password.",
    },
    {
        key: 'website',
        label: 'Website',
        autoComplete: 'new-password',
    },
    {
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
        rows: 2,
        autoComplete: 'new-password',
    },
]

const inputs = [
    ...clientInputs,
    {
        key: 'allowedUserIds',
        label: 'Share with others',
        type: 'resourceList',
        apiUrl: '/users',
        initialQuery: {
            fields: 'email,firstName,lastName',
        },
        selectedIdField: 'id',
        filters: filterFields,
        paginated: true,
        loadMoreButton: true,

        paginationLimit: 5,
        getItemsFromResponse: (data) => data?.results,
        getSelectedItemText: (item) => getUserDisplayName(item),
        fields: [
            {
                column: 1,
                getValue: (item) => getUserDisplayName(item),
            },
        ],
    },
]

export default function CreatePassword() {
    const auth = useAuth()

    return (
        <SectionContainer>
            <DataFormNew
                url={'/v1/vault'}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={'/vault'}
                inputs={auth.isClient ? clientInputs : inputs}
                mainButton
            />
        </SectionContainer>
    )
}
