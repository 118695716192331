import { ICONS } from '../../common/constants'
import fetchAPI from '../../common/fetchAPI'
import useData from '../../common/hooks/useData'
import useToast from '../../common/hooks/useToast'
import Button from '../../components/common/Button'

export default function FavoriteInfo({ type, id }) {
    const { favorite, favoriteError, favoriteMutate, favoriteLoading } =
        useData(
            `/v1/users/favorites?resourceId=${id}&resource=${type}`,
            'favorite',
            (data) => data?.[0],
        )
    const setToast = useToast()

    const isFavorite = favorite && !favoriteError

    async function onChange() {
        if (isFavorite) {
            const { error } = await fetchAPI(
                `/v1/users/favorites/${favorite.favoriteId}`,
                {},
                'DELETE',
            )
            if (error) {
                setToast(error, 'alert')
                return
            }
        } else {
            const { error } = await fetchAPI(
                '/v1/users/favorites',
                {
                    resourceId: id,
                    resource: type,
                },
                'POST',
            )
            if (error) {
                setToast(error, 'alert')
                return
            }
        }
        favoriteMutate()
    }

    if (favoriteLoading) {
        return null
    }

    return (
        <Button
            onClick={onChange}
            hideText
            plain
            small
            text="Favorite"
            icon={isFavorite ? ICONS.HEART_FILLED_WHITE : ICONS.HEART_WHITE}
        />
    )
}
