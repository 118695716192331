import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import useLongPress from '../../common/hooks/useLongPress'
import Button from './Button'
import { useScrollLock } from '../../common/hooks/useScrollLock'

const MAX_WIDTH = 220
const NAV_HEIGHT = 80
const AFTER_DIMENSION = 20

function handlePosition(buttonEl, childrenEl, position) {
    let buttonCoords = {
        x: buttonEl.getBoundingClientRect().x,
        y: buttonEl.getBoundingClientRect().y,
        width: buttonEl.getBoundingClientRect().width,
        height: buttonEl.getBoundingClientRect().height,
    }

    let childrenCoords = {
        x: childrenEl.getBoundingClientRect().x,
        y: childrenEl.getBoundingClientRect().y,
        width: childrenEl.getBoundingClientRect().width,
        height: childrenEl.getBoundingClientRect().height,
    }

    // Calculate the middle of the button
    const middleOfButtonX = buttonCoords.x + buttonCoords.width / 2
    const middleOfButtonY = buttonCoords.y + buttonCoords.height / 2
    const bottomOfButtonY = buttonCoords.y + buttonCoords.height
    const leftOfButton = buttonCoords.x

    let finalPosObject

    const bottomPos = {
        '--left': `${leftOfButton}px`,
        '--top': `${bottomOfButtonY + AFTER_DIMENSION}px`,
        '--after-left': `${middleOfButtonX - AFTER_DIMENSION / 2}px`,
        '--after-top': `${bottomOfButtonY}px`,
        '--arrow-transform': 'rotate(-90deg)',
        '--max-height': `unset`,
    }

    const topPos = {
        '--left': `${leftOfButton}px`,
        '--top': `${
            buttonCoords.y - childrenCoords.height - AFTER_DIMENSION
        }px`,
        '--after-left': `${middleOfButtonX - AFTER_DIMENSION / 2}px`,
        '--after-top': `${buttonCoords.y - AFTER_DIMENSION}px`,
        '--max-height': `unset`,
        '--arrow-transform': 'rotate(90deg)',
    }

    const leftPos = {
        '--left': `${leftOfButton - childrenCoords.width - AFTER_DIMENSION}px`,
        '--top': `${buttonCoords.y}px`,
        '--after-left': `${leftOfButton - AFTER_DIMENSION}px`,
        '--after-top': `${middleOfButtonY - AFTER_DIMENSION / 2}px`,
        '--max-height': `unset`,
        '--arrow-transform': 'rotate(0)',
    }

    const rightPos = {
        '--left': `${leftOfButton + buttonCoords.width + AFTER_DIMENSION}px`,
        '--top': `${buttonCoords.y}px`,
        '--after-left': `${leftOfButton + buttonCoords.width}px`,
        '--after-top': `${middleOfButtonY - AFTER_DIMENSION / 2}px`,
        '--max-height': `unset`,
        '--arrow-transform': 'rotate(180deg)',
    }

    switch (position) {
        case 'bottom':
            finalPosObject = bottomPos
            break
        case 'top':
            finalPosObject = topPos
            break
        case 'left':
            finalPosObject = leftPos
            break
        case 'right':
            finalPosObject = rightPos
            break
        default:
            break
    }

    childrenEl.style.opacity = 0
    Object.entries(finalPosObject).forEach(([key, value]) => {
        childrenEl.style.setProperty(key, value)
    })

    childrenCoords = {
        x: childrenEl.getBoundingClientRect().x,
        y: childrenEl.getBoundingClientRect().y,
        width: childrenEl.getBoundingClientRect().width,
        height: childrenEl.getBoundingClientRect().height,
    }

    const overflowsHeight =
        childrenCoords.height > window.innerHeight - NAV_HEIGHT

    const overflowsBottom =
        childrenCoords.y + childrenCoords.height >
        window.innerHeight - NAV_HEIGHT
    const overflowsTop = childrenCoords.y < 0
    const overflowsLeft = childrenCoords.x < 0
    const overflowsRight =
        childrenCoords.x + childrenCoords.width > window.innerWidth

    if (overflowsBottom) {
        if (position === 'bottom') {
            // If it is, the children must go on the top of the button
            finalPosObject = topPos
        } else if (position === 'left' || position === 'right') {
            const newBottom = window.innerHeight - NAV_HEIGHT - 10
            const newTop = newBottom - childrenCoords.height
            finalPosObject['--top'] = `${newTop}px`
        }
    }

    // Check if the top of the element is outside the viewport
    if (overflowsTop) {
        if (position === 'top') {
            // If it is, the children must go on the bottom of the button
            finalPosObject = bottomPos
        }
    }

    if (overflowsHeight) {
        finalPosObject = rightPos
        finalPosObject['--top'] = `${10}px`
        finalPosObject['--max-height'] = `calc(100vh - ${NAV_HEIGHT + 20}px)`

        // finalPosObject['--left'] = // Put it to the right of the button
        //     middleOfButtonX + buttonCoords.width / 2 + 10 + 'px'
    }

    // Check if the left of the element is outside the viewport
    if (overflowsLeft) {
        finalPosObject['--left'] = `${10}px`
    }

    // Check if the right of the element is outside the viewport
    if (overflowsRight) {
        finalPosObject['--left'] = `${
            window.innerWidth - childrenCoords.width - 10
        }px`
    }

    childrenEl.style.opacity = 1
    Object.entries(finalPosObject).forEach(([key, value]) => {
        childrenEl.style.setProperty(key, value)
    })
}

const Popover = forwardRef(function PopoverInner(
    {
        children,
        renderActivator,
        isLongPress,
        onClick,
        position = 'bottom',
        closeOnChildClick,
    },
    ref,
) {
    const [isOpen, setIsOpen] = useState(false)
    const activatorRef = useRef(undefined)
    const childrenRef = useRef(undefined)

    const longPressListeners = useLongPress(handleToggleClick, onClick)
    const { lockScroll, unlockScroll } = useScrollLock()

    useImperativeHandle(ref, function () {
        return {
            closePopover: () => setIsOpen(false),
        }
    })

    useEffect(
        function () {
            if (isOpen) {
                lockScroll()
            } else {
                unlockScroll()
            }

            return function () {
                unlockScroll()
            }
        },
        [isOpen],
    )

    function handleToggleClick(e) {
        e.preventDefault()
        setIsOpen((v) => !v)

        // e.currentTarget doesn't work when we're
        // using the longPressListeners
        const buttonEl = activatorRef.current
        const childrenEl = childrenRef.current

        handlePosition(buttonEl, childrenEl, position)
    }

    function handleOverlayClick(e) {
        e.preventDefault()
        setIsOpen(false)
    }

    const activatorListeners = isLongPress
        ? longPressListeners
        : {
              onClick: handleToggleClick,
          }

    return (
        <>
            <div
                tabIndex="0"
                className={`popover`}
                style={{ '--z-index': isOpen ? 25 : 0 }}
            >
                {renderActivator(activatorRef, activatorListeners)}

                <div
                    ref={childrenRef}
                    style={{
                        '--max-width': `${MAX_WIDTH}px`,
                        opacity: 0,
                    }}
                    className={`popover-childen${isOpen ? ' open' : ''}`}
                >
                    {closeOnChildClick ? (
                        <div onClick={() => setIsOpen(false)}>{children}</div>
                    ) : (
                        children
                    )}
                </div>
                <div
                    onClick={handleOverlayClick}
                    className="popover-overlay"
                ></div>
            </div>
        </>
    )
})

export default Popover
