import React from 'react'
import { useStore } from '../../common/Context'
import useModal from '../../common/hooks/useModal'

function Modal() {
    /**
     * Use more context in texts e.g. Confirm placing (company name) on Hold
     * Confirm button: full width primary
     * Cancel: link appearance
     *
     */
    const [state] = useStore()
    const { setModal } = useModal()

    if (!state.isModalOpen || !state.modalChildren) return <></>

    return (
        <div className="modal">
            <div
                className={`modal-container ${state.modalClass} ${
                    state.modalTitle ? 'has-title' : ''
                }`}
            >
                <div className="modal-header">
                    <h4>{state.modalTitle}</h4>

                    <button
                        className="modal-close-btn"
                        onClick={() => setModal(null)}
                    >
                        &#10005;
                    </button>
                </div>
                <div className="modal-content">{state.modalChildren}</div>
            </div>
            <div className="modal-overlay"></div>
        </div>
    )
}

export default Modal
