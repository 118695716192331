import { ICONS } from '../../../common/constants'
import ResourceList from '../../../components/admin/ResourceList'
import AppProductItem from '../products/AppProductItem'

const filterFields = {
    search: true,
    sort: {
        title: {
            label: 'Title',
            dbFields: ['title'],
            iconAsc: ICONS.BELL_WHITE,
            iconDesc: ICONS.CHECK_WHITE,
            asc: true,
        },
    },
}

export default function AllProductsList() {
    return (
        <ResourceList
            itemClickPath={'/shop/products/:item.id'}
            apiUrl={'/shop/products'}
            initialQuery={{
                fields: 'title,ownerId',
                populate:
                    'enabledShopifyShopsInfo.shopifyShop.syncStrategies,enabledShopifyShopsInfo.shopifyShop.ownerId',
            }}
            itemViews={['list', 'square', 'double']}
            getItemsFromResponse={(data) => data?.results}
            paginated={true}
            filters={filterFields}
            paginationLimit={10}
            fields={[
                {
                    column: 1,
                    getValue: (item) => <AppProductItem item={item} />,
                    getClassName: () => 'title',
                },
            ]}
        />
    )
}
