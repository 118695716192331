import { useStore } from '../Context'

function useModal() {
    const [state, dispatch] = useStore()
    const { isModalLoading } = state

    function setModal(content, title, className) {
        if (!content) {
            dispatch({ type: 'CLOSE_MODAL' })
            dispatch({
                type: 'SET_MODAL_CLASS',
                payload: '',
            })
            return
        }
        dispatch({
            type: 'OPEN_MODAL',
            payload: content,
        })
        if (title) {
            dispatch({
                type: 'SET_MODAL_TITLE',
                payload: title,
            })
        }
        setTimeout(() => {
            dispatch({
                type: 'SET_MODAL_CLASS',
                payload: `show ${className || ''}`,
            })
        }, 100)
    }

    function setModalLoading(isLoading) {
        dispatch({ type: 'SET_MODAL_LOADING', payload: isLoading })
    }

    return { setModal, setModalLoading, isModalLoading }
}

export default useModal
