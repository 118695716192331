import { useRef, useState } from 'react'
import Button from '../Button'
import { ICONS } from '../../../common/constants'

function BrowserFileInput({
    id = 'file',
    required = false,
    multiple = false,
    accept = '*',
    setFiles,
    prevPreviewValue,
    clearOnSelect,
    small,
    button,
}) {
    const ref = useRef(null)
    const [fileNames, setFileNames] = useState([])

    function onChange(e) {
        setFileNames(Array.from(e.target.files).map((file) => file.name))
        setFiles(e.target.files)
        if (clearOnSelect) {
            onClear()
        }
    }

    function onClear() {
        ref.current.value = null
        setFileNames([])
    }

    async function handleKeyDown(e) {
        // Check if CTRL+V is pressed
        if (!e.ctrlKey || e.key !== 'v') return

        try {
            const clipboardItems = await navigator.clipboard.read()
            const imageItem = clipboardItems.find(
                (item) =>
                    item.types.includes('image/png') ||
                    item.types.includes('image/jpeg'),
            )

            if (!imageItem) return

            const imageType = imageItem.types.find((type) =>
                type.startsWith('image/'),
            )
            const blob = await imageItem.getType(imageType)
            const file = new File(
                [blob],
                `pasted-image.${blob.type.split('/')[1]}`,
                {
                    type: blob.type,
                },
            )

            const dataTransfer = new DataTransfer()
            dataTransfer.items.add(file)
            ref.current.files = dataTransfer.files

            // Manually trigger the onChange event
            onChange({ target: { files: dataTransfer.files } })
        } catch (err) {
            console.error('Failed to read clipboard contents:', err)
        }
    }

    return (
        <div
            className={`browser-file-input${
                small ? ' browser-file-input-small' : ''
            }${button ? ' browser-file-input-button' : ''}`}
        >
            {Boolean(button) && (
                <div className="button-container">
                    <Button
                        small
                        icon={ICONS.IMAGE_WHITE}
                        onClick={() => {
                            ref.current.click()
                        }}
                    />
                </div>
            )}
            {fileNames.length > 0 && (
                <ul>
                    {fileNames.map((name, index) => (
                        <li key={index}>
                            <div>{name}</div>
                        </li>
                    ))}
                </ul>
            )}

            {fileNames.length > 0 && (
                <Button
                    type="button"
                    outline
                    destructive
                    tiny
                    alignRight
                    text="Clear all"
                    onClick={onClear}
                />
            )}

            <div className="input-container">
                {Boolean(prevPreviewValue) && (
                    <img
                        src={prevPreviewValue}
                        alt="preview"
                        width={100}
                        height={100}
                    />
                )}

                <input
                    ref={ref}
                    type="file"
                    id={id}
                    accept={accept}
                    required={required}
                    onInput={onChange}
                    multiple={multiple}
                />

                <div
                    onClick={() => {}}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    className="paste-handle"
                >
                    <small>
                        CTRL
                        <br />
                        +V
                    </small>
                </div>
            </div>
        </div>
    )
}

export default BrowserFileInput
