import { Link } from 'react-router-dom'

function Terms({ children }) {
    return (
        <div className="terms">
            <small>
                <Link to="/pages/terms-of-service">App Terms,</Link>
                &nbsp;
                <a
                    href="https://www.aco.digital/Resources/termsofuse"
                    target="_blank"
                    rel="noreferrer"
                >
                    Terms
                </a>
                {/* &nbsp;&&nbsp;
                <a
                    href="https://www.aco.digital/Resources/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy
                </a> */}
                &nbsp;&nbsp;|&nbsp;
                <Link to="/pages/privacypolicy">Privacy Policy</Link>
                &nbsp;&nbsp;|&nbsp;<Link to="/pages/ccpa">CCPA</Link>
                &nbsp;&nbsp;|&nbsp; <Link to="/pages/faqs">FAQ</Link>
                &nbsp;&nbsp;|&nbsp; <Link to="/pages/hours">Hours</Link>
                &nbsp;&nbsp;|&nbsp; <Link to="/pages/donate">Donate</Link>
                &nbsp;&nbsp;|&nbsp;&#169;&nbsp;
                <Link to="/pages/aCo">Agency Couture LLC</Link>&nbsp;
                {new Date().getFullYear()}
            </small>
            <br />
            <br />
            <div>{children}</div>
        </div>
    )
}

export default Terms
