import { useParams } from 'react-router-dom'
import DataFormNew from '../../components/common/DataFormNew'
import SectionContainer from '../../components/common/SectionContainer'

const inputs = [
    {
        key: 'internalNotes',
        type: 'textarea',
        label: 'Internal notes',
        rows: 3,
    },
    {
        key: 'externalNotes',
        type: 'textarea',
        label: 'External notes',
        rows: 3,
    },
]

export default function EditUserNotes() {
    const { userId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/users/${userId}?fields=internalNotes,externalNotes`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={-1}
                inputs={inputs}
                mapItemToData={(item) => ({
                    internalNotes: item.internalNotes,
                    externalNotes: item.externalNotes,
                })}
                mutationRegexes={[/\/v1\/users/]}
                mainButton
            />
        </SectionContainer>
    )
}
