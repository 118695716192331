import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { formatDate, formatMoney } from '../../../common/helpers'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'

export default function DonationSubsList() {
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    async function onCancel(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/app-billing/me/donations/${item.id}`,
                        {},
                        'DELETE',
                    )
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    matchMutate(/\/v1\/app-billing\/me\/donations/)
                    setToast('Subscription cancelled')
                }}
                onCancel={() => setModal(null)}
            />,
            'Cancel donation subscription?',
        )
    }

    return (
        <ResourceList
            title="Donation subscriptions"
            apiUrl={'/app-billing/me/donations'}
            paginated
            emptyText={<>No donation subscriptions.</>}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => formatMoney(item?.amount, false, 'app'),
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        `Monthly, since ${formatDate(
                            Math.floor(item?.createdAt / 1000),
                        )}`,
                    getClassName: () => 'subtitle',
                },
            ]}
            actions={[
                {
                    title: 'Cancel',
                    icon: ICONS.TRASH_RED,
                    type: 'alert',
                    onClick: (item) => onCancel(item),
                },
            ]}
        />
    )
}
