import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function TempMenuRedirect() {
    const navigate = useNavigate()
    useEffect(function () {
        navigate('/pages/menu')
    }, [])
    return null
}
