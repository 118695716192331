import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import ResourceList from '../../../components/admin/ResourceList'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function RepoPage() {
    const { repoName } = useParams()
    // const setToast = useToast()
    // const matchMutate = useMatchMutate()
    // const navigate = useNavigate()
    // const { setModal, isModalLoading, setModalLoading } = useModal()
    const { repo, repoLoading, repoError } = useData(
        `/v1/repos/${repoName}`,
        'repo',
    )

    // async function onDelete(item) {
    //     setModal(
    //         <ConfirmModalContent
    //             text={`Are you sure?`}
    //             onConfirm={async () => {
    //                 if (isModalLoading) return
    //                 setModalLoading(true)

    //                 const { error } = await fetchAPI(
    //                     `/v1/contact-forms/${repoName}/submissions/${item.id}`,
    //                     {},
    //                     'DELETE',
    //                 )
    //                 setModalLoading(false)

    //                 if (error) {
    //                     setModal(null)
    //                     setToast(error, 'alert')
    //                     return
    //                 }
    //                 setModal(null)
    //                 matchMutate(/\/v1\/contact-forms/)
    //                 setToast('Deleted message')
    //             }}
    //         />,
    //     )
    // }

    if (repoLoading) {
        return <Spinner />
    }

    if (repoError) {
        return (
            <SectionContainer>
                <ErrorMessage>{repoError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer className="info-header">
                <h2>{repo.name}</h2>

                {/* <ActionsDropdown
                    actions={[
                        {
                            title: 'Copy embed code',
                            onClick: () => {
                                copyToClipboard(form.embedHtml)
                                setToast('Copied to clipboard')
                            },
                            icon: ICONS.CLIPBOARD_GRAY,
                        },
                        {
                            title: 'Edit',
                            icon: ICONS.EDIT_GRAY,
                            onClick: () =>
                                navigate(`/contact-forms/${repoName}/edit`),
                        },
                    ]}
                /> */}
            </SectionContainer>

            <SectionContainer>
                <a href={repo.editUrl} target="_blank" rel="noreferrer">
                    Edit with VSCode →
                </a>
            </SectionContainer>
            <ResourceList
                title="Collaborators"
                items={repo.collaborators || []}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.handle,
                    },
                ]}
                emptyText={`No collaborators yet`}
            />
        </>
    )
}
