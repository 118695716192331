import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import { formatDateTime } from '../../../common/helpers'

export default function SocialPostPlanPostsList() {
    const { socialPostPlanId } = useParams()
    const auth = useAuth()

    return (
        <ResourceList
            apiUrl={'/social-posts'}
            initialQuery={{
                userId: auth.user.id,
                socialPostingPlanId: socialPostPlanId,
            }}
            getItemsFromResponse={(data) => data?.results || []}
            paginated={true}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            {item.imageUrl ? (
                                <img
                                    width={50}
                                    height={50}
                                    alt="post"
                                    src={item.imageUrl}
                                />
                            ) : null}

                            <div>
                                {item.postedDate
                                    ? formatDateTime(item.postedDate)
                                    : null}
                            </div>
                        </>
                    ),
                    getClassName: (item) => (item.imageUrl ? 'with-image' : ''),
                },
            ]}
        />
    )
}
