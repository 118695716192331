import { useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { addressToString } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useData from '../../../common/hooks/useData'
import Tag from '../../../components/common/Tag'
import InfoList from '../../../components/common/InfoList'
import InlineStack from '../../../components/common/InlineStack'
import Button from '../../../components/common/Button'
import InputModalContent from '../../../components/common/InputModalContent'
import SectionContainer from '../../../components/common/SectionContainer'

function ContactDetailsPage() {
    const { userId, contactId } = useParams()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const isOwnProfile = !userId && !contactId

    let url
    if (isOwnProfile) {
        url = `/v1/users/me?fields=firstName&populate=contactDetails`
    } else if (userId) {
        url = `/v1/users/${userId}?fields=firstName&populate=contactDetails`
    } else {
        url = `/v1/contacts/${contactId}?fields=firstName&populate=contactDetails`
    }
    const { user, userError, userLoading, userMutate } = useData(url, 'user')

    if (userLoading) {
        return <Spinner />
    }

    if (userError) {
        return <ErrorMessage section>{userError}</ErrorMessage>
    }

    function onDeletePhone(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-details/${user.contactDetails.id}`,
                        {
                            phones: user.contactDetails.phones
                                .filter((p) => p._id !== item._id)
                                .map((p) => ({ ...p, _id: undefined })),
                        },
                        'PATCH',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    userMutate()
                    setToast('Deleted')
                }}
            />,
            'Are you sure you want to delete this phone?',
        )
    }

    function onEditPhone(item) {
        setModal(
            <InputModalContent
                defaultValue={item.phone}
                label={'Phone'}
                type={'tel'}
                onConfirm={async ({ text }) => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-details/${user.contactDetails.id}`,
                        {
                            phones: user.contactDetails.phones
                                .map((p) =>
                                    p._id === item._id
                                        ? { ...p, phone: text }
                                        : p,
                                )
                                .map((p) => ({ ...p, _id: undefined })),
                        },
                        'PATCH',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    userMutate()
                    setToast('Updated')
                }}
            />,
            'Phone',
        )
    }

    function onDeleteEmail(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-details/${user.contactDetails.id}`,
                        {
                            emails: user.contactDetails.emails
                                .filter((p) => p._id !== item._id)
                                .map((p) => ({
                                    ...p,
                                    _id: undefined,
                                    isVerified: undefined,
                                })),
                        },
                        'PATCH',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    userMutate()
                    setToast('Deleted')
                }}
            />,
            'Are you sure you want to delete this email?',
        )
    }

    function onEditEmail(item) {
        setModal(
            <InputModalContent
                defaultValue={item.email}
                label={'Email'}
                type={'email'}
                onConfirm={async ({ text }) => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-details/${user.contactDetails.id}`,
                        {
                            emails: user.contactDetails.emails
                                .map((p) =>
                                    p._id === item._id
                                        ? { ...p, email: text }
                                        : p,
                                )
                                .map((p) => ({
                                    ...p,
                                    _id: undefined,
                                    isVerified: undefined,
                                })),
                        },
                        'PATCH',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    userMutate()
                    setToast('Updated')
                }}
            />,
            'Email',
        )
    }

    let newItemPath = ''
    let itemEditPath = ''
    if (userId) {
        newItemPath = `/users/${userId}/contact-details/new`
        itemEditPath = `/users/${userId}/contact-details/:item.id/edit`
    } else if (contactId) {
        newItemPath = `/contacts/${contactId}/contact-details/new`
        itemEditPath = `/contacts/${contactId}/contact-details/:item.id/edit`
    } else {
        newItemPath = `/profile/contact-details/new`
        itemEditPath = `/profile/contact-details/:item.id/edit`
    }

    const contactDetails = user.contactDetails || {
        phones: [],
        emails: [],
    }

    const phonesKey = `phones-${
        contactDetails.phones.length
    }-${contactDetails.phones.map((p) => p.phone).join(',')}`

    const emailsKey = `emails-${
        contactDetails.emails.length
    }-${contactDetails.emails.map((p) => p.email).join(',')}`

    return (
        <>
            <ResourceList
                key={emailsKey}
                title={'Emails'}
                items={contactDetails.emails}
                smallTitle
                newItemPath={
                    isOwnProfile
                        ? '/profile/contact-details/emails/new'
                        : `/${contactId ? 'contacts' : 'users'}/${userId || contactId}/contact-details/emails/new`
                }
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <>
                                <img
                                    width="16"
                                    height="16"
                                    alt="icon"
                                    src={ICONS.MAIL_WHITE}
                                />
                                <span>{item.email}</span>
                            </>
                        ),
                        getClassName: () => 'with-icon',
                    },
                    {
                        column: 1,
                        getValue: (item) => <Tag outline>{item.emailType}</Tag>,
                    },
                ]}
                actions={[
                    {
                        title: 'Edit',
                        icon: ICONS.EDIT_GRAY,
                        onClick: onEditEmail,
                    },
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: onDeleteEmail,
                    },
                ]}
            />
            <br />
            <ResourceList
                key={phonesKey}
                title={'Phones'}
                items={contactDetails.phones}
                smallTitle
                newItemPath={
                    isOwnProfile
                        ? '/profile/contact-details/phones/new'
                        : `/${contactId ? 'contacts' : 'users'}/${userId || contactId}/contact-details/phones/new`
                }
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <>
                                <img
                                    width="16"
                                    height="16"
                                    alt="icon"
                                    src={ICONS.PHONE_WHITE}
                                />
                                <span>{item.phone}</span>
                            </>
                        ),
                        getClassName: () => 'with-icon',
                    },
                    {
                        column: 1,
                        getValue: (item) => <Tag outline>{item.phoneType}</Tag>,
                    },
                ]}
                actions={[
                    {
                        title: 'Edit',
                        icon: ICONS.EDIT_GRAY,
                        onClick: onEditPhone,
                    },
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: onDeletePhone,
                    },
                ]}
            />
            <br />
            <SectionContainer>
                <InlineStack>
                    <h4>Other info</h4>
                    <Button
                        text="Edit"
                        small
                        alignRight
                        icon={ICONS.EDIT_WHITE}
                        href={
                            isOwnProfile
                                ? '/profile/contact-details/other-info'
                                : `/${contactId ? 'contacts' : 'users'}/${userId || contactId}/contact-details/other-info`
                        }
                    />
                </InlineStack>
            </SectionContainer>
            <InfoList
                rows={[
                    {
                        content: `Prefers ${
                            contactDetails?.prefersCommunication || 'all'
                        } comm.`,
                    },
                    {
                        content:
                            contactDetails?.bestTimeToCall?.from &&
                            contactDetails?.bestTimeToCall?.to
                                ? `Best time to call: ${contactDetails?.bestTimeToCall?.from} - ${contactDetails?.bestTimeToCall?.to}`
                                : '',
                    },
                    {
                        content:
                            contactDetails?.timeToNotCall?.from &&
                            contactDetails?.timeToNotCall?.to
                                ? `Do not call: ${contactDetails?.timeToNotCall?.from} - ${contactDetails?.timeToNotCall?.to}`
                                : '',
                    },
                    {
                        content: `Alternative first name: ${
                            contactDetails?.alternativeFirstName || '-'
                        }`,
                    },
                    {
                        content: `Alternative last name: ${
                            contactDetails?.alternativeLastName || '-'
                        }`,
                    },
                    {
                        content: `Nickname: ${contactDetails?.nickname || '-'}`,
                    },
                    {
                        content: `Facebook profile URL: ${
                            contactDetails?.facebook || '-'
                        }`,
                    },
                    {
                        content: `Instagram: ${
                            contactDetails?.instagram || '-'
                        }`,
                    },
                    {
                        content: `TikTok: ${contactDetails?.tiktok || '-'}`,
                    },
                    {
                        content: `YouTube: ${contactDetails?.youtube || '-'}`,
                    },
                    {
                        content: `Twitter: ${contactDetails?.twitter || '-'}`,
                    },
                    {
                        content: `Pinterest: ${
                            contactDetails?.pinterest || '-'
                        }`,
                    },
                    {
                        content: `Snapchat: ${contactDetails?.snapchat || '-'}`,
                    },
                    {
                        content: `LinkedIn: ${contactDetails?.linkedin || '-'}`,
                    },
                    {
                        content: `Nextdoor: ${contactDetails?.nextdoor || '-'}`,
                    },
                    {
                        content: `GitHub: ${contactDetails?.github || '-'}`,
                    },
                    {
                        content: `Website: ${contactDetails?.website || '-'}`,
                    },
                    {
                        content: `Marketing opt-in: ${
                            contactDetails?.marketingOptIn ? 'Yes' : 'No'
                        }`,
                    },
                ]}
            />
        </>
    )
}

export default ContactDetailsPage
