import { USER_PREFERENCES } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Spinner from '../../../components/common/Spinner'

const PREF_KEYS = [USER_PREFERENCES.SKIP_NOTIFS.COMMENT_ADDED]

export default function NofitSettings() {
    const setToast = useToast()

    const { prefs, prefsLoading, prefsError, prefsMutate } = useData(
        `/v1/users/me/preferences?${PREF_KEYS.map(
            (key) => `keys[]=${key}`,
        ).join('&')}`,
        'prefs',
    )

    async function handleSave(v) {
        const { error } = await fetchAPI(
            `/v1/users/me/preferences`,
            {
                [USER_PREFERENCES.SKIP_NOTIFS.COMMENT_ADDED]: v
                    ? 'true'
                    : 'false',
            },
            'POST',
        )

        if (error) {
            setToast(error, 'alert')
            return
        }

        prefsMutate()
    }

    if (prefsLoading) {
        return <Spinner />
    }

    if (prefsError) {
        return <ErrorMessage>{prefsError}</ErrorMessage>
    }

    return (
        <section>
            <CheckboxInput
                id="skip_notifs_comment_added"
                label="Skip 'Comment added' notifications"
                value={
                    prefs[USER_PREFERENCES.SKIP_NOTIFS.COMMENT_ADDED] === 'true'
                }
                onChange={handleSave}
            />
        </section>
    )
}
