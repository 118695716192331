import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'

export default function LateFeeSettings() {
    const [lateFeeAmount, setLateFeeAmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const setToast = useToast()

    const { settingLoading, settingError, settingMutate } = useData(
        '/v1/settings/lateFeeAmount',
        'setting',
        (data) => data?.settingValue || 0,
        false,
        false,
        (data) => {
            setLateFeeAmount(data?.settingValue || 0)
        },
    )

    async function onSaveLateFeeAmount(e) {
        setError('')
        e.preventDefault()

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/lateFeeAmount`,
            { settingValue: String(lateFeeAmount) },
            'PATCH',
        )
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        settingMutate()
    }

    if (settingLoading) return <Spinner />

    if (settingError) {
        return <ErrorMessage section>{settingError}</ErrorMessage>
    }

    return (
        <form>
            {error && <ErrorMessage section>{error}</ErrorMessage>}

            <label htmlFor="form-late-fee-amount">Fee amount</label>
            <TextInput
                type="number"
                id="form-late-fee-amount"
                value={lateFeeAmount ? lateFeeAmount / 100 : ''}
                onChange={(v) => setLateFeeAmount(v * 100)}
            />

            <Button
                onClick={onSaveLateFeeAmount}
                isLoading={loading}
                text={'Save'}
                type="submit"
                small
                alignRight
            />
        </form>
    )
}
