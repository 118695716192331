// .company ready
import { Link } from 'react-router-dom'
import { getUserDisplayName } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'

function getIsLink(user, authUser) {
    if (user.role === 'admin') {
        return false
    }
    if (authUser.role === 'admin') {
        return true
    }
    if (
        authUser.role === 'staff' &&
        authUser.assignedClientIds.includes(user.id)
    ) {
        return true
    }
    return false
}

function ContactName(props) {
    const { user, showCompany } = props

    const auth = useAuth()

    const companyOrg = user.orgsData?.find(
        (o) => o.organizationType === 'company',
    )

    return (
        <>
            {getIsLink(user, auth.user) ? (
                <>
                    <Link to={`/users/${user.id}`} className={'contact-name'}>
                        {getUserDisplayName(user)}
                    </Link>

                    {showCompany && companyOrg && user.role === 'user' ? (
                        <>
                            <Link
                                to={`/organizations/${companyOrg.id}`}
                                className={'contact-company'}
                            >
                                {companyOrg.name}
                            </Link>
                        </>
                    ) : null}
                </>
            ) : (
                <span className={'contact-name'}>
                    {getUserDisplayName(user)}
                </span>
            )}
        </>
    )
}

export default ContactName
