import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { getFacebookLoginRequestUri } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useError from '../../../common/hooks/useError'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import MainButton from '../../../components/admin/MainButton'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import ErrorMessage from '../../../components/common/ErrorMessage'
import FacebookConnectedAccountItem from './FacebookConnectedAccountItem'

export default function FacebookConnectedAccountsList() {
    const auth = useAuth()
    const [error, setError] = useError()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const mutate = useMatchMutate()

    function onLoginWithFacebookToReconnect(accountId) {
        setModal(
            <ConfirmModalContent
                text="You might need to re-configure your social posting settings."
                onConfirm={() => {
                    const url = getFacebookLoginRequestUri(
                        auth.user.id,
                        accountId,
                    )
                    window.location.href = url
                }}
            />,
            'Reconnect to Facebook?',
        )
    }

    function onLoginWithFacebook() {
        const url = getFacebookLoginRequestUri(auth.user.id)
        window.location.href = url
    }

    async function onDeleteFacebookAccount(accountId) {
        setError('')

        setModal(
            <ConfirmModalContent
                text="You might need to re-configure your social posting settings."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/connected-accounts/${accountId}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast(
                        'Deleted, please re-configure your social posting settings if necessary.',
                    )
                    mutate(/\/v1\/connected-accounts/)
                    mutate(/\/v1\/social-posts\/plans/)
                }}
            />,
            'Are you sure you want to delete this account?',
        )
    }

    return (
        <>
            {error && <ErrorMessage section>{error}</ErrorMessage>}

            <MainButton
                label="New account"
                // disabled={loading}
                functionality="CREATE"
                onClick={onLoginWithFacebook}
            />

            <br />
            <ResourceList
                emptyText="Click the '+' button to create a new account."
                initialQuery={{
                    userId: auth.user.id,
                    service: 'facebook',
                }}
                apiUrl={'/connected-accounts'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <FacebookConnectedAccountItem
                                userId={auth.user.id}
                                account={item}
                            />
                        ),
                    },
                ]}
                actions={[
                    {
                        title: 'Reconnect',
                        onClick: (item) =>
                            onLoginWithFacebookToReconnect(item.id),
                    },
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: (item) => onDeleteFacebookAccount(item.id),
                    },
                ]}
            />
        </>
    )
}
