// v18

import { useEffect, useRef, useState } from 'react'
import { ICONS } from '../../common/constants'
import ResourceList from '../admin/ResourceList'
import fetchAPI from '../../common/fetchAPI'
import useAuth from '../../common/hooks/useAuth'
import useToast from '../../common/hooks/useToast'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import useModal from '../../common/hooks/useModal'
import InputModalContent from './InputModalContent'
import Button from './Button'
import { formatDateTime } from '../../common/helpers'
import Tag from './Tag'
import { useLocation } from 'react-router-dom'

function strToRegex(str) {
    return new RegExp(str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i')
}

function sortComments(comments) {
    // Create a map for quick lookups
    const commentMap = new Map(comments.map((comment) => [comment.id, comment]))

    // Sort comments by createdAt
    comments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

    const sortedComments = []
    const added = new Set()

    comments.forEach((comment) => {
        if (!added.has(comment.id)) {
            sortedComments.push(comment)
            added.add(comment.id)

            // Add replies immediately after the parent comment
            let currentId = comment.id
            while (commentMap.has(currentId)) {
                const replies = comments.filter(
                    (c) => c.replyToCommentId === currentId && !added.has(c.id),
                )
                replies.forEach((reply) => {
                    sortedComments.push(reply)
                    added.add(reply.id)
                })

                currentId =
                    replies.length > 0 ? replies[replies.length - 1].id : null
            }
        }
    })

    return sortedComments
}

function CommentItem({ comment, notif }) {
    const ref = useRef(undefined)
    const matchMutate = useMatchMutate()

    useEffect(function () {
        if (!notif) return
        const observer = new IntersectionObserver(
            function (entries) {
                if (entries[0].isIntersecting) {
                    markNotifRead()
                }
            },
            { threshold: 1 },
        )

        observer.observe(ref.current)

        return function () {
            observer.disconnect()
        }
    }, [])

    async function markNotifRead() {
        await fetchAPI(`/v1/notifs/${notif.id}/read`, {}, 'PATCH')
        matchMutate(/\/v1\/notifs/)
    }

    const userName =
        (!comment.isRemoved &&
            `${comment.user.firstName} ${comment.user.lastName}`.trim()) ||
        'Anonymous'

    return (
        <div
            ref={ref}
            className={`comment-item ${
                comment.replyToCommentId ? 'reply' : ''
            }`}
        >
            <div>
                <pre>{comment.content}</pre>
            </div>
            <div>
                <small className="text-subdued">{`${userName} - ${formatDateTime(
                    comment.createdAt,
                    false,
                    true,
                )}`}</small>
                &nbsp;{Boolean(notif) && <Tag color={'alert'}>New</Tag>}
            </div>
        </div>
    )
}

export default function Comments({ url, notifs }) {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const auth = useAuth()
    const location = useLocation()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const { setModal, setModalLoading } = useModal()
    const elRef = useRef(null)
    const [revalidateKey, setRevalidateKey] = useState(0)

    useEffect(function () {
        if (location.hash === 'comments') {
            elRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }, [])

    async function onReply(item) {
        setModal(
            <InputModalContent
                label={'Your comment'}
                type={'textarea'}
                onConfirm={async ({ text }) => {
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/${url}`,
                        { content: text, replyToCommentId: item.id },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        return
                    }
                    setToast('Comment added')
                    // matchMutate(/.*/g)
                    setRevalidateKey(Date.now())
                    setModal(null)
                }}
            />,
            'Your comment',
        )
    }

    async function onEdit(item) {
        setModal(
            <InputModalContent
                defaultValue={item.content}
                label={'Your comment'}
                type={'textarea'}
                onConfirm={async ({ text }) => {
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/${url}/${item.id}`,
                        { content: text },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        return
                    }
                    setToast('Comment added')
                    // matchMutate(/.*/g)
                    setRevalidateKey(Date.now())
                    setModal(null)
                }}
            />,
            'Your comment',
        )
    }

    async function onPost() {
        setModal(
            <InputModalContent
                type={'textarea'}
                label={'Your comment'}
                onConfirm={async ({ text }) => {
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/${url}`,
                        { content: text },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        return
                    }
                    setToast('Comment added')
                    // matchMutate(/.*/g)
                    setRevalidateKey(Date.now())
                    setModal(null)
                }}
            />,
            'Your comment',
        )
    }

    async function onDelete(item) {
        setDeleteLoading(true)
        const { error } = await fetchAPI(`/v1/${url}/${item.id}`, {}, 'DELETE')
        setDeleteLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Comment deleted')
        // matchMutate(/.*/g)
        setRevalidateKey(Date.now())
    }

    return (
        <div id="comments" ref={elRef}>
            <h2 className="m-0">Comments</h2>
            <ResourceList
                key={revalidateKey}
                apiUrl={url}
                getItemsFromResponse={(data) => sortComments(data)}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <CommentItem
                                comment={item}
                                notif={notifs.find((n) => n.field4 === item.id)}
                            />
                        ),
                    },
                ]}
                actions={[
                    {
                        title: 'Reply',
                        onClick: (item) => {
                            onReply(item)
                        },
                        getHide: (item) =>
                            item.userId === auth.user.id ||
                            item.isRemoved ||
                            item.replyToCommentId,
                    },
                    {
                        title: 'Edit',
                        onClick: (item) => {
                            onEdit(item)
                        },
                        getHide: (item) =>
                            item.userId !== auth.user.id || item.isRemoved,
                    },
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        loading: deleteLoading,
                        onClick: (item) => {
                            onDelete(item)
                        },
                        getHide: (item) =>
                            (item.userId !== auth.user.id && !auth.isAdmin) ||
                            item.isRemoved,
                    },
                ]}
            />
            <br />
            <Button outline text={'Post comment'} onClick={onPost} />
        </div>
    )
}
