import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
        autoComplete: 'new-password',
    },
    {
        key: 'bodyHtml',
        label: 'Content',
        required: true,
        autoComplete: 'new-password',
        type: 'html',
    },
    {
        key: 'summaryHtml',
        label: 'Excerpt',
        autoComplete: 'new-password',
        type: 'textarea',
    },
    {
        key: 'tags',
        label: 'Tags',
        autoComplete: 'new-password',
        type: 'text',
        infoTop: 'Comma-separated',
    },
    {
        key: 'files',
        prevPreviewKey: 'image',
        label: 'Featured image',
        infoBottom: 'This will replace the current image.',
        type: 'file',
        accept: 'image/jpeg',
    },
]

export default function EditArticle() {
    const { shopId, blogId, articleId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/blogs/${shopId}/${blogId}/articles/${articleId}?fields=id,title,body_html,tags,summary_html,image`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                inputs={inputs}
                submitNavArg={`/content/blogs/${shopId}/${blogId}`}
                getBody={(data) => {
                    const MAX_FILE_SIZE = 10 * 1024 * 1024
                    if (data?.files?.[0]?.size > MAX_FILE_SIZE) {
                        throw new Error(`Max file size is 10MB.`)
                    }
                    const formData = new FormData()
                    if (data.files?.[0]) {
                        formData.append(`file`, data.files[0])
                    }
                    delete data.files
                    const body = { ...data }
                    delete body.image
                    formData.append('data', JSON.stringify(body))

                    return formData
                }}
                mapItemToData={(data) => ({
                    title: data.title,
                    bodyHtml: data.body_html,
                    summaryHtml: data.summary_html,
                    tags: data.tags,
                    files: [],
                    image: data.image.src,
                })}
                mutationRegexes={[/\/v1\/blogs/]}
                mainButton
            />
        </SectionContainer>
    )
}
