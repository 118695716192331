// .acronym ok
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import {
    formatDate,
    formatMoney,
    getUnitSumFromCalendar,
    daysBetween,
} from '../../../common/helpers'
import Spinner from '../../../components/common/Spinner'
import Tag from '../../../components/common/Tag'

function ClientCard(props) {
    const { customer } = props

    const { userId } = useParams()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.customer) return

        const getClientBilling = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/payment/billing-overview/${userId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) {
                // setError(error)
                return
            }
            setData(responseData)
        }

        getClientBilling()
    }, [customer])

    if (props.customer === null && data === null) return null
    if (props.customer === null && loading) return <Spinner />

    const client = customer || data

    // Date indications
    const paidToDate = formatDate(client.paidToDate) || ''
    const lastPaymentDate = formatDate(client.lastPaymentDate) || ''
    const lastOpenInvoiceDueDate =
        formatDate(client.lastOpenInvoiceDueDate) || ''
    const oldestPastDueInvoiceDueDate =
        formatDate(client.oldestPastDueInvoiceDueDate) || ''

    let dueDate
    let dueDays
    let warnDueDate = false
    if (client.oldestPastDueInvoiceDueDate) {
        dueDate = client.oldestPastDueInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
        warnDueDate = true
    } else if (client.lastOpenInvoiceDueDate * 1000 > new Date().getTime()) {
        dueDate = client.lastOpenInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
    } else {
        dueDays = ''
    }

    // Money indications
    const amountOwed = client.amountOwed ? formatMoney(client.amountOwed) : ''
    const amountPastDue = client.amountPastDue
        ? formatMoney(client.amountPastDue)
        : ''

    let totalPaidInActivePeriod = 0
    totalPaidInActivePeriod = getUnitSumFromCalendar(
        {},
        '',
        client.paymentsCalendar,
    )

    return (
        <div className="client">
            <div
                className={`photo${
                    client.isPremiumUser ? ' photo-premium' : ''
                }`}
            ></div>

            <div>
                <div className="name">
                    {client.firstName}&nbsp;
                    {client.lastName}
                </div>
                <div className="email">
                    <small>{`${client.email}`}</small>
                </div>
                {/* {client.customerName || client.customerEmail} */}
                {client.customerDescription && (
                    <div className="company">{client.customerDescription}</div>
                )}
                {client.isActive ? (
                    <Tag color={'success'}>Paid</Tag>
                ) : (
                    <Tag color="alert">Free</Tag>
                )}
                {client.isBlocked ? <Tag color="alert">Blocked</Tag> : null}
                {client.onHold ? <Tag color="alert">Hold</Tag> : null}
                {client.onBlackList ? <Tag color="alert">Blacklist</Tag> : null}
                {client.onCollections ? (
                    <Tag color="alert">Collections</Tag>
                ) : null}
            </div>

            <div>
                <div className={`date ${warnDueDate ? 'alert' : ''}`}>
                    Due&nbsp;{dueDays ? `${dueDays} days` : '' || '(never)'}
                </div>
                <div className="date">
                    Paid to&nbsp;{paidToDate || '(never)'}
                </div>
                <div className="owed alert">
                    {amountPastDue || '$0'} past due
                </div>
                <div className="owed">{amountOwed || '$0'} owed</div>
            </div>
            <div className="extras">
                <div>
                    {client.collectionMethods?.length ? (
                        <p>
                            <small>
                                Billing:&nbsp;
                                <strong>
                                    {(client.collectionMethods || []).join(
                                        ', ',
                                    )}
                                </strong>
                            </small>
                        </p>
                    ) : null}

                    <p>
                        <small>
                            Last paid&nbsp;
                            <strong>{lastPaymentDate || '(never)'}</strong>
                        </small>
                    </p>
                    <p>
                        <small>
                            Recent invoice due&nbsp;
                            <strong>
                                {lastOpenInvoiceDueDate || '(never)'}
                            </strong>
                        </small>
                    </p>
                    <p>
                        <small>
                            Overdue since&nbsp;
                            <strong>
                                {oldestPastDueInvoiceDueDate || '(never)'}
                            </strong>
                        </small>
                    </p>
                    <p>
                        <small>
                            Paid&nbsp;
                            <strong>
                                {formatMoney(totalPaidInActivePeriod || 0)}
                            </strong>
                        </small>
                    </p>
                    <p>
                        {/* <small>
                            {client.contracts.length
                                ? `Contract ${formatDate(
                                      client.contracts[0].startDate,
                                  )}-${formatDate(
                                      client.contracts[0].endDate,
                                  )} paid ${formatMoney(
                                      client.contracts[0].paid || 0,
                                  )}/${formatMoney(
                                      client.contracts[0].totalAmount || 0,
                                  )}${
                                      client.contracts.length > 1
                                          ? '+ more'
                                          : ''
                                  }`
                                : 'No contracts'}
                        </small> */}
                    </p>
                    <p>
                        {client.oldestCancelDate ? (
                            <small>
                                Canceled&nbsp;
                                <strong>
                                    {formatDate(client.oldestCancelDate || 0)}
                                </strong>
                            </small>
                        ) : null}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ClientCard
