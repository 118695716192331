import { useLocation } from 'react-router-dom'
import CreateDonation from './CreateDonation'
import useModal from '../../../common/hooks/useModal'
import { useEffect } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'

export default function DonationModal() {
    const { state: locationState } = useLocation()
    const { setModal } = useModal()
    const auth = useAuth()

    useEffect(() => {
        async function checkDonations() {
            const { responseData } = await fetchAPI(
                '/v1/app-billing/me/donations',
            )
            if (!responseData?.length) setModal(<CreateDonation />)
        }

        if (auth.isAdmin) return
        if (locationState?.showDonationModal) {
            checkDonations()
        }
    }, [locationState])

    return null
}
