import { useNavigate, useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import { formatDateTime, sanitizeHTML } from '../../../common/helpers'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { ICONS } from '../../../common/constants'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import Image from '../../../components/common/Image'
import Tag from '../../../components/common/Tag'
import ErrorMessage from '../../../components/common/ErrorMessage'
import InlineStack from '../../../components/common/InlineStack'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ArticlePage() {
    const { shopId, blogId, articleId } = useParams()
    const navigate = useNavigate()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const [actionLoading, setActionLoading] = useState(false)

    const {
        blog: _blog,
        blogError,
        blogLoading,
    } = useData(
        `/v1/blogs/${shopId}/${blogId}?fields=shop.fullName,title`,
        'blog',
    )

    const { article, articleError, articleLoading } = useData(
        `/v1/blogs/${shopId}/${blogId}/articles/${articleId}?fields=id,title,image,body_html,summary_html,tags,published_at`,
        'article',
    )

    async function handlePublish() {
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/blogs/${shopId}/${blogId}/articles/${articleId}`,
            { publishedAt: new Date() },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Published')
        matchMutate(/\/v1\/blogs/)
    }

    async function handleUnpublish() {
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/blogs/${shopId}/${blogId}/articles/${articleId}`,
            { publishedAt: null },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Changed to draft')
        matchMutate(/\/v1\/blogs/)
    }

    if (articleLoading || blogLoading) return <Spinner />

    if (articleError || blogError) {
        return <ErrorMessage section>{articleError || blogError}</ErrorMessage>
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>{article.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Edit',
                                    onClick: () =>
                                        navigate(
                                            `/content/blogs/${shopId}/${blogId}/articles/${articleId}/edit`,
                                        ),
                                    icon: ICONS.EDIT_GRAY,
                                },
                                {
                                    title: 'Draft',
                                    hide: !article.published_at,
                                    onClick: handleUnpublish,
                                    icon: ICONS.EYE_OFF_GRAY,
                                    loading: actionLoading,
                                },
                                {
                                    title: 'Publish',
                                    hide: article.published_at,
                                    onClick: handlePublish,
                                    icon: ICONS.EYE_GRAY,
                                    loading: actionLoading,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                <br />
                <div>
                    {article.published_at ? (
                        <>
                            <Tag color={'success'}>Published</Tag>
                            {formatDateTime(article.published_at)}
                        </>
                    ) : (
                        <Tag outline>Draft</Tag>
                    )}
                </div>
                {article.tags?.length > 0 && (
                    <div>
                        Tags:{' '}
                        {Array.isArray(article?.tags)
                            ? article.tags.join(', ')
                            : article.tags}
                    </div>
                )}
            </SectionContainer>
            {article.image?.src && (
                <SectionContainer>
                    <br />
                    <div>
                        <Image
                            src={article.image?.src}
                            width={article.image?.width}
                            height={article.image?.height}
                            alt="article featured"
                        />
                    </div>
                </SectionContainer>
            )}
            <SectionContainer>
                <h2>Summary</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(article.summary_html) || '-',
                    }}
                ></div>
            </SectionContainer>
            <SectionContainer>
                <h2>Content</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(article.body_html) || '-',
                    }}
                ></div>
            </SectionContainer>
        </>
    )
}
