import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import Button from '../../../components/common/Button'
import { formatMoney } from '../../../common/helpers'
import useToast from '../../../common/hooks/useToast'
import Spinner from '../../../components/common/Spinner'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function getGroupData(groupings, items) {
    if (!groupings || !items.length) {
        return { groups: null, sortedGroupings: null }
    }

    let groups = null
    let sortedGroupings = null
    groups = []
    sortedGroupings = [...groupings].sort((a, b) => a.order - b.order)
    const noGroupItems = []
    for (const grouping of sortedGroupings) {
        const { ids } = grouping
        const groupItems = []
        for (const item of items) {
            if (ids.includes(item.id)) {
                groupItems.push(item)
            } else {
                noGroupItems.push(item)
            }
        }
        groups.push(groupItems)
    }
    sortedGroupings.push({ title: 'Others' })
    groups.push(noGroupItems)

    return { groups, sortedGroupings }
}

export default function ServiceRevenuePage() {
    const [service, setService] = useState(null)
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(true)
    const [mode, setMode] = useState('categories')
    const [_error, setError] = useState('')
    const [revenue, setRevenue] = useState(null)
    const [revenueLoading, setRevenueLoading] = useState(false)
    const [groupings, setGroupings] = useState([])
    const [grouping, setGrouping] = useState(null)
    const setToast = useToast()

    const { groups, sortedGroupings } = useMemo(
        () => getGroupData(groupings, services),
        [groupings, services],
    )

    useEffect(() => {
        const getData = async () => {
            //
            setLoading(true)
            const { responseData: servicesData, error: servicesError } =
                await fetchAPI(`/v1/services?source=stripe`, {}, 'GET')
            const { responseData: groupingsData, error: groupingsError } =
                await fetchAPI(`/v1/services/categories`, {}, 'GET')
            setLoading(false)
            if (servicesError || groupingsError) {
                setError(servicesError || groupingsError)
                return
            }

            if (groupingsData?.results?.length) {
                setGroupings(
                    groupingsData.results.map((category) => ({
                        title: category.title,
                        order: category.order,
                        ids: category.services,
                    })),
                )
            }

            setServices(servicesData)
        }

        getData()
    }, [])

    useEffect(() => {
        if (!service?.prices?.length) return
        const getData = async () => {
            setRevenueLoading(true)
            const priceIds = service.prices.map((price) => price.id)
            const amountsData = await Promise.all(
                priceIds.map(async (id) => {
                    const { responseData, error } = await fetchAPI(
                        `/v1/payment/monthly-revenue?source=stripe&price=${id}`,
                        {},
                        'GET',
                    )
                    if (error) {
                        return {
                            amount: 0,
                            errors: `Error fetching price ${id}`,
                        }
                    }
                    return responseData
                }),
            )
            setRevenueLoading(false)

            const newRevenue = amountsData.reduce((a, b) => a + b.amount, 0)
            const responseErrors = amountsData.reduce(
                (a, b) => `${a}, ${b.errors}`,
                '',
            )
            setRevenue(newRevenue)
            if (responseErrors) setToast(responseErrors, 'alert')
        }

        getData()
    }, [service])

    useEffect(() => {
        if (!groupings?.length) return
        if (!grouping) return setRevenue(null)
        const getData = async () => {
            const index = sortedGroupings.indexOf(grouping)
            const groupServices = groups[index]

            let newRevenue = 0
            let allErrors = ''
            setRevenueLoading(true)
            for await (const service of groupServices) {
                const priceIds = service.prices.map((price) => price.id)
                const amountsData = await Promise.all(
                    priceIds.map(async (id) => {
                        const { responseData, error } = await fetchAPI(
                            `/v1/payment/monthly-revenue?source=stripe&price=${id}`,
                            {},
                            'GET',
                        )
                        if (error) {
                            return {
                                amount: 0,
                                errors: `Error fetching price ${id}`,
                            }
                        }
                        return responseData
                    }),
                )
                newRevenue += amountsData.reduce((a, b) => a + b.amount, 0)
                const errors = amountsData.reduce(
                    (a, b) => `${a}, ${b.errors}`,
                    '',
                )
                if (errors) allErrors += `${errors}, `
            }
            setRevenueLoading(false)
            setRevenue(newRevenue)
            if (allErrors) setToast(allErrors, 'alert')
        }

        getData()
    }, [grouping])

    if (loading) return <Spinner />

    return (
        <SectionContainer>
            <Button
                onClick={() => {
                    setRevenue(null)
                    setMode(mode === 'categories' ? 'services' : 'categories')
                }}
                text={
                    mode === 'categories' ? ' Show services' : 'Show categories'
                }
            />
            {mode === 'categories' ? (
                <SelectInput
                    disabled={revenueLoading}
                    value={service?.id}
                    onChange={(v) => {
                        const newGrouping = groupings[v]
                        setGrouping(newGrouping)
                    }}
                    options={[
                        { value: '', label: '' },
                        ...groupings.map((grouping, i) => ({
                            value: i,
                            label: grouping.title,
                        })),
                    ]}
                />
            ) : (
                <SelectInput
                    disabled={revenueLoading}
                    value={service?.id}
                    onChange={(v) => {
                        setService(services.find((service) => service.id === v))
                    }}
                    options={
                        sortedGroupings?.length
                            ? sortedGroupings.map((grouping, i) => {
                                  const groupItems = groups[i]
                                  return {
                                      groupTitle: grouping.title,
                                      options: groupItems.map((service) => ({
                                          value: service.id,
                                          label: service.title,
                                      })),
                                  }
                              })
                            : services.map((service) => ({
                                  value: service.id,
                                  label: service.title,
                              }))
                    }
                />
            )}
            {revenueLoading && <Spinner />}
            {revenue !== null && !revenueLoading && (
                <>
                    <h2>This month</h2>
                    <p>{formatMoney(revenue)}</p>
                </>
            )}
        </SectionContainer>
    )
}
