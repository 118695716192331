import HorizontalScroll from '../common/HorizontalScroll'
import Spinner from '../common/Spinner'

function isFilterCombo(currFilter, combo) {
    const comboEntries = Object.entries(combo.fields)
    if (comboEntries.length !== Object.entries(currFilter).length) {
        return false
    }
    return Object.entries(currFilter).every(([k, v]) => {
        if (combo.fields[k] !== v) {
            return false
        }
        return true
    })
}

function ResourceListTabFilters(props) {
    const { filters, currFilter, onSubmit, tabFilterKey, filterCombos } = props

    const hasCombos = Object.keys(filterCombos || {}).length

    return (
        <div className="resource-list-tab-filters">
            <div className="tab-filters">
                <HorizontalScroll>
                    {hasCombos
                        ? Object.entries(filterCombos).map(([k, v]) =>
                              v.loader ? (
                                  <button type="button" key={'loader'} disabled>
                                      <Spinner />
                                  </button>
                              ) : (
                                  <button
                                      type="button"
                                      className={
                                          isFilterCombo(currFilter, v)
                                              ? 'active'
                                              : ''
                                      }
                                      key={k}
                                      onClick={() => {
                                          onSubmit({
                                              clearPrev: true,
                                              filterValues: {
                                                  ...v.fields,
                                              },
                                          })
                                      }}
                                  >
                                      {v.label}
                                  </button>
                              ),
                          )
                        : filters.filters[tabFilterKey].options.map((option) =>
                              option.loader ? (
                                  <button type="button" key={'loader'} disabled>
                                      <Spinner size="tiny" />
                                  </button>
                              ) : (
                                  <button
                                      type="button"
                                      className={
                                          currFilter[tabFilterKey] ===
                                              option.value ||
                                          (option.value === 'undefined' &&
                                              typeof currFilter[
                                                  tabFilterKey
                                              ] === 'undefined')
                                              ? 'active'
                                              : ''
                                      }
                                      key={option.value}
                                      onClick={() => {
                                          onSubmit({
                                              filterValues: {
                                                  [tabFilterKey]: option.value,
                                              },
                                          })
                                      }}
                                  >
                                      {option.label}
                                  </button>
                              ),
                          )}
                </HorizontalScroll>
            </div>
        </div>
    )
}

export default ResourceListTabFilters
