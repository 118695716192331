import { useState } from 'react'
import useModal from '../../common/hooks/useModal'
import SectionContainer from '../../components/common/SectionContainer'
import MultipleInputs from '../../components/common/data-form/MultipleInputs'
import LiveSearch from '../../components/common/data-form/LiveSearch'
import LiveSearchAddItemsInput from '../../components/common/data-form/LiveSearchAddItemsInput'
import { ICONS } from '../../common/constants'
import MultiplePhoneInputs from '../../components/common/data-form/MultiplePhoneInputs'

export default function Dev() {
    const { setModal } = useModal()
    const [emails, setEmails] = useState([])

    return (
        <SectionContainer>
            <label>Email</label>
            <MultiplePhoneInputs
                value={emails}
                onChange={(v) => setEmails(v)}
                typeLabels={{
                    main: 'Main',
                    business: 'Work',
                    other: 'Other',
                }}
                maxLength={13}
            />
            {/* <MultipleInputs
                value={emails}
                onChange={(v) => setEmails(v)}
                icon={ICONS.MAIL_GRAY}
                type={'email'}
                typeLabels={{
                    main: 'Main',
                    business: 'Work',
                    other: 'Other',
                }}
            /> */}
        </SectionContainer>
    )
}
