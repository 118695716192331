import Button from '../../../components/common/Button'

function PremiumBanner() {
    return (
        <div className="premium-banner">
            <div>
                <strong className="text-gold">Premium feature</strong>
            </div>
            <Button
                small
                alignRight
                outline
                white
                gold
                href={`/profile/billing/purchase-premium`}
                text="Upgrade now"
            />
        </div>
    )
}

export default PremiumBanner
