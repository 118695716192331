import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'

export default function SocialPostPlanLog() {
    const { socialPostPlanId } = useParams()
    return (
        <ResourceList
            apiUrl={'/user-logs'}
            initialQuery={{
                logType: 'SOCIAL_POST_PLAN',
                PLAN_ID: socialPostPlanId,
                sortBy: 'creationDate:desc',
            }}
            getItemsFromResponse={(data) => data?.results || []}
            paginated={true}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.creationDate,
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => item.message,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) => item.data?.PROPERTY_NAME,
                },
            ]}
        />
    )
}
