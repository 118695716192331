import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import useTranslations from '../../../common/hooks/useTranslations'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'

export default function Referrals() {
    const auth = useAuth()
    const { t, tLoading } = useTranslations()
    const setToast = useToast()
    const { link, linkLoading, linkError } = useData(
        '/v1/users/me/referral-link',
        'link',
        (data) => data?.link,
    )

    const { counts, countsLoading, countsError } = useData(
        '/v1/users/me/referral-counts',
        'counts',
        (data) => data?.counts,
    )

    async function onCopy() {
        navigator.clipboard.writeText(link)
        setToast('Copied')
    }

    if (linkLoading || countsLoading || tLoading) {
        return <Spinner />
    }

    if (linkError || countsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{linkError || countsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <Tag outline>Beta</Tag>
            <h2>
                {t(
                    'referrals.main.title',
                    'Refer your friends and make money!',
                )}
            </h2>
            <div style={{ whiteSpace: 'pre-line' }}>
                {t('referrals.main.top_text', '')}
            </div>
            <h3 className="m-0">Your referral link</h3>
            <div>{link}</div>
            <br />
            <Button
                outline
                small
                text="Copy"
                icon={ICONS.COPY_ACTIVE}
                onClick={onCopy}
                alignRight
            ></Button>

            <div>
                <h4 className="m-0">Users referred</h4>
                <div>{counts.users || '0'}</div>
                <h4 className="m-0">Premium users referred</h4>
                <div>{counts.premiumUsers || '0'}</div>
            </div>

            {(auth.isAdmin || auth.isAccountant) && (
                <>
                    <br />

                    <Link to="/reports/referrals">View referrals report</Link>
                </>
            )}

            <div style={{ whiteSpace: 'pre-line' }}>
                <br />
                {t('referrals.main.bottom_text', '')}
            </div>
        </SectionContainer>
    )
}
