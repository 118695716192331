import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import { formatDateTime } from '../../../common/helpers'
import { ICONS } from '../../../common/constants'
import MessageSection from '../../../components/common/MessageSection'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import { Link } from 'react-router-dom'
import Button from '../../../components/common/Button'
import SectionContainer from '../../../components/common/SectionContainer'

const POST_TYPE_TITLES = {
    ownProducts: 'Post products from Shopify stores',
    articles: 'Post blog posts from Shopify stores',
}

export default function SocialPostPlansList({ allUsers }) {
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    function handlePostNow(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return

                    setModalLoading(true)
                    const url = `/v1/social-posts/plans/${item.id}/post-now`
                    const { error } = await fetchAPI(url, {}, 'POST')
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setToast('Plan post was triggered.')
                    matchMutate(/\/v1\/social-posts\/plans/)
                }}
            />,
            'Reset last post date and post now?',
        )
    }

    function handleDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return

                    setModalLoading(true)
                    const url = `/v1/social-posts/plans/${item.id}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setToast('Plan deleted')
                    matchMutate(/\/v1\/social-posts\/plans/)
                }}
            />,
            'Are you sure you want to delete this plan?',
        )
    }

    return (
        <>
            {auth.isAdmin && allUsers && (
                <SectionContainer>
                    <MessageSection
                        // fullWidth
                        small
                        type="info"
                        title="Only admins see this."
                    ></MessageSection>
                </SectionContainer>
            )}
            {!auth.isPremiumUser && !auth.isAdmin && (
                <SectionContainer>
                    <MessageSection
                        small
                        type="warning"
                        title="Temporary free trial"
                    >
                        <ul>
                            <li>
                                <a
                                    href="https://app.aco.digital/pages/terms-of-service"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Read terms of use
                                </a>
                                &nbsp;and
                            </li>
                            <li>
                                <details>
                                    <summary>Free trial terms</summary>
                                    <div>
                                        This feature is provided for your use
                                        for free for an undefined period of
                                        time. Agency Couture may at any time
                                        without notice revoke access to the
                                        feature or request payment for use of
                                        software as a service. At which time it
                                        will be your choice to pay for the tool
                                        or discontinue use and potentially lose
                                        any data. We make no guarantees to data,
                                        backups, or loss of data. This feature
                                        is in beta testing and use at your own
                                        risk. Even after beta testing ends
                                        Agency Couture and its successors will
                                        not be held liable or responsible for
                                        your use of the tools, features or
                                        software or loss of data. Agency Couture
                                        reserves the right to backup, repurpose,
                                        or utilize data and usage notes for
                                        commercial use, resale, and financial
                                        gain. Under no circumstances will you,
                                        as a user have right to any intellectual
                                        property, idea submissions, client data
                                        or usage notes for the software. Any
                                        attempt to misuse the software or lack
                                        of payment for other services or
                                        cancellation may result in an immediate
                                        block or ban from the software. Any
                                        attempt to steal the software code,
                                        intellectual property or copyright is
                                        strictly prohibited and may result in
                                        legal action, fines and other recourse.
                                        Passwords stored in the vault are
                                        intended for client-agency usage and may
                                        be shared with the Agency Couture team.
                                        Use of this feature is at your own risk
                                        and you agree to indemnify Agency
                                        Couture, its staff, successor and
                                        affiliates from all claims, loss of
                                        data, loss of privacy, errors or
                                        negligence related to your use of all
                                        software as a service connected with
                                        your use of this software.
                                        <br />
                                        By using this software you agree
                                        explicitly to all of these terms and any
                                        changes with notice made only via this
                                        application. Your only recourse if you
                                        disagree with these terms is to
                                        discontinue usage.
                                    </div>
                                </details>
                            </li>
                        </ul>
                    </MessageSection>
                </SectionContainer>
            )}

            <ResourceList
                emptyText={
                    allUsers
                        ? undefined
                        : "Click the '+' button to create a new plan."
                }
                initialQuery={{
                    userId: allUsers ? undefined : auth.user.id,
                    postTypes: 'ownProducts,articles',
                }}
                apiUrl={'/social-posts/plans'}
                renderMainButton={!allUsers}
                newItemLabel="New plan"
                newItemPath={allUsers ? undefined : '/social/plans/new'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => POST_TYPE_TITLES[item.postType],
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) =>
                            `Posting to ${
                                item.propertyIds?.length || 0
                            } properties`,
                    },
                    {
                        column: 1,
                        getValue: (item) => `Plan ID: ${item.id}`,
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 2,
                        getValue: (item) =>
                            `Posting ${
                                item.settings?.frequency || 0
                            } times a day`,
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 2,
                        getValue: (item) =>
                            `Last posted: ${
                                item.lastPostDate
                                    ? formatDateTime(item.lastPostDate)
                                    : 'Not yet posted'
                            }`,
                        getClassName: () => 'subtitle',
                    },
                    ...(allUsers
                        ? [
                              {
                                  column: 2,
                                  getValue: (item) => (
                                      <Button
                                          tiny
                                          link
                                          href={`/users/${item.userId}`}
                                          text={'Open user'}
                                      />
                                  ),
                                  getClassName: () => 'subtitle',
                              },
                          ]
                        : []),
                ]}
                actions={
                    allUsers
                        ? []
                        : [
                              {
                                  title: 'Edit plan',
                                  link: '/social/plans/:item.id/edit',
                                  icon: ICONS.EDIT_GRAY,
                              },
                              {
                                  title: 'Edit hashtags',
                                  link: '/social/plans/:item.id/hashtags',
                                  icon: ICONS.HASH_GRAY,
                              },
                              {
                                  title: 'Post now',
                                  onClick: (item) => {
                                      handlePostNow(item)
                                  },
                              },
                              {
                                  title: 'View log',
                                  link: '/social/plans/:item.id/log',
                              },
                              {
                                  title: 'Delete',
                                  onClick: (item) => {
                                      handleDelete(item)
                                  },
                                  type: 'alert',
                                  icon: ICONS.TRASH_RED,
                              },
                          ]
                }
            />
        </>
    )
}
