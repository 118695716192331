import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import { useParams } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

function ClientBuyerSettings() {
    const { userId } = useParams()

    const { user, userError, userLoading } = useData(
        `/v1/users/${userId}?fields=email&populate=orgsData`,
        'user',
    )

    const companyOrgs = user?.orgsData?.filter(
        (o) => o.organizationType === 'company',
    )

    const { accounts, accountsError, accountsLoading } = useData(
        companyOrgs?.length
            ? `/v1/accounts?accountType=wholesaleBuyer${companyOrgs
                  .map((o) => `&ownerId=${o.id}`)
                  .join('')}`
            : null,
        'accounts',
    )

    if (userError || accountsError) {
        return (
            <SectionContainer>
                <Toggle title="Wholesale buyer">
                    <ErrorMessage>{userError || accountsError}</ErrorMessage>
                </Toggle>
            </SectionContainer>
        )
    }

    if (userLoading || accountsLoading) {
        return (
            <SectionContainer>
                <Toggle title="Wholesale buyer">
                    <Spinner />
                </Toggle>
            </SectionContainer>
        )
    }

    if (!accounts?.length) return null

    return (
        <SectionContainer>
            <Toggle title={'Wholesale buyer'}>
                <form>
                    {accounts.map((account, i) => (
                        <div key={i}>
                            <CheckboxInput
                                value={true}
                                id={`is-buyer-${i + 1}`}
                                label={`Enable wholesale Buyer for company "${
                                    companyOrgs.find(
                                        (o) => o.id === account.ownerId,
                                    )?.name
                                }"`}
                                disabled={true}
                            />
                        </div>
                    ))}
                </form>
                {/* <div>
                    <h4>Information</h4>
                    <small>
                        EIN / SSN:&nbsp;
                        <strong>
                            {user.todo?.companyInfo?.ein ||
                                user.todo?.companyInfo?.ssn ||
                                'None'}
                        </strong>
                        ,&nbsp; Company:&nbsp;
                        <strong>{user.todo?.name || 'None'}</strong>
                        ,&nbsp;Title:&nbsp;
                        <strong>{companyMemberInfo?.title || 'None'}</strong>
                    </small>
                    <br />
                </div>

                <div>
                    <h4>Submitted proof of work</h4>
                    {companyMemberInfo?.workProofFileIds?.length ? (
                        companyMemberInfo.workProofFileIds.map((id, i) => (
                            <PrivateFile
                                key={i}
                                openFullOnClick
                                driveFileId={id}
                            />
                        ))
                    ) : (
                        <span className="warning">None</span>
                    )}
                </div> */}
            </Toggle>
        </SectionContainer>
    )
}

export default ClientBuyerSettings
