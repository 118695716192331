// v18

import { useEffect, useRef, useState } from 'react'

function useDebounce(data, onChange = () => {}, delay = 500) {
    const [debouncedData, setDebouncedData] = useState(data)
    const [hasInitialized, setHasInitialized] = useState(false)
    const debounceTimeoutRef = useRef(undefined)

    useEffect(
        function () {
            setHasInitialized(true)
            debounceTimeoutRef.current = setTimeout(function () {
                hasInitialized && onChange(data)
                setDebouncedData(data)
            }, delay || 500)

            return () => {
                clearTimeout(debounceTimeoutRef.current)
            }
        },
        [typeof data === 'object' ? JSON.stringify(data) : data, delay],
    )

    return debouncedData
}

export default useDebounce
