import { Link } from 'react-router-dom'

function InfoList(props) {
    const { rows, noIcons } = props

    const hasIcons = !noIcons && rows.some((row) => row.icon)

    return (
        <div className="info-list">
            <ul className={noIcons ? 'no-icons' : ''}>
                {rows.map((row, i) => {
                    return (
                        <li
                            key={i}
                            className={`${hasIcons ? 'with-icons' : ''}`}
                        >
                            {row.icon ? (
                                <img
                                    width="16"
                                    height="16"
                                    alt="icon"
                                    src={row.icon}
                                />
                            ) : (
                                <div></div>
                            )}
                            <span className={`${row.strong ? 'bold' : ''}`}>
                                {row.link ? (
                                    <Link to={row.link}>
                                        {row.content}&nbsp;&rarr;
                                    </Link>
                                ) : (
                                    row.content
                                )}
                            </span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default InfoList
