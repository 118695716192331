import { formatMoney } from '../../../common/helpers'
import { MONTHS } from '../../../common/constants'
import Toggle from '../../../components/common/Toggle'

const calendarToArray = (calendar) => {
    const result = []
    for (const year of Object.keys(calendar)) {
        for (const month of Object.keys(calendar[year])) {
            result.push({
                year,
                month,
                ...calendar[year][month],
            })
        }
    }

    const sortedRows = result.sort((a, b) => {
        return b.year - a.year || b.month - a.month
    })

    return sortedRows
}

export default function WholesaleMonthlyBilling(props) {
    const months = calendarToArray(props.billing.calendarData)

    return (
        <div>
            {months.map((data, i) => (
                <Toggle
                    open={i === 0}
                    key={i}
                    title={`${MONTHS[data.month - 1].abbreviation} ${
                        data.year
                    }`}
                >
                    <div className="vendor-monthly-earn-container">
                        <div>
                            <h4>Gross</h4>
                            <div>
                                {formatMoney(data.price, false, 'shopify')}
                            </div>
                        </div>
                        <div>
                            <h4>Tax</h4>
                            <div>
                                {formatMoney(data.taxes, false, 'shopify')}
                            </div>
                        </div>
                    </div>
                    <div className="vendor-monthly-earn-container">
                        <div>
                            <h4>Shipping</h4>
                            <div>
                                {formatMoney(data.shipping, false, 'shopify')}
                            </div>
                        </div>
                        <div>
                            <h4>Transaction fees</h4>
                            <div>
                                {formatMoney(
                                    data.transaction,
                                    false,
                                    'shopify',
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="vendor-monthly-earn-container">
                        <div>
                            <h4>Returns</h4>
                            <div>
                                {formatMoney(data.returns, false, 'shopify')}
                            </div>
                        </div>

                        <div>
                            <h4>Discounts</h4>
                            <div>
                                {formatMoney(data.discount, false, 'shopify')}
                            </div>
                        </div>
                    </div>
                    <div className="vendor-monthly-earn-container">
                        <div></div>
                        <div>
                            <h4>Total earning</h4>
                            <div>
                                {formatMoney(
                                    data.price +
                                        data.shipping +
                                        data.taxes -
                                        data.returns -
                                        data.discount,
                                    false,
                                    'shopify',
                                )}
                            </div>
                        </div>
                    </div>
                </Toggle>
            ))}
        </div>
    )
}
