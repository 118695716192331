export default function BorderWrapper({ children, contentBottom }) {
    return (
        <div className="border-wrapper">
            {children}

            {contentBottom && (
                <div className="border-content-bottom">{contentBottom}</div>
            )}
        </div>
    )
}
