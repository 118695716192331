import { useState } from 'react'
import ImageModalContent from '../common/ImageModalContent'
import useModal from '../../common/hooks/useModal'

export default function ImageSlider({
    slides,
    contentTop,
    contentTopRight,
    contentBottomRight,
    ratio = '1',
    hasZoom,
}) {
    // slides[0].imageUrl =
    //     'https://styles.redditmedia.com/t5_2sn78/styles/communityIcon_kagwtg8m01511.png?width=256&s=856f0b7a52b9866f3d204b8ca227e9a67eb4f6d0'
    const [currentSlideIndex, setCurrentSlide] = useState(0)
    const { setModal } = useModal()
    const length = slides.length

    function handleNextSlide() {
        setCurrentSlide(
            currentSlideIndex === length - 1 ? 0 : currentSlideIndex + 1,
        )
    }

    function handlePrevSlide() {
        setCurrentSlide(
            currentSlideIndex === 0 ? length - 1 : currentSlideIndex - 1,
        )
    }

    function handleMainClick() {
        if (!hasZoom) return
        setModal(<ImageModalContent src={slides[currentSlideIndex].imageUrl} />)
    }

    if (!slides.length) {
        return null
    }

    const currentSlide = slides[currentSlideIndex]

    const imageStyle = {
        '--padding-bottom': `${ratio * 100}%`,
    }

    const showPrevButton = length > 1 && currentSlideIndex !== 0
    const showNextButton = length > 1 && currentSlideIndex !== length - 1

    return (
        <div className="image-slider">
            <div className="inner-slider">
                <div className="slider-content">
                    <div>{contentTop}</div>
                    <div>{contentTopRight}</div>
                    <div></div>
                    <div>{contentBottomRight}</div>
                </div>

                {showPrevButton && (
                    <button onClick={handlePrevSlide} title="Previous">
                        <img
                            alt="previous"
                            src="/img/icons/chevron-left-white.svg"
                        ></img>
                    </button>
                )}

                <div style={imageStyle}>
                    {currentSlide.imageUrl ? (
                        <img
                            onClick={handleMainClick}
                            src={currentSlide.imageUrl}
                            alt="slider"
                            className="slider-image"
                            style={imageStyle}
                        />
                    ) : (
                        <div className="slider-image-placeholder"></div>
                    )}
                </div>

                {showNextButton && (
                    <button onClick={handleNextSlide} title="Next">
                        <img
                            style={{ transform: 'rotate(180deg)' }}
                            alt="next"
                            src="/img/icons/chevron-left-white.svg"
                        ></img>
                    </button>
                )}
            </div>

            <ul>
                {slides.map((slide, index) => (
                    <li
                        role="button"
                        onClick={() => setCurrentSlide(index)}
                        className={`${currentSlide === slide ? 'active' : ''}`}
                        key={index}
                    ></li>
                ))}
            </ul>
        </div>
    )
}
