import authStorage from './authStorage'
import { API_URL } from './constants'

class HttpError extends Error {
    constructor(message, httpStatus) {
        super(message)
        this.name = 'HttpError'
        this.httpStatus = httpStatus
    }
}

const fetchAPI = async (
    url,
    data,
    method = 'GET',
    headers = { 'Content-Type': 'application/json' },
    _withAuth = true,
    attempt = 1,
    restOpts = {},
) => {
    try {
        const opts = {
            method: method,
            headers: {
                Authorization: 'Bearer ' + authStorage.getItem('access_token'),
                ...headers,
            },
        }

        if (method !== 'GET') {
            if (data instanceof FormData) {
                opts.body = data
            } else {
                opts.body = JSON.stringify(data)
            }
        }

        const response = await fetch(API_URL + url, { ...opts, ...restOpts })

        const isJson = response.headers
            .get('content-type')
            ?.includes('application/json')

        const responseData = isJson ? await response.json() : response

        if (!response.ok) {
            const refreshToken = authStorage.getItem('refresh_token')
            if (
                response.status === 401 &&
                responseData.message === 'Please authenticate' &&
                refreshToken &&
                attempt === 1
            ) {
                // Assume expired access token
                // (avoiding recursion with attempt counter)
                const refreshResponse = await fetch(
                    API_URL + `/v1/auth/refresh-tokens`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            refreshToken: refreshToken,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                const refreshResponseData = await refreshResponse.json()
                const { access, refresh } = refreshResponseData
                authStorage.setItem('access_token', access.token)
                authStorage.setItem('refresh_token', refresh.token)

                return await fetchAPI(
                    url,
                    data,
                    method,
                    headers,
                    true,
                    2,
                    restOpts,
                )
            }
            throw new HttpError(responseData.message, response.status)
        }

        return {
            responseData,
            error: null,
            status: response.status,
            headers: response.headers,
        }
    } catch (error) {
        console.error(error)
        return {
            responseData: null,
            error:
                error.message ||
                `Unknown error ${error.httpStatus ? error.httpStatus : ''}`,
            status: error.httpStatus,
        }
    }
}

export default fetchAPI
