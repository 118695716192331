export default function Swatches({ colorData }) {
    if (!colorData) return null

    return (
        <div className="swatches">
            <ul>
                {colorData.map(({ colorValue }) => (
                    <li
                        key={colorValue}
                        style={{ '--color': colorValue || '#fff' }}
                    ></li>
                ))}
            </ul>
        </div>
    )
}
