import ResourceList from '../../../components/admin/ResourceList'

export default function ComponentsList() {
    return (
        <ResourceList
            itemClickPath={'/storybook/:item.title'}
            items={[
                {
                    title: 'Tag',
                },
            ]}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                },
            ]}
        />
    )
}
