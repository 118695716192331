import { CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES } from '../../../common/constants'
import { formatDateTime } from '../../../common/helpers'
import Tag from '../../../components/common/Tag'

function FacebookConnectedAccountItem(props) {
    const { account } = props

    return (
        <div>
            <div>
                <Tag color={'success'}>Connected</Tag>
            </div>
            <div>
                {new Date(account.serviceKeyExpiryDate) < new Date() ? (
                    <div>
                        <Tag color={'alert'}>Expired</Tag>
                    </div>
                ) : null}
            </div>
            <p>
                <span className="text-subdued">Username&nbsp;</span>
                <span>{account.accountName}</span>
            </p>
            <p>
                <span className="text-subdued">Expires on&nbsp;</span>
                <span>{formatDateTime(account.serviceKeyExpiryDate)}</span>
            </p>
            <div className="list">
                <h5>Connected properties</h5>
                <br />
                <ul>
                    {account.properties.map((item) => (
                        <li key={item.propertyId}>
                            <span>
                                <Tag outline>
                                    {CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES[
                                        item.propertyType
                                    ].toUpperCase()}
                                </Tag>
                                &nbsp;
                                {item.name}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default FacebookConnectedAccountItem
