import './App.scss'
import React from 'react'
import Menu from './pages/more/Menu'
import SubsList from './pages/admin/subscription/SubsList'
import InvoicePage from './pages/admin/invoices/InvoicePage'
import EditInvoice from './pages/admin/invoices/EditInvoice'
import ServicesList from './pages/reports/services/ServicesList'
import ServicePage from './pages/reports/services/ServicePage'
import EditTeamMember from './pages/admin/team/EditTeamMember'
import Settings from './pages/settings/Settings'
import SubPage from './pages/admin/subscription/SubPage'
import EditSub from './pages/admin/subscription/EditSub'
import InvoicesList from './pages/admin/invoices/InvoicesList'
import CreateInvoice from './pages/admin/invoices/CreateInvoice'
import CreateExpense from './pages/reports/expenses/CreateExpense'
import ExpensePage from './pages/reports/expenses/ExpensePage'
import CreatePrice from './pages/reports/services/CreatePrice'
import CreateSub from './pages/admin/subscription/CreateSub'
import CreateService from './pages/reports/services/CreateService'
import ClientSettings from './pages/admin/clients/ClientSettings'
import CreateClientAssignment from './pages/admin/client-assignments/CreateClientAssignment'
import ServiceRevenue from './pages/reports/services/ServiceRevenuePage'
import CreateServiceCategory from './pages/settings/service-categories/CreateServiceCategory'
import ServiceCategoriesList from './pages/settings/service-categories/ServiceCategoriesList'
import CreateTask from './pages/admin/tasks/CreateTask'
import TaskPage from './pages/admin/tasks/TaskPage'
import AssignedDrivePage from './pages/admin/assigned-drives/AssignedDrivePage'
import AssignNewDrive from './pages/admin/assigned-drives/AssignNewDrive'
import EditTask from './pages/admin/tasks/EditTask'
import RejectTask from './pages/admin/tasks/RejectTask'
import Login from './pages/auth/Login'
import Register from './pages/auth/register/Register'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import VerifyEmailCallback from './pages/auth/VerifyEmailCallback'
import Refresh from './pages/auth/Refresh'
import { Navigate } from 'react-router-dom'
import RegisterDetails from './pages/auth/register/RegisterDetails'
import RegisterVerifyEmail from './pages/auth/register/RegisterVerifyEmail'
import DBOrdersList from './pages/shop/orders/AdminDBOrdersList'
import LateFeeList from './pages/reports/late-fees/LateFeeList'
import BuyerPage from './pages/shop/orders/BuyerPage'
import ProductPage from './pages/shop/products/ProductPage'
import AdminVendorPage from './pages/admin/wholesale-vendors/AdminVendorPage'
import OrderPage from './pages/shop/orders/OrderPage'
import CustomProductsList from './pages/admin/wholesale-vendors/CustomProductsList'
import CreateCustomProduct from './pages/admin/wholesale-vendors/CreateCustomProduct'
import TempPayoutValidationList from './pages/admin/wholesale-payouts/TempPayoutValidationList'
import PasswordsList from './pages/more/passwords/PasswordsList'
import CreatePassword from './pages/more/passwords/CreatePassword'
import EditPassword from './pages/more/passwords/EditPassword'
import PasswordHistory from './pages/more/passwords/PasswordHistory'
import EditProfile from './pages/admin/clients/EditProfile'
import EditProfileVendor from './pages/admin/clients/EditProfileVendor'
import NotifsList from './pages/admin/notifs/NotifsList'
import TranslationsList from './pages/admin/translations/TranslationsList'
import EditUserNotes from './pages/user-profile/EditUserNotes'
import EditClientCompany from './pages/admin/clients/EditClientCompany'
import VendorSettings from './pages/admin/clients/VendorSettings'
import PdfContractsList from './pages/more/pdf-contracts/PdfContractsList'
import EditPdfContract from './pages/more/pdf-contracts/EditPdfContract'
import EditProfileRetailBuyer from './pages/admin/clients/EditProfileRetailBuyer'
import AdminCreateShopifyShop from './pages/shop/shops/AdminCreateShopifyShop'
import CreateClientPayPalBilling from './pages/admin/clients/CreateClientPayPalBilling'
import FacebookLoginCallback from './pages/admin/settings/FacebookLoginCallback'
import ContentOverview from './pages/content/ContentOverview'
import CreateShopifyShop from './pages/shop/shops/CreateShopifyShop'
import EditShopifyShop from './pages/shop/shops/EditShopifyShop'
import ShortLinkCallback from './pages/admin/shortlinks/ShortLinkCallback'
import TempIowaCalendarEmbed from './pages/admin/embeds/TempIowaCalendarEmbed'
import EditSocialPostPlanHashtags from './pages/content/social-plans/EditSocialPostPlanHashtags'
import TempEventFormEmbed from './pages/admin/embeds/TempEventFormEmbed'
import SocialPostPlanLog from './pages/content/social-plans/SocialPostPlanLog'
import EditClientLabels from './pages/admin/clients/EditClientLabels'
import CreatePage from './pages/more/pages/CreatePage'
import PagesOverview from './pages/more/pages/PagesOverview'
import EditPage from './pages/more/pages/EditPage'
import Page from './pages/admin/pages/Page'
import PlainFormsList from './pages/more/plain-forms/PlainFormsList'
import PlainFormPage from './pages/more/plain-forms/PlainFormPage'
import UsersResourceList from './pages/contacts/ContactsOverview'
import VendorEventRegistrationPage from './pages/admin/wholesale-vendors/VendorEventRegistrationPage'
import CreateAddress from './pages/admin/addresses/CreateAddress'
import EditAddress from './pages/admin/addresses/EditAddress'
import Contact from './pages/more/contact-us/Contact'
import HtmlContractsList from './pages/more/html-contracts/HtmlContractsList'
import EditHtmlContract from './pages/more/html-contracts/EditHtmlContract'
import CreateHtmlContract from './pages/more/html-contracts/CreateHtmlContract'
import VendorQuestions from './pages/admin/clients/VendorQuestions'
import TaxForms from './pages/admin/clients/TaxForms'
import HtmlContractHistory from './pages/more/html-contracts/HtmlContractHistory'
import StripeIntentCompleteCallback from './pages/admin/app-billing/StripeIntentCompleteCallback'
import PurchasePremium from './pages/admin/app-billing/PurchasePremium'
import StripePremiumSubCompleteCallback from './pages/admin/app-billing/StripePremiumSubCompleteCallback'
import EditConfigObject from './pages/settings/misc/EditConfigObject'
import PremiumSubPage from './pages/admin/app-billing/PremiumSubPage'
import SocialPostPlanPostsList from './pages/content/social-plans/SocialPostPlanPostsList'
import ResetPin from './pages/auth/ResetPin'
import EditTimesheet from './pages/admin/timesheet/EditTimesheet'
import TimesheetsList from './pages/admin/timesheet/TimesheetsList'
import AdminTimesheetsUserList from './pages/admin/timesheet/AdminTimesheetsUserList'
import AdminTimesheetPage from './pages/admin/timesheet/AdminTimesheetPage'
import EditContact from './pages/admin/contacts/EditContact'
import CreateContact from './pages/admin/clients/CreateContact'
import EditUser from './pages/admin/clients/EditUser'
import ProfilePage from './pages/user-profile/ProfilePage'
import ShopOverview from './pages/shop/ShopOverview'
import PermissionSettings from './pages/admin/settings/PermissionSettings'
import ResourcePermissions from './pages/admin/settings/ResourcePermissions'
import ResourceIndividualPermissions from './pages/admin/settings/ResourceIndividualPermissions'
import DonationCompleteCallback from './pages/admin/app-billing/DonationCompleteCallback'
import DonationSubsList from './pages/admin/donations/DonationSubsList'
import CreateDonation from './pages/admin/donations/CreateDonation'
import DonationsOverview from './pages/admin/donations/DonationsOverview'
import Dev from './pages/admin/Dev'
import Referrals from './pages/more/referrals/Referrals'
import Home from './pages/home/Home'
import BillingOverview from './pages/reports/BillingOverview'
import CreateBlog from './pages/content/blogs/CreateBlog'
import ArticlesList from './pages/content/blogs/ArticlesList'
import CreateArticle from './pages/content/blogs/CreateArticle'
import BulkCreateArticles from './pages/content/blogs/BulkCreateArticles'
import ArticlePage from './pages/content/blogs/ArticlePage'
import EditArticle from './pages/content/blogs/EditArticle'
import ScheduleArticle from './pages/content/blogs/ScheduleArticle'
import CreatePostPlan from './pages/content/social-plans/CreatePostPlan'
import EditSocialPostPlan from './pages/content/social-plans/EditSocialPostPlan'
import ShopifyProductTagsSetting from './pages/settings/wholesale/ShopifyProductTagsSetting'
import LogsList from './pages/more/logs/LogsList'
import ComponentsList from './pages/more/components/ComponentsList'
import ComponentPage from './pages/more/components/ComponentPage'
import ReposList from './pages/more/repos/ReposList'
import RepoPage from './pages/more/repos/RepoPage'
import ContactFormPage from './pages/more/contact-forms/ContactFormPage'
import ContactFormsList from './pages/more/contact-forms/ContactFormsList'
import CreateContactForm from './pages/more/contact-forms/CreateContactForm'
import EditContactForm from './pages/more/contact-forms/EditContactForm'
import OrganizationsList from './pages/more/organizations/OrganizationsList'
import CreateOrganization from './pages/more/organizations/CreateOrganization'
import OrganizationPage from './pages/more/organizations/OrganizationPage'
import AssignOrganizationMembers from './pages/more/organizations/AssignOrganizationMembers'
import EditCompanyMemberInfo from './pages/admin/clients/EditCompanyMemberInfo'
import EditProfileCompanyInfoEin from './pages/user-profile/EditProfileCompanyInfoEin'
import CreateAccounts from './pages/admin/clients/CreateAccounts'
import CreateCompany from './pages/user-profile/CreateCompany'
import EditOrganization from './pages/more/organizations/EditOrganization'
import EditShopifyGraphQLProduct from './pages/shop/products/EditShopifyGraphQLProduct'
import TempMenuRedirect from './pages/redirects/TempMenuRedirect'
import ImportProducts from './pages/shop/products/ImportProducts'
import EditProduct from './pages/shop/products/EditProduct'
import CreateProduct from './pages/shop/products/CreateProduct'
import AppProductPage from './pages/shop/products/AppProductPage'
import ShopLocationsList from './pages/shop/shops/ShopLocationsList'
import CreateShopLocation from './pages/shop/shops/CreateShopLocation'
import EditProductQuantities from './pages/shop/products/EditProductQuantities'
import LocationInventoryItemsList from './pages/shop/shops/LocationInventoryItemsList'
import CustomLocationsList from './pages/settings/wholesale/CustomLocationsList'
import CreateCustomLocation from './pages/settings/wholesale/CreateCustomLocation'
import WishlistProductsList from './pages/more/wishlist/WishlistProductsList'
import PublicVendorPage from './pages/shop/vendor/PublicVendorPage'
import EditUserMetafields from './pages/user-profile/EditUserMetafields'
import ShopifyShopPage from './pages/shop/shops/ShopifyShopPage'
import ImportProductsOwn from './pages/shop/products/ImportProductsOwn'
import EditContactMetafields from './pages/user-profile/EditContactMetafields'
import AddressesList from './pages/admin/addresses/AddressesList'
import ContactDetailsPage from './pages/admin/contact-details/ContactDetailsPage'
import CreatePhone from './pages/admin/contact-details/CreatePhone'
import CreateEmail from './pages/admin/contact-details/CreateEmail'
import EditOtherContactInfo from './pages/admin/contact-details/EditOtherContactInfo'
import ProfileCompaniesList from './pages/more/organizations/ProfileCompaniesList'
import InviteOrganizationMember from './pages/more/organizations/InviteOrganizationMember'
import AcceptOrgInviteCallback from './pages/more/organizations/AcceptOrgInviteCallback'
import UserPagesOverview from './pages/more/pages/UserPagesOverview'
import CreateAppMetafieldDefinition from './pages/settings/metafields/CreateAppMetafieldDefinition'
import AdminChat from './pages/more/chat/AdminChat'
import ChatsList from './pages/more/chat/ChatsList'
import MenuTiles from './pages/settings/misc/MenuTiles'

export const publicRoutes = [
    {
        url: 'login',
        component: <Login />,
        public: true,
    },
    {
        url: 'forgot-password',
        component: <ForgotPassword />,
        public: true,
    },
    {
        url: 'reset-password',
        component: <ResetPassword />,
        public: true,
    },
    {
        url: 'register',
        component: <Register />,
        public: true,
        registrationFlow: true,
    },
    {
        url: 's/:shortCode',
        component: <ShortLinkCallback />,
        public: true,
    },
]

export const userRoutes = [
    {
        url: 'profile',
        component: <ProfilePage />,
        title: 'Profile',
        staff: true,
        client: true,
        backUrl: '/',
    },
    {
        url: 'profile/details/edit',
        component: <EditProfile />,
        title: 'Complete Profile',
        client: true,
    },
    {
        url: 'profile/accounts/new',
        component: <CreateAccounts />,
        title: 'Accounts',
        client: true,
    },
    {
        url: 'profile/companies',
        component: <ProfileCompaniesList />,
        staff: true,
        client: true,
        backUrl: '/profile',
        title: 'Companies',
    },
    {
        url: 'profile/organizations',
        component: <ProfileCompaniesList />,
        staff: true,
        client: true,
        backUrl: '/profile',
        title: 'Companies',
    },
    {
        url: 'profile/companies/new',
        component: <CreateCompany />,
        staff: true,
        client: true,
    },
    {
        url: 'profile/companies/:organizationId',
        component: <OrganizationPage isOwnProfile />,
        staff: true,
        client: true,
        backUrl: '/profile/companies',
    },
    {
        url: 'profile/companies/:organizationId/edit',
        component: <EditOrganization />,
        staff: true,
        client: true,
    },
    {
        url: 'profile/company/edit',
        component: <EditOrganization />,
        title: 'Company info',
        staff: true,
        client: true,
    },
    {
        url: 'profile/company/new',
        component: <CreateCompany />,
        title: 'Company info',
        client: true,
    },
    {
        url: 'profile/company-ein/edit',
        component: <EditProfileCompanyInfoEin />,
        title: 'Company info',
        client: true,
    },
    // {
    //     url: 'profile/ssn/edit',
    //     component: <EditProfileSsn />,
    //     title: 'SSN',
    //     client: true,
    //     backUrl: '/profile',
    // },
    {
        url: 'profile/vendor/edit',
        component: <EditProfileVendor />,
        title: 'Vendor account request',
        client: true,
    },
    // {
    //     url: 'profile/vendor/compensation-waiver',
    //     component: <CreateCompensationWaiver />,
    //     title: 'Compensation Waiver',
    //     client: true,
    //     backUrl: '/profile',
    // },
    {
        url: 'profile/retail-buyer/edit',
        component: <EditProfileRetailBuyer />,
        title: 'Retail buyer info',
        client: true,
    },
    {
        url: 'profile/addresses',
        component: <AddressesList />,
        title: 'Addresses',
        client: true,
        backUrl: '/profile',
    },
    {
        url: 'profile/addresses/new',
        component: <CreateAddress />,
        title: 'New billing address',
        client: true,
    },
    {
        url: 'profile/billing/paypal/new',
        component: <CreateClientPayPalBilling />,
        title: 'PayPal',
        client: true,
    },
    {
        url: 'profile/billing/purchase-premium',
        component: <PurchasePremium />,
        title: 'Premium',
        client: true,
    },
    {
        url: 'profile/billing/premium',
        component: <PremiumSubPage />,
        title: 'Premium',
        client: true,
        backUrl: '/profile',
    },
    {
        url: 'profile/products-questions/edit',
        component: <VendorQuestions />,
        title: 'Products & brand info',
        client: true,
        backUrl: '/profile',
    },
    {
        url: 'profile/tax-forms/edit',
        component: <TaxForms />,
        title: 'Tax forms',
        client: true,
    },
    {
        url: 'profile/company-member-info/edit',
        component: <EditCompanyMemberInfo />,
        title: 'Company member info',
        client: true,
    },
    {
        url: 'shop/shopify-shops/new',
        component: <CreateShopifyShop />,
        title: 'New Shopify shop',
        staff: true,
        client: true,
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId',
        component: <ShopifyShopPage />,
        title: 'Shop',
        staff: true,
        client: true,
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/edit',
        component: <EditShopifyShop />,
        title: 'Edit Shopify shop',
        staff: true,
        client: true,
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations',
        component: <ShopLocationsList />,
        title: 'Locations',
        // staff: true,
        // client: true,
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations/new',
        component: <CreateShopLocation />,
        title: 'Locations',
        // staff: true,
        // client: true,
    },
    {
        url: 'settings/custom-locations/:locationId/inventory-items',
        component: <LocationInventoryItemsList />,
        title: 'Location inventory items',
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations/:locationId/inventory-items',
        component: <LocationInventoryItemsList />,
        title: 'Location inventory items',
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/products/import',
        component: <ImportProductsOwn />,
        title: 'Import products',
        staff: true,
        client: true,
    },
    // {
    //     url: 'shop/shopify-shops/:shopId/blogs/:blogId',
    //     component: <ArticlesList />,
    //     title: 'Blog posts',
    //     client: true,
    //     staff: true,
    // },
    {
        url: 'showroom/products/new',
        component: <CreateProduct />,
        title: 'New product',
        client: true,
    },
    {
        url: 'showroom/products/:shop/:productId',
        component: <ProductPage />,
        title: 'Product',
        client: true,
    },
    {
        url: 'showroom/products/:shop/:productId/edit',
        component: <EditShopifyGraphQLProduct />,
        title: 'Product',
        client: true,
    },
    {
        url: 'register/verify-email',
        component: <RegisterVerifyEmail />,
        client: true,
        registrationFlow: true,
    },
    {
        url: 'register/details',
        component: <RegisterDetails />,
        client: true,
        registrationFlow: true,
    },
    {
        url: 'menu',
        component: <TempMenuRedirect />,
        client: true,
        staff: true,
        public: true,
    },
    {
        url: 'events',
        component: <TempIowaCalendarEmbed />,
        client: true,
        staff: true,
    },
    {
        url: 'event-registration',
        component: <TempEventFormEmbed />,
        client: true,
        staff: true,
        public: true,
    },
    {
        url: 'event-registration-new',
        component: <VendorEventRegistrationPage />,
        client: true,
        staff: true,
        mixedAuth: true,
    },
    {
        url: 'donate',
        component: <DonationsOverview />,
        client: true,
        staff: true,
    },
    {
        url: 'donation',
        component: <CreateDonation />,
        client: true,
        staff: true,
    },
    {
        url: 'donations',
        component: <DonationSubsList />,
        client: true,
        staff: true,
    },
    {
        url: 'contactus',
        component: <Contact />,
        client: true,
        staff: true,
        mixedAuth: true,
    },
]

export const adminRoutes = [
    {
        url: '/',
        component: <Home />,
        staff: true,
        client: true,
        title: 'Dashboard',
    },
    // CONTACTS
    // Lists
    {
        url: 'contacts',
        component: <UsersResourceList />,
        title: 'Contacts',
        staff: true,
        client: true,
        backUrl: '/',
    },
    // Create
    {
        url: 'contacts/new',
        component: <CreateContact />,
        title: 'New contact',
        staff: true,
        client: true,
    },
    // Page
    {
        url: 'users/:userId',
        component: <ProfilePage />,
        staff: true,
        title: 'Profile',
    },
    {
        url: 'contacts/:contactId',
        component: <ProfilePage />,
        title: 'Contact',
        staff: true,
        client: true,
    },
    // Edit
    {
        url: 'contacts/:contactId/profile/edit',
        component: <EditContact />,
        title: 'Edit contact',
        staff: true,
        client: true,
    },
    {
        url: 'users/:userId/addresses',
        component: <AddressesList />,
        title: 'Addresses',
        staff: true,
    },
    {
        url: 'contacts/:contactId/addresses',
        component: <AddressesList />,
        title: 'Addresses',
        staff: true,
    },
    {
        url: 'users/:userId/notes/edit',
        component: <EditUserNotes />,
        title: 'Edit notes',
        staff: true,
    },
    {
        url: 'users/:userId/metafields',
        component: <EditUserMetafields />,
        title: 'Metafields',
    },
    {
        url: 'contacts/:contactId/metafields',
        component: <EditContactMetafields />,
        title: 'Metafields',
        staff: true,
        client: true,
    },
    {
        url: 'contact-forms',
        component: <ContactFormsList />,
        title: 'Contact forms',
        staff: true,
        client: true,
    },
    {
        url: 'contact-forms/new',
        component: <CreateContactForm />,
        title: 'Create contact form',
        staff: true,
        client: true,
    },
    {
        url: 'contact-forms/:contactFormId',
        component: <ContactFormPage />,
        title: 'Contact form',
        staff: true,
        client: true,
    },
    {
        url: 'contact-forms/:contactFormId/edit',
        component: <EditContactForm />,
        title: 'Edit contact form',
        staff: true,
        client: true,
    },
    {
        url: 'repos',
        component: <ReposList />,
        title: 'Repos',
    },
    {
        url: 'repos/:repoName',
        component: <RepoPage />,
        title: 'Repo',
    },
    {
        url: 'storybook',
        component: <ComponentsList />,
        title: 'Components',
    },
    {
        url: 'storybook/:componentName',
        component: <ComponentPage />,
        title: 'Component',
    },
    {
        url: 'referrals',
        component: <Referrals />,
        staff: true,
        client: true,
        title: 'Referrals Program',
    },
    {
        url: 'settings',
        component: <Settings />,
        staff: true,
        client: true,
        title: 'Settings',
    },
    {
        url: 'reset-pin',
        component: <ResetPin />,
        staff: true,
        client: true,
    },
    {
        url: 'settings/shopify-shops/new',
        component: <AdminCreateShopifyShop />,
        title: 'New Shop',
    },
    {
        url: 'settings/shopify-product-tags',
        component: <ShopifyProductTagsSetting />,
        title: 'Available product tags',
    },
    {
        url: 'settings/custom-locations',
        component: <CustomLocationsList />,
        title: 'Custom locations',
    },
    {
        url: 'settings/custom-locations/new',
        component: <CreateCustomLocation />,
        title: 'New custom location',
    },
    {
        url: 'settings/menu-tiles',
        component: <MenuTiles />,
        title: 'Menu tiles',
    },
    {
        url: 'settings/config',
        component: <EditConfigObject />,
        title: 'Edit config',
    },
    {
        url: 'metafields/app-definitions/new',
        component: <CreateAppMetafieldDefinition />,
        title: 'App metafield definition',
    },
    {
        url: 'logs',
        component: <LogsList />,
        title: 'Logs',
    },
    {
        url: 'notifications',
        component: <NotifsList />,
        title: 'Notifications',
        staff: true,
        client: true,
    },
    {
        url: 'timesheets',
        component: <TimesheetsList />,
        title: 'Timesheets',
        staff: true,
    },
    {
        url: 'users/:userId/timesheets',
        component: <TimesheetsList />,
        title: 'Timesheets',
    },
    {
        url: 'users/:userId/timesheets/:timesheetStartStr',
        component: <AdminTimesheetPage />,
        title: 'Timesheets',
    },
    {
        url: 'timesheets-users',
        component: <AdminTimesheetsUserList />,
        title: 'Timesheets users',
    },
    {
        url: 'timesheets/:timesheetStartStr',
        component: <EditTimesheet />,
        title: 'Timesheet',
        staff: true,
    },
    {
        url: 'translations',
        component: <TranslationsList />,
        title: 'Translations',
    },

    {
        url: 'more',
        component: <Menu />,
        staff: true,
        client: true,
    },
    {
        url: 'menu',
        component: <Menu />,
        staff: true,
        client: true,
        title: 'Menu',
    },
    {
        url: 'service-revenue',
        component: <ServiceRevenue />,
        title: 'Service revenue',
        accountant: true,
    },
    {
        url: 'content',
        component: <ContentOverview />,
        title: 'Content',
        client: true,
        staff: true,
    },
    {
        url: 'social/plans/new',
        component: <CreatePostPlan />,
        title: 'Posting plan',
        client: true,
        socialEditor: true,
    },
    {
        url: 'social/plans/:socialPostPlanId/edit',
        component: <EditSocialPostPlan />,
        title: 'Posting plan',
        client: true,
        socialEditor: true,
    },
    {
        url: 'social/plans/:socialPostPlanId/hashtags',
        component: <EditSocialPostPlanHashtags />,
        title: 'Hashtags',
        client: true,
        socialEditor: true,
    },
    {
        url: 'social/plans/:socialPostPlanId/log',
        component: <SocialPostPlanLog />,
        title: 'Posting log',
        client: true,
        socialEditor: true,
    },
    {
        url: 'social/plans/:socialPostPlanId/history',
        component: <SocialPostPlanPostsList />,
        title: 'Post history',
        client: true,
        socialEditor: true,
    },
    {
        url: '/content/blogs/new',
        component: <CreateBlog />,
        title: 'New blog',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId',
        component: <ArticlesList />,
        title: 'Blog posts',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/new',
        component: <CreateArticle />,
        title: 'New blog post',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/bulk-create',
        component: <BulkCreateArticles />,
        title: 'Bulk blog posts',
        client: true,
        staff: true,
    },
    {
        url: '/settings/permissions',
        component: <PermissionSettings />,
        title: 'Permissions',
        client: true,
        staff: true,
    },
    {
        url: '/settings/permissions/:resource/:id',
        component: <ResourcePermissions />,
        title: 'Permissions',
        client: true,
        staff: true,
    },
    {
        url: '/settings/permissions/:resource/:id/:permission/user',
        component: <ResourceIndividualPermissions />,
        title: 'User permissions',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId',
        component: <ArticlePage />,
        title: 'Blog post',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId/edit',
        component: <EditArticle />,
        title: 'Edit post',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId/schedule',
        component: <ScheduleArticle />,
        title: 'Schedule publishing',
        client: true,
        staff: true,
    },
    {
        url: 'billing',
        component: <BillingOverview />,
        title: 'Billing',
        collections: true,
        accountant: true,
        assistant: true,
    },

    {
        url: 'late-fees',
        component: <LateFeeList />,
        title: 'Late fees',
        lateFeeAssistant: true,
        accountant: true,
    },
    {
        url: 'users/:userId/edit-labels',
        component: <EditClientLabels />,
        staff: true,
    },
    {
        url: 'users/:userId/companies',
        component: <ProfileCompaniesList />,
        assistant: true,
        backUrl: '/users/:userId',
    },
    {
        url: 'users/:userId/edit-company',
        component: <EditClientCompany />,
        assistant: true,
        title: 'Edit company',
    },
    {
        url: 'users/:userId/addresses/new',
        component: <CreateAddress />,
        staff: true,
        client: true,
        title: 'New address',
    },
    {
        url: 'users/:userId/addresses/:addressId/edit',
        component: <EditAddress />,
        staff: true,
        title: 'Edit address',
    },
    {
        url: 'contacts/:contactId/addresses/new',
        component: <CreateAddress />,
        staff: true,
        client: true,
        title: 'New address',
    },
    {
        url: 'contacts/:contactId/addresses/:addressId/edit',
        component: <EditAddress />,
        staff: true,
        title: 'Edit address',
    },
    {
        url: 'profile/addresses/:addressId/edit',
        component: <EditAddress />,
        client: true,
        title: 'Edit address',
    },
    {
        url: 'users/:userId/contact-details',
        component: <ContactDetailsPage />,
        staff: true,
        client: true,
        title: 'Contact details',
    },
    {
        url: 'users/:userId/contact-details/phones/new',
        component: <CreatePhone />,
        staff: true,
        client: true,
        title: 'New phone',
    },
    {
        url: 'users/:userId/contact-details/emails/new',
        component: <CreateEmail />,
        staff: true,
        client: true,
        title: 'New email',
    },
    {
        url: 'users/:userId/contact-details/other-info',
        component: <EditOtherContactInfo />,
        staff: true,
        client: true,
        title: 'Other contact info',
    },
    {
        url: 'contacts/:contactId/contact-details',
        component: <ContactDetailsPage />,
        staff: true,
        client: true,
        title: 'Contact details',
    },
    {
        url: 'contacts/:contactId/contact-details/phones/new',
        component: <CreatePhone />,
        staff: true,
        client: true,
        title: 'New phone',
    },
    {
        url: 'contacts/:contactId/contact-details/emails/new',
        component: <CreateEmail />,
        staff: true,
        client: true,
        title: 'New email',
    },
    {
        url: 'contacts/:contactId/contact-details/other-info',
        component: <EditOtherContactInfo />,
        staff: true,
        client: true,
        title: 'Other contact info',
    },
    {
        url: 'profile/contact-details',
        component: <ContactDetailsPage />,
        staff: true,
        client: true,
        title: 'Contact details',
    },
    {
        url: 'profile/contact-details/phones/new',
        component: <CreatePhone />,
        staff: true,
        client: true,
        title: 'New phone',
    },
    {
        url: 'profile/contact-details/emails/new',
        component: <CreateEmail />,
        staff: true,
        client: true,
        title: 'New emails',
    },
    {
        url: 'profile/contact-details/other-info',
        component: <EditOtherContactInfo />,
        staff: true,
        client: true,
        title: 'Other contact info',
    },
    {
        url: 'users/:userId/settings',
        component: <ClientSettings />,
        title: 'Client settings',
        assistant: true,
    },
    {
        url: 'users/:userId/vendor-settings',
        component: <VendorSettings />,
        title: 'Vendor settings',
        assistant: true,
    },
    // {
    //     url: 'accounts/vendors/:accountId/settings',
    //     component: <VendorSettings />,
    //     title: 'Vendor settings',
    //     assistant: true,
    // },
    {
        url: 'organizations',
        component: <OrganizationsList />,
        title: 'Organizations',
        assistant: true,
    },
    {
        url: 'organizations/new',
        component: <CreateOrganization />,
        title: 'New organization',
        assistant: true,
    },
    {
        url: 'organizations/:organizationId',
        component: <OrganizationPage />,
        title: 'Organization',
        assistant: true,
    },
    {
        url: 'organizations/:organizationId/edit',
        component: <EditOrganization />,
        title: 'Edit organization',
        assistant: true,
    },
    {
        url: 'organizations/:organizationId/members',
        component: <AssignOrganizationMembers />,
        title: 'Organization members',
        assistant: true,
    },
    {
        url: 'organizations/:organizationId/members/invite',
        component: <InviteOrganizationMember />,
        title: 'Invite member',
        assistant: true,
        client: true,
    },
    {
        url: 'pdf-contracts',
        component: <PdfContractsList />,
        title: 'PDF Contracts',
    },
    {
        url: 'pdf-contracts/edit-addendum/:fileName',
        component: <EditPdfContract />,
        title: 'Edit PDF',
    },
    {
        url: 'html-contracts',
        component: <HtmlContractsList />,
        title: 'HTML contracts',
    },
    {
        url: 'html-contracts/new',
        component: <CreateHtmlContract />,
        title: 'Create contract',
        backUrl: '/html-contracts',
    },
    {
        url: 'html-contracts/:contractId/edit',
        component: <EditHtmlContract />,
        title: 'Edit contract',
        backUrl: '/html-contracts',
    },
    {
        url: 'html-contracts/:contractId/history',
        component: <HtmlContractHistory />,
        title: 'Contract history',
    },
    {
        url: 'users/:userId/settings/assigned-drives/new',
        component: <AssignNewDrive />,
        title: 'New Google drive',
        assistant: true,
    },
    {
        url: 'users/:userId/settings/assigned-drives/:driveId',
        component: <AssignedDrivePage />,
        title: 'Google drive',
        assistant: true,
    },
    {
        url: 'users/:userId/subscriptions',
        accountant: true,
        component: <SubsList />,
    },
    {
        url: 'users/:userId/subscriptions/new',
        component: <CreateSub />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId',
        accountant: true,
        component: <SubPage />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/edit',
        component: <EditSub />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices',
        accountant: true,
        component: <InvoicesList />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices/:invoiceId',
        component: <InvoicePage />,
        accountant: true,
        lateFeeAssistant: true,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices/:invoiceId/edit',
        component: <EditInvoice />,
    },
    {
        url: 'users/:userId/invoices',
        accountant: true,
        component: <InvoicesList />,
    },
    {
        url: 'users/:userId/invoices/new',
        component: <CreateInvoice />,
    },
    {
        url: 'users/:userId/invoices/:source/:invoiceId',
        component: <InvoicePage />,
        accountant: true,
        lateFeeAssistant: true,
    },
    {
        url: 'expenses/new',
        component: <CreateExpense />,
        title: 'New expense',
    },
    {
        url: 'expenses/:expenseId',
        accountant: true,
        component: <ExpensePage />,
    },
    {
        url: 'invoices/new',
        component: <CreateInvoice />,
        title: 'New invoice',
    },
    {
        url: 'services',
        component: <ServicesList />,
        accountant: true,
        title: 'Services',
    },
    {
        url: 'services/new',
        component: <CreateService />,
        title: 'New service',
    },
    {
        url: 'services/:source/:serviceId',
        accountant: true,
        component: <ServicePage />,
    },
    {
        url: 'service-categories',
        component: <ServiceCategoriesList />,
        accountant: true,
        title: 'Service categories',
    },
    {
        url: 'service-categories/new',
        component: <CreateServiceCategory />,
        title: 'New service category',
    },

    // {
    //     url: 'expenses/:expenseId/edit',
    //     component: <EditExpense />
    // }
    {
        url: 'services/:serviceId/prices/new',
        component: <CreatePrice />,
    },
    {
        url: 'team-members/:teamMemberId/assign',
        component: <CreateClientAssignment />,
        title: 'Assign clients',
        assistant: true,
    },
    {
        url: 'tasks/new',
        component: <CreateTask />,
        title: 'New task',
        staff: true,
        client: true,
    },
    {
        url: 'tasks/:taskId',
        component: <TaskPage />,
        title: 'Task Details',
        // noCollections: true,
        staff: true,
        client: true,
    },
    {
        url: 'tasks/:taskId/edit',
        component: <EditTask />,
        title: 'Edit task',
        staff: true,
    },
    {
        url: 'tasks/:taskId/reject',
        component: <RejectTask />,
        title: 'Reject task',
        assistant: true,
    },
    {
        url: 'users/:userId/tasks/new',
        component: <CreateTask />,
        title: 'New task',
        assistant: true,
    },
    {
        url: 'users/:userId/tasks/:taskId',
        component: <TaskPage />,
        title: 'Task',
        assistant: true,
    },
    {
        url: 'users/:userId/tasks/:taskId/edit',
        component: <EditTask />,
        title: 'Edit task',
        assistant: true,
    },
    {
        url: 'shop/products/import',
        component: <ImportProducts />,
        title: 'Import products',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/new',
        component: <CreateProduct />,
        title: 'Create product',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/:shop/:productId',
        component: <ProductPage />,
        title: 'Inventory details',
        assistant: true,
    },
    {
        url: 'shop/orders/:dbOrderId',
        component: <OrderPage />,
        title: 'Order',
        accountant: true,
        assistant: true,
    },
    {
        url: 'shop/db-orders',
        component: <DBOrdersList />,
        title: 'Showroom orders',
        accountant: true,
        assistant: true,
    },
    {
        url: 'shop',
        component: <ShopOverview />,
        title: 'Shop',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/new',
        component: <CreateProduct />,
        title: 'Create product',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/:productId',
        component: <AppProductPage />,
        title: 'Product Details',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/:productId/edit',
        component: <EditProduct />,
        title: 'Edit product',
        staff: true,
        client: true,
    },
    {
        url: 'shop/products/:productId/quantities/edit',
        component: <EditProductQuantities />,
        title: 'Product quantities',
        staff: true,
        client: true,
    },
    {
        url: 'shop/wishlist',
        component: <WishlistProductsList />,
        title: 'Wishlist',
        staff: true,
        client: true,
    },
    {
        url: 'shop/vendors/:organizationId',
        component: <PublicVendorPage />,
        title: 'Vendor',
        staff: true,
        client: true,
    },
    {
        url: 'temp/payout-validation',
        component: <TempPayoutValidationList />,
        title: 'Orders (raw)',
        accountant: true,
    },
    {
        url: 'shop/db-vendors/:userId',
        component: <AdminVendorPage />,
        title: 'Vendor',
        accountant: true,
        assistant: true,
    },
    {
        url: 'shop/db-vendors/:userId/custom-products',
        component: <CustomProductsList />,
        title: 'Custom products',
        assistant: true,
    },
    {
        url: 'shop/db-vendors/:userId/custom-products/new',
        component: <CreateCustomProduct />,
        title: 'New custom product',
        assistant: true,
    },
    {
        url: 'accounts/vendors/:accountId/custom-products',
        component: <CustomProductsList />,
        title: 'Custom products',
        assistant: true,
    },
    {
        url: 'accounts/vendors/:accountId/custom-products/new',
        component: <CreateCustomProduct />,
        title: 'New custom product',
        assistant: true,
    },
    {
        url: 'shop/db-orders/:dbOrderId/buyer',
        component: <BuyerPage />,
        title: 'Buyer',
        // assistant: true,
    },
    {
        url: 'vault',
        component: <PasswordsList />,
        title: 'Passwords Vault',
        staff: true,
        client: true,
    },
    {
        url: 'passwords/new',
        component: <CreatePassword />,
        title: 'New password',
        staff: true,
        client: true,
    },
    {
        url: 'passwords/:passwordId/edit',
        component: <EditPassword />,
        title: 'Edit password',
        staff: true,
        client: true,
    },
    {
        url: 'passwords/:passwordId/history',
        component: <PasswordHistory />,
        title: 'Password history',
    },
    {
        url: 'all-pages',
        component: <UserPagesOverview />,
        title: 'Pages',
        client: true,
        staff: true,
    },
    {
        url: 'edit-pages/new',
        component: <CreatePage />,
        title: 'New page',
        assistant: true,
    },
    {
        url: 'edit-pages/:pageId',
        component: <EditPage />,
        title: 'Edit page',
        assistant: true,
    },
    {
        url: 'edit-pages',
        component: <PagesOverview />,
        title: 'Pages',
        assistant: true,
    },
    {
        url: 'pages/:slug',
        component: <Page />,
        mixedAuth: true,
    },
    {
        url: 'pages/:categorySlug/:slug',
        component: <Page />,
        mixedAuth: true,
    },
    {
        url: 'plain-forms',
        component: <PlainFormsList />,
        title: 'Forms',
    },
    {
        url: 'plain-forms/:formId',
        component: <PlainFormPage />,
        title: 'Form',
    },
    {
        url: 'admin-chat',
        component: <AdminChat />,
        title: 'Admin chat',
    },
    {
        url: 'chats',
        component: <ChatsList />,
        title: 'Chats',
        staff: true,
        client: true,
    },
    {
        url: 'dev',
        component: <Dev />,
        title: 'Form',
        staff: true,
        client: true,
        hideFooter: true,
    },
]

export const callbackRoutes = [
    {
        url: 'verify-email-callback',
        component: <VerifyEmailCallback />,
        public: true,
    },
    {
        url: 'refresh',
        component: <Refresh />,
        public: true,
    },
    {
        url: 'callbacks/connected-account', // TODO test FB redirect on Stage
        component: (
            <Navigate
                to={sessionStorage.getItem('redirect_connect_account_url')}
            />
        ),
        client: true,
        staff: true,
    },
    {
        url: 'callbacks/facebook-login',
        component: <FacebookLoginCallback />,
        client: true,
        staff: true,
        backUrl: '/content#connect__',
    },
    {
        url: 'callbacks/stripe-intent-complete',
        component: <StripeIntentCompleteCallback />,
        client: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/stripe-premium-complete',
        component: <StripePremiumSubCompleteCallback />,
        client: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/donation-complete',
        component: <DonationCompleteCallback />,
        client: true,
        staff: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/org-invite-accept',
        component: <AcceptOrgInviteCallback />,
        client: true,
        staff: true,
    },
]

const routes = [
    ...publicRoutes,
    ...callbackRoutes,
    ...userRoutes,
    ...adminRoutes,
]

export default routes
