import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStore } from '../../common/Context'
import fetchAPI from '../../common/fetchAPI'
import authStorage from '../../common/authStorage'

// This is a page that loads from external URL
// where the server has redirected the user,
// with the temp token URL parameter

const Refresh = () => {
    const [searchParams] = useSearchParams()
    const [state, dispatch] = useStore()

    React.useEffect(() => {
        const fetchNewTokens = async () => {
            if (state.user) {
                // Cleanup user if logged in
                // TODO test this
                authStorage.removeItem('access_token')
                authStorage.removeItem('refresh_token')
                dispatch({ type: 'LOGOUT' })
            }

            const tempToken = searchParams.get('temp_token')
            const { responseData: tokens, error: _error } = await fetchAPI(
                `/v1/auth/replace-temp-token`,
                { tempToken },
                'POST',
                {},
                false,
            )
            // TODO handle err

            authStorage.setItem('access_token', tokens.access.token)
            authStorage.setItem('refresh_token', tokens.refresh.token)

            window.location.href = '/register/details'
        }

        fetchNewTokens()
    }, [])

    return <div>Loading...</div>
}

export default Refresh
