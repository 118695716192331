import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function TempEventFormEmbed() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/pages/event-registration')
    }, [])

    return <div></div>
}

export default TempEventFormEmbed
