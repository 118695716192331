export const cartInitialState = {
    items: [],
}

export const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ITEM': {
            return { ...state, items: [...state.items, action.payload] }
        }
        default:
            return state
    }
}
