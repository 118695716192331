import { useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import { addressToString } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Tag from '../../../components/common/Tag'

function AddressesList({ userId, contactId, plain }) {
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const auth = useAuth()
    const { userId: paramUserId, contactId: paramContactId } = useParams()
    const finalUserId = userId || paramUserId
    const finalContactId = contactId || paramContactId

    function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/addresses/${userId || contactId || auth.user.id}/${
                            item.id
                        }`,
                        {},
                        'DELETE',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    matchMutate(/\/v1\/addresses*/)
                    setToast('Deleted')
                }}
            />,
            'Are you sure you want to delete this address?',
        )
    }

    function onSetType(item, newType) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { id, addressTypes } = item
                    const newAddressTypes = Array.from(
                        new Set([...addressTypes, newType]),
                    ).filter((addressType) => addressType)

                    const { error } = await fetchAPI(
                        `/v1/addresses/${
                            userId || contactId || auth.user.id
                        }/${id}`,
                        {
                            addressTypes: newAddressTypes,
                        },
                        'PATCH',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    matchMutate(/\/v1\/addresses*/)
                    setToast('Saved')
                }}
            />,
        )
    }

    let newItemPath = ''
    let itemEditPath = ''
    if (finalUserId) {
        newItemPath = `/users/${finalUserId}/addresses/new`
        itemEditPath = `/users/${finalUserId}/addresses/:item.id/edit`
    } else if (finalContactId) {
        newItemPath = `/contacts/${finalContactId}/addresses/new`
        itemEditPath = `/contacts/${finalContactId}/addresses/:item.id/edit`
    } else {
        newItemPath = `/profile/addresses/new`
        itemEditPath = `/profile/addresses/:item.id/edit`
    }

    return (
        <ResourceList
            minimal={Boolean(plain)}
            newItemPath={!plain && newItemPath}
            apiUrl={`/addresses/${finalUserId || finalContactId || auth.user.id}`}
            getItemsFromResponse={(data) => data?.results}
            initialQuery={{
                ownerId: finalUserId || finalContactId || auth.user.id,
                ownerType: !finalContactId ? 'User' : 'Lead',
            }}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <img
                                width="16"
                                height="16"
                                alt="icon"
                                src={ICONS.HOME_WHITE}
                            />
                            <span>{addressToString(item)}</span>
                        </>
                    ),
                    getClassName: () => 'with-icon',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.addressTypes.map((addressType) =>
                            addressType ? (
                                <Tag key={addressType} outline>
                                    {addressType}
                                </Tag>
                            ) : null,
                        ),
                },
            ]}
            actions={
                plain
                    ? []
                    : [
                          {
                              title: 'Edit',
                              icon: ICONS.EDIT_GRAY,
                              link: itemEditPath,
                          },
                          {
                              title: 'Set as main',
                              onClick: (item) => onSetType(item, 'main'),
                              getHide: (item) =>
                                  item.addressTypes.includes('main'),
                          },
                          {
                              title: 'Set as shipping',
                              onClick: (item) => onSetType(item, 'shipping'),
                              getHide: (item) =>
                                  item.addressTypes.includes('shipping'),
                          },
                          {
                              title: 'Set as billing',
                              onClick: (item) => onSetType(item, 'billing'),
                              getHide: (item) =>
                                  item.addressTypes.includes('billing'),
                          },
                          {
                              title: 'Delete',
                              icon: ICONS.TRASH_RED,
                              type: 'alert',
                              onClick: (item) => {
                                  onDelete(item)
                              },
                              getDisabled: (item) =>
                                  item.addressTypes.includes('main') ||
                                  item.addressTypes.includes('shipping') ||
                                  item.addressTypes.includes('billing'),
                          },
                      ]
            }
        />
    )
}

export default AddressesList
