import { useEffect, useRef } from 'react'

function PdfViewer(props) {
    const { objectUrl } = props
    const ref = useRef(null)

    useEffect(
        function () {
            if (!ref.current) {
                return
            }
            if (!objectUrl) {
                ref.current.src = ''
                return
            }
            const embed = ref.current
            embed.src = objectUrl
            embed.type = 'application/pdf'
            window.URL.revokeObjectURL(objectUrl)
        },
        [objectUrl],
    )

    if (!objectUrl) return null

    return (
        <div className="pdf-viewer">
            <iframe ref={ref} title="Pdf content" width={0} height={0} />
        </div>
    )
}

export default PdfViewer
