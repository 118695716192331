import { useNavigate } from 'react-router-dom'
import useAuth from '../../common/hooks/useAuth'
import { getUserDisplayName } from '../../common/helpers'
import SectionContainer from '../../components/common/SectionContainer'

export default function ProfileCard() {
    const auth = useAuth()
    const navigate = useNavigate()

    return (
        <SectionContainer>
            <div
                className={`profile-card ${auth.isClient ? 'has-link' : ''}`}
                role={auth.isClient ? 'button' : undefined}
                onClick={auth.isClient ? () => navigate('/profile') : () => {}}
            >
                <div className="img-container">
                    <img
                        width="15"
                        height="15"
                        alt=""
                        src="/img/icons/user.svg"
                    />
                </div>
                <div className="info-container">
                    <span className="name">
                        {getUserDisplayName(auth.user)}
                    </span>
                    <span className="rest-info">
                        {auth.isAdmin && 'Admin'}
                        {auth.isStaff && 'aCo Team'}
                    </span>
                </div>
            </div>
        </SectionContainer>
    )
}
