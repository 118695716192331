import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'

function HtmlContractHistory() {
    const { contractId } = useParams()

    return (
        <pre>
            <ResourceList
                apiUrl={`/html-contracts/${contractId}`}
                getItemsFromResponse={(data) => data?.history || []}
                initialQuery={{
                    withHistory: true,
                }}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.creationDate,
                    },
                    {
                        column: 1,
                        getValue: (item) => (
                            <div>
                                <details className="details">
                                    <summary>
                                        <h5>Content</h5>
                                    </summary>
                                    <div>
                                        {item.htmlContent
                                            .replace(/&lt;/g, '<')
                                            .replace(/&gt;/g, '>') || ''}
                                    </div>
                                </details>
                            </div>
                        ),
                    },
                ]}
            />
        </pre>
    )
}

export default HtmlContractHistory
