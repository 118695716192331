import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import {
    formatDate,
    formatMoney,
    getUserDisplayName,
} from '../../../common/helpers'
import { ICONS } from '../../../common/constants'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import Tag from '../../../components/common/Tag'

// const filterFields = [

//     {
//         name: 'CREATION',
//         asc: false,
//         sorter: (a, b) => {
//             return a?.creationDate - b?.creationDate
//         },
//     },

// ]

export default function LateFeeList() {
    const navigate = useNavigate()
    const { setModal, isModalLoading, setModalLoading } = useModal()
    const { mutate } = useSWRConfig()
    const setToast = useToast()

    function onCreateFeeInvoice(lateFeeItem) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/payment/late-fee-invoices/${lateFeeItem.id}/send`,
                        {},
                        'POST',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    mutate('/v1/payment/late-fee-invoices')
                    mutate('/v1/payment/late-fee-invoices?')
                    setToast(`Invoice sent`)
                }}
            />,
            `Create and send Stripe invoice of ${formatMoney(
                lateFeeItem.feeAmount,
            )}?`,
        )
    }

    return (
        <ResourceList
            renderMainButton={false}
            apiUrl={'/payment/late-fee-invoices'}
            getItemsFromResponse={(data) => data.results}
            // filters={filterFields}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            Bill {formatMoney(item.feeAmount)} for late invoice
                            of&nbsp;{formatMoney(item.lateAmount)}
                        </>
                    ),
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.client ? getUserDisplayName(item.client) : null,
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <>Created {formatDate(item.creationDate)}</>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            {item.billDate
                                ? `Late fee invoiced ${formatDate(
                                      item.billDate,
                                  )}`
                                : null}
                        </>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.billDate ? (
                            <Tag color={'success'}>Billed</Tag>
                        ) : (
                            <Tag color={'warning'}>Not billed</Tag>
                        ),
                },
            ]}
            actions={[
                {
                    title: 'Late invoice',
                    onClick: (item) =>
                        navigate(
                            `/users/${item.clientId}/invoices/stripe/${item.lateInvoiceId}`,
                        ),
                    icon: ICONS.LINK_GRAY,
                },
                {
                    title: 'Bill amount now',
                    onClick: (item) => onCreateFeeInvoice(item),
                },
            ]}
        />
    )
}
