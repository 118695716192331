import React, { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import { useParams } from 'react-router-dom'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import MainButton from '../../../components/admin/MainButton'
import useModal from '../../../common/hooks/useModal'
import FormModalContent from '../../../components/common/FormModalContent'
import { ICONS } from '../../../common/constants'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

function EditClientLabels() {
    const { userId } = useParams()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()

    const { user, userError, userLoading, userMutate } = useData(
        `/v1/users/${userId}?fields=labels`,
        'user',
    )

    const [actionLoading, setActionLoading] = useState(false)

    const { labelsLoading, labelsError, labels } = useData(
        '/v1/settings/userLabels',
        'labels',
        (data) => data?.settingValue?.split('\n') || [],
    )

    if (labelsLoading) {
        return <Spinner />
    }

    if (labelsError && labelsError !== 'Setting not found') {
        return (
            <SectionContainer>
                <ErrorMessage>{labelsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    function onNewLabel() {
        setModal(
            <FormModalContent
                fields={[
                    {
                        name: 'label',
                        label: 'Label',
                        type: 'select',
                        default: '',
                        options: [
                            { value: '', label: '' },
                            ...labels.map((label) => ({
                                value: label,
                                label: label,
                            })),
                        ],
                    },
                ]}
                confirmText="Create"
                onConfirm={async (data) => {
                    const text = Object.values(data)[0]
                    if (!text) {
                        setModal(null)
                        return
                    }
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/users/${userId}`
                    const { responseData, error } = await fetchAPI(
                        url,
                        {
                            labels: [...(user.labels || []), text],
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Created label')
                    userMutate({ ...user, ...responseData })
                }}
            />,
        )
    }

    async function onDelete(label) {
        if (actionLoading) return
        setActionLoading(true)
        const url = `/v1/users/${userId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                labels: user.labels.filter((item) => item !== label),
            },
            'PATCH',
        )
        setActionLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Deleted label')
        userMutate({ ...user, ...responseData })
    }

    if (userLoading) return <Spinner />
    if (userError) return <ErrorMessage section>{userError}</ErrorMessage>

    return (
        <>
            <MainButton
                functionality="CREATE"
                label="Add Label"
                onClick={onNewLabel}
            />

            <ResourceList
                items={user.labels || []}
                itemsLoading={userLoading}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item,
                    },
                ]}
                actions={[
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        loading: actionLoading,
                        onClick: (item) => onDelete(item),
                    },
                ]}
            />
        </>
    )
}

export default EditClientLabels
