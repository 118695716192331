import Break from '../components/common/Break'
import Button from '../components/common/Button'
import ErrorMessage from '../components/common/ErrorMessage'
import InlineStack from '../components/common/InlineStack'

export default function AppError() {
    return (
        <ErrorMessage section>
            Something went wrong. <Break />
            <div>
                <InlineStack gap="sm">
                    <Button
                        inline
                        white
                        small
                        outline
                        text="Refresh"
                        onClick={() => window.location.reload()}
                    />
                    <Button
                        inline
                        white
                        small
                        outline
                        text="Go to homepage"
                        onClick={() => (window.location.href = '/')}
                    />
                </InlineStack>
            </div>
        </ErrorMessage>
    )
}
