import { useEffect, useState } from 'react'
import { ICONS } from '../../../common/constants'
import { formatMoney } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import CustomDetails from '../../../components/admin/CustomDetails'

export default function AppProductItem({ item }) {
    const auth = useAuth()
    const [isInWishList, setIsInWishList] = useState(false)

    useEffect(
        function () {
            setIsInWishList(
                localStorage.getItem('wishList')?.includes(item.id) || false,
            )
        },
        [item.id],
    )

    function toggleWishList(e) {
        e.preventDefault()
        const wishList = localStorage.getItem('wishList')
        if (wishList?.includes(item.id)) {
            localStorage.setItem(
                'wishList',
                wishList.replace(`${item.id},`, ''),
            )
        } else {
            localStorage.setItem('wishList', `${wishList}${item.id},`)
        }
        setIsInWishList(!isInWishList)
    }

    // Mock data
    // item.excerpt = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod.`
    // item.starRating = 4
    // item.reviewCount = 10

    const isInAppShop =
        item.enabledShopifyShopsInfo &&
        item.enabledShopifyShopsInfo.some((s) => !s.shopifyShop?.ownerId)

    const isInOwnUserShop =
        item.enabledShopifyShopsInfo &&
        item.enabledShopifyShopsInfo.some((s) =>
            auth.user.orgsData.some((o) => o.id === s.shopifyShop?.ownerId),
        )

    const isInOwnUserShopOnly = !isInAppShop && isInOwnUserShop

    return (
        <div className="admin-product-item">
            <div className="wishlist-add-container">
                {!isInOwnUserShopOnly && (
                    <img
                        src={
                            isInWishList
                                ? ICONS.HEART_FILLED_WHITE
                                : ICONS.HEART_WHITE
                        }
                        onClick={toggleWishList}
                        alt="heart"
                        width="24"
                        height={24}
                        role="button"
                        title={
                            isInWishList
                                ? 'Remove from wishlist'
                                : 'Add to wishlist'
                        }
                    />
                )}
            </div>
            {/* <div className="admin-product-item-acronym">
                {item.owner?.todo?.acronym
                    ? item.owner?.todo?.acronym
                    : ''}
            </div> */}
            <div className="admin-product-item-img-container">
                {item.featuredImage?.url ? (
                    <img
                        width="70"
                        height="70"
                        src={item.featuredImage.url}
                        alt={item.title}
                        className="product-list-image"
                    />
                ) : null}
            </div>
            <div className="admin-product-item-info">
                <div className="title">{item.title}</div>
                <div className="text-subdued product-excerpt">
                    {item.excerpt ? (
                        <CustomDetails maxHeight={100}>
                            <small>{item.excerpt}</small>
                        </CustomDetails>
                    ) : (
                        '-'
                    )}
                </div>

                <div>
                    {!isInOwnUserShopOnly && (
                        <small className="text-subdued">
                            {`Quantity: ${
                                item.inventoryInfo
                                    ?.totalQuantityExclOwnLocations || 0
                            }`}
                        </small>
                    )}
                </div>
                <div className="item-price">
                    {!isInOwnUserShopOnly && (
                        <small className="text-subdued">
                            {`Retail price: ${
                                item.variants?.[0]?.retailPrice
                                    ? formatMoney(item.variants[0].retailPrice)
                                    : '-'
                            }`}
                        </small>
                    )}
                </div>
                <div>
                    {!isInOwnUserShopOnly && (
                        <small className="text-subdued">
                            {`Wholesale price: ${
                                item.variants?.[0]?.retailPrice
                                    ? formatMoney(
                                          item.variants[0].retailPrice,
                                          'app',
                                      )
                                    : '-'
                            }`}
                        </small>
                    )}
                </div>
                <div>
                    {!isInOwnUserShopOnly && (
                        <small className="text-subdued">
                            {`MOQ: ${
                                item.variants?.[0]?.minimumOrderType ===
                                    'quantity' &&
                                item.variants?.[0]?.minimumOrderValue
                                    ? item.variants[0].minimumOrderValue
                                    : '-'
                            }`}
                        </small>
                    )}
                </div>
                {/* {auth.isAdmin && (
                    <div>
                        {!isInOwnUserShopOnly && (
                            <small className="text-subdued">
                                {`Commission: ${
                                    item.owner?.todo?.vendorAccount
                                        ?.commissionAmount &&
                                    item.variants?.[0]?.wholesalePrice
                                        ? formatMoney(
                                              item.owner.todo
                                                  .vendorAccount
                                                  .commissionAmount *
                                                  item.variants[0]
                                                      .wholesalePrice *
                                                  0.01,
                                              'app',
                                          )
                                        : '-'
                                }`}
                            </small>
                        )}
                    </div>
                )} */}

                <>
                    <span className="reviews-container">
                        {!isInOwnUserShopOnly && (
                            <>
                                <br />
                                <br />
                                {Array.from({ length: 5 }).map((_, i) =>
                                    item.starRating > i ? (
                                        <img
                                            src={ICONS.STAR_FILLED_WHITE}
                                            width={16}
                                            height={16}
                                            alt="star"
                                            key={i}
                                        />
                                    ) : (
                                        <img
                                            src={ICONS.STAR_WHITE}
                                            width={16}
                                            height={16}
                                            alt="star"
                                            key={i}
                                        />
                                    ),
                                )}
                                <small className="text-subdued">
                                    &nbsp;
                                    {`(${
                                        item.reviewCount === 1
                                            ? `${item.reviewCount} review`
                                            : `${item.reviewCount || 0} reviews`
                                    })`}
                                </small>
                            </>
                        )}
                    </span>
                    {/* {Boolean(withBuy) && (
                        <>
                            <br />
                            <ButtonGroup row>
                                <Button
                                    text={'Add to cart'}
                                    outline
                                    small
                                    onClick={handleAddToCart}
                                />
                                <Button
                                    text={'Buy now'}
                                    outline
                                    small
                                    onClick={handleAddToCart}
                                />
                            </ButtonGroup>
                        </>
                    )} */}
                </>
            </div>
        </div>
    )
}
