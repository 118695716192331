import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'

export default function UserLabelSettings() {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const setToast = useToast()

    const { labelsSettingLoading, labelsSettingError, labelsSettingMutate } =
        useData(
            '/v1/settings/userLabels',
            'labelsSetting',
            (data) => data?.settingValue || '',
            false,
            false,
            (data) => {
                setData(data?.settingValue || '')
            },
        )

    async function onSubmitLabels(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/userLabels`,
            { settingValue: data || '' },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error)
        }
        setToast('Saved')
        labelsSettingMutate()
    }

    if (labelsSettingLoading) {
        return <Spinner />
    }

    if (labelsSettingError && labelsSettingError !== 'Setting not found') {
        return <ErrorMessage section>{labelsSettingError}</ErrorMessage>
    }

    return (
        <>
            {error && <ErrorMessage section>{error}</ErrorMessage>}

            <form onSubmit={onSubmitLabels}>
                <label htmlFor="form-labels">
                    User labels (new line separates labels)
                </label>
                <TextInput
                    type="textarea"
                    rows={6}
                    id="form-labels"
                    onChange={(v) => setData(v)}
                    value={data || ''}
                />
                <Button
                    type="submit"
                    text="Save team names"
                    small
                    alignRight
                    isLoading={loading}
                />
                <br />
            </form>
        </>
    )
}
