import { useState } from 'react'
import Button from '../../../components/common/Button'
import TextInput from '../../../components/common/data-form/TextInput'

function TranslationItem(props) {
    const { translation, onSubmit, loading } = props
    const [value, setValue] = useState(translation.value || '')
    const parts = translation.key.split('.')
    const lastPart = parts.pop()

    function handleSubmit(e) {
        e.preventDefault()
        onSubmit(translation.key, value)
    }

    return (
        <form className="translation-item" onSubmit={handleSubmit}>
            <label htmlFor={`t-${translation.key}`}>{lastPart}</label>
            <TextInput
                type="textarea"
                name={translation.key}
                id={`t-${translation.key}`}
                value={value}
                rows={1}
                onChange={(v) => setValue(v)}
                className={
                    translation.defaultValue?.length > 50 ? 'long-text' : ''
                }
            />
            <Button
                small
                outline
                type="submit"
                text="Save"
                isLoading={loading}
            />
            <small>Default: {translation.defaultValue || '-'}</small>
        </form>
    )
}

export default TranslationItem
