import { useState } from 'react'

export default function useError() {
    const [error, setError] = useState(null)

    const setNewError = (error) => {
        setError(error)
        if (!error) return
        window.scrollTo(0, 0)
    }

    return [error, setNewError]
}
