import useData from '../../../common/hooks/useData'
import ResourceList from '../../../components/admin/ResourceList'
import Spinner from '../../../components/common/Spinner'

const filterFields = {
    search: true,
    sort: {
        id: {
            label: 'Product ID',
            dbFields: ['id'],
        },
    },
}

export default function AdminShopifyGraphQLProductsList() {
    const { vendors } = useData(
        `/v1/users?type=vendors&fields=acronym`,
        'vendors',
        (data) => data?.results?.map((item) => item.acronym) || [],
    )

    const { shops, shopsLoading } = useData(
        `/v1/wholesale/shops?fields=name`,
        'shops',
        (data) => data?.map((item) => item.name) || [],
    )

    const updatedFilterFields = {
        filters: {
            vendor: {
                label: 'Vendor',
                dbField: 'vendor',
                options: [
                    {
                        label: 'Any',
                        value: 'undefined',
                    },
                    ...vendors
                        .filter((v) => v)
                        .map((vendor) => ({
                            label: vendor,
                            value: vendor,
                        })),
                ],
            },
            shop: {
                label: 'Shop',
                dbField: 'shop',
                options: [
                    ...shops.map((shop) => ({
                        label: shop,
                        value: shop,
                    })),
                ],
            },
        },
    }

    if (shopsLoading) return <Spinner />

    return (
        <ResourceList
            apiUrl={'/wholesale/products-graphql'}
            getItemsFromResponse={(data) => data?.results}
            paginated={true}
            cursorPagination
            paginationLimit={5}
            filters={{ ...filterFields, ...updatedFilterFields }}
            itemClickPath={'/shop/products/:filter.shop/:item.legacyResourceId'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) => item.vendor,
                },
                {
                    column: 1,
                    getValue: (item) => `${item.totalInventory} in stock`,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        `Price: $${item.priceRangeV2.minVariantPrice.amount}`,
                },
                {
                    column: 1,
                    getValue: (item) =>
                        `Cost: $${Math.min(
                            item.variants.edges
                                .map((edge) =>
                                    Number(
                                        edge.node.inventoryItem.unitCost
                                            ?.amount || 0,
                                    ),
                                )
                                .filter((v) => v),
                        )}`,
                },
            ]}
        />
    )
}
