import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import { getUserDisplayName } from '../../../common/helpers'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import Toggle from '../../../components/common/Toggle'
import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import InputModalContent from '../../../components/common/InputModalContent'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import PasswordInput from '../../../components/common/data-form/PasswordInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
}

export default function EditPassword() {
    const { passwordId } = useParams()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [error, setError] = useError()
    const [actionLoading, setActionLoading] = useState(false)
    const navigate = useNavigate()
    const setToast = useToast()
    const [password, setPassword] = useState(null)

    const [data, setData] = useState({
        title: '',
        username: '',
        password: '',
        website: '',
        notes: '',
        allUsersAllowed: false,
        allStaffAllowed: false,
        allowedUserIds: [],
    })

    useEffect(function () {
        setModal(
            <InputModalContent
                label="PIN (4 numbers)"
                type="password"
                confirmText="OK"
                onCancel={() => {
                    navigate('/vault')
                }}
                onConfirm={async (data) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { responseData, error } = await fetchAPI(
                        `/v1/vault/${passwordId}`,
                        { pin: data.text },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        navigate('/vault')
                        return
                    }
                    setModal(null)
                    setPassword(responseData)
                    setData({
                        title: responseData.title,
                        username: responseData.username,
                        password: responseData.password,
                        website: responseData.website,
                        notes: responseData.notes,
                        allowedUserIds: responseData.allowedUserIds || [],
                        allUsersAllowed: responseData.allUsersAllowed,
                        allStaffAllowed: responseData.allStaffAllowed,
                    })
                    setDataLoaded(true)
                }}
            />,
            'Provide your PIN',
        )
    }, [])

    async function onSubmit(e) {
        e.preventDefault()
        setError('')

        if (data.allowedUserIds?.length > 10) {
            setError('Currently, you can only share with up to 10 users.')
            return
        }

        if (
            data.allowedUserIds?.length ||
            data.allStaffAllowed ||
            data.allUsersAllowed
        ) {
            setModal(
                <ConfirmModalContent
                    onConfirm={async () => {
                        if (isModalLoading) return
                        setModalLoading(true)
                        const url = `/v1/vault/${passwordId}`
                        const { responseData, error } = await fetchAPI(
                            url,
                            data,
                            'PATCH',
                        )
                        setModalLoading(false)
                        if (error) {
                            setModal(null)
                            setError(error)
                            return
                        }
                        setModal(null)
                        setToast('Saved')
                        navigate('/vault')
                        setPassword({ ...password, ...responseData })
                    }}
                />,
                'Are you sure you want to share this password?',
            )
        } else {
            setActionLoading(true)
            const url = `/v1/vault/${passwordId}`
            const body = { ...data }
            if (auth.isClient) {
                delete body.allowedUserIds
                delete body.allUsersAllowed
                delete body.allStaffAllowed
            }
            const { responseData, error } = await fetchAPI(url, body, 'PATCH')
            setActionLoading(false)
            if (error) {
                setError(error)
                return
            }
            setToast('Saved')
            navigate('/vault')
            setPassword({ ...password, ...responseData })
        }
    }

    if (!password) return null

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <label className="form-title">Title</label>
                <TextInput
                    id="form-title"
                    label={'Title'}
                    required
                    value={data.title || ''}
                    onChange={(e) =>
                        setData({ ...data, title: e.target.value })
                    }
                    autoComplete="off"
                />

                <label className="form-username">Username</label>
                <TextInput
                    id="form-username"
                    required
                    value={data.username || ''}
                    onChange={(v) => setData({ ...data, username: v })}
                    autoComplete="off"
                />

                <label className="required" htmlFor="form-password">
                    Password
                </label>
                <PasswordInput
                    required
                    id="form-password"
                    onChange={(v) =>
                        setData({
                            ...data,
                            password: v,
                        })
                    }
                    value={data.password || ''}
                    autoComplete="off"
                />
                <small className="input-info">
                    {`The app's administration can view and edit this password.`}
                </small>

                <label className="form-website">Website</label>
                <TextInput
                    id="form-website"
                    value={data.website || ''}
                    onChange={(v) => setData({ ...data, website: v })}
                    autoComplete="off"
                    name="website"
                />

                <label className="form-notes">Notes</label>
                <TextInput
                    type="textarea"
                    id="form-notes"
                    value={data.notes || ''}
                    onChange={(v) => setData({ ...data, notes: v })}
                    autoComplete="off"
                    rows={2}
                />

                {!auth.isClient && (
                    <Toggle title="Share with others">
                        {dataLoaded ? (
                            <>
                                <CheckboxInput
                                    id={'share-with-all-clients'}
                                    onChange={(v) => {
                                        setData({
                                            ...data,
                                            allUsersAllowed: v,
                                        })
                                    }}
                                    label={'Share with all clients'}
                                    value={Boolean(data.allUsersAllowed)}
                                />
                                <CheckboxInput
                                    id={'share-with-all-team'}
                                    onChange={(v) => {
                                        setData({
                                            ...data,
                                            allStaffAllowed: v,
                                        })
                                    }}
                                    label={'Share with all team members'}
                                    value={Boolean(data.allStaffAllowed)}
                                />
                                <ResourceList
                                    getSelectedItemText={(item) =>
                                        getUserDisplayName(item)
                                    }
                                    apiUrl={'/users'}
                                    initialQuery={{
                                        fields: 'email,firstName,lastName',
                                    }}
                                    filters={filterFields}
                                    getItemsFromResponse={(data) =>
                                        data?.results
                                    }
                                    isSelectable={true}
                                    preselectedIds={data.allowedUserIds}
                                    onSelectedItemsChange={(ids) =>
                                        setData({
                                            ...data,
                                            allowedUserIds: ids,
                                        })
                                    }
                                    paginated
                                    paginationLimit={5}
                                    loadMoreButton
                                    fields={[
                                        {
                                            column: 1,
                                            getValue: (item) =>
                                                getUserDisplayName(item),
                                        },
                                    ]}
                                />
                            </>
                        ) : null}
                    </Toggle>
                )}

                <Break />

                <div>
                    <Button
                        text={'Save'}
                        type="submit"
                        isLoading={actionLoading}
                    />
                </div>
            </form>
        </SectionContainer>
    )
}
