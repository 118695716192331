import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'

export default function ShopBlogsList() {
    const { shopifyShopId } = useParams()

    return (
        <ResourceList
            apiUrl={`/blogs/${shopifyShopId}`}
            initialQuery={{
                fields: 'id,title,shop.id,shop.fullName',
            }}
            itemClickPath={`/content/blogs/${shopifyShopId}/:item.id`}
            emptyText={
                'No blogs. Make sure your shop keys have the "write_content" permission.'
            }
            newItemLabel={'New blog'}
            newItemPath={`/shop/shopify-shops/${shopifyShopId}/blogs/new`}
            renderMainButton
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) => item.shop.fullName,
                    getClassName: () => 'subtitle',
                },
            ]}
        />
    )
}
