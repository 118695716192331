import React from 'react'
import { ICONS } from '../../common/constants'
import { Link } from 'react-router-dom'
import Spinner from '../common/Spinner'

function MainButton({
    functionality,
    onClick,
    disabled,
    label = 'New',
    loading,
    href,
}) {
    let icon
    let text
    switch (functionality) {
        case 'CREATE':
            icon = ICONS.PLUS_WHITE
            text = label
            break
        case 'SAVE':
            icon = ICONS.CHECK_WHITE
            text = 'Save'
            break
        case 'EDIT':
            icon = ICONS.EDIT_WHITE
            text = 'Edit'
            break
        default:
            break
    }

    const contentHtml = (
        <>
            {loading ? <Spinner /> : <img src={icon} alt={functionality} />}
            <span>{text}</span>
        </>
    )

    const className = `main-button main-button-${functionality.toLowerCase()}`

    const buttonType = ['CREATE'].includes(functionality) ? 'button' : 'submit'

    if (href) {
        return (
            <Link to={href} className={className} disabled={disabled === true}>
                {contentHtml}
            </Link>
        )
    }

    return (
        <button
            onClick={onClick || undefined}
            href={href || undefined}
            className={className}
            disabled={disabled === true}
            type={buttonType}
        >
            {contentHtml}
        </button>
    )
}

export default MainButton
