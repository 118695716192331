import { forwardRef, useRef } from 'react'
import BlockStack from '../BlockStack'
import InlineStack from '../InlineStack'
import InlineStackItem from '../InlineStackItem'
import Popover from '../Popover'
import Button from '../Button'
import TextInput from './TextInput'
import { ICONS } from '../../../common/constants'

const typeLabels = {
    main: 'Main',
    business: 'Work',
    other: 'Other',
}

const InputItem = forwardRef(function InputItemInner(
    {
        item,
        items,
        setItem,
        onAddMore,
        addMoreDisabled,
        removeItem,
        icon,
        type,
        typeLabels,
        requiredType,
        uniqueType,
        ...rest
    },
    ref,
) {
    const popoverRef = useRef(null)
    const { value, type: itemType } = item

    const hasTypeLabels = !!Object.keys(typeLabels).length

    return (
        <InlineStack itemsStretch gap="tiny">
            <InlineStackItem grow1 shrink0>
                <div className="input-container">
                    {hasTypeLabels && (
                        <Popover
                            ref={popoverRef}
                            closeOnChildClick
                            renderActivator={(ref, listeners) => (
                                <div ref={ref}>
                                    <Button
                                        {...listeners}
                                        tiny
                                        text={
                                            <InlineStack>
                                                <span>
                                                    {typeLabels[itemType]}
                                                </span>
                                                <div
                                                    style={{
                                                        transform:
                                                            'rotate(90deg)',
                                                    }}
                                                >
                                                    <img
                                                        width={16}
                                                        height={16}
                                                        alt="toggle"
                                                        src={
                                                            ICONS.CHEVRON_RIGHT_WHITE
                                                        }
                                                    />
                                                </div>
                                            </InlineStack>
                                        }
                                    />
                                </div>
                            )}
                        >
                            {Object.keys(typeLabels)
                                .filter(
                                    (type) =>
                                        !uniqueType ||
                                        !items.some((i) => i.type === type),
                                )
                                .map((type) => (
                                    <div
                                        role="button"
                                        key={type}
                                        onClick={() =>
                                            setItem({ ...item, type })
                                        }
                                    >
                                        <div>{typeLabels[type]}</div>
                                    </div>
                                ))}
                        </Popover>
                    )}
                    <div ref={ref}>
                        {!!icon && (
                            <img
                                className="icon"
                                width={16}
                                height={16}
                                src={icon}
                            />
                        )}

                        <TextInput
                            withClear
                            noMargin
                            value={value}
                            onChange={(v) => setItem({ ...item, value: v })}
                            type={type}
                            {...rest}
                        />
                    </div>
                </div>
            </InlineStackItem>
            {!!onAddMore && (
                <Button
                    disabled={addMoreDisabled}
                    iconTop
                    text={'Add'}
                    icon={ICONS.PLUS_WHITE}
                    plain
                    tiny
                    onClick={onAddMore}
                />
            )}
        </InlineStack>
    )
})

export default function MultipleInputs({
    value = [],
    onChange,
    icon,
    inputType,
    typeLabels = [],
    requiredType,
    uniqueType,
    ...rest
}) {
    const inputRefs = useRef(null)

    const hasTypeLabels = !!Object.keys(typeLabels).length

    const items = value
    if (!items.length) {
        items.push({
            value: '',
            ...(hasTypeLabels
                ? { type: requiredType || Object.keys(typeLabels)[0] }
                : {}),
        })
    }

    function getInputsMap() {
        if (!inputRefs.current) {
            inputRefs.current = new Map()
        }
        return inputRefs.current
    }

    function handleAddMore() {
        const newItems = [...items]

        const nonUniqueType = uniqueType
            ? Object.keys(typeLabels)
                  .reverse()
                  .find((t) => uniqueType !== t)
            : Object.keys(typeLabels).reverse()[0]

        newItems.push({
            value: '',
            ...(hasTypeLabels ? { type: nonUniqueType } : {}),
        })
        onChange(newItems)

        setTimeout(function () {
            const map = getInputsMap()
            const lastKey = Array.from(map.keys()).pop()
            const lastInputContainer = map.get(lastKey)
            if (lastInputContainer) {
                lastInputContainer.querySelector('input').focus()
            }
        }, 0)
    }

    return (
        <div className="multiple-inputs">
            <BlockStack gap={'sm'}>
                {value.map((item, i) => (
                    <InputItem
                        key={i}
                        icon={icon}
                        type={inputType}
                        typeLabels={typeLabels}
                        item={item}
                        items={items}
                        setItem={(newItem) => {
                            const newItems = [...items]
                            newItems[i] = newItem
                            onChange(newItems)
                        }}
                        removeItem={() => {
                            const newItems = [...items]
                            newItems.splice(i, 1)
                            onChange(newItems)
                        }}
                        addMoreDisabled={items.some((item) => !item.value)}
                        onAddMore={
                            i === items.length - 1
                                ? () => handleAddMore(i)
                                : null
                        }
                        ref={(node) => {
                            const map = getInputsMap()
                            if (node) {
                                map.set(i, node)
                            } else {
                                map.delete(i)
                            }
                        }}
                        requiredType={requiredType}
                        uniqueType={uniqueType}
                        {...rest}
                    />
                ))}
            </BlockStack>
        </div>
    )
}
