import useData from '../../common/hooks/useData'
import HorizontalScroll from '../../components/common/HorizontalScroll'
import Spinner from '../../components/common/Spinner'
import ContactThumbnail from '../admin/clients/ContactThumbnail'

export default function FavoriteContacts() {
    const { favorites, favoritesError, favoritesLoading } = useData(
        `/v1/users/favorites`,
        'favorites',
    )

    return (
        <div className="favorite-contacts">
            {favorites?.length > 0 && <h2 className="h6 m-0">Favorites</h2>}
            <HorizontalScroll gap="sm" flushMargins>
                {favoritesLoading ? <Spinner /> : null}
                {favorites?.map((favorite) => (
                    <ContactThumbnail
                        data={favorite}
                        isContact={favorite.resource === 'Lead'}
                        isOrg={favorite.resource === 'Organization'}
                        key={favorite.id}
                    />
                ))}
            </HorizontalScroll>
        </div>
    )
}
