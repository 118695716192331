import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { getFacebookLoginRequestUri } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const FacebookLoginCallback = () => {
    const [searchParams] = useSearchParams()
    const errorParam = searchParams.get('error')
    const codeParam = searchParams.get('code')
    const stateParam = searchParams.get('state')
    const mutate = useMatchMutate()

    const [error, setError] = useState('')
    const navigate = useNavigate()
    const setToast = useToast()

    const auth = useAuth()

    useEffect(() => {
        if (errorParam) {
            setError(
                'There was an error. Please try again and if the problem persists, contact Support.',
            )
            return
        }

        const [userId, existingId] = stateParam.split('-')
        if (String(auth.user.id) !== userId) {
            setError('Your login could not be verified. Please try again.')
            return
        }

        postCode()

        async function postCode() {
            const { error } = await fetchAPI(
                `/v1/connected-accounts/facebook-code`,
                {
                    code: codeParam,
                    userId: auth.user.id,
                    requestUri: getFacebookLoginRequestUri(auth.user.id),
                    connectedAccountId: existingId || undefined,
                },
                existingId ? 'PATCH' : 'POST',
            )

            if (error) {
                setError(error)
                return
            }

            mutate(/\/v1\/connected-accounts/)
            mutate(/\/v1\/social-posts\/plans/)
            navigate('/content#connect__')
            setToast('Facebook connected successfully')
        }
    }, [])

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    return <Spinner />
}

export default FacebookLoginCallback
