function NotFound() {
    return (
        <div className="not-found-page">
            <span>404</span>
            <h1>Not Found</h1>
        </div>
    )
}

export default NotFound
