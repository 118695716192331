import React, { useEffect, useState } from 'react'
import useError from '../../../common/hooks/useError'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'

export default function ContactFormPreview({ data }) {
    const [result, setResult] = useState(null)
    const [error, setError] = useError(null)
    const [loading, setLoading] = useState(false)

    useEffect(function () {
        async function fetchData() {
            const body = { ...data }
            delete body.whitelistedDomains
            delete body.title
            for (const field of data.fields) {
                if (
                    field.fieldType === 'select' &&
                    typeof field.options === 'string'
                ) {
                    field.options = field.options.split(',')
                }
            }

            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/contact-forms/preview`,
                body,
                'POST',
            )
            setLoading(false)

            if (error) {
                setError(error)
                return
            }

            setResult(responseData.result)
        }
        fetchData()
    }, [])

    if (error) {
        return <ErrorMessage section>{error}</ErrorMessage>
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: result,
            }}
        ></div>
    )
}
