import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useStore } from '../../../common/Context'
import fetchAPI from '../../../common/fetchAPI'
import { setToast } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import Button from '../../../components/common/Button'

import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreatePrice() {
    const { serviceId } = useParams()
    const initialData = {
        pricingModel: 'recurring',
        recurringPeriod: 'week',
    }
    const [loading, setLoading] = useState(true)
    const [error, setError] = useError()
    const [data, setData] = useState(initialData)
    const [service, setService] = useState({})
    const [_state, dispatch] = useStore()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/services/${serviceId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)
            setService(responseData)
        }

        getData()
    }, [])

    const onSubmit = async (e) => {
        if (
            !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(data.amount)
        ) {
            setError('Invalid amount')
            return e.preventDefault()
        }
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/services/${serviceId}`,
                        {
                            prices: [
                                {
                                    pricingModel: data.pricingModel,
                                    recurringPeriod: data.recurringPeriod,
                                    amount: String(data.amount * 100),
                                },
                            ],
                        },
                        'PATCH',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast(dispatch, 'Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to create this price?',
        )
    }

    if (loading) return <Spinner />

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <h2>New Price for {service.title}</h2>
            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-pricing">Pricing</label>
                    <SelectInput
                        name="pricing"
                        id="form-pricing"
                        value={data.pricingModel}
                        onChange={(v) =>
                            setData({
                                ...data,
                                pricingModel: v,
                            })
                        }
                        options={[{ value: 'recurring', label: 'Recurring' }]}
                    />

                    <label htmlFor="form-period">Period</label>
                    <SelectInput
                        name="period"
                        id="form-period"
                        value={data.recurringPeriod}
                        onChange={(v) =>
                            setData({
                                ...data,
                                recurringPeriod: v,
                            })
                        }
                        options={[
                            { value: 'year', label: 'Year' },
                            { value: 'month', label: 'Month' },
                            { value: 'week', label: 'Week' },
                        ]}
                    />

                    <label htmlFor="form-amount" className="required">
                        Amount ($)
                    </label>
                    <TextInput
                        type="number"
                        value={data.amount}
                        id="form-amount"
                        name="amount"
                        required
                        onChange={(v) =>
                            setData({
                                ...data,
                                amount: v,
                            })
                        }
                    />
                </div>
                <div>
                    <Button text="Create" type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreatePrice
