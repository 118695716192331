import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import Break from '../../components/common/Break'
import InfoList from '../../components/common/InfoList'
import MessageSection from '../../components/common/MessageSection'

export default function BillingMethodInfo({ billingMethods }) {
    const auth = useAuth()

    if (!billingMethods?.length) {
        return 'No billing methods (e.g. PayPal) found.'
    }

    return (
        <>
            {auth.isAdmin && (
                <>
                    <MessageSection
                        fullWidth
                        small
                        type="info"
                        title="Only users viewing their profile, admins and assistants see this."
                    ></MessageSection>
                    <Break />
                </>
            )}

            <InfoList
                rows={billingMethods.map((item) => ({
                    content: `${item.displayName} ${item.email}`,
                    icon: ICONS.PAYPAL_WHITE,
                }))}
            />
        </>
    )
}
