import React from 'react'
import { useStore } from '../../common/Context'
import useModal from '../../common/hooks/useModal'
import Button from './Button'

function TextModalContent(props) {
    const [state] = useStore()
    const { setModal } = useModal()

    const onCancel = () => {
        setModal(null)
    }

    return (
        <>
            <div className="modal-body">{props.content}</div>
            <div className="modal-buttons">
                <Button
                    small
                    onClick={onCancel}
                    isLoading={state.isModalLoading}
                    text={'OK'}
                />
            </div>
        </>
    )
}

export default TextModalContent
