// .company ready
// .acronym ok

import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateExpense() {
    const [searchParams] = useSearchParams()
    const userId = searchParams.get('userId')
    const reason = searchParams.get('reason')

    const { orgs, orgsError, orgsLoading } = useData(
        userId
            ? `/v1/organizations?memberUserId=${userId}&fields=name,acronym&populate=vendorAccount`
            : null,
        'orgs',
    )

    const vendorOrg = orgs?.results?.find((org) => org.vendorAccount)

    let initialTitle = ''
    if (reason === 'vendorManualPayout' && userId && vendorOrg) {
        initialTitle = `Manual Payout to Vendor ${vendorOrg?.acronym}`
    }

    const initialData = {
        teamMemberId: '',
        expenseModel: 'fixed',
        title: initialTitle,
        reason: reason || '',
        userId: userId || '',
    }
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState(initialData)
    const [teamMembers, setTeamMembers] = useState([])
    const navigate = useNavigate()
    const setToast = useToast()
    const mutate = useMatchMutate()

    useEffect(() => {
        const getData = async () => {
            const { responseData, error } = await fetchAPI(
                `/v1/users?role=staff&fields=firstName,lastName`,
                {},
                'GET',
            )
            if (error) {
                setError(error)
                return
            }
            setTeamMembers(responseData.results)
        }

        getData()
    }, [])

    const onSubmit = async (e) => {
        if (
            !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(data.amount)
        ) {
            setError('Invalid amount')
            return e.preventDefault()
        }
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/expenses`,
            {
                title:
                    reason === 'vendorManualPayout'
                        ? `Manual Payout to Vendor ${vendorOrg?.acronym}`
                        : data.title,
                creationDate: data.created || null,
                amount: String(Math.round(data.amount * 100)),
                teamMember: data.teamMemberId || null,
                expenseModel: data.expenseModel,
                reason: data.reason,
                userId: data.userId,
                accountId: vendorOrg?.vendorAccount?.id,
            },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Expense created')
        navigate(
            reason === 'vendorManualPayout'
                ? `/shop/db-vendors/${userId}`
                : '/billing#expenses',
        )
        mutate(/\/v1\/expenses/)
        mutate(/\/v1\/paymeny\/balance/)
    }

    if (orgsLoading) return <Spinner />

    if (orgsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{orgsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (reason === 'vendorManualPayout' && !vendorOrg) {
        return (
            <SectionContainer>
                <ErrorMessage>No vendor account found</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-title">Title</label>
                    <TextInput
                        name="title"
                        id="form-title"
                        type="text"
                        value={
                            reason === 'vendorManualPayout'
                                ? `Manual Payout to Vendor ${vendorOrg?.acronym}`
                                : data.title
                        }
                        disabled={reason === 'vendorManualPayout'}
                        onChange={(v) =>
                            setData({
                                ...data,
                                title: v,
                            })
                        }
                    />

                    <label htmlFor="form-amount">Amount</label>
                    <TextInput
                        name="amount"
                        id="form-amount"
                        type="number"
                        value={data.amount}
                        onChange={(v) =>
                            setData({
                                ...data,
                                amount: v,
                            })
                        }
                    />

                    <label htmlFor="form-created" className="required">
                        Created
                    </label>
                    <TextInput
                        name="created"
                        id="form-created"
                        type="date"
                        value={data.created}
                        required
                        onChange={(v) =>
                            setData({
                                ...data,
                                created: v,
                            })
                        }
                    />

                    {reason !== 'vendorManualPayout' && (
                        <>
                            <label htmlFor="form-team-member">
                                Team member
                            </label>
                            <SelectInput
                                name="team-member"
                                id="form-team-member"
                                value={data.teamMemberId}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        teamMemberId: v,
                                    })
                                }
                                options={[
                                    { value: '', label: 'None' },
                                    ...teamMembers.map((member) => ({
                                        value: member.id,
                                        label: `${member.firstName} ${member.lastName}`,
                                    })),
                                ]}
                            />
                        </>
                    )}

                    {data.teamMemberId ? (
                        <>
                            <label htmlFor="form-model">Expense type</label>
                            <SelectInput
                                name="model"
                                id="form-model"
                                value={data.expenseModel}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        expenseModel: v,
                                    })
                                }
                                options={[
                                    { value: 'fixed', label: 'Fixed' },
                                    {
                                        value: 'recurring',
                                        label: 'Recurring (salary)',
                                    },
                                ]}
                            />
                        </>
                    ) : null}
                </div>

                <div>
                    <Button text="Save" type="submit" isLoading={loading} />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreateExpense
