import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import InputModalContent from '../../../components/common/InputModalContent'
import fetchAPI from '../../../common/fetchAPI'

export default function LocationInventoryItemsList() {
    const { shopifyShopId, locationId } = useParams()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    async function onEditQuantity(id) {
        setModal(
            <InputModalContent
                label="Quantity"
                confirmText="Save"
                onConfirm={async ({ text }) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/shop/inventory-items/${id}`
                    const { error } = await fetchAPI(
                        url,
                        {
                            available: text,
                            reportedAvailable: text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                    matchMutate(/\/v1\/shop\/shops/)
                    matchMutate(/\/v1\/shop\/inventory-items/)
                    matchMutate(/\/v1\/shop\/inventory-item/)
                }}
            />,
            'Quantity',
        )
    }

    return (
        <>
            <ResourceList
                onItemClick={(item) => onEditQuantity(item.id)}
                apiUrl={
                    shopifyShopId
                        ? `/shop/shops/${shopifyShopId}/locations/${locationId}/inventory-items`
                        : `/shop/locations/${locationId}/inventory-items`
                }
                getItemsFromResponse={(data) => data?.results}
                paginated={true}
                paginationLimit={10}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `${item.product.title} - ${item.productVariant?.title}`,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) =>
                            `Quantity: ${
                                item.available ?? '-'
                            } / Owner reported: ${
                                item.reportedAvailable ?? '-'
                            }`,
                        getClassName: () => 'subtitle',
                    },
                ]}
            />
        </>
    )
}
