import { formatMoney } from '../../../common/helpers'
import { useState } from 'react'
import useModal from '../../../common/hooks/useModal'
import FormModalContent from '../../../components/common/FormModalContent'
import useToast from '../../../common/hooks/useToast'
import HorizontalScroll from '../../../components/common/HorizontalScroll'
import InlineStack from '../../../components/common/InlineStack'
import Break from '../../../components/common/Break'

function FinancialBillingList(props) {
    const { billing } = props
    const [type, setType] = useState('all')
    const [grouping, setGrouping] = useState('all')
    const [vendor, setVendor] = useState('all')
    const [month, setMonth] = useState('all')
    const [product, setProduct] = useState('all')
    const [order, setOrder] = useState('all')
    const { setModal } = useModal()
    const setToast = useToast()

    const groupingMap = {
        vendor: 'vendorData',
        product: 'productData',
        order: 'tempOrderData',
    }

    const stateMap = {
        product,
        vendor,
        order,
    }

    const months = Object.entries(billing.calendarData)
        .map(([year, months]) =>
            Object.keys(months).map((month) => `${month}/${year}`),
        )
        .flat()
        .reverse()

    let totalAmounts
    if (
        grouping === 'all' ||
        (grouping === 'vendor' && vendor === 'all') ||
        (grouping === 'month' && month === 'all') ||
        (grouping === 'product' && product === 'all') ||
        (grouping === 'order' && order === 'all')
    ) {
        totalAmounts = Object.values(billing.productData).reduce((a, b) => {
            const result = { ...a }
            for (const key of Object.keys(b)) {
                if (!a[key]) a[key] = 0
                result[key] = a[key] + b[key]
            }
            return result
        }, {})
    } else if (['product', 'order', 'vendor'].includes(grouping)) {
        totalAmounts = billing[groupingMap[grouping]][stateMap[grouping]]
    } else if (grouping === 'month') {
        const selectedMonth = month.split('/')[0]
        const selectedYear = month.split('/')[1]
        totalAmounts = billing.calendarData[selectedYear][selectedMonth]
    }

    const onFiltersModalOpen = () => {
        setModal(
            <FormModalContent
                fields={[
                    {
                        name: 'month',
                        label: 'Month',
                        type: 'select',
                        default: month,
                        options: [
                            { value: 'all', label: 'All' },
                            ...months.map((month) => ({
                                value: month,
                                label: month,
                            })),
                        ],
                    },
                    {
                        name: 'vendor',
                        label: 'Vendor',
                        type: 'select',
                        default: vendor,
                        options: [
                            { value: 'all', label: 'All' },
                            ...Object.keys(billing.vendorData).map(
                                (vendor) => ({
                                    value: vendor,
                                    label: vendor,
                                }),
                            ),
                        ],
                    },
                    {
                        name: 'product',
                        label: 'Product',
                        type: 'select',
                        default: product,
                        options: [
                            { value: 'all', label: 'All' },
                            ...Object.entries(billing.productData).map(
                                ([product, data]) => ({
                                    value: product,
                                    label: data.extras.name,
                                }),
                            ),
                        ],
                    },
                    {
                        name: 'order',
                        label: 'Order',
                        type: 'select',
                        default: order,
                        options: [
                            { value: 'all', label: 'All' },
                            ...Object.keys(billing.tempOrderData)
                                .reverse()
                                .map((order) => ({
                                    value: order,
                                    label: order,
                                })),
                        ],
                    },
                ]}
                onConfirm={(data) => {
                    if (
                        Object.values(data).filter((item) => item !== 'all')
                            .length > 1
                    ) {
                        setToast(
                            'You can only filter by one field at a time',
                            'alert',
                        )
                        return
                    }
                    setMonth('all')
                    setProduct('all')
                    setVendor('all')
                    setOrder('all')
                    if (data.month !== 'all') {
                        setGrouping('month')
                        setMonth(data.month)
                    } else if (data.vendor !== 'all') {
                        setGrouping('vendor')
                        setVendor(data.vendor)
                    } else if (data.product !== 'all') {
                        setGrouping('product')
                        setProduct(data.product)
                    } else if (data.order !== 'all') {
                        setGrouping('order')
                        setOrder(data.order)
                    }
                    setModal(null)
                }}
            />,
            'Filters',
        )
    }

    return (
        <div className="financial-billing-list">
            <InlineStack gap={'sm'} spaceBetween>
                <InlineStack gap={'sm'}>
                    {['all', 'finalized'].map((item) => (
                        <button
                            key={item}
                            onClick={() => setType(item)}
                            className={type === item ? 'active' : ''}
                        >
                            {item}
                        </button>
                    ))}
                </InlineStack>

                <button className="filter-toggle" onClick={onFiltersModalOpen}>
                    <img
                        src={`/img/icons/filter-white.svg`}
                        alt="filter"
                        width="24"
                        height="24"
                    />
                </button>
            </InlineStack>

            <Break />
            <div className="financial-data">
                <div>
                    <h3>Cost</h3>
                    <small>Cost that is input in Shopify per product</small>
                    <p>{formatMoney(totalAmounts.cost, false, 'shopify')}</p>
                </div>
                <div>
                    <h3>Vendor Price</h3>
                    <small>
                        Wholesale price - equals cost, or price if no cost is
                        set
                    </small>
                    <p>
                        {formatMoney(
                            totalAmounts.adjustedCost,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>
                <div>
                    <h3>Product Price</h3>
                    <small>Gross (Shopify)</small>
                    <p>{formatMoney(totalAmounts.price, false, 'shopify')}</p>
                </div>

                <hr />

                <div>
                    <h3>Markup</h3>
                    <small>
                        aCo Markup Commission (Product - Vendor price)
                    </small>
                    <p>{formatMoney(totalAmounts.markup, false, 'shopify')}</p>
                </div>
                <br />
                <br />

                <div>
                    <h3>Discount</h3>
                    <p>
                        {formatMoney(totalAmounts.discount, false, 'shopify')}
                    </p>
                </div>
                <div>
                    <h3>Returns</h3>
                    <p>{formatMoney(totalAmounts.returns, false, 'shopify')}</p>
                </div>

                <div>
                    <h3>Chargebacks</h3>
                    <small>
                        For currently selected orders and do not include fees
                    </small>
                    <p>
                        {formatMoney(totalAmounts.chargeback, false, 'shopify')}
                    </p>
                </div>
                <hr />

                <div>
                    <h3>Net</h3>
                    <small>Product price - Returns - Discount</small>
                    <p>
                        {formatMoney(
                            totalAmounts.price -
                                totalAmounts.returns -
                                totalAmounts.discount,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>
                <div>
                    <h3>Vendor Net</h3>
                    <small>
                        Wholesale net, Vendor price - Returns (if discounted
                        price &lt; cost, also - Discount)
                    </small>
                    <p>
                        {formatMoney(totalAmounts.vendorNet, false, 'shopify')}
                    </p>
                </div>
                <br />
                <br />

                <div>
                    <h3>Net Commission</h3>
                    <small>
                        aCo Net Commission (orders that are chargebacks are
                        excluded)
                    </small>
                    <p>
                        {formatMoney(totalAmounts.commission, false, 'shopify')}
                    </p>
                </div>

                {/* <div>
                    <h3
                    >Chargeback Commission</h3>
                    <p>- Not implemented -</p>
                </div> */}

                <div>
                    <h3>Total Commission</h3>
                    <small>Markup + Net commission</small>
                    <p>
                        {formatMoney(
                            totalAmounts.commission + totalAmounts.markup,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>
                <div>
                    <h3>Total Commission (Vendor)</h3>
                    <small>Net comission</small>
                    <p>
                        {formatMoney(totalAmounts.commission, false, 'shopify')}
                    </p>
                </div>

                <br />
                <br />

                <div>
                    <h3>Transaction fees</h3>
                    <p>
                        {formatMoney(
                            totalAmounts.transaction,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>

                <hr />

                <br />
                <br />

                <div>
                    <h3>Tax</h3>
                    <p>{formatMoney(totalAmounts.taxes, false, 'shopify')}</p>
                </div>

                <div>
                    <h3>Shipping</h3>
                    <p>
                        {formatMoney(totalAmounts.shipping, false, 'shopify')}
                    </p>
                </div>

                <hr />

                <div>
                    <h3>Gross sales</h3>
                    <small>Price + tax + shipping</small>
                    <p>
                        {formatMoney(
                            totalAmounts.price +
                                totalAmounts.taxes +
                                totalAmounts.shipping,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>
                <div>
                    <h3>Gross sales (Vendor)</h3>
                    <small>Vendor price + tax + shipping</small>
                    <p>
                        {formatMoney(
                            totalAmounts.adjustedCost +
                                totalAmounts.taxes +
                                totalAmounts.shipping,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>
                <div>
                    <h3>Total sales</h3>
                    <small>Net + tax + shipping</small>
                    <p>
                        {formatMoney(
                            totalAmounts.price -
                                totalAmounts.discount -
                                totalAmounts.returns +
                                totalAmounts.taxes +
                                totalAmounts.shipping,
                            false,
                            'shopify',
                        )}
                    </p>
                </div>

                {/* <div>
                    <h3>Payout (deprecated)</h3>
                    <small>
                        Vendor net - net commission - transaction fees
                    </small>
                    <p>
                        {formatMoney(
                            totalAmounts.vendorNet -
                                totalAmounts.commission -
                                totalAmounts.transaction,
                            false,
                            'shopify',
                        )}
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default FinancialBillingList
