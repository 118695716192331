import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'

export default function ContactThumbnail({ data, isContact, isOrg }) {
    let url
    if (isOrg) {
        url = `/organizations/${data.id}`
    } else {
        url = `/${isContact ? 'contacts' : 'users'}/${data.id}`
    }

    return (
        <Link to={url} className="contact-thumbnail">
            {isOrg ? (
                <div className={`photo`}>
                    {/* <img
                        className="opacity-25"
                        src={ICONS.FOLDER_GRAY}
                        alt="org"
                        width={12}
                        height={12}
                    /> */}
                    <span>{data?.acronym?.slice(0, 3)}</span>
                </div>
            ) : (
                <div
                    className={`photo ${data.isPremiumUser ? 'photo-premium' : ''}${
                        isContact ? ' photo-lead' : ''
                    }`}
                >
                    {data.firstName ? (
                        <span>{data.firstName?.[0]?.toUpperCase()}</span>
                    ) : null}
                    {data.lastName ? (
                        <span>{data.lastName?.[0]?.toUpperCase()}</span>
                    ) : null}
                    {!data.firstName && !data.lastName && (
                        <span>{data.email?.[0]?.toUpperCase()}</span>
                    )}
                </div>
            )}
        </Link>
    )
}
