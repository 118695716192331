import React from 'react'
import fetchAPI from './fetchAPI'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        fetchAPI(
            '/v1/logs',
            {
                error: { message: error.message },
                info,
                url: window.location.href,
            },
            'POST',
        ).catch(() => {
            console.log('Error logging error')
        })
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children
    }
}

export default ErrorBoundary
