import { useParams } from 'react-router-dom'
import { useRef } from 'react'
import useError from '../../../common/hooks/useError'
import Button from '../../../components/common/Button'
import useData from '../../../common/hooks/useData'
import SignatureCanvas from 'react-signature-canvas'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

function BuyerPage() {
    const { dbOrderId } = useParams()
    const [error, setError] = useError()
    const signatureRef = useRef(undefined)

    const { dbOrder, dbOrderError, dbOrderLoading } = useData(
        `/v1/wholesale/db-orders/${dbOrderId}`,
        'dbOrder',
    )

    const loading = dbOrderLoading

    const onClearSignature = (e) => {
        e.preventDefault()
        setError('')
        signatureRef?.current?.clear()
    }

    if (loading) return <Spinner marginTop />
    if (dbOrderError) {
        return (
            <SectionContainer>
                <ErrorMessage>{dbOrderError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </SectionContainer>
            )}

            <SectionContainer>
                <header className="info-header">
                    <h2>Buyer page</h2>
                </header>
            </SectionContainer>
            <SectionContainer className="info-details">
                <div>Info</div>
                <div>{dbOrder.id}</div>

                {dbOrder.shippingAddress ? (
                    <div>
                        <p>
                            <strong>First name&nbsp;</strong>
                            {dbOrder.shippingAddress.firstName}
                        </p>
                        <p>
                            <strong>Last name&nbsp;</strong>
                            {dbOrder.shippingAddress.lastName}
                        </p>
                        <p>
                            <strong>Address 1&nbsp;</strong>
                            {dbOrder.shippingAddress.address1}
                        </p>
                        <p>
                            <strong>City&nbsp;</strong>
                            {dbOrder.shippingAddress.city}
                        </p>
                        <p>
                            <strong>Province&nbsp;</strong>
                            {dbOrder.shippingAddress.province}
                        </p>
                        <p>
                            <strong>Country&nbsp;</strong>
                            {dbOrder.shippingAddress.country}
                        </p>
                        <p>
                            <strong>Zip&nbsp;</strong>
                            {dbOrder.shippingAddress.zip}
                        </p>
                        <p>
                            <strong>Address 2&nbsp;</strong>
                            {dbOrder.shippingAddress.address2}
                        </p>
                        <p>
                            <strong>Phone&nbsp;</strong>
                            {dbOrder.shippingAddress.phone}
                        </p>
                    </div>
                ) : null}
            </SectionContainer>
            <SectionContainer>
                <div className="sign-container">
                    <SignatureCanvas
                        ref={signatureRef}
                        penColor="black"
                        canvasProps={{
                            className: 'sign-canvas',
                            width: 300,
                            height: 130,
                        }}
                    />
                </div>
                <Button
                    text="Clear signature"
                    outline
                    destructive
                    onClick={onClearSignature}
                />
            </SectionContainer>
        </>
    )
}

export default BuyerPage
