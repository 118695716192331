import { useParams } from 'react-router-dom'
import useAuth from '../../../common/hooks/useAuth'
import ResourceList from '../../../components/admin/ResourceList'
import OrgCard from '../../contacts/OrgCard'
import MessageSection from '../../../components/common/MessageSection'
import InlineStack from '../../../components/common/InlineStack'
import Button from '../../../components/common/Button'
import { useState } from 'react'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'
import useMatchMutate from '../../../common/hooks/useMatchMutate'

function PendingInviteMessages({ listKey, setListKey }) {
    const [loading, setLoading] = useState(false)
    const setToast = useToast()
    const auth = useAuth()
    const matchMutate = useMatchMutate()

    async function acceptInvite(id) {
        setLoading(true)
        const url = `/v1/organizations/invites/${id}/accept`
        const { error } = await fetchAPI(url, {}, 'POST')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite accepted')
        setListKey(Date.now())
        matchMutate(/\/v1\/organizations\/invites*/)
    }

    async function declineInvite(id) {
        setLoading(true)
        const url = `/v1/organizations/invites/${id}`
        const { error } = await fetchAPI(url, {}, 'DELETE')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite declined')
        setListKey(Date.now())
        matchMutate(/\/v1\/organizations\/invites*/)
    }

    return (
        <ResourceList
            key={listKey}
            emptyText={''}
            minimal
            apiUrl={'/organizations/invites'}
            initialQuery={{ invitedId: auth.user.id }}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <MessageSection
                            small
                            type="info"
                            title={`You were invited to join ${item.org?.name}`}
                        >
                            <InlineStack justifyEnd gap={'sm'}>
                                <Button
                                    outline
                                    text="Decline"
                                    white
                                    small
                                    isLoading={loading}
                                    onClick={() => declineInvite(item.id)}
                                />
                                <Button
                                    text="Accept"
                                    small
                                    isLoading={loading}
                                    onClick={() => acceptInvite(item.id)}
                                />
                            </InlineStack>
                        </MessageSection>
                    ),
                },
            ]}
        />
    )
}

export default function ProfileCompaniesList() {
    const { userId } = useParams()
    const auth = useAuth()
    const [listKey, setListKey] = useState(Date.now())

    return (
        <>
            <PendingInviteMessages listKey={listKey} setListKey={setListKey} />
            <ResourceList
                key={listKey}
                emptyText={'No companies found'}
                apiUrl={'/organizations'}
                initialQuery={{
                    fields: 'name,organizationType',
                    memberUserId: userId || auth.user.id,
                    organizationType: 'company',
                }}
                getItemsFromResponse={(data) => data?.results}
                newItemPath={
                    auth.isAdmin
                        ? `/organizations/new?organizationType=company&userId=${userId}`
                        : undefined
                }
                renderMainButton={auth.isAdmin}
                paginated
                itemClickPath={`/profile/companies/:item.id`}
                paginationLimit={20}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <OrgCard org={item} noLink />,
                    },
                ]}
            />
        </>
    )
}
