import { useParams } from 'react-router-dom'
import {
    comparePrimitiveArrays,
    comparePrimitiveObjects,
} from '../../../common/helpers'
import DataFormNew from '../../../components/common/DataFormNew'
import { useUploads } from '../../../common/UploadContext'
import { API_URL } from '../../../common/constants'
import useToast from '../../../common/hooks/useToast'
import { useEffect, useState } from 'react'

function getExtraHasChanged(data, item) {
    return (
        data.attachmentFiles?.length > 0 ||
        !comparePrimitiveObjects(
            data?.requirements || {},
            item?.submissionRequirements || {},
        )
    )
}

function EditTaskAttachments() {
    const { taskId } = useParams()
    const { progress, uploadFiles } = useUploads()
    const setToast = useToast()
    const [key, setKey] = useState(Date.now())

    useEffect(
        function () {
            if (progress === 0) {
                setKey(Date.now())
            }
        },
        [progress],
    )

    const inputs = [
        {
            shouldHide: (data) => !data.attachmentFileIds?.length,
            key: 'attachmentFileIds',
            type: 'driveFiles',
            label: 'Remove attachments',
            id: 'form-attachment-file-ids',
        },
        {
            key: 'attachmentFiles',
            type: 'file',
            label: 'Upload attachments',
            id: 'form-attachment-browser',
            multiple: true,
            infoBottom: 'Add attachments from device.',
            small: true,
        },
    ]

    function mapItemToData(item) {
        const newData = {
            attachmentFileIds: item.attachmentFileIds,
        }
        return newData
    }

    const getBody = async (data, item) => {
        const MAX_FILE_SIZE = 50 * 1024 * 1024

        for (const file of data.attachmentFiles || []) {
            if (file.size > MAX_FILE_SIZE) {
                throw new Error(`Max file size is 50MB.`)
            }
        }

        const body = {
            attachmentFileIds: !comparePrimitiveArrays(
                item.attachmentFileIds,
                data.attachmentFileIds,
            )
                ? data.attachmentFileIds
                : undefined,
        }

        const formData = new FormData()
        const bodyData = { ...body }

        formData.append('data', JSON.stringify(bodyData))

        if (data.attachmentFiles?.length) {
            const { error } = uploadFiles(
                data.attachmentFiles,
                `${API_URL}/v1/tasks/${item.id}/attachments`,
            )
            if (error) {
                setToast(error, 'alert')
            } else {
                setToast('Files upload in progress, it may take a few minutes.')
            }
        }

        return formData
    }

    return (
        <DataFormNew
            key={key}
            url={`/v1/tasks/${taskId}`}
            method="PATCH"
            submitText="Save"
            submitToast="Saved"
            submitNavArg={`/tasks/${taskId}`}
            inputs={inputs}
            getBody={getBody}
            mapItemToData={mapItemToData}
            mainButton
            mutationRegexes={[/\/v1\/tasks/]}
            getExtraHasChanged={getExtraHasChanged}
            hasHistory
            onSuccess={() => {
                setKey(Date.now())
            }}
        />
    )
}

export default EditTaskAttachments
