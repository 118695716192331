import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'

function EditPdfContract() {
    const navigate = useNavigate()
    const setToast = useToast()

    const { fileName } = useParams()
    const [data, setData] = useState({ addendum: '' })
    const [saveLoading, setSaveLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)

    const { contractError, contractLoading } = useData(
        `/v1/files/pdf-contracts/${fileName}/data`,
        'contract',
        (data) => data,
        undefined,
        undefined,
        (data) =>
            setData({
                addendum: data.addendum,
            }),
    )

    const onDownloadPreview = async () => {
        setDownloadLoading(true)
        const { responseData, error, headers } = await fetchAPI(
            `/v1/files/pdf-contracts/${fileName}/preview`,
            {},
            'POST',
        )
        setDownloadLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }
        const blob = await responseData.blob()

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = headers.get('Filename')
        a.click()
        window.URL.revokeObjectURL(url)
    }

    async function onSubmit(e) {
        e.preventDefault()

        setSaveLoading(true)
        const { error } = await fetchAPI(
            `/v1/files/pdf-contracts/${fileName}/data`,
            data,
            'PATCH',
        )
        setSaveLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Contract updated')
        navigate('/pdf-contracts')
    }

    if (contractLoading) return <Spinner marginTop />
    if (contractError) {
        return (
            <SectionContainer>
                <ErrorMessage>{contractError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <h1>{fileName}</h1>
            <form onSubmit={onSubmit}>
                <label htmlFor="form-addendum">Addendum</label>
                <TextInput
                    rows={15}
                    id="form-addendum"
                    onChange={(v) =>
                        setData({
                            ...data,
                            addendum: v,
                        })
                    }
                    value={data.addendum || ''}
                />

                <Button isLoading={saveLoading} type="submit" text="Save" />
            </form>
            <Button
                onClick={onDownloadPreview}
                isLoading={downloadLoading}
                type="button"
                text="Save & Download preview"
                outline
            />
        </SectionContainer>
    )
}

export default EditPdfContract
