import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'name',
        label: 'Custom product name',
        required: true,
        infoBottom: (
            <>
                All orders that include a product with this name and no vendor
                will be attributed to this vendor.
                <br />
                Any line items that already have a vendor will{' '}
                <strong>not</strong> be updated.
            </>
        ),
    },
]

function CreateCustomProduct() {
    const { accountId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                sendQuery={'?accountType=vendor'}
                url={`/v1/accounts/${accountId}?accountType=vendor`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/accounts/vendors/${accountId}/custom-products`}
                inputs={inputs}
                getBody={(data, item) => ({
                    customProductNames: [
                        ...(item?.customProductNames || []),
                        data.name,
                    ],
                })}
                mutationRegexes={[/\/v1\/accounts/]}
                mainButton
            />
        </SectionContainer>
    )
}

export default CreateCustomProduct
