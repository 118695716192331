import TabsOverview from '../../../components/navigation/TabsOverview'
import ShopBlogsList from './ShopBlogsList'
import ShopProductsList from './ShopProductsList'

export default function ShopifyShopPage() {
    const pages = {}

    const defaultTab = 'blogs'
    pages.blogs = <ShopBlogsList />
    // pages.orders = <ShopOrdersList />
    pages.products = <ShopProductsList />

    return <TabsOverview defaultTab={defaultTab} pagesMap={pages} />
}
