import ResourceList from '../../../components/admin/ResourceList'

export default function ReposList() {
    // const auth = useAuth()

    return (
        <ResourceList
            renderMainButton
            itemClickPath={`/repos/:item.name`}
            newItemLabel={'New form'}
            apiUrl={'/repos'}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/repos/new'}
            paginated
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.name,
                    getClassName: () => 'title',
                },
            ]}
        />
    )
}
