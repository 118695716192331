import { useState } from 'react'
import { getQuarter, getWeek } from '../../../common/helpers'
import BillingChart from './BillingChart'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function BillingChartPage() {
    const now = new Date()

    const auth = useAuth()

    const [range, setRange] = useState('month')
    const [activeDate, setActiveDate] = useState({
        year: String(now.getFullYear()),
        quarter: getQuarter(now),
        month: String(now.getMonth()),
        week: getWeek(now),
    })

    const {
        billingData = {
            clients: [],
            expenses: {},
        },
        billingDataError: error,
        billingDataLoading: loading,
    } = useData(
        `/v1/payment/billing-overview`,
        'billingData',
        (data) => data,
        false,
        false,
        () => {},
    )

    if (loading) return <Spinner marginTop />

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            {!auth.isCollections && (
                <BillingChart
                    data={billingData}
                    range={range}
                    setRange={setRange}
                    activeDate={activeDate}
                    setActiveDate={setActiveDate}
                />
            )}
        </SectionContainer>
    )
}
