import Spinner from '../common/Spinner'

function ListLayout(props) {
    return (
        <>
            <div
                className={`list-layout ${
                    props.type === 'files' ? ' file-grid' : ''
                } ${props.loading ? ' list-loading' : ''} ${
                    props.hasWidths ? ' list-has-widths' : ''
                }${props.itemsView ? ` item-view-${props.itemsView}` : ''}`}
            >
                {props.children}
                {!props.loading && !props.children && 'No data'}
                {props.loading && <Spinner />}
            </div>
        </>
    )
}

export default ListLayout
