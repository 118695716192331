import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'

function EditSub() {
    const { userId, subId } = useParams()
    const initialData = {
        service: {},
        price: {},
    }
    const [_loading, setLoading] = useState(false)
    const [_error, setError] = useError()
    const [data, setData] = useState(initialData)
    const setToast = useToast()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/payment/clients/${userId}/subscriptions/manual/${subId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)

            setData({
                onHold: responseData.onHold,
            })
        }

        getData()
    }, [])

    const _onSubmit = async (e) => {
        if (!window.confirm('Are you sure?')) return
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/payment/clients/${userId}/subscriptions/manual/${subId}`,
            {
                onHold: data.onHold ? true : false,
            },
            'PATCH',
        )
        setLoading(false)
        if (error) return setError(error)
        setToast('Done')
    }

    return <p style={{ marginTop: '50px' }}>Under construction</p>
}

export default EditSub
