import useScrollTop from '../../common/hooks/useScrollTop'

function ScrollTopButton() {
    const showScrollTopBtn = useScrollTop()

    function onScrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <button
            className={`scroll-top-button ${showScrollTopBtn ? 'show' : ''}`}
            onClick={onScrollTop}
            title="Scroll to top"
        >
            <img
                src="/img/icons/chevron-left-white.svg"
                alt="to top"
                style={{ transform: 'rotate(90deg)' }}
                width="16"
                height="16"
            />
        </button>
    )
}

export default ScrollTopButton
