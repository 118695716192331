import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'name',
        label: 'Name',
        required: true,
    },
]

export default function CreateShopLocation() {
    const { shopifyShopId } = useParams()
    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/shop/shops/${shopifyShopId}/locations`}
                    submitText="Save"
                    submitToast="Saved"
                    submitNavArg={`/shop/shopify-shops/${shopifyShopId}/locations`}
                    inputs={inputs}
                    mutationRegexes={[/\/v1\/shop\/shops/]}
                    mainButton
                    getBody={(data) => ({
                        name: data.name,
                        shopifyShopId,
                    })}
                />
            </SectionContainer>
        </>
    )
}
